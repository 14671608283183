using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;

namespace Workflow_bridge
{
    class PanelOrdreCreation : Panel
    {
        Button bClose, bNew, bModify, bSave, bCancel;
        MensualView.MensualView view = null;
        Dictionary<string, Control> fields = new Dictionary<string, Control>();
        Combo cboOrdre;
        Label lst = null;

        string[] ostatus = { "", "Ordre nouveau", "Ordre modifié", "", "Ordre Terminé", "", "Indépendant ouvert", "Indépendant fermé", "Fixe en cours", "Fixe terminé" };
        string[] otype = { "Simple", "Passage", "Forfait réf. A", "Forfait réf. B", "", "", "", "", "", "", "Projet ouvert", "", "", "", "Projet fermé" };

        Dictionary<int, string> ofs = new Dictionary<int, string>();
        Dictionary<int, string> cfs = new Dictionary<int, string>();
        Dictionary<int, string> pers = new Dictionary<int, string>();

        Field date1, date2;
        CheckBox cInv;
        ListBox lstP1, lstP2;
        Panel lstEntries;

        //List<PanelValidationQ.Qty> qties = new List<PanelValidationQ.Qty>();
        List<Panel> lstPEntries = new List<Panel>();
        Dictionary<int, string> dicoPers = new Dictionary<int, string>();

        bool createNew = false;

        int fromMode = -1;
        int fromOF = -1;
        int fromOID = -1;
        PanelOFCreation pof = null;

        Color[] validColor = new Color[32];

        public PanelOrdreCreation(MensualView.MensualView view)
        {
            ostatus[1] = Res.Get(11800, ostatus[1]);
            ostatus[2] = Res.Get(11801, ostatus[2]);
            ostatus[4] = Res.Get(11802, ostatus[4]);
            ostatus[6] = Res.Get(11803, ostatus[6]);
            ostatus[7] = Res.Get(11804, ostatus[7]);
            ostatus[8] = Res.Get(11805, ostatus[8]);
            ostatus[9] = Res.Get(11806, ostatus[9]);

            otype[0] = Res.Get(11850, otype[0]);
            otype[1] = Res.Get(11851, otype[1]);
            otype[2] = Res.Get(11852, otype[2]);
            otype[3] = Res.Get(11853, otype[3]);
            otype[10] = Res.Get(11854, otype[10]);
            otype[14] = Res.Get(11855, otype[14]);


            this.view = view;
            BackgroundColor = GetColor("window.back");
            Size = new Size(1270, 850);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = GetColor("toolbar.back");

            var lTitle = new Label(Res.Get(11500, "Acitivtés"), 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            var x = 4;
            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle, false, 99);
            bClose.OnButtonClick += BMenu_Click; x += 64;

            bNew = new Button(x, 0, 32, 32, "images/63_new_t.png", pTitle, false, 1);
            bNew.OnButtonClick += BMenu_Click; x += 40;
            bModify = new Button(x, 0, 32, 32, "images/63modifie_t.png", pTitle, false, 2);
            bModify.OnButtonClick += BMenu_Click; x += 40;
            bSave = new Button(x, 0, 32, 32, "images/63save_t.png", pTitle, false, 3);
            bSave.OnButtonClick += BMenu_Click; x += 40;
            bCancel = new Button(x, 0, 32, 32, "images/64annule_t.png", pTitle, false, 4);
            bCancel.OnButtonClick += BMenu_Click; x += 40;

            validColor[0] = Color.Blue;
            validColor[1] = Color.Green;
            validColor[2] = Color.Red;
            validColor[4] = new Color(255, 127, 0, 255);
            validColor[8] = new Color(255, 127, 0, 255);
            validColor[16] = Color.Green;

            PlaceObj1();
            PlaceObj2();
            PlaceObj3();

            SetState(false);
        }

        private void BMenu_Click(Button c, Bridge.Html5.MouseEvent arg)
        {
            switch(c.id1)
            {
                case 99:
                    if (fromMode == -1)
                        view.CloseLock();
                    else
                    {
                        Visible = Bridge.Html5.Visibility.Hidden;
                        pof.Update(fromOF);
                    }
                    break;

                case 1:
                    New();
                    SetState(true);
                    break;
                case 2:
                    SetState(true);
                    break;
                case 3:
                    Save();
                    SetState(false);
                    if (fromMode > -1 && fromMode < 2)
                    {
                        Visible = Bridge.Html5.Visibility.Hidden;
                        pof.Update(fromOF);
                    }
                    break;
                case 4:
                    Cancel();
                    SetState(false);
                    if (fromMode > -1 && fromMode < 2)
                    {
                        Visible = Bridge.Html5.Visibility.Hidden;
                        pof.Update(fromOF);
                    }
                    break;
            }
        }

        public void Reset()
        {
            createNew = false;
            LoadOrdres();
        }

        public void FromOF(int type, int ofid, int oid, PanelOFCreation pof)
        {
            fromMode = type;
            fromOF = ofid;
            fromOID = oid;
            this.pof = pof;
        }

        void PlaceObj1()
        {
            var p = new Panel(16, 48, 600, 420, Color.White, this);
            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(11510, "Information générales"), 8, 8, p2.Width - 8, 16, "left", p2);
            l.Color = Color.White;

            cboOrdre = new Combo("", p2.Width - 258, 2, 250, 28, p2);
            cboOrdre.OnChange += CboOrdre_OnChange;

            string[] txtlabel = { "Id code saisie", "Id code numérique", "Description", "Description courte", "Dénomination",
                                    "OF/Affaire/Projet", "Centre de frais", "Chef de projet" };

            string[] ids = { "idc", "idn", "desc", "descc", "denom",
                                    "of", "cf", "cproj", "typeO" };

            for(var i = 0; i < txtlabel.Length; i++)
                txtlabel[i] = Res.Get(11511 + i, txtlabel[i]);

            var labels = new Label[txtlabel.Length];

            var x = 16;
            var y = 64;
            for(var i = 0; i < txtlabel.Length; i++)
            {
                labels[i] = new Label(txtlabel[i] + ":", x, y, 170, 16, "right", p);
                if (i == 1) y += 16;
                if (i == 4) y += 16;
                if (i == 5) y += 16;
                if (i == 6) y += 16;
                y += 32;
            }


            fields[ids[0]] = new Field(labels[0], 150); // idc
            fields[ids[1]] = new Field(labels[1], 150); // idn
            fields[ids[2]] = new Field(labels[2], 350); // desc
            fields[ids[3]] = new Field(labels[3], 350); // descc
            fields[ids[4]] = new Field(labels[4], 350); // denom
            fields[ids[5]] = new Combo("", labels[5].Right + 4, labels[5].Top - 4, 150, 28, p); // of
            fields[ids[6]] = new Combo("", labels[6].Right + 4, labels[6].Top - 4, 150, 28, p); // cf
            fields[ids[7]] = new Combo("", labels[7].Right + 4, labels[7].Top - 4, 150, 28, p); // cf

            fields[ids[8]] = new Combo("Type", 390, labels[0].Top - 4, 150, 28, p); // type
            for(var i = 0; i < ostatus.Length; i++)
            {
                var s = ostatus[i];
                if (s.Length <= 0) continue;
                ((Combo)fields[ids[8]]).Add(i, s);
            }


            lst = new Label("", 4, p.Height - 24, p.Width - 8, 16, "center", p);
            lst.Color = new Color(150);
        }

        void PlaceObj2()
        {
            var p = new Panel(632, 48, Width - 648, Height - 64, Color.White, this);
            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(11700, "Validité"), 8, 8, p2.Width - 8, 16, "left", p2);
            l.Color = Color.White;

            l = new Label(Res.Get(11701, "Date de validité du/au") + " :", 16, 64, 250, 16, "right", p);
            date1 = new Field(l, 250, Field.FieldFormat.DateComplete);
            date2 = new Field(l, 250, Field.FieldFormat.DateComplete);
            date2.Top += 32;

            var w = (p.Width - 64) / 2;
            lstP1 = new ListBox(16, 160, w, p.Height - 176, Res.Get(11702, "Collaborateurs"), p);
            lstP1.Multiple = true;
            lstP2 = new ListBox(lstP1.Right + 32, lstP1.Top, lstP1.Width, lstP1.Height, Res.Get(11703, "s/Projet"), p);
            lstP2.Multiple = true;

            lstP1.List.OnMouseDown += (a) => 
            {
                if (a.Button == 2)
                    AddToP2(null);
            };
            lstP1.OnDoubleClick += (a) => { AddToP2(a.Selected); };

            lstP2.List.OnMouseDown += (a) =>
            {
                if (a.Button == 2)
                    RmvToP2(null);
            };
            lstP2.OnDoubleClick += (a) => { RmvToP2(a.Selected); };


            cInv = new CheckBox("cinv", "cinb", "false");
            cInv.Place(lstP2.Right - 20, lstP2.Top - 20, 16, 16, p);
            l = new Label(Res.Get(11704, "Sens inversé"), cInv.Left - 200, cInv.Top, 192, 16, "right", p);
        }

        void PlaceObj3()
        {
            var p = new Panel(16, 484, 600, Height - 500, Color.White, this);
            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(11600, "Dernières saisies"), 8, 8, p2.Width - 8, 16, "left", p2);
            l.Color = Color.White;

            fields["date"] = l = new Label("", 8, 1, p2.Width - 16, 16, "right", p2);
            l.Color = Color.White;
            l.FontSize = 9;

            lstEntries = new Panel(0, 48, p.Width, p.Height - 48, new Color(220), p);
            lstEntries.SetScrolling(Bridge.Html5.Overflow.Hidden, Bridge.Html5.Overflow.Scroll);
        }

        void SetState(bool v)
        {
            bClose.Enabled = !v;
            bNew.Enabled = !v;
            bModify.Enabled = !v;
            bSave.Enabled = v;
            bCancel.Enabled = v;
            cboOrdre.List.Disabled = v;

            date1.Input.ReadOnly = !v;
            date2.Input.ReadOnly = !v;
            lstP1.List.Disabled = !v;
            lstP2.List.Disabled = !v;

            if (cboOrdre.Count == 0) bModify.Enabled = false;

            foreach (var c in fields.Values)
            {
                if (c is Field) ((Field)c).Input.ReadOnly = !v;
                else if (c is Combo) ((Combo)c).List.Disabled = !v;
            }
        }

        private void CboOrdre_OnChange(Combo combo)
        {
            var id = cboOrdre.SelectedId;
            
            var func = new Api.ApiFunction(Api.ApiFuncType.OrdreCreateOrdre);
            func.iparam1 = id;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                Clear();

                if (b.return_s1.Length > 0)
                {
                    var csv = new CSVTool(b.return_s1)[0];
                    var csvV = new CSVTool(b.return_s2)[0];

                    ((Field)fields["idc"]).Value = csv[0];
                    ((Field)fields["idn"]).Value = csv[1];
                    ((Field)fields["desc"]).Value = csv[2];
                    ((Field)fields["descc"]).Value = csv[3];
                    ((Field)fields["denom"]).Value = csv[4];
                    ((Combo)fields["of"]).SelectById(csv.I32(5));
                    ((Combo)fields["cf"]).SelectById(csv.I32(6));
                    ((Combo)fields["cproj"]).SelectById(csv.I32(9));

                    var of = csv.I32(5);
                    var cf = csv.I32(6);
                    var t = csv.I32(7);
                    var s = csv.I32(8);

                    ((Combo)fields["typeO"]).SelectById(s);

                    var st = string.Format("[{0}]-[{1}]-[{2}] {3} /  {4}",id,t,s,otype[t],ostatus[s]);
                    lst.Text = st;

                    date1.Value = csvV.Date(0);
                    date2.Value = csvV.Date(1);
                    var inv = csvV.I32(2);
                    cInv.Checked = inv == 1 ? true : false;
                    for(var i = 3; i < csvV.Count; i++)
                    {
                        var pid = csvV.I32(i);
                        lstP2.Add(pid, pers[pid]);
                    }
                }
            };

            LoadEntries(id);
        }

        void LoadOrdres(int wanted = -1)
        {
            Console.WriteLine("wantedB: " + wanted);

            var func = new Api.ApiFunction(Api.ApiFuncType.OrdreCreateOrdres);
            func.iparam2 = Workflow.pid;

            var sbPid = new StringBuilder();
            foreach (var item in view.CboWF.Items)
                if (item.Value != "0")
                {
                    sbPid.Append(item.Value);
                    sbPid.Append(';');
                }
            func.param1 = sbPid.ToString();


            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                cboOrdre.Clear();
                ofs.Clear();
                cfs.Clear();
                pers.Clear();
                lstP1.Clear();
                lstP2.Clear();
                ((Combo)fields["cproj"]).Clear();
                ((Combo)fields["cproj"]).Add(0, "----");

                if (b.return_s1.Length > 1)
                {
                    var csvOrdre = new CSVTool(b.return_s1)[0];
                    var csvOF = new CSVTool(b.return_s2)[0];
                    var csvCF = new CSVTool(b.return_s3)[0];
                    var csvPers = new CSVTool(b.return_s4)[0];

                    var count = csvOrdre.I32(0);
                    var idx = 1;

                    for(var i = 0; i < count; i++)
                    {
                        var oid = csvOrdre.I32(idx++);
                        var idc = csvOrdre[idx++];
                        var name = csvOrdre[idx++];

                        var s = idc.Trim() + " " + name.Trim();

                        cboOrdre.Add(oid, s);
                    }

                    count = csvOF.I32(0);
                    idx = 1;
                    for (var i = 0; i < count; i++)
                    {
                        ofs[csvOF.I32(idx++)] = csvOF[idx++];
                        idx++;
                    }

                    idx = 0;
                    for (var i = 0; i < csvCF.Count / 2; i++)
                        cfs[csvCF.I32(idx++)] = csvCF[idx++];

                    count = csvPers.I32(0);
                    idx = 1;
                    for (var i = 0; i < count; i++)
                    {
                        var pid = csvPers.I32(idx++);
                        var name = csvPers[idx++];
                        var addL1 = csvPers.Bool(idx++);
                        pers[pid] = name;
                        if (addL1)
                            lstP1.Add(pid, name);
                        ((Combo)fields["cproj"]).Add(pid, name);
                    }
                    ((Combo)fields["cproj"]).Sort();

                    FillOFCF();

                    //Console.WriteLine("wantedB: " + wanted);

                    if (fromMode == -1 || fromMode == 2)
                    {
                        if (wanted <= 0)
                            cboOrdre.SelectByIdx(0, true);
                        else
                            cboOrdre.SelectById(wanted, true);

                        if (bClose.Enabled && !bModify.Enabled && cboOrdre.Count > 0)
                            bModify.Enabled = true;
                    }
                }


                if (fromMode == 0)
                {
                    New();
                    SetState(true);
                    ((Combo)(fields["of"])).SelectById(fromOF);
                    ((Field)(fields["idc"])).Focus();
                    ((Combo)(fields["of"])).List.Disabled = true;
                }
                else if (fromMode == 1)
                {
                    cboOrdre.SelectById(fromOID, true);
                    SetState(true);
                    ((Field)(fields["idc"])).Focus();
                }
                else if(fromMode == 2)
                {

                }

            };
        }

        void FillOFCF()
        {
            var cOF = fields["of"] as Combo;
            var cCF = fields["cf"] as Combo;

            cOF.Clear();
            cCF.Clear();

            //cOF.Add(99999, "Créer un OF");
            cOF.Add(0, "----");
            foreach(var item in ofs)
                cOF.Add(item.Key, item.Value);

            cCF.Add(0, "----");
            foreach (var item in cfs)
                cCF.Add(item.Key, item.Value);
        }

        void New()
        {
            Clear();
            createNew = true;
        }

        void Save()
        {

            var func = new Api.ApiFunction(Api.ApiFuncType.OrdreCreateSave);
            func.iparam1 = createNew ? 1 : 0;
            func.iparam2 = cboOrdre.Selected != null ? cboOrdre.SelectedId : -1;

            var csv = new CSVTool();
            var csvValidity = new CSVTool();
            
            csv.A(((Field)fields["idc"]).Input.Value);
            csv.A(((Field)fields["idn"]).Input.Value);
            csv.A(((Field)fields["desc"]).Input.Value);
            csv.A(((Field)fields["descc"]).Input.Value);
            csv.A(((Field)fields["denom"]).Input.Value);
            csv.A(((Combo)fields["of"]).SelectedId);
            csv.A(((Combo)fields["cf"]).SelectedId);
            csv.A(((Combo)fields["cproj"]).SelectedId);
            csv.A(((Combo)fields["typeO"]).SelectedId);

            csvValidity.A(date1.ValueDate);
            csvValidity.A(date2.ValueDate);
            csvValidity.A(cInv.Checked ? 1 : 0);
            csvValidity.A(lstP2.Count);
            if (lstP2.Count > 0)
            {
                foreach (var item in lstP2.Items)
                {
int pid;
                    int.TryParse(item.Value, out pid);
                    csvValidity.A(pid);
                }
            }

            func.param1 = csv.ToString();
            func.param2 = csvValidity.ToString();

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                var rid = b.return_i1;
                var txt = b.return_s1;

                Clear();
                if (createNew)
                {
                    //cboOrdre.Add(rid, txt);
                    //cboOrdre.SelectById(rid, true);
                    LoadOrdres(rid);
                }
                else
                {
                    cboOrdre.SelectById(cboOrdre.SelectedId, true);
                }

                createNew = false;
            };

            

        }

        void Cancel()
        {
            Clear();
            if (cboOrdre.Selected != null)
                cboOrdre.SelectById(cboOrdre.SelectedId, true);

            createNew = false;
        }

        void Clear()
        {
            ((Field)fields["idc"]).Value = "";
            ((Field)fields["idn"]).Value = "";
            ((Field)fields["desc"]).Value = "";
            ((Field)fields["descc"]).Value = "";
            ((Field)fields["denom"]).Value = "";
            ((Combo)fields["of"]).SelectById(0);
            ((Combo)fields["cf"]).SelectById(0);
            ((Combo)fields["cproj"]).SelectById(0);
            lst.Text = "";
            date1.Value = DateTime.MinValue;
            date2.Value = DateTime.MinValue;


            foreach (var o in lstP1.Items)
                if (o.Selected)
                    o.Selected = false;

            lstP2.Clear();
        }

        void AddToP2(Bridge.Html5.HTMLOptionElement itemA)
        {
System.Action<Bridge.Html5.HTMLOptionElement> Add = null;
Add = (item) =>
{
    int v;
    int.TryParse(item.Value, out v);
    var ok = true;
    foreach (var p in lstP2.Items)
    {
        int v2;
        int.TryParse(p.Value, out v2);
        if (v == v2)
        {
            ok = false;
            break;
        }
    }

    if (ok)
    {
        var p = pers[v];
        lstP2.Add(v, pers[v]);
    }
}

;
            if(itemA == null)
            {
                foreach (var item in lstP1.AllSelected)
                    Add(item);
            }
            else
            {
                Add(itemA);
            }

            
        }

        void RmvToP2(Bridge.Html5.HTMLOptionElement item)
        {
            if (item == null)
            {
                var lst = new List<Bridge.Html5.HTMLOptionElement>();
                foreach (var it in lstP2.AllSelected)
                    lst.Add(it);
                foreach (var it in lst)
                    lstP2.Rmv(it);
            }
            else
                lstP2.Rmv(item);
        }

        void LoadEntries(int oid)
        {
            lstEntries.ClearChildren();
            lstPEntries.Clear();

            ((Label)fields["date"]).Text = string.Format("{0}<br />{1}",Fmt.DateComplete(DateTime.Now.AddDays(-40)),Fmt.DateComplete(DateTime.Now));

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityQties2);
            func.iparam1 = Workflow.pid;
            func.iparam2 = D2000.T(DateTime.Now);
            func.iparam3 = 40;
            func.iparam4 = 99;
            func.iparam5 = 40;
            func.iparam6 = oid;

            var sbPid = new StringBuilder();
            foreach (var item in view.CboWF.Items)
                if (item.Value != "0")
                {
                    sbPid.Append(item.Value);
                    sbPid.Append(';');
                }
            func.param1 = sbPid.ToString();

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                if (b.return_s1.Length > 0)
                {
                    var csv = new CSVTool(b.return_s1)[0];

                    int y = 0;
                    for (var i = 0; i < csv.Count; i += 28)
                    {
                        var q = new PanelValidationQ.Qty(csv, i);
                        y = AddQ(q, y);
                    }
                }

            };
        }

        int AddQ(PanelValidationQ.Qty qty, int y)
        {
            var p = new Panel(0, y, lstEntries.Width, 24, Color.White, lstEntries, lstPEntries);
            p.tag = qty;

            var l = new Label(Fmt.DateComplete(qty.date), 20, 4, p.Width - 44, p.Height - 8, "left", p);
            l = new Label(qty.name, p.Width / 2, 4, p.Width - 44, p.Height - 8, "left", p);

            l = new Label(Fmt.HHmm(qty.duree), 20, 4, p.Width - 44, p.Height - 8, "right", p);
            l.FontSize = 12;


            var pV = new Panel(0, 0, 8, p.Height, Color.White, p);
            pV.id1 = 100;
            pV.BackgroundColor = validColor[qty.validation];

            qty.pRow = p;
            qty.pValid = pV;

            return p.Bottom + 1;
        }

    }
}
