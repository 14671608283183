using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using Mobatime.JSControl;

namespace Workflow_bridge.TodoList
{
    class TodoWindow
    {
        class Row
        {
            public int pid = 0;
            public string name = "";
            public int wfid = 0;
            public DateTime date = DateTime.MinValue;

            public Row(int pid, string name, int wfid, DateTime date)
            {
                this.pid = pid;
                this.wfid = wfid;
                this.date = date;
                this.name = name;
            }
        }

        //WindowInstance win = null;
        DateTime d1, d2;
        HtmlPage npage;
        Mobatime.JSControl.Window window;
        Button bClose;

        Panel pList;
        Combo cboWF;

        bool unlock = false;// true;

        CheckBox[] checkbox = new CheckBox[8];
        List<TodoRow> rows = new List<TodoRow>();
        List<Row> rows2 = new List<Row>();

        Combo cboPers, cboMois;

        //public static WindowInstance win = null;

        public void Show(bool su = false)
        {
            d1 = new DateTime(DateTime.Now.Year, DateTime.Now.Month, 1);
            d2 = d1.AddMonths(1).AddDays(-1);

            Workflow.SaveLocalData();
            Bridge.Html5.Window.LocalStorage.SetItem("tododblclick", "0");
                    
            if (!MensualView.MensualView.todo_loaded)
            {
                MensualView.MensualView.todo_win = Bridge.Html5.Window.Open("index.html?func=1000", "MobaWFTodo", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=600, height=900");
                MensualView.MensualView.todo_win.Focus();
            }
            else
            {
                MensualView.MensualView.todo_win.Focus();
            }

            if (Workflow.TimerTodoID == 0)
                Workflow.TimerTodoID = Bridge.Html5.Window.SetInterval((global::System.Action)CheckModif, 1000);




        }

        void OnUnload(Bridge.Html5.Event e)
        {
            Console.WriteLine("unload");
            MensualView.MensualView.todo_loaded = false;
        }

        void CheckModif()
        {
            //Console.WriteLine("A " + Environment.TickCount);

            var tempo = Bridge.Html5.Window.LocalStorage.GetItem("tododblclick");
            //Console.WriteLine(tempo);


            var s = Bridge.Html5.Window.LocalStorage.GetItem("tododblclick") as string;

            if (!string.IsNullOrEmpty(s) && s.Length > 3)
            {
                //Bridge.Html5.Window.Alert(s);

                var swfid = s.Substring(0, 5);
                var spid = s.Substring(5, 5);
                var syear = s.Substr(10, 4);
                var smonth = s.Substring(14, 2);
                var sday = s.Substring(16, 2);
int wfid;

                int.TryParse(swfid, out wfid);
int pid;
                int.TryParse(spid, out pid);
int year;
                int.TryParse(syear, out year);
int month;
                int.TryParse(smonth, out month);
int day;
                int.TryParse(sday, out day);

                var date = new DateTime(year, month, day);


                Bridge.Html5.Window.LocalStorage.SetItem("tododblclick", "0");
                Bridge.Html5.Window.Document.Cookie = "todo=0";

                Console.WriteLine("M1");
                MensualView.MensualView.Select(wfid, pid, date);

                Console.WriteLine("jump to " + wfid + " " + pid + " " + date.ToString());

            }
            else
            {
                

                var cookie = Bridge.Html5.Window.Document.Cookie;
                var splitted = cookie.Split(';');

                //Console.WriteLine("cookie mode " + splitted.Length + " " + splitted[0]);

                for (var i = 0; i < splitted.Length; i += 2)
                {
                    
                    if (splitted[i].StartsWith("todo="))
                    {
                        s = splitted[i];
                        break;
                    }
                }

                if (s.Length > 8)
                {


                    var swfid = s.Substring(5, 5);
                    var spid = s.Substring(10, 5);
                    var syear = s.Substr(15, 4);
                    var smonth = s.Substring(19, 2);
                    var sday = s.Substring(21, 2);
int wfid;

                    int.TryParse(swfid, out wfid);
int pid;
                    int.TryParse(spid, out pid);
int year;
                    int.TryParse(syear, out year);
int month;
                    int.TryParse(smonth, out month);
int day;
                    int.TryParse(sday, out day);

                    var date = new DateTime(year, month, day);

                        
                    Bridge.Html5.Window.LocalStorage.SetItem("tododblclick", "0");

                    Console.WriteLine("M2 => " + cookie);
                    MensualView.MensualView.Select(wfid, pid, date);

                    Console.WriteLine("jump to " + wfid + " " + pid + " " + date.ToString());

                    Bridge.Html5.Window.Document.Cookie = "todo=0";
                }
            }

            //Console.WriteLine("C");
        }


        public void Place(HtmlPage page)
        {

            var todowf = Tool.LSRead("todowf", "");

            var csv = new CSVTool(todowf)[0];

            unlock = Workflow.perm_todo_unlock;

            npage = page;
            window = new Mobatime.JSControl.Window(npage, 40);

            bClose = window.Toolbar.AddButton(1000);

            bClose.OnButtonClick += BClose_OnButtonClick;

            var imgdir = Workflow.BaseWebPath + "/wf2018/";

            bClose.SetImage(imgdir + "images/63_porte_t.png", 32, 32);

            var w = 660;
            var h = 900;


            var title = Res.Get(3000, "Todo Liste");

            var lTitle = new Label(title, w - 200, 12, 180, 16, "right");
            window.Toolbar.AddChild(lTitle);
            lTitle.Color = Color.White;
            lTitle.FontSize = 14;


            cboWF = new Combo();
            cboWF.Place(48, 4, 250, 32, window.Toolbar);
            cboWF.Add(0, Res.Get(3005, "Tous les workflows"));

            if (csv.Count > 1)
            {
                for (var i = 0; i < csv.Count; i += 2)
                {
int wfid;
                    int.TryParse(csv[i], out wfid);
                    cboWF.Add(wfid, csv[i + 1]);
                }
            }
            cboWF.OnChange += CboWF_OnChange;

            cboPers = new Combo();
            cboPers.Place(16, 8, 200, 32, window.Container);
            cboPers.OnChange += CboPers_OnChange;

            cboMois = new Combo();
            cboMois.Place(cboPers.Right + 8, 8, 150, 32, window.Container);
            cboMois.OnChange += CboMois_OnChange;

            pList = new Panel(window.Container);
            pList.Place(0, 48, w - 2, h - 48 - 16 - 32);
            pList.BackgroundColor = new Color(240);
            pList.SetScrolling(Overflow.Hidden, Overflow.Scroll);
            pList.SetBorderStyle(1, "solid", new Color(240));


            string[] sn = { Res.Get(3001, "Ptg"), Res.Get(3002, "Abs"), Res.Get(3003, "Ano"), Res.Get(3004, "Frais"), "",  "Unlock", "", "" };

            if (Workflow.todo_piquet) sn[sn.Length - 2] = "P";
            if (Workflow.todo_workopen) sn[sn.Length - 1] = "WO";

            if (!unlock)
                sn[sn.Length - 3] = "";

            var x = w - sn.Length * 32 - 20;
            for (var i = 0; i < sn.Length; i++)
            {
                if (sn[i].Length == 0) continue;

                var l = new Label(sn[i], x + i * 32, pList.Top - 32, 32, 16, "center", window.Container);
                l.FontSize = 8;
                var c = new CheckBox("cTodo", "", "");
                c.Place(x + i * 32 + 8, pList.Top - 18, 16, 16, window.Container);
                c.Checked = true;
                c.SetAttribute("cid", i.ToString());

                var data = Bridge.Html5.Window.LocalStorage.GetItem("todo_c" + i);
                if(data != null)
                {
bool status;
                    bool.TryParse(data.ToString(), out status);
                    c.Checked = status;
                }

                c.Input.OnChange = Check_OnChange;

                checkbox[i] = c;
            }


            bool[] checks = new bool[8];
            checks[1] = true;


            var func = new Api.ApiFunction(); 
            func.function = Api.ApiFuncType.get_workflow_todo;
            func.iparam1 = Workflow.pid;
            func.iparam4 = 1;   // avec jours non verrouillés
            if (Workflow.todo_piquet) func.iparam5 = 1;
            if (Workflow.todo_workopen) func.iparam6 = 1;

            var dataTodo = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataTodo.OnSuccess += DataTodo_OnSuccess;

            window.Container.OnResize += Npage_OnResize;



        }

        private void CboMois_OnChange(Combo combo)
        {
            var v = combo.SelectedId;
            var month = v % 100;
            var year = v / 100;

            Tool.LSWrite("wftodo_cbomonth", combo.SelectedId);

            Filtre(cboPers.SelectedId, month, year);
        }

        private void CboPers_OnChange(Combo combo)
        {
            Tool.LSWrite("wftodo_cbopers", combo.SelectedId);
            Filtre(combo.SelectedId);
        }

        private void CboWF_OnChange(Combo combo)
        {
            Tool.LSWrite("wftodo_cbowf", combo.SelectedId);
            Filtre();
        }

        private void Npage_OnResize(Control c)
        {
            var w = npage.Width;
            var h = npage.Height;
            
            pList.Place(0, 48, w - 2, h - 48 - 16 - 32);
        }

        private void DataTodo_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            Console.WriteLine("success");
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);

            var count = csv[0].strings.Count / 13;
            pList.ClearChildren();

            rows2.Clear();

            bool[] checks = new bool[8];

            SortedList<string, TodoRow> lstTodoRows = new SortedList<string, TodoRow>(); 
            SortedList<string, Row> lstRows  = new SortedList<string, Row>();

            var idx = 0;
            for(var i = 0; i < count; i++)
            {
                var p = csv[0];

                var pid = p.I32(idx++);
                var name = p.String(idx++);
                var wfid = p.I32(idx++);
                var year = p.I32(idx++);
                var month = p.I32(idx++);
                var day = p.I32(idx++);
                var ptg = p.Bool(idx++);
                var abs = p.Bool(idx++);
                var ano = p.Bool(idx++);
                var frais = p.Bool(idx++);
                var isNotLocked = p.Bool(idx++);
                var piquet = p.Bool(idx++);
                var workopen = p.Bool(idx++);

                var d = new DateTime(year, month, day);

                checks[0] = ptg;
                checks[1] = abs;
                checks[2] = ano;
                checks[3] = frais;
                checks[5] = isNotLocked;
                checks[6] = piquet;
                checks[7] = workopen;

                var key = name.PadRight(60, ' ') + pid.ToString().PadLeft(6, '0') + year + month.ToString().PadLeft(2, '0') + day.ToString().PadLeft(2, '0');
                //Console.WriteLine(key);

                var row = new TodoRow(pList, pid, name, wfid, d, checks);
                lstTodoRows.Add(key, row);
                //rows.Add(row);

                //rows2.Add(new Row(pid, name, wfid, d));
                lstRows.Add(key, new Row(pid, name, wfid, d));
            }

            foreach (var v in lstTodoRows.Values)
                rows.Add(v);
            foreach (var v in lstRows.Values)
                rows2.Add(v);



            var cmonth = Tool.LSReadInt("wftodo_cbomonth", 0);
            var cpers = Tool.LSReadInt("wftodo_cbopers", 0);
            var cwf = Tool.LSReadInt("wftodo_cbowf", 0);

            cboWF.SelectById(cwf);
            cboPers.SelectById(cpers);
            cboMois.SelectById(cmonth, true);

            //Console.WriteLine($"{cwf} {cpers} {cmonth}");

            Filtre();

            
            cboWF.SelectById(cwf);
            cboPers.SelectById(cpers);
            cboMois.SelectById(cmonth, true);

        }

        private void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            Bridge.Html5.Window.Close();
        }

        void Check_OnChange(Event<HTMLInputElement> arg)
        {
            var target = arg.Target as HTMLInputElement;
            var scid = target.GetAttribute("cid");
int cid;
            int.TryParse(scid, out cid);


            Bridge.Html5.Window.LocalStorage.SetItem("todo_c" + cid, checkbox[cid].Checked);

            Filtre();
        }

        void Filtre(int pid = -1, int month = -1, int year = -1)
        {
            var ptg = checkbox[0].Checked;
            var abs = checkbox[1].Checked;
            var ano = checkbox[2].Checked;
            var frais = checkbox[3].Checked;
            var isNotLocked = false;
            var piquet = false;
            var workOpen = false;

            if (unlock && checkbox[5] != null)
                isNotLocked = checkbox[5].Checked;

            piquet = Workflow.todo_piquet && checkbox[6].Checked;
            workOpen = Workflow.todo_workopen && checkbox[7].Checked;

            pList.ClearChildren();

            var selWF = cboWF.SelectedId;
            if (pid == -1)
            {
                var dicoPid = new Dictionary<int, int>();
                cboPers.Clear();
                cboPers.Add(0, Res.Get(3006, "Tous"));
                foreach (var r in rows2)
                {
                    if ((r.wfid == selWF || selWF == 0) && !dicoPid.ContainsKey(r.pid))
                    {
                        cboPers.Add(r.pid, r.name);
                        dicoPid.Add(r.pid, r.pid);
                    }
                }
                cboPers.SelectById(0);
            }

            var selPers = cboPers.SelectedId;

            if (month == -1 && year == -1)
            {
                var dicoPer = new Dictionary<int, int>();
                cboMois.Clear();
                cboMois.Add(0, "----");
                foreach(var r in rows2)
                {
                    var key = r.date.Year * 100 + r.date.Month;
                    if ((r.wfid == selWF || selWF == 0) && (r.pid == selPers || selPers == 0) && !dicoPer.ContainsKey(key))
                    {
                        cboMois.Add(key, Res.months[r.date.Month - 1] + " " + r.date.Year);
                        dicoPer.Add(key, key);
                    }
                }
            }

            var selMois = cboMois.SelectedId;



            var k = 0;
            foreach (var row in rows)
            {

                var ok0 = false;
                var ok1 = false;
                var ok2 = false;
                var ok3 = false;
                var ok5 = false;
                var ok6 = false;
                var ok7 = false;

                if (selWF > 0 && selWF != row.WFId) continue;
                if (selPers > 0 && selPers != row.PId) continue;
                var keydate = row.Date.Year * 100 + row.Date.Month;
                if (selMois > 0 && selMois != keydate) continue;
                
                if (ptg && row.checks[0]) ok0 = true;
                if (abs && row.checks[1]) ok1 = true;
                if (ano && row.checks[2]) ok2 = true;
                if (frais && row.checks[3]) ok3 = true;
                if (unlock && isNotLocked && row.checks[5]) ok5 = true;
                if (piquet && row.checks[6]) ok6 = true;
                if (workOpen && row.checks[7]) ok7 = true;

                var total = ok0 || ok1 || ok2 || ok3 || ok5 || ok6 || ok7;
                Console.WriteLine(string.Format("{0} {1} {2} {3} {4} {5} {6}",ok0,ok1,ok2,ok3,ok5,ok6,ok7));
                if(!total)
                {
                }
                else
                {
                    pList.AddChild(row);
                    row.Top = k * 21;
                    k++;
                }

            }
                
        }

    }
}
