using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class PSwapOrdre : pSwapOption
    {

        int ordre = 0;
        Panel pList = null;

        Panel selP = null;
        Label selL = null;

        Button bOk = null;
        Button[] bType = new Button[4];

        Field fSearch = null;

        public PSwapOrdre(pPunchWKSwap parent) : base(parent)
        {
            var x = Width - 16 - 96;
            var y = Height - 48;

            pList = new Panel(16, 48, Width - 32, Height - 120, Color.White, this);
            pList.SetScrolling(Overflow.Hidden, Overflow.Scroll);


            var b = new Button(x, y, 96, 32, "", this, true);
            b.Text = "Annuler";
            b.OnButtonClick += (aa, bb) =>
            {
                parent.CloseOption();
            };

            x -= 96 + 8;
            b = bOk = new Button(x, y, 96, 32, "", this, true);
            b.Text = "Confirmer";
            b.OnButtonClick += (aa, bb) =>
            {
                Apply();
            };


            string[] bTxt = { "10", "Agence", "Fixes", "Tous" };
            int[] bId = { 10, 1, 2, 0 };

            for (var i = 0; i < bTxt.Length; i++)
            {
                b = new Button(-112, 192 + i * 88, 96, 80, "", this, true, bId[i]);
                b.Text = bTxt[i];

                var id = bId[i];
                b.OnButtonClick += B_OnButtonClick;

                bType[i] = b;
            }

            fSearch = new Field(Width - 136, 8, 120, "", this);
            fSearch.OnValidateEnter += FSearch_OnValidateEnter;
        }

        private void FSearch_OnValidateEnter(Field field)
        {
            var id = 0;
            foreach(var b in bType)
            {
                if(b.Selected)
                {
                    id = b.id1;
                    break;
                }
            }

            LoadOrdres(type, id);
        }

        private void B_OnButtonClick(Button c, MouseEvent arg)
        {
            fSearch.Input.Value = "";
            LoadOrdres(type, c.id1);
        }

        public override void Show(int type = 0)
        {
            base.Show(type);

            fSearch.Input.Value = "";
            bOk.Visible = Visibility.Hidden;
            lTitle.Text = "Sélectionnez un ordre.";

            LoadOrdres(type, 10);
        }

        void LoadOrdres(int type = 0, int ordresType = 0)
        {
System.Action<int, int, string, string, string, string, string, string> AddRow = null;
            var search = fSearch.Input.Value.Trim().ToLower();

            foreach(var b in bType)
            {
                b.SelectMe(ordresType == b.id1);
            }

            pList.ClearChildren();

            var c1 = new Color(240);
            var c2 = new Color(220);

            var offset = 0;
AddRow = (idx, oid, t1, t2, t3, t4, t5, t6) =>
{
    var c = c1;
    if (idx % 2 == 1)
        c = c2;
    var p = new Panel(0, idx * 82, pList.Width, 80, c, pList);
    var s = string.Format("{0}<br/>{1} {2} {3} {4} {5}", t1, t2, t3, t4, t5, t6);
    var l = new Label(s, 8, 8, p.Width - 16, p.Height - 16, "left", p);
    p.SetAttribute("sel", "0");
    p.Div.OnMouseEnter += e =>
    {
        var attsel = p.GetAttribute("sel");
        if (attsel == "1")
        {
            p.BackgroundColor = Color.Mobatime1;
            l.Color = Color.White;
        }
        else
        {
            p.BackgroundColor = Color.Mobatime2;
            l.Color = Color.White;
        }
    }

    ;
    p.Div.OnMouseLeave += e =>
    {
        var attsel = p.GetAttribute("sel");
        if (attsel == "1")
        {
            p.BackgroundColor = Color.Mobatime1;
            l.Color = Color.White;
        }
        else
        {
            p.BackgroundColor = c;
            l.Color = Color.Mobatime1;
        }
    }

    ;
    p.Div.OnMouseDown += e =>
    {
        if (selP != null)
        {
            selP.BackgroundColor = c;
            selP.SetAttribute("sel", "0");
        }

        if (selL != null)
            selL.Color = Color.Mobatime1;
        p.SetAttribute("sel", "1");
        p.BackgroundColor = Color.Mobatime1;
        l.Color = Color.White;
        ordre = oid;
        selP = p;
        selL = l;
        bOk.Visible = Visibility.Inherit;
    }

    ;
}

;
            if(type == 0)
            {
                AddRow(0, 0, "Aucun ordre", "", "", "", "", "");
                offset++;
            }

            bOk.Visible = Visibility.Hidden;
            ordre = 0;
            
            var lst10 = new List<int>();
            for (var i = 0; i < 10; i++)
            {
                var key = string.Format("last10order_{0}_{1}",Workflow.pid,i);
                var id = Tool.LSReadInt(key, -1);
                if (id != ordre && id != -1) lst10.Add(id);
            }

            var func = new Api.ApiFunction(Api.ApiFuncType.SwapGetOrdres);
            func.iparam1 = Workflow.pid;
            func.iparam2 = ordresType;
            foreach(var oid in lst10)
                func.array_int.Add(oid);

            var da = new DataApi<Api.ApiReturn>(Workflow.api, func);
            da.OnSuccess += (a, ret) => 
            {
                var split = ret.return_s1.Split(';');

                var w = pList.Width / 2;
                var count = 0;
                for (var i = 0; i < split.Length; i += 8)
                {
                    var idx = i / 8;

                    if (i + 7 >= split.Length)
                        break;
int oid;

                    int.TryParse(split[i + 0], out oid);

                    
                    var ok = false;
                    if (search == "") ok = true;
                    else
                    {
                        for(var j = 1; j <= 6; j++)
                        {
                            if (split[i + j].ToLower().Contains(search))
                            {
                                ok = true;
                                break;
                            }
                        }
                    }

                    if (ok)
                    {
                        AddRow(count + offset, oid, split[i + 1], split[i + 2], split[i + 3], split[i + 4], split[i + 5], split[i + 6]);
                        count++;
                    }

                    if (count >= 500)
                        break;
                }
            };

            
        }

        protected override void Apply()
        {
            base.Apply();

            Api.ApiFunction func = null;


            if (type == 0)   // début journée
            {
                func = new Api.ApiFunction(Api.ApiFuncType.SwapStartDay);
                func.iparam1 = Workflow.pid;
                func.iparam2 = ordre;

            }
            else if (type == 1) // swap
            {
                func = new Api.ApiFunction(Api.ApiFuncType.SwapSwap);
                func.iparam1 = Workflow.pid;
                func.iparam2 = ordre;
            }

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, ret) =>
            {
                if (ret.return_b1)
                    Bridge.Html5.Window.Alert("Opération effectuée");
                else
                    Bridge.Html5.Window.Alert("Erreur !");

                parent.CloseOption(true);

                if (ordre > 0)
                {
                    var lst10 = new List<int>();
                    for (var i = 0; i < 10; i++)
                    {
                        var key = string.Format("last10order_{0}_{1}",Workflow.pid,i);
                        var id = Tool.LSReadInt(key, -1);
                        if (id != ordre && id != -1) lst10.Add(id);
                    }
                    lst10.Insert(0, ordre);
                    for (var i = 0; i < 10; i++)
                    {
                        var key = string.Format("last10order_{0}_{1}",Workflow.pid,i);
                        var v = -1;
                        if (i < lst10.Count) v = lst10[i];
                        Tool.LSWrite(key, v);
                    }
                }
            };

        }

    }
}
