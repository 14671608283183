
using System;
using Bridge;
using Bridge.Html5;
using Mobatime.JSControl;

namespace Workflow_bridge
{
    internal class Program
    {
        public static void Main(string[] args)
        {

            Console.WriteLine("Start Workflow session");

            var _page = new HtmlPage(Document.Body.OwnerDocument);
            var p = Bridge.Html5.Window.Location.Href;

            if(p.Contains("mas=false"))
            {
                Workflow.ForceMASToFalse = true;
            }

            var idx = p.IndexOf("?func=");
            var sfunc = p.Substring(idx + 6, 4);
int func;
            int.TryParse(sfunc, out func);

            Workflow.Dark = p.Contains("dark=1");

            int p0 = 0;
            idx = p.IndexOf("&p0=");
            if (idx > 0)
            {
                idx += 4;
                var sp0 = "";
                bool ok = false;
                for (var i = idx; i < p.Length; i++)
                    if (p[i] == '&')
                    {
                        sp0 = p.Substring(idx + 4, i - idx);
                        ok = true;
                        break;
                    }

                if (!ok)
                {
                    sp0 = p.Substring(idx);
                }

                int.TryParse(sp0, out p0);
            }

            if (p.Contains("?func=1000"))
            {
                Workflow.LoadLocalData();

                var todo = new TodoList.TodoWindow();

                todo.Place(_page);

            }
            else if (p.Contains("?func=2000"))
            {
                Workflow.LoadLocalData();
                var jumbo = new Jumbo.JumboView();
                jumbo.Place(_page, false);
            }
            else if (p.Contains("?func=2001"))
            {
                Workflow.LoadLocalData();
                var jumbo = new Jumbo.JumboView();
                jumbo.Place(_page, true);
            }
            else if(p.Contains("?func=5000"))
            {
                Workflow.LoadLocalData();
                var pip = new Pip.PipView();
                pip.Place(_page);
            }
            else if (func >= 6000 && func <= 6004)
            {
                // pip sans login
                Workflow.InitializePath();
                Workflow.wfid_su = 0;
                Workflow.wfid_re = 0;
                var pip = new Pip.PipView(func - 6000, true, p0);
                pip.Place(_page, true);
            }
            else if (func == 7000)
            {
                Workflow.LoadLocalData();
                var cockpit = new Cockpit.CockpitView();
                cockpit.Place(_page);
            }
            else if (func == 8000)
            {
                Workflow.LoadLocalData();
                var wkact = new WorkActivity.WorkActivityView();
                wkact.Place(_page);
            }
            else
            {
                var wfapp = new WorkflowApp(_page);
            }

            Document.Body.Style.Overflow = Overflow.Hidden;
            Document.Body.Style.SetProperty("user-select", "none");
            Document.Body.Style.SetProperty("-webkit-touch-callout", "none");
            Document.Body.Style.SetProperty("-webkit-user-select", "none");
            Document.Body.Style.SetProperty("-khtml-user-select", "none");
            Document.Body.Style.SetProperty("-moz-user-select", "none");
            Document.Body.Style.SetProperty("-ms-user-select", "none");
            
            Document.OnContextMenu += OnContextMenu;
            
        }

        private static void OnContextMenu(Event arg)
        {
            arg.PreventDefault();
        }
    }
}