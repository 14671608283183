using System;
using Mobatime.JSControl;
using Bridge.jQuery2;

namespace Workflow_bridge.Login
{
    public class LoginLinkWorkflow : LoginLink
    {

        private HtmlPage _page = null;
        private long token = 0;
        
        public LoginLinkWorkflow(HtmlPage page) : base()
        {
            _page = page;
            Res.CurrentLg = -1;
            Res.LgCom = -1;

            var mas = Tool.LSRead("MAS");// Tool.LSReadInt("MAS", 0);
            //Console.WriteLine($"MAS: {mas} {Workflow.MAS}");
            if (!Workflow.MAS) mas = "0";

            if (mas != "0")
            {
                var func = new Api.ApiFunction(Api.ApiFuncType.CheckMASCode);
                func.param5 = mas;

                var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
                data.OnSuccess += ((Data<Api.ApiReturn> data1, Api.ApiReturn result) =>
                {
                    Console.WriteLine("result : " + result);

                    if (result.return_b1)
                    {
                        Console.WriteLine("MAS LOGIN OK");
                        data1.o1 = "";
                        DataOnSuccess(data1, result);
                    }
                });
            }


        }


        public override void TestLogin(string c1, string c2, string c3)
        {
            //base.Login(c1, c2, c3);

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.login_pass;
            func.param1 = c1;
            func.param2 = c2;
            func.param3 = c3;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += DataTestOnSuccess;
        }

        public override void Login(string c1, string c2, string c3, int type)
        {
            base.Login(c1, c2, c3, type);

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.login_pass;
            func.param1 = c1;
            func.param2 = c2;
            func.param3 = c3;
            func.iparam1 = type;

            Workflow.OFs = null;
            Workflow.ordres = null;


            
            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.o1 = c2;
            data.OnSuccess += DataOnSuccess;
        }

        private void DataOnSuccess(Data<Api.ApiReturn> data1, Api.ApiReturn result)
        {
            Console.WriteLine("b1: " + result.return_b1);

            if (result.return_b1)
            {
                var pass = data1.o1.ToString();
                if (pass != null && (pass == "mobatime"))
                {
                    Console.WriteLine("Need to change password");
                    TestLoginRcv(result.return_b1);
                }
                else
                {
                    Console.WriteLine("MAS OK");

                    PId = result.return_i1;
                    Result1 = result.return_i2;
                    Result2 = result.return_i3;
                    Result3 = result.return_i4;
                    Result4 = result.return_i6;
                    mascode = result.return_s7;// .return_ul1;// result.return_i7;
                    SResult1 = result.return_s1;
                    SResult2 = result.return_s2;
                    SResult3 = result.return_s3;
                    token = result.token;

                    if (Result4 == 0) Workflow.PtgCount = 30;
                    else Workflow.PtgCount = 20;

                    Console.WriteLine("pfunc: " + Result1);
                    Console.WriteLine("ptg_count: " + Workflow.PtgCount);
                    Console.WriteLine("CurrentLg: " + result.return_i5);
                    Console.WriteLine("LgCom: " + result.return_l1);

                    Workflow.empl = SResult2;
                    Workflow.pfunc = Result1;
                    Workflow.WFSU = result.return_i8;
                    Res.CurrentLg = result.return_i5;
                    Res.LgCom = (int)result.return_l1;
                    Res.FillDaysMonths();
                     
                    if (mascode != "-1")
                        Tool.LSWrite("MAS", mascode);

                    Accept();
                }
            }
            else Refuse();
        }

        private void DataTestOnSuccess(Data<Api.ApiReturn> data1, Api.ApiReturn result)
        {
            TestLoginRcv(result.return_b1);
        }

        public override void Accept()
        {
            base.Accept();

            Workflow.connectedPID = PId;

            var fpid = Tool.LSReadInt("forcep", 0);
            if(fpid > 0)
            {
                PId = fpid;
                Tool.LSWrite("forcep", 0);
            }

            Workflow.pid = PId;
            Workflow.wfid_su = Result3;
            Workflow.nom = SResult1;
            Workflow.token = token;
            Workflow.empl = SResult2;
            Workflow.pfunc = Result1;
            //Load§(SResult3);


            var func1 = new Api.ApiFunction();
            func1.function = Api.ApiFuncType.get_catabsence;
            func1.token = Workflow.token;
            func1.iparam1 = Res.LgCom;
            var data1 = new DataApi<Api.API_CatAbs>(Workflow.api, func1);
            data1.OnSuccess += CatAbs_OnSuccess;


        }

        
        private void CFs_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);
            var obj = csv[0];

            var count = result.return_i1;

            for (var i = 0; i < count; i++)
            {
                var id = obj.I32(i * 2 + 0);
                var abrev = obj[i * 2 + 1];
                Workflow.CFAbrev[id] = abrev;
                Workflow.CFAbrevSorted.Add(id, abrev);

            }

            var mensuel = new MensualView.MensualView(_page);

        }



        void CatAbs_OnSuccess(Data<Api.API_CatAbs> data, Api.API_CatAbs result)
        {

            Workflow.CatAbs = result;

            Console.WriteLine("absences count: " + result.abrev.Count);

            var func1 = new Api.ApiFunction();
            func1.function = Api.ApiFuncType.get_CFs;
            func1.token = Workflow.token;
            func1.iparam1 = Res.LgCom;
            var data2 = new DataApi<Api.ApiReturn>(Workflow.api, func1);
            data2.OnSuccess += CFs_OnSuccess;

        }


        public override void Refuse()
        {
            base.Refuse(); 
        } 
         

        public override void GetLoginType() 
        {
            var func = new Api.ApiFunction(); 
            func.function = Api.ApiFuncType.GetLoginType;

            var dataLType = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataLType.OnSuccess += DataLType_OnSuccess;
        }

        private void DataLType_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            if (!result.return_b1)
            { 
                win_user = result.return_s1;
                 
                Console.WriteLine("win user: " + win_user);  
                 
                LoginTypeRcv(result.return_i2);
            } 
            else
            {
                LoginTypeRcv(result.return_i2, result.return_b1, result.return_s1);
            }
             
        }


         
        public override void SaveNewPassword(string user, string pass1, string pass2)
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.login_new_pass;
            func.param1 = user;
            func.param2 = pass1;
            func.param3 = pass2;

            var dataNewPass = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataNewPass.OnSuccess += DataNewPass_OnSuccess;
        }

        private void DataNewPass_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            SetNewPassword(true);
        }
    }
}