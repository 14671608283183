using System;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Workflow_bridge.Login;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge
{
    public class WorkflowApp
    {
        public static WorkflowApp self = null;
        
        private readonly HtmlPage _page = null;
        
        
        public WorkflowApp(HtmlPage page)
        {

            self = this;

            _page = page;

            Workflow.Init();
            Workflow.InitializePath();

            LoadPerm();


            //CreateLogin();
        }

        void LoadPerm()
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.LoadPerm);
            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                var p = b.return_s1;

                //Console.WriteLine(p);

                if (p.Contains("wkdata_complement = true"))
                {
                    Workflow.wkdata_complement = true;
                    Console.WriteLine("wkdata_complement: " + Workflow.wkdata_complement);
                }

                if (p.Contains("akt_hidefixebutton = true"))
                {
                    Workflow.akt_hidefixebutton = true;
                    Console.WriteLine("akt_hidefixebutton: " + Workflow.akt_hidefixebutton);
                }
                

                if (p.Contains("perm_bValidMonth = false"))
                {
                    Workflow.perm_bValidMonth = false;
                    Console.WriteLine("perm_bValidMonth: " + Workflow.perm_bValidMonth);
                }

                if (p.Contains("perm_uploadCost = false"))
                {
                    Workflow.perm_uploadCost = false;
                    Console.WriteLine("perm_uploadCost: " + Workflow.perm_uploadCost);
                }

                if (p.Contains("perm_su_hjexc = true"))
                {
                    Workflow.perm_su_hjexc = true;
                    Console.WriteLine("perm_su_hjexc: " + Workflow.perm_su_hjexc);
                }

                if (p.Contains("hide_outdated_pers = true"))
                {
                    Workflow.hide_outdated_pers = true;
                    Console.WriteLine("hide_outdated_pers: " + Workflow.hide_outdated_pers);
                }

                if (p.Contains("todo_unlock = false"))
                {
                    Workflow.perm_todo_unlock = false;
                    Console.WriteLine("todo_unlock: " + Workflow.perm_todo_unlock);
                }

                if (p.Contains("show_work_data = true"))
                {
                    Workflow.show_work_data = true;
                    Console.WriteLine("show_work_data: " + Workflow.show_work_data);
                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                if (p.Contains("show_work_data_switch = false"))
                {
                    Workflow.show_work_data_switch = false;
                    Console.WriteLine("show_work_data_switch: " + Workflow.show_work_data_switch);
                }

                if (p.Contains("MAS = true") && !Workflow.ForceMASToFalse)
                {
                    Workflow.MAS = true;
                    Console.WriteLine("MAS: " + Workflow.MAS);
                }

                if (p.Contains("activity_full_orders = true"))
                {
                    Workflow.activity_full_orders = true;
                    Console.WriteLine("activity_full_orders: " + Workflow.activity_full_orders);
                }

                if(p.Contains("activity_hide_compl = true"))
                {
                    Workflow.activity_hide_compl = true;
                    Console.WriteLine("activity_hide_compl: " + Workflow.activity_hide_compl);
                }

                if(p.Contains("wf_absrefused_alwaysred = true"))
                {
                    Workflow.wf_absrefused_alwaysred = true;
                    Console.WriteLine("wf_absrefused_alwaysred: " + Workflow.wf_absrefused_alwaysred);
                }

                if (p.Contains("workdata_show_qty = true"))
                {
                    Workflow.workdata_show_qty = true;
                    Console.WriteLine("workdata_show_qty: " + Workflow.workdata_show_qty);
                }

                if(p.Contains("wf_absintro_nolimit_su ="))
                {
                    var idx = p.IndexOf("wf_absintro_nolimit_su ");
                    var p2 = p.Substring(idx + 24);
                    var split = p2.Split(';');
                    
                    Workflow.absintro_special.Clear();
                    for (var i = 0; i < split.Length; i++)
                    {
int v;
                        int.TryParse(split[i], out v);
                        Workflow.absintro_special.Add(v);
                    }
                }

                if(p.Contains("qty_no_zero = true"))
                    Workflow.qty_no_zero = true;

                if(Workflow.ForceMASToFalse)
                {                                                     
                    Tool.LSWrite("MAS", 0);
                }

                if (p.Contains("show_todo_su = false")) Workflow.show_todo_su = false;

                if (p.Contains("mode_prometerre = true")) Workflow.mode_prometerre = true;

                if (p.Contains("qty_less_than_24h = true")) Workflow.qty_less_than_24h = true;

                if (p.Contains("todo_piquet = true")) Workflow.todo_piquet = true;
                if (p.Contains("todo_workopen = true")) Workflow.todo_workopen = true;
                if (p.Contains("perm_HideOldQtyButton = true")) Workflow.perm_HideOldQtyButton = true;

                if (p.Contains("show_saveAkt+ = true")) Workflow.show_saveAktMore = true;

                //if (p.Contains("special_stefan = true")) Workflow.special_stefan = true;

                Console.WriteLine(p);

                Res.CurrentLg = -1;
                Res.LgCom = -1;
                Res.Load();
                Res.OnLoaded += Res_OnLoaded;
            };
        }

        private void Res_OnLoaded()
        {

            if (Res.PathCas == null || Res.PathCas.Length < 5)
                CreateLogin();
            else
                Cas();

        }

        private void Cas()
        {
            Console.WriteLine("Path Cas: " + Res.PathCas);
            Console.WriteLine(Workflow.BaseWebPath);
            var urlToAuthenticate = Workflow.BaseWebPath + "/wf2018/index.html";
            var url = Bridge.Html5.Window.Location.Href;

            if (!url.ToLower().Contains("ticket="))
            {
                var func = new Api.ApiFunction(Api.ApiFuncType.CASCMD);
                func.iparam1 = 1;
                func.param1 = urlToAuthenticate;
                var dataCAS = new DataApi<Api.ApiReturn>(Workflow.api, func);
                dataCAS.OnSuccess += (Data<Api.ApiReturn> data, Api.ApiReturn result) =>
                {
                    if (result.return_i1 == 1)
                    {
                        Console.WriteLine("CAS signed :" + result.return_s1);
                        var loginLink = new LoginLinkWorkflow(_page);
                        loginLink.Login(result.return_s1, "", "", 1);
                    }
                    else if (result.return_i1 == 2)
                    {
                        Console.WriteLine("CAS not signed, goto:" + result.return_s1);
                        Bridge.Html5.Window.Location.Href = result.return_s1;
                    }
                };
            }
            else
            {
                var ticket = url.Substring(url.IndexOf("ticket=") + 7);

                var func = new Api.ApiFunction(Api.ApiFuncType.CASCMD);
                func.iparam1 = 2;
                func.param1 = urlToAuthenticate;
                func.param2 = ticket;
                var dataCAS = new DataApi<Api.ApiReturn>(Workflow.api, func);
                dataCAS.OnSuccess += (Data<Api.ApiReturn> data, Api.ApiReturn result) =>
                {
                    if (result.return_i1 == 1)
                    {
                        Bridge.Html5.Window.Location.Href = urlToAuthenticate;
                    }
                    else if (result.return_i1 == 3)
                    {
                        Console.WriteLine("CAS error" + result.return_s1);
                    }
                };
            }
        }
        
        public void CreateLogin()
        {
            string[] texts = { "Identification", "Login", "Utilisateur", "Mot de passe", "Nouveau mot de passe", "2ème saisie", "Mauvais mot de passe" };

            texts[0] = Res.Get(200, texts[0]);
            texts[1] = Res.Get(200, texts[1]);
            texts[2] = Res.Get(201, texts[2]);
            texts[3] = Res.Get(202, texts[3]);
            texts[4] = Res.Get(210, texts[4]);
            texts[5] = Res.Get(211, texts[5]);
            texts[6] = Res.Get(212, texts[6]);


            var loginLink = new LoginLinkWorkflow(_page);
            var login = new Mobatime.JSControl.Login(_page, loginLink, texts, "", Workflow.Dark);
        }

    }
}