using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;
using Bridge.Html5;

namespace Workflow_bridge.Jumbo
{
    class JumboView
    {

        Panel pPers, pData, pHeader, pIndiv, pJumbo, pPrefer;
        Mobatime.JSControl.Window window = null;
        HtmlPage npage = null;

        public static int ColWidth = 40;

        DateTime d1 = DateTime.Now;
        DateTime d2 = DateTime.MinValue;

        Label lTitle, lDate1, lDate2, lYear;
        Combo cboPer = null;

        Button bClose, bPer1, bPer2, bPer3, bPrint, bIndiv;
        Combo cboType, cboWF, cboIndiv, cboPers;

        Label lCount = null;

        Dictionary<DateTime, Panel> panels = new Dictionary<DateTime, Panel>();

        Label[] lP = new Label[18];
        Label[] lPV = new Label[18];
        Panel[] lPP = new Panel[18];

        bool su = false;

        public void Show(bool su = false)
        {

            this.su = su;
            d1 = new DateTime(DateTime.Now.Year, DateTime.Now.Month, 1);
            d2 = d1.AddMonths(1).AddDays(-1);

            Workflow.SaveLocalData();

            var id = 2000;
            if (su) id = 2001;

            Bridge.Html5.Window.Open("index.html?func=" + id, "MobaWFJumbo", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1440, height=900");
        }

        public void Place(HtmlPage page, bool su)
        {
            this.su = su;
            npage = page;

            calcPeriode(0);

            Document.Title = "Mobatime JBM workflow - Aperçu";

            CreateWindow();
        }

        void CreateWindow()
        {
            window = new Mobatime.JSControl.Window(npage, 40);

            

            bClose = window.Toolbar.AddButton(1000);
            bPer1 = window.Toolbar.AddButton(-1);
            bPer2 = window.Toolbar.AddButton(0);
            bPer3 = window.Toolbar.AddButton(1);
            bPrint = window.Toolbar.AddButton(10);
            bIndiv = window.Toolbar.AddButton(2000);

            bClose.OnButtonClick += BPer1_OnButtonClick;
            bPer1.OnButtonClick += BPer1_OnButtonClick;
            bPer2.OnButtonClick += BPer1_OnButtonClick;
            bPer3.OnButtonClick += BPer1_OnButtonClick;
            bPrint.OnButtonClick += BPrint_OnButtonClick;
            bIndiv.OnButtonClick += BPer1_OnButtonClick;

            var imgdir = Workflow.BaseWebPath + "/wf2018/";

            bClose.SetImage(imgdir + "images/63_porte_t.png", 32, 32);
            bPer1.SetImage(imgdir + "images/arrow_left.png", 32, 32);
            bPer2.SetImage(imgdir + "images/now.png", 32, 32);
            bPer3.SetImage(imgdir + "images/arrow_right.png", 32, 32);
            bPrint.SetImage(imgdir + "images/printer-64.png", 32, 32);
            bIndiv.SetImage(imgdir + "images/calendar@32px.png", 32, 32);

            npage.OnBodyResize += Npage_OnBodyResize;

            var w = npage.Width;// win.InnerWidth;
            var h = npage.Height;// win.InnerHeight;


            pJumbo = new Panel(window.Container);
            pJumbo.Place(0, 0, 0, 0);
            pJumbo.Visible = Visibility.Inherit;

            pIndiv = new Panel(window.Container);
            pIndiv.Place(0, 0, 0, 0);
            pIndiv.Visible = Visibility.Hidden;

            var p = new Panel(pJumbo);
            p.Place(0, 0, 250, 40);
            p.BackgroundColor = new Color(240);
            var p2 = new Panel(p);
            p2.Place(0, 0, p.Width - 1, p.Height - 1);
            p2.BackgroundColor = new Color(255);


            pPers = new Panel(pJumbo);
            pPers.Place(0, 41, 250, h - 41 - 40 - 20);
            pPers.BackgroundColor = new Color(240);
            pPers.SetScrolling(Bridge.Html5.Overflow.Hidden, Bridge.Html5.Overflow.Hidden);

            pData = new Panel(pJumbo);
            pData.Place(pPers.Width, 41, w - pPers.Width, h - 41 - 40);
            pData.BackgroundColor = new Color(240);
            pData.SetScrolling(Bridge.Html5.Overflow.Scroll, Bridge.Html5.Overflow.Scroll);
            pData.Div.OnScroll += Div_OnScroll;

            pHeader = new Panel(pJumbo);
            pHeader.Place(pPers.Width, 0, w - pPers.Width - 20, 40);
            pHeader.BackgroundColor = new Color(240);
            pHeader.SetScrolling(Bridge.Html5.Overflow.Hidden, Bridge.Html5.Overflow.Hidden);


            var title = "Jumbo";
            if (su) title = "Jumbo Team";
            lTitle = new Label(title, w - 200, 12, 180, 16, "right");
            window.Toolbar.AddChild(lTitle);
            lTitle.Color = Color.White;
            lTitle.FontSize = 14;


            cboIndiv = new Combo();
            cboIndiv.Place(268 + 128, 4, 150, 32);
            cboIndiv.Visible = Visibility.Hidden;
            window.Toolbar.AddChild(cboIndiv);
            cboIndiv.Add(0, Res.Get(2020, "N° jours alignés"));
            cboIndiv.Add(1, Res.Get(2021, "Jours alignés"));
            cboIndiv.SelectById(1);
            cboIndiv.OnChange += CboIndiv_OnChange;

            cboPer = new Combo();
            cboPer.Place(268 + 64, 4, 150, 32);
            window.Toolbar.AddChild(cboPer);
            cboPer.Add(1, "1 " + Res.Get(2000, "mois"));
            cboPer.Add(2, "2 " + Res.Get(2000, "mois"));
            cboPer.Add(3, "3 " + Res.Get(2000, "mois"));
            cboPer.Add(4, "4 " + Res.Get(2000, "mois"));
            cboPer.Add(6, "6 " + Res.Get(2000, "mois"));
            cboPer.Add(12, "12 " + Res.Get(2000, "mois"));

            lDate1 = new Label("date 1", cboPer.Right + 8, 4, 300, 16, "left");
            lDate1.Color = Color.White;
            window.Toolbar.AddChild(lDate1);
            lDate2 = new Label("date 2", cboPer.Right + 8, 20, 300, 16, "left");
            lDate2.Color = Color.White;
            window.Toolbar.AddChild(lDate2);

            lYear = new Label("2020", bIndiv.Right + 32, 8, 100, 24, "left");
            lYear.Color = Color.White;
            lYear.FontSize = 14;
            lYear.Visible = Visibility.Hidden;
            window.Toolbar.AddChild(lYear);

            cboPer.OnChange += CboPer_OnChange;
            cboPer.SelectById(1, true);




            if (!su)
            {
                cboType = new Combo();
                cboType.Place(lDate1.Right + 16, 4, 250, 32);
                window.Toolbar.AddChild(cboType);
                cboType.Add(1, Res.Get(2001, "Mon équipe"));
                cboType.Add(2, Res.Get(2002, "Mes collaborateurs"));
                cboType.Add(3, Res.Get(2003, "Mes collaborateurs soldes"));
                cboType.Visible = Bridge.Html5.Visibility.Visible;
                cboType.OnChange += CboType_OnChange;


                cboWF = new Combo();
                cboWF.Place(cboType.Right + 8, 4, 250, 32);
                window.Toolbar.AddChild(cboWF);
                cboWF.Add(0, "Tout le monde");
                cboWF.Visible = Bridge.Html5.Visibility.Hidden;
                cboWF.SelectById(0);
                cboType.SelectById(1);
                cboWF.OnChange += CboWF_OnChange;
            }

            lCount = new Label("", 8, 8, 100, 16, "left", pJumbo);
            lCount.FontSize = 9;
            lCount.Color = new Color(150);

            
        }

        private void CboIndiv_OnChange(Combo combo)
        {
            CreateAppIndiv();
            loadDataIndiv();
        }

        private void BPrint_OnButtonClick(Button c, MouseEvent arg)
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.WFPrintJumbo;
            func.iparam1 = Workflow.pid;
            func.iparam2 = 1;
            func.iparam3 = Workflow.wfid_su;
            if (Workflow.RightSU.JumboSelf) func.iparam6 = 1;

            if (cboType != null && cboType.Visible == Visibility.Visible)
            {
                func.iparam2 = cboType.SelectedId;
            }

            if (func.iparam2 > 1)
            {
                func.iparam3 = cboWF.SelectedId;

                if (func.iparam3 == 9999)
                {
                    foreach (var wf in cboWF.Items)
                    {
int wfid;
                        int.TryParse(wf.Value, out wfid);
                        if (wfid == 0) continue;

                        func.param1 += wfid + ";";
                    }
                }
            }

            func.iparam4 = D2000.T(d1);
            func.iparam5 = D2000.T(d2);

            var dataPrint = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataPrint.OnSuccess += (Data<Api.ApiReturn> data, Api.ApiReturn result) =>
            {
                var file = Workflow.BaseWebPath + "/print/" + result.return_s1;
                Bridge.Html5.Window.Open(file, "pdf", "");
            };
        }

        private void CboWF_OnChange(Combo combo)
        {
            if (pIndiv.Visible == Visibility.Hidden)
                loadData();
            else
                FillCboPers();
        }

        private void FillCboPers()
        {
            cboPers.Clear();
            cboPers.Add(Workflow.pid, "-. " + Workflow.nom + " .-");

            if (cboWF != null && cboWF.Visible == Visibility.Visible)
            {
                var func = new Api.ApiFunction(Api.ApiFuncType.get_workflow_pop);
                func.iparam1 = cboWF.SelectedId;
                func.iparam2 = D2000.T(new DateTime(d1.Year, 1, 1));
                func.iparam3 = D2000.T(new DateTime(d1.Year, 12, 31));

                var dataPop = new DataApi<Api.ApiReturn>(Workflow.api, func);
                dataPop.OnSuccess += ((Data<Api.ApiReturn> data, Api.ApiReturn result) =>
                {
                    var csv = new CSVTool();
                    csv.LoadData(result.return_s1);

                    var count = result.return_i1;

                    for (var i = 0; i < count; i++)
                    {
                        var id = csv[0].I32(i * 2 + 0);
                        var name = csv[0].String(i * 2 + 1);
                        cboPers.Add(id, name);
                    }

                    var selpid = Tool.LSReadInt("cbopersindiv", -1);
                    if (selpid > -1)
                    {
                        Tool.LSWrite("cbopersindiv", -1);
                        cboPers.SelectById(selpid, true);
                    }
                    else
                        cboPers.SelectByIdx(0);
                });

            }
        }

        private void CboType_OnChange(Combo combo)
        {
            if (cboType.SelectedId > 1)
            {
                cboWF.Visible = Visibility.Visible;
                FillCBOWF(Workflow.RightSU);
            }
            else cboWF.Visible = Visibility.Hidden;

            if (cboType.SelectedId == 3) bPrint.Visible = Visibility.Hidden;
            else bPrint.Visible = Visibility.Inherit;

            loadData();
        }

        private void BPer1_OnButtonClick(Button c, Bridge.Html5.MouseEvent arg)
        {
            var id = c.id1;

            if(cboPers != null && cboPers.Visible != Visibility.Hidden)
            {
                Tool.LSWrite("cbopersindiv", cboPers.SelectedId);
            }

            if (id < 1000)
            {
                calcPeriode(id);
                if (pJumbo.Visible == Visibility.Inherit)
                {
                    
                    loadData();
                }
                else
                {
                    CreateAppIndiv();
                    loadDataIndiv();
                }
            }
            else if(id == 1000)
            {
                Bridge.Html5.Window.Close();
            }
            else if(id == 2000)
            {
                if(pJumbo.Visible == Visibility.Inherit)
                {
                    pJumbo.Visible = Visibility.Hidden;
                    pIndiv.Visible = Visibility.Inherit;
                    lYear.Visible = Visibility.Inherit;
                    bPrint.Visible = Visibility.Hidden;
                    cboPer.Visible = Visibility.Hidden;
                    lDate1.Visible = Visibility.Hidden;
                    lDate2.Visible = Visibility.Hidden;
                    cboIndiv.Visible = Visibility.Inherit;
                    if (cboType != null)
                        cboType.Visible = Visibility.Hidden;

                    calcPeriode(0);
                    CreateAppIndiv();
                    loadDataIndiv();
                }
                else
                {
                    pJumbo.Visible = Visibility.Inherit;
                    pIndiv.Visible = Visibility.Hidden;
                    lYear.Visible = Visibility.Hidden;
                    //bPrint.Visible = Visibility.Inherit;
                    if (cboType != null && cboType.SelectedId == 3) bPrint.Visible = Visibility.Hidden;
                    else bPrint.Visible = Visibility.Inherit;
                    cboPer.Visible = Visibility.Inherit;
                    lDate1.Visible = Visibility.Inherit;
                    lDate2.Visible = Visibility.Inherit;
                    cboIndiv.Visible = Visibility.Hidden;

                    if (cboType != null)
                        cboType.Visible = Visibility.Visible;

                    calcPeriode(0);
                    loadData();
                }

                
                
            }
        }

        private void CboPer_OnChange(Combo combo)
        {
            calcPeriode(99);
            loadData();
        }

        private void Npage_OnBodyResize()
        {
            var w = npage.Width;// win.InnerWidth;
            var h = npage.Height;// win.InnerHeight;

            pPers.Place(0, 41, 200, h - 64 - 20);
            pData.Place(pPers.Width + 1, pPers.Top, w - pPers.Width - 1, h - 41 - 40);
            pHeader.Place(pPers.Width + 1, 0, w - pPers.Width - 1 - 20, 40);

            lTitle.Left = w - 200;
        }

        void Div_OnScroll(Bridge.Html5.Event<Bridge.Html5.HTMLDivElement> arg)
        {
            pHeader.Div.ScrollLeft = pData.Div.ScrollLeft;
            pPers.Div.ScrollTop = pData.Div.ScrollTop;
        }

        private void WinOnResize(Bridge.Html5.Event arg)
        {

        }

        void calcPeriode(int sens = 0)
        {
            if (pJumbo == null || pJumbo.Visible == Visibility.Inherit)
            {

                if (sens == 0)
                    d1 = new DateTime(DateTime.Now.Year, DateTime.Now.Month, 1);
                else if (sens != 0 && sens < 99)
                    d1 = d1.AddMonths(sens);

                if (cboPer != null)
                {
                    var nbMonth = 0;
                    nbMonth = cboPer.SelectedId - 1;
                    d2 = d1.AddMonths(nbMonth);
                    d2 = new DateTime(d2.Year, d2.Month, DateTime.DaysInMonth(d2.Year, d2.Month));
                }
                else
                {
                    d2 = new DateTime(d1.Year, d1.Month, DateTime.DaysInMonth(d1.Year, d1.Month));
                }

                if (lDate1 != null)
                {
                    lDate1.Text = Fmt.DateComplete(d1);
                    lDate2.Text = Fmt.DateComplete(d2);
                }

                if (lYear != null)
                    lYear.Text = d1.Year.ToString();
            }
            else
            {
                if (sens == 0)
                    d1 = new DateTime(DateTime.Now.Year, DateTime.Now.Month, 1);
                else if (sens != 0 && sens < 99)
                    d1 = d1.AddYears(sens);

                lYear.Text = d1.Year.ToString();
            }
        }

        void loadData()
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.GetWFJumbo;
            func.iparam1 = Workflow.pid;
            func.iparam2 = 1;
            func.iparam3 = Workflow.wfid_su;
            if (Workflow.RightSU.JumboSelf) func.iparam6 = 1;
           
            if (cboType != null && cboType.Visible == Visibility.Visible)
            {
                func.iparam2 = cboType.SelectedId;
            }

            if (func.iparam2 > 1)
            {
                func.iparam3 = cboWF.SelectedId;

                if (func.iparam3 == 9999)
                {
                    foreach (var wf in cboWF.Items)
                    {
int wfid;
                        int.TryParse(wf.Value, out wfid);
                        if (wfid == 0) continue;

                        func.param1 += wfid + ";";
                    }
                }
            }

            func.iparam4 = D2000.T(d1);
            func.iparam5 = D2000.T(d2);

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += Data_OnSuccess;
        }

        void loadDataIndiv()
        {
            var nd = new DateTime(d1.Year, 1, 1);

            Console.WriteLine(string.Format("cbopers: {0}",cboPers.SelectedId));

            var func = new Api.ApiFunction(Api.ApiFuncType.GetWFIndiv);
            func.iparam1 = cboPers.SelectedId;
            func.iparam2 = d1.Year;
            var dataIndiv = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataIndiv.OnSuccess += ((Data<Api.ApiReturn> data, Api.ApiReturn result) =>
            {
                var csv = new CSVTool(result.return_s1)[0];
                var csv2 = new CSVTool(result.return_s2)[0];
                var csv3 = new CSVTool(result.return_s3)[0];
                var count = result.return_i1;
                var days = result.return_i2;

                for (var i = 0; i < days; i++)
                {
                    var p = panels[nd];

                    var contrat = csv.I32(i * count + 0);
                    var hn = csv.I32(i * count + 1);
                    var pres = csv.I32(i * count + 2);

                    var ac1 = csv.I32(i * count + 3);
                    var at1 = csv.I32(i * count + 4);
                    var ad1 = csv.I32(i * count + 5);
                    var ac2 = csv.I32(i * count + 6);
                    var at2 = csv.I32(i * count + 7);
                    var ad2 = csv.I32(i * count + 8);

                    p.ClearChildren();

                    if (nd.DayOfWeek == DayOfWeek.Sunday)
                        p.BackgroundColor = new Color(240);
                    else
                        p.BackgroundColor = Color.White;

                    p.SetBorderStyle(1, "solid", new Color(240));

                    if (contrat <= 0)
                    {
                        p.BackgroundColor = new Color(150);
                    }
                    else
                    {
                        if (hn > 0) p.SetBorderStyle(1, "solid", Color.Mobatime2);
                        SetAbs(ac1, at1, ad1, ac2, at2, ad2, nd, p);
                    }
                    nd = nd.AddDays(1);
                }

                var prefer = csv2.Bool(0);
                if (prefer)
                {
                    pPrefer.Visible = Visibility.Inherit;

                    for (var i = 0; i < csv3.Count; i += 4)
                    {
                        var tid = csv3.I32(i);
                        var txt = csv3[i + 1];
                        var val = csv3[i + 2];
                        var col = csv3.I32(i + 3);

                        lP[tid].Text = txt + " :";
                        lPV[tid].Text = val;
                        lPP[tid].BackgroundColor = Color.White;
                        if(col > -1)
                        {
                            Console.WriteLine("col: " + col);
                            lPP[tid].BackgroundColor = Color.ColorV6(col);
                        }
                        
                    }
                }
                else pPrefer.Visible = Visibility.Hidden;

            });
        }

        private void SetAbs(int ac1, int at1, int ad1, int ac2, int at2, int ad2, DateTime d, Panel p)
        {
            if(ac1 > 0 && ac2 > 0)
            {
                DrawAbs(ac1, 0, 0, p.Width, p.Height / 2 + 1, p);
                DrawAbs(ac2, 0, p.Height / 2 + 1, p.Width, p.Height / 2, p);

                var title = Fmt.DateComplete(d) + "\r\n" + Workflow.CatAbs.name[ac1] + " " + Fmt.HHmm(ad1);
                title += "\r\n" + Workflow.CatAbs.name[ac2] + " " + Fmt.HHmm(ad2);
                p.Div.Title = title;
            }
            else if(ac1 > 0)
            {
                if (ad1 < 300) DrawAbs(ac1, 0, p.Height / 2 + 1, p.Width, p.Height / 2, p);
                else DrawAbs(ac1, 0, 0, p.Width, p.Height, p);

                var title = Fmt.DateComplete(d) + "\r\n" + Workflow.CatAbs.name[ac1] + " " + Fmt.HHmm(ad1);
                p.Div.Title = title;
            }
            else if(ac2 > 0)
            {
                if (ad2 < 300) DrawAbs(ac2, 0, 0, p.Width, p.Height / 2, p);
                else DrawAbs(ac2, 0, 0, p.Width, p.Height, p);

                var title = Fmt.DateComplete(d) + "\r\n" + Workflow.CatAbs.name[ac2] + " " + Fmt.HHmm(ad2);
                p.Div.Title = title;
            }
        }

        private void DrawAbs(int ac, int x, int y, int w, int h, Panel p)
        {
            var colId = Workflow.CatAbs.color[ac];
            //var text = Workflow.CatAbs.txt_info[ac];
            var color = Workflow.Colors16[colId];

            if (w == p.Width && h == p.Height)
                p.BackgroundColor = color;
            else
            {
                var np = new Panel(p);
                np.Place(x, y, w, h);
                np.BackgroundColor = color;
            }
        }

        private void Data_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);

            ////Console.WriteLine(cboType.SelectedId);

            if (cboType != null && cboType.SelectedId == 3) makeSolde(csv);
            else makeJumbo(csv);
        }

        void makeJumbo(CSVTool csv)
        {
            pHeader.ClearChildren();
            pData.ClearChildren();
            pPers.ClearChildren();


            var nbDay = (int)(d2 - d1).TotalDays;
            nbDay++;

            var oldD = d1.AddMonths(-1);
            int nbMonth = 0;
            for (var d = d1; d <= d2; d = d.AddDays(1))
            {
                if (oldD.Month != d.Month)
                {
                    nbMonth++;
                    oldD = d;
                }
            }

            oldD = d1.AddMonths(-1);
            var k = 0;
            var x = 0;

            for (var d = d1; d <= d2; d = d.AddDays(1))
            {

                if (oldD.Month != d.Month)
                {
                    var w = DateTime.DaysInMonth(d.Year, d.Month) * ColWidth;

                    if (x > 0)
                        x += 3;

                    var p = new Panel();
                    p.Place(x, 0, w - 1, 23, pHeader);
                    p.BackgroundColor = new Color(255);

                    var l1 = new Label("", 5, 2, w - 10, 16, "left", p);
                    l1.Text = Res.months[d.Month - 1][0].ToString().ToUpper() + Res.months[d.Month - 1].Substring(1) + " " + d.Year;
                    l1.BackgroundColor = new Color(255);

                }

                var l2 = new Label("", x, 23, ColWidth - 1, 16, "center", pHeader);
                l2.Text = d.Day.ToString();
                if (d.DayOfWeek != DayOfWeek.Sunday) l2.BackgroundColor = new Color(255);
                else l2.BackgroundColor = new Color(245);

                x += ColWidth;
                k++;
                oldD = d;
            }

            if (cboType != null && cboType.SelectedId == 2)
            {
                string[] sn = { "HN", "Prés", "+-" };

                sn[0] = Res.Get(1101, sn[0]);
                sn[1] = Res.Get(1102, sn[1]);

                for (var i = 0; i < 3; i++)
                {
                    var l2 = new Label("", x, 23, ColWidth * 2 - 1, 16, "center", pHeader);
                    l2.Text = sn[i];
                    l2.BackgroundColor = new Color(245);

                    x += ColWidth * 2;
                }
            }


            int count = 0;
            for (var i = 0; i < csv.ObjectsCount; i++)
            {
                var obj = csv[i];

                var pid = obj.I32(0);

                var rowP = new JumboRowPers(pid, obj[1]);
                pPers.AddChild(rowP);
                rowP.Top = i * 33;

                var type = 1;
                if (cboType != null) type = cboType.SelectedId;

                //Console.WriteLine(d1 + "   " + d2 + "  " + pid + "  " + type);

                var rowD = new JumboRow(pid, d1, d2, type, obj, null);
                pData.AddChild(rowD);
                rowD.Top = i * 33;
                count++;
            }

            lCount.Text = count.ToString();

        }

        void makeSolde(CSVTool csv)
        {
            pHeader.ClearChildren();
            pData.ClearChildren();
            pPers.ClearChildren();

            var objT = csv[0];

            var count = objT.I32(0);

            var inDays = new int[6];

            var x = 0;
            for (var i = 0; i < count; i++)
            {
                var title = objT.strings[1 + i * 2 + 0];
                inDays[i] = objT.I32(1 + i * 2 + 1);

                var l2 = new Label(title, x, 23, ColWidth * 3 - 1, 16, "center", pHeader);
                l2.BackgroundColor = new Color(255);

                x += ColWidth * 3;
            }

            for (var i = 1; i < csv.ObjectsCount; i++)
            {
                var obj = csv[i];

                var pid = obj.I32(0);

                var rowP = new JumboRowPers(pid, obj[1]);
                pPers.AddChild(rowP);
                rowP.Top = (i - 1) * 33;

                var type = 3;

                var rowD = new JumboRow(pid, d1, d2, type, obj, inDays);
                pData.AddChild(rowD);
                rowD.Top = (i - 1) * 33;
            }

        }

        private void FillCBOWF(Right result)
        {
            var current_id = cboWF.SelectedId;

            cboWF.Clear();

            Workflow.WFProfil.Clear();



            if (result != null)
            {
                for (var i = 0; i < result.WFChefIDs.Count; i++)
                {
                    var id = result.WFChefIDs[i];
                    var name = result.WFChefNames[i];
                    cboWF.Add(id, name);
                }
            }

            cboWF.Sort();

            if (cboType.SelectedId == 2)
                cboWF.Add(9999, "*.*");

            cboWF.SelectById(current_id);


        }


        private void CreateAppIndiv()
        {
            panels.Clear();
            pIndiv.ClearChildren();

            var mode = cboIndiv.SelectedId;
            var year = d1.Year;
            var yStart = 96;
            var xStart = 32 + 100 + 10;
            var didMax = 0;

            cboPers = new Combo();
            cboPers.Place(lDate1.Right + 274, 8, 250, 32, pIndiv);
            cboPers.OnChange += CboPers_OnChange;

            for (var i = 0; i < 12; i++)
            {
                var y = yStart + i * 40;
                var lm = new Label(Res.months[i], 32, y + 14, 100, 16, "right", pIndiv);
                var dim = DateTime.DaysInMonth(year, i + 1);

                var offset = 0;

                if (mode == 1)
                {
                    var firstDay = new DateTime(year, i + 1, 1);
                    var did = (int)firstDay.DayOfWeek;
                    if (did == 0) did = 7;
                    did--;

                    if (did > didMax) didMax = did;

                    offset = did * 33;
                }

                for (var j = 0; j < 31; j++)
                {
                    if (j >= dim) continue;

                    var x = xStart + offset + j * 33;

                    var d = new DateTime(year, i + 1, j + 1);

                    var p = new Panel(pIndiv);
                    p.Place(x, y, 30, 37);

                    if (d.DayOfWeek == DayOfWeek.Sunday) p.BackgroundColor = new Color(240);
                    else p.BackgroundColor = new Color(250);

                    p.SetBorderStyle(1, "solid", new Color(230));

                    panels.Add(d, p);
                    p.SetAttribute("dateY", year.ToString());
                    p.SetAttribute("dateM", (i + 1).ToString());
                    p.SetAttribute("dateD", (j + 1).ToString());
                }
            }


            if (mode == 0)
            {
                for (var i = 0; i < 31; i++)
                {
                    var lm = new Label((i + 1).ToString(), xStart + i * 33, yStart - 24, 30, 16, "center", pIndiv);
                }
            }
            else
            {
                for (var i = 0; i < 31 + didMax; i++)
                {
                    var did = i % 7;
                    var lm = new Label(Res.days2[did], xStart + i * 33, yStart - 24, 30, 16, "center", pIndiv);
                }
            }

            FillCboPers();

            var pp = pPrefer = new Panel(32, 630, 1280, 160, Color.White, pIndiv);
            for(var i = 0; i < 18; i++)
            {
                var x = i / 5;
                var y = i % 5;
                y *= 30;
                x *= 190;
                y += 16;
                var l1 = lP[i] = new Label("", x, y, 120, 16, "right", pp);
                var lv1 = lPV[i] = new Label("", x + 128, y, 62, 16, "right", pp);
                var pColor = lPP[i] = new Panel(lv1.Right + 4, y, 5, 16, Color.White, pp);
            }


        }

        private void CboPers_OnChange(Combo combo)
        {
            //Console.WriteLine("cboid: " + cboPer.SelectedId);
            loadDataIndiv();
        }
    }
}
