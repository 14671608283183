using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Mobatime.JSControl;
using Bridge.Html5;

namespace Workflow_bridge.Pip
{
    class p4Weeks
    {

        Panel parent = null;
        Panel back = null;
        Panel bp = null;

        Button bClose = null;

        Label[] lweek = new Label[4];
        Label[] ldate = new Label[28];
        Label[] labs1 = new Label[28];
        Label[] labs2 = new Label[28];
        Panel[] pabs1 = new Panel[28];
        Panel[] pabs2 = new Panel[28];

        Label lnom = null;

        int fulldayHeight = 0;
        bool showAbs = false;

        public p4Weeks(Panel parent)
        {
            this.parent = parent;
            back = new Panel(parent);
            back.Place(0, -40, parent.Width, parent.Height + 40);
            //back.Size = new Size(parent.Width, parent.Height);
            back.BackgroundColor = Color.White;
            this.parent.OnResize += Parent_OnResize;

            bp = new Panel(back);
            bp.Place(0, 0, back.Width, 40);
            bp.BackgroundColor = Color.Mobatime1;

            bClose = new Button(4, 4, 32, 32, "images/64annule_t.png", bp);
            bClose.OnButtonClick += BClose_OnButtonClick;

            lnom = new Label("", new Point(bp.Width - 320, 8), new Size(300, 32), "right");
            lnom.FontSize = 14;
            lnom.Color = Color.White;
            bp.AddChild(lnom);

            var p5 = new Panel(back);
            p5.Place(66, bp.Bottom + 16, 700, 30);
            p5.BackgroundColor = new Color(245);

            for (var i = 0; i < 7; i++)
            {
                var l = new Label(Res.days[i], new Point(i * 100, 4), new Size(100, 16), "Center");
                l.FontSize = 9;
                p5.AddChild(l);
            }

            var k = 0;
            for (var i = 0; i < 4; i++)
            {
                var p = new Panel(back);
                p.Place(16, bp.Bottom + 16 + 31 + i * 100, 750, 92);
                p.BackgroundColor = new Color(200);

                var p2 = new Panel(p);
                p2.Place(1, 1, 48, p.Height - 2);
                p2.BackgroundColor = new Color(245);

                var l = lweek[i] = new Label("34", new Point(0, p2.Height / 2 - 12), new Size(p2.Width, p2.Height), "Center");
                l.FontSize = 14;
                p2.AddChild(l);

                for(var j = 0; j < 7; j++)
                {
                    var p3 = new Panel(p);
                    p3.Place(50 + j * 100, 1, 99, 30);
                    p3.BackgroundColor = new Color(245);

                    var p4 = new Panel(p);
                    p4.Place(p3.Left, 32, p3.Width, p.Height - p3.Bottom - 2);
                    p4.BackgroundColor = new Color(255);

                    var l2 = ldate[k] = new Label("", new Point(0, 8), new Size(p3.Width, 16), "center");
                    l2.FontSize = 9;
                    p3.AddChild(l2);

                    var l3 = labs1[k] = new Label("", new Point(0, 6), new Size(p3.Width, 16), "center");
                    var l4 = labs2[k] = new Label("", new Point(0, 34), new Size(p3.Width, 16), "center");

                    pabs1[k] = new Panel(p4);
                    pabs1[k].Place(4, 2, 8, p4.Height / 2 - 3);
                    //pabs1[k].BackgroundColor = Color.Red;

                    pabs2[k] = new Panel(p4);
                    pabs2[k].Place(4, p4.Height / 2 + 2, 8, p4.Height / 2 - 3);
                    //pabs2[k].BackgroundColor = Color.Blue;

                    p4.AddChild(l3);
                    p4.AddChild(l4);


                    k++;
                }

                fulldayHeight = pabs1[0].Height;
            }

        }

        private void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            Hide();
        }


        private void Parent_OnResize(Control c)
        {
            back.Place(0, -40, parent.Width, parent.Height + 40);
            bp.Place(0, 0, parent.Width, 40);
            lnom.Place(bp.Width - 320, 8, 300, 32);
        }

        public void Update(int pid, bool showAbs)
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.get_4weeks_info);
            func.token = Workflow.token;
            var dte = DateTime.Now.Date;
            var did = (int)dte.DayOfWeek;
            if (did == 0) did = 7;
            did--;

            dte = dte.AddDays(-did);

            func.iparam1 = pid;
            func.iparam2 = D2000.T(dte);

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.d1 = dte;
            data.OnSuccess += Data_OnSuccess;

            this.showAbs = showAbs;

        }

        private void Data_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);
            var abs = csv[0];

            for(var i = 0; i < 4; i++)
            {
                var wid = Tool.ISOWeekNumber(data.d1.AddDays(3 + i * 7));
                lweek[i].Text = wid.ToString();
            }

            for(var i = 0; i < 28; i++)
            {
                var dte = data.d1.AddDays(i);
                ldate[i].Text = dte.Day + " " + Res.months[dte.Month - 1];


                var ac1 = abs.I32(i * 6 + 0);
                var txt1 = abs[i * 6 + 1];
                var fulld1 = abs.Bool(i * 6 + 2);

                var ac2 = abs.I32(i * 6 + 3);
                var txt2 = abs[i * 6 + 4];
                var fulld2 = abs.Bool(i * 6 + 5);

                var col1 = Color.White;
                var col2 = Color.White;

                if(ac1 > 0)
                {
                    if (txt1.Trim() == "") col1 = new Color(220);
                    else
                    {
                        var color = Workflow.CatAbs.color[ac1];
                        col1 = GetColor(color);
                    }
                }

                if (ac2 > 0)
                {
                    if (txt2.Trim() == "") col2 = new Color(220);
                    else
                    {
                        var color = Workflow.CatAbs.color[ac2];
                        col2 = GetColor(color);
                    }
                }

                if (showAbs)
                {
                    labs1[i].Text = txt1;
                    labs2[i].Text = txt2;
                    pabs1[i].BackgroundColor = col1;
                    pabs2[i].BackgroundColor = col2;

                    if (fulld1) pabs1[i].Height = fulldayHeight;
                    else pabs1[i].Height = fulldayHeight / 2;

                    if (fulld2) pabs2[i].Height = fulldayHeight;
                    else pabs2[i].Height = fulldayHeight / 2;
                }
                else
                {
                    labs1[i].Text = "";
                    labs2[i].Text = "";
                    pabs1[i].BackgroundColor = Color.White;
                    pabs2[i].BackgroundColor = Color.White;
                }
            }

            lnom.Text = result.return_s2;

            Show();

        }

        Color GetColor(int id)
        {
            return Workflow.Colors16[id];
        }

        public void Show()
        {
            back.Visible = Visibility.Inherit;
        }

        public void Hide()
        {
            back.Visible = Visibility.Hidden;
        }
    }
}
