using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Workflow_bridge.WorkActivity
{
    public class SchemaPrometerre
    {
        public int OFId = 0;

        public List<int> ordres = new List<int>();
        public List<int> ordres_tt = new List<int>();

        public List<int> tt = new List<int>();
    }
}
