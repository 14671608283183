using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using System.Linq;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class pShowGT : Panel
    {

        PanelLabel lHj = null;
        PanelLabel lPres = null;
        PanelLabel lOrdre = null;
        PanelLabel lOrdre2 = null;
        Label percentO1 = null;
        Label percentO2 = null;
        PanelLabel lAbs1, lAbs2;
        Label[] lPtg = new Label[12];
        CheckBox cAM, cPM;

        int pid = 0;
        DateTime date = DateTime.MinValue;

        public pShowGT(Panel parent) : base(700, 514, parent.Width - 700 - 16, parent.Height - 514 - 16, Color.White, parent)
        {
            Label l;

            l = new Label(Res.Get(9070, "Gestion du temps"), 0, 4, this.Width - 4, 16, "right", this);
            l.Color = new Color(200);

            CreateHJ();
            CreatePtg();
        }

        void CreateHJ()
        {
            var p = new Panel(16, 32, Width / 2 - 24, this.Height - 48, Color.White, this);
            p.SetBorderStyle(1, "solid", new Color(240));

            var l = new Label(Res.Get(9071, "Horaire journalier"), 0, 8, p.Width, 16, "center", p);
            l.FontSize = 9;

            var y = 30;

            var pl = lHj = new PanelLabel("", 16, y, p.Width - 32, 20, "center", p);
            pl.FontSize = 9;
            pl.BackgroundColor = new Color(240);
            pl.SetBorderStyle(1, "solid", new Color(200));

            y += 42;

            l = new Label(Res.Get(9072, "Présence"), 16, y, pl.Width / 2, 16, "right", p);
            l.FontSize = 9;
            pl = lPres = new PanelLabel("", l.Right + 8, l.Top - 4, 50, 20, "center", p);
            pl.FontSize = 9;
            pl.BackgroundColor = new Color(192, 255, 192);
            pl.SetBorderStyle(1, "solid", new Color(200));

            y += 28;

            l = new Label(Res.Get(9073, "T/O"), 16, y, l.Width, 16, "right", p);
            l.FontSize = 9;
            pl = lOrdre = new PanelLabel("", l.Right + 8, l.Top - 4, 50, 20, "center", p);
            pl.FontSize = 9;
            pl.BackgroundColor = new Color(192, 224, 255);
            pl.SetBorderStyle(1, "solid", new Color(200));

            percentO1 = new Label("100%", pl.Right + 8, pl.Top + 4, 40, 16, "right", p);
            percentO1.FontSize = 9;


            y += 28;

            l = new Label("T/O Exporté", 16, y, l.Width, 16, "right", p);
            l.FontSize = 9;
            pl = lOrdre2 = new PanelLabel("", l.Right + 8, l.Top - 4, 50, 20, "center", p);
            pl.FontSize = 9;
            pl.BackgroundColor = new Color(192, 224, 255);
            pl.SetBorderStyle(1, "solid", new Color(200));

            percentO2 = new Label("100%", percentO1.Left, pl.Top + 4, percentO1.Width, 16, "right", p);
            percentO2.FontSize = 9;

            y += 32;

            y += 32;

            l = new Label(Res.Get(9074, "Absence 1 & 2"), 16, y, p.Width - 32, 16, "left", p);
            l.FontSize = 9;

            pl = lAbs1 = new PanelLabel("", 16, l.Top + 16, p.Width - 32, 20, "center", p);
            pl.FontSize = 9;
            pl.SetBorderStyle(1, "solid", new Color(200));

            pl = lAbs2 = new PanelLabel("", 16, pl.Bottom + 8, p.Width - 32, 20, "center", p);
            pl.FontSize = 9;
            pl.SetBorderStyle(1, "solid", new Color(200));

        }

        void CreatePtg()
        {
            var p = new Panel(Width / 2 + 8, 32, Width / 2 - 24, this.Height - 48, Color.White, this);
            p.SetBorderStyle(1, "solid", new Color(240));

            var l = new Label(Res.Get(9075, "Pointages"), 0, 8, p.Width, 16, "center", p);
            l.FontSize = 9;

            var y = 30;
            var x1 = p.Width / 4 - 30 + 10;
            var x2 = p.Width / 4 * 3 - 30 - 10;

            for (var i = 0; i < 6; i++)
            {
                var pp = new Panel(x1, y, 60, 21, new Color(240), p);
                pp.SetBorderStyle(1, "solid", new Color(200));

                l = lPtg[i * 2 + 0] = new Label("", 0, 4, pp.Width, 16, "center", pp);
                l.FontSize = 9;

                pp = new Panel(x2, y, 60, 21, new Color(240), p);
                pp.SetBorderStyle(1, "solid", new Color(200));

                l = lPtg[i * 2 + 1] = new Label("", 0, 4, pp.Width, 16, "center", pp);
                l.FontSize = 9;

                y += 26;
            }

            y += 6;

            l = new Label(Res.Get(9076, "Ouv. AM"), x1, y, 60, 16, "center", p);
            l.FontSize = 9;
            cAM = new CheckBox("cam", "Ouv. Matin", "false");
            cAM.Place(l.Right, l.Top, 16, 16, p);

            l = new Label(Res.Get(9077, "Ouv. PM"), x2, y, 60, 16, "center", p);
            l.FontSize = 9;
            cPM = new CheckBox("cpm", "Ouv. Matin", "false");
            cPM.Place(l.Right, l.Top, 16, 16, p);

            cAM.Input.OnChange += arg => { OpenDay(); };
            cPM.Input.OnChange += arg => { OpenDay(); };
        }

        void OpenDay()
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.openHJ);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(date);
            func.iparam3 = cAM.Checked ? 1 : 0;
            func.iparam4 = cPM.Checked ? 1 : 0;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += ((rd, r) => 
            {
                var p = (PanelWkData)Parent;
                p.SelectDay();
            });
        }

        public void Show(int pid, DateTime date, bool chef, Right right)
        {
            cAM.Input.Disabled = !chef;
            cPM.Input.Disabled = !chef;

            
            cAM.Input.ReadOnly = !right.OpenHJ;
            cPM.Input.ReadOnly = !right.OpenHJ;
        }

        public void SetData(int pid, DateTime date, string data)
        {
            this.pid = pid;
            this.date = date;

            var csv = new CSVTool(data)[0];

            lHj.Text = csv[0];
            lPres.Text = csv[1];
            lOrdre.Text = csv[2];
            lAbs1.Text = csv[3];
            lAbs2.Text = csv[4];

            for(var i = 0; i < 12; i++)
                lPtg[i].Text = csv[5 + i];

            cAM.Checked = csv.Bool(17);
            cPM.Checked = csv.Bool(18);

            lOrdre2.Text = csv[19];
            percentO1.Text = csv[20];
            percentO2.Text = csv[21];
        }

    }
}
