using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using Bridge.Html5;
using Mobatime.JSControl;
using Window = Bridge.Html5.Window;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisieDebours : PanelSaisie
    {
        public static PanelSaisieDebours self = null;


        bool NoUpdate = false;

        Api.API_Data_Day dat = null;
        DateTime date = DateTime.MinValue;

        class Debours
        {
            public int id = 0;
            public string name = "";
            public int min, seuil, max;
            public int repas = 0;
            public bool km = false;
        }

        Label[] lid = new Label[5];
        Label[] lLink = new Label[5];
        Button[] bRmv = new Button[5];
        Combo[] cboType = new Combo[5];
        Field[] fValue = new Field[5];
        Combo[] cboDocu = new Combo[5];
        Combo[] cboJust = new Combo[5];
        Combo[] cboWK = new Combo[5];
        Combo cboFoto;
        bool[] toDelete = new bool[5];


        Button bSave, bCancel, bUpload, bView;
        Label lError = null;
        Dictionary<int, Debours> dico_debours = new Dictionary<int, Debours>();

        Right rights = null;
        Panel pUpload = null;

        public PanelSaisieDebours(Panel pParent) : base(pParent)
        {
            self = this;
            id = 5;

            lsKey = "pDebourClosed";

            _lHeader.Text = Res.Get(4500, "Débours");
            sz1 = 464;
            if (!Workflow.perm_uploadCost) sz1 -= 96;

            _pBack.Size = new Size(600 - 32, sz1);
            int w = _pBack.Width / 2 - 32;
            _pHeader.Height = 32;

            bSave = new Button(600 - 160, 0, 32, 32, "images/63save_t.png", _pHeader);
            bCancel = new Button(bSave.Right + 8, 0, 32, 32, "images/64annule_t.png", _pHeader);

            bSave.OnButtonClick += BSave_OnButtonClick;
            bCancel.OnButtonClick += BCancel_OnButtonClick;


            lError = new Label(Res.Get(4501, "Saisie non valide"), 192, 8, 200, 20, "center", _pHeader);
            lError.BackgroundColor = Color.White;
            lError.Color = Color.Red;
            lError.Visible = Visibility.Hidden;

            var y = 32;

            for (var i = 0; i < 5; i++)
            {
                lid[i] = new Label((i + 1).ToString() + ".", 8, y, 24, 16, "center", _pBack);
                lid[i].FontSize = 14;
                lid[i].Color = new Color(200);

                var c1 = cboType[i] = new Combo();
                c1.Place(32, y, 250, 24, _pBack);
                c1.OnChange += C1_OnChange;
                c1.id1 = i;

                var f1 = fValue[i] = new Field(null, 75, Field.FieldFormat.EntierCentieme);
                f1.Place(c1.Right + 16, y, 75, 24, _pBack);
                f1.OnValidate += F1_OnValidate;
                f1.Visible = Visibility.Hidden;
                f1.id1 = i;
                f1.Style.FontSize = "11pt";

                var c2 = cboDocu[i] = new Combo();
                c2.Place(f1.Right + 48, y, 55, 24, _pBack);
                c2.OnChange += C2_OnChange;
                c2.Style.TextAlign = "center";
                c2.Style.FontSize = "11pt";
                c2.Visible = Visibility.Hidden;
                c2.id1 = i;

                c2.Add(0, "----");
                for (var j = 1; j < 26; j++)
                {
                    c2.Add(j, ((char)(64 + j)).ToString());
                }

                var c3 = cboJust[i] = new Combo();
                c3.Place(c2.Right + 16, y, 55, 24, _pBack);
                c3.OnChange += C2_OnChange;
                c3.Style.TextAlign = "center";
                c3.id1 = i;

                c3.Add(0, "----");
                c3.Visible = Visibility.Hidden;
                for (var j = 1; j <= 9; j++)
                {
                    c3.Add(j, j.ToString());
                }

                lLink[i] = new Label("Link", c2.Left, c2.Top + 8, c2.Width, 16, "left", _pBack);
                lLink[i].Visible = Visibility.Hidden;
                lLink[i].Color = Color.Blue;
                lLink[i].Paragraph.Style.TextDecoration = "underline";
                lLink[i].OnDblClick += LLink_OnDblClick;
                lLink[i].id1 = i;

                bRmv[i] = new Button(c3.Left, c3.Top + 4, 32, 32, "images/poubelleb.png", _pBack, false, i);
                bRmv[i].OnButtonClick += BRMV_OnButtonClick;

                cboWK[i] = new Combo("", 32, y + 32, 250, 24, _pBack);
                if (Workflow.mode_prometerre) cboWK[i].Width = 350;
                cboWK[i].Visible = Visibility.Hidden;
                cboWK[i].OnChange += C3_OnChange;

                y += 64;
            }

            var l = new Label(Res.Get(4502, "Type de débours"), cboType[0].Left, cboType[0].Top - 16, cboType[0].Width, 16, "left", _pBack);
            l.FontSize = 9;
            l.Color = new Color(150);

            l = new Label(Res.Get(4503, "Montant"), fValue[0].Left, fValue[0].Top - 16, fValue[0].Width, 16, "left", _pBack);
            l.FontSize = 9;
            l.Color = new Color(150);

            l = new Label(Res.Get(4504, "Docu"), cboDocu[0].Left, cboDocu[0].Top - 16, cboDocu[0].Width, 16, "left", _pBack);
            l.FontSize = 9;
            l.Color = new Color(150);

            l = new Label(Res.Get(4505, "N°Justif."), cboJust[0].Left, cboJust[0].Top - 16, cboJust[0].Width, 16, "left", _pBack);
            l.FontSize = 9;
            l.Color = new Color(150);



            pUpload = new Panel();
            pUpload.Place(0, sz1 - 88, 568, 88, _pBack);

            var pUploadSep = new Panel();
            pUploadSep.Place(16, 0, 568 - 32, 1, pUpload);
            pUploadSep.BackgroundColor = new Color(150);


            cboFoto = new Combo();
            cboFoto.Place(16, 32, 300, 32, pUpload);

            bUpload = new Button(568 - 96 - 16, cboFoto.Top, 96, 32, "", pUpload, true);
            bUpload.Text = "Upload";
            bUpload.OnButtonClick += BUpload_OnButtonClick;


            var lUpload = new Label(Res.Get(4506, "Documents justificatifs"), cboFoto.Left, cboFoto.Top - 16, cboFoto.Width, 16, "left", pUpload);
            lUpload.FontSize = 9;
            lUpload.Color = new Color(150);

            bView = new Button(cboFoto.Right + 16, bUpload.Top, 96, 32, "", pUpload, true);
            bView.Text = "View";
            bView.OnButtonClick += BView_OnButtonClick;

            if (!Workflow.perm_uploadCost)
                pUpload.Visible = Visibility.Hidden;

            Close();

            SetState(false);

        }

        private void BRMV_OnButtonClick(Button c, MouseEvent arg)
        {
            var id = c.id1;
            var v = cboType[id].SelectedId;
            var v2 = fValue[id].Value;
            cboType[id].SelectById(0, true);
            cboType[id].SelectById(v, true);

            lLink[id].Visible = Visibility.Hidden;
            bRmv[id].Visible = Visibility.Hidden;

            cboDocu[id].SelectByIdx(0);
            cboJust[id].SelectByIdx(0);
            cboDocu[id].id1 = cboJust[id].id1 = 0;

            fValue[id].Value = v2;

            Check();

            SetState(true);

            toDelete[id] = true;
        }

        private void LLink_OnDblClick(Label label, MouseEvent<HTMLParagraphElement> e)
        {
            Console.WriteLine("dblclick");

            var l = lLink[label.id1];

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.CopyFotoTempo;
            //func.param1 = cboFoto.Selected.Text.Substring(4);
            func.iparam1 = 1;
            func.iparam2 = MensualView.self.CurrentPers;
            func.iparam3 = label.id1;
            func.iparam4 = D2000.T(date);

            var dataCpy = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataCpy.OnSuccess += (a, b) =>
            {
                var path = "../public/" + b.return_s1 + "?tick=" + Environment.TickCount;
                Window.Open(path, "Ticket", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=800");
            }; 
        }

        private void BView_OnButtonClick(Button c, MouseEvent arg)
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.CopyFotoTempo;
            func.param1 = cboFoto.Selected.Text.Substring(4);

            var dataCpy = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataCpy.OnSuccess += DataCpy_OnSuccess;
        }

        private void DataCpy_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var path = "../public/" + cboFoto.Selected.Text.Substring(4);
            Window.Open(path, "Ticket", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=800");
        }

        private void BUpload_OnButtonClick(Button c, MouseEvent arg)
        {
            MensualView.self.OpenUpload();
        }

        private void BCancel_OnButtonClick(Button c, MouseEvent arg)
        {
            Update(dat, date, rights);
            Check();

            SetState(false);
        }

        private void BSave_OnButtonClick(Button c, MouseEvent arg)
        {
            var r = Check();


            var func = new Api.ApiFunction();

            func.function = Api.ApiFuncType.save_frais;
            func.iparam1 = MensualView.self.CurrentPers;//. Workflow.pid;
            func.iparam2 = D2000.T(date);
            func.iparam3 = Workflow.pid;

            var csv = new CSVTool();
            csv.A(5);
            for (var i = 0; i < 5; i++)
            {
                var fid = cboType[i].SelectedId;
                var montant = fValue[i].ValueI;
                var docu = cboDocu[i].SelectedId;
                var just = cboJust[i].SelectedId;

                if (cboDocu[i].Visible == Visibility.Hidden)
                {
                    docu = 0;
                    just = 0;
                }


                Debours d = null;
                if (dico_debours.ContainsKey(fid)) d = dico_debours[fid];
                if (d != null && d.km)
                    montant /= 100;

                var repas = 0;

                csv.A(fid);
                csv.A(montant);
                csv.A(repas);
                csv.A(docu);
                csv.A(just);
            }
            func.param1 = csv.ToString();

            var csv2 = new CSVTool();
            for(var i = 0; i < 5; i++)
                csv2.A(toDelete[i]);
            func.param2 = csv2.ToString();


            func.iparam5 = rights.DeboursWKType;
            var csv3 = new CSVTool();
            for(var i = 0; i < 5;i++)
                csv3.A(cboWK[i].SelectedId);
            func.param3 = csv3.ToString();

            var dataSave = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataSave.OnSuccess += DataSave_OnSuccess;



        }

        private void DataSave_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            SetState(false);

            MensualView.self.SetState(false);
            MensualView.self.SetDate(98, true, true);
            MensualView.self.RefreshTodo();
        }

        private void F1_OnValidate(Field field)
        {
            var c = Check();

            SetState(true);
            if (!c) bSave.Enabled = false;

        }

        private void C1_OnChange(Combo combo)
        {
            //Modified = true;

            var id = combo.id1;

            Debours d = null;
            if (dico_debours.ContainsKey(combo.SelectedId)) d = dico_debours[combo.SelectedId];

            fValue[id].Visible = Visibility.Hidden;
            if (d != null) fValue[id].Visible = Visibility.Inherit;

            fValue[id].Value = 0;
            cboDocu[id].SelectByIdx(0, true);
            cboJust[id].SelectByIdx(0);

            cboWK[id].Visible = rights.DeboursWKType > 0 && combo.SelectedId > 0 ? Visibility.Inherit : Visibility.Hidden;
            cboWK[id].SelectByIdx(0);

            SetState(true);

            Check();
        }

        private void C2_OnChange(Combo combo)
        {
            //var id = combo.id1;
            //Debours d = null;
            //if (dico_debours.ContainsKey(combo.SelectedId)) d = dico_debours[combo.SelectedId];

            //SetState(true);

            var c = Check();
            SetState(c);
        }

        private void C3_OnChange(Combo combo)
        {
            var c = Check();
            SetState(c);
        }

        public void Update(Api.API_Data_Day dat, DateTime date, Right rights)
        {
            this.rights = rights;
            this.dat = dat;
            this.date = date;

            for (var i = 0; i < 5; i++)
            {
                toDelete[i] = false;
                var v = dat.ext_debours[i];

                cboDocu[i].Visible = Visibility.Hidden;
                cboJust[i].Visible = Visibility.Hidden;
                cboWK[i].Visible = Visibility.Hidden;
                lLink[i].Visible = Visibility.Hidden;
                bRmv[i].Visible = Visibility.Hidden;

                var montant = v & 0xffffff;
                var tb = v & 0xff000000;
                tb >>= 24;
                tb %= 64;

                cboType[i].SelectById((int)tb, true);

                Debours d = null;
                if (dico_debours.ContainsKey((int)tb)) d = dico_debours[(int)tb];
                if (d != null && d.km)
                    montant *= 100;

                fValue[i].Value = montant;

                var docu = dat.ext_debours[8 + i] / 256;
                var just = dat.ext_debours[8 + i] % 256;

                cboDocu[i].id1 = docu;

                Console.WriteLine(string.Format("docu: {0}",docu));

                if (docu != 99 && docu != 100)
                {
                    cboDocu[i].SelectById(docu);
                    cboJust[i].SelectById(just);
                }

                if (docu == 100)
                {
                    lLink[i].Visible = Visibility.Inherit;
                    bRmv[i].Visible = Visibility.Inherit;
                }

                cboType[i].List.Disabled = dat.ctrId <= 0;
                cboJust[i].List.Disabled = dat.ctrId <= 0;
                cboDocu[i].List.Disabled = dat.ctrId <= 0;
            }

            SetState(false);

            for (var i = 0; i < cboType.Length; i++)
            {
                cboType[i].List.Disabled = MensualView.Verrou[date.Day];
                cboDocu[i].List.Disabled = MensualView.Verrou[date.Day];
                cboJust[i].List.Disabled = MensualView.Verrou[date.Day];
                fValue[i].Input.Disabled = MensualView.Verrou[date.Day];
                bRmv[i].Enabled = !MensualView.Verrou[date.Day];
            }

            cboFoto.List.Disabled = MensualView.Verrou[date.Day];
            bView.Enabled = !MensualView.Verrou[date.Day];
            bUpload.Enabled = !MensualView.Verrou[date.Day];

            LoadImagesName(false);

            var space = 40;
            if (rights.DeboursWKType > 0)
            {
                for (var i = 0; i < 5; i++)
                {
                    cboWK[i].Clear();
                    cboWK[i].Add(0, "----");
                    var lst = dat.day_OF;
                    if (rights.DeboursWKType == 2) lst = dat.day_Ordre;
                    var ok = false;
                    foreach (var pair in lst)
                    {
                        cboWK[i].Add(pair.Key, pair.Value);
                        if (pair.Key == dat.debours_wk[i]) ok = true;
                    }
                    if(!ok)                
                        cboWK[i].Add(dat.debours_wk[i], dat.debours_wkText[i]);
                    cboWK[i].SelectById(dat.debours_wk[i]);
                }


                space = 64;
            }
           
            var y = 32;
            for(var i = 0; i < 5; i++)
            {
                cboType[i].Top = y;
                cboDocu[i].Top = y;
                cboJust[i].Top = y;
                fValue[i].Top = y;
                lid[i].Top = y;

                //cboWK[i].Visible = space > 40 ? Visibility.Inherit : Visibility.Hidden;

                y += space;
            }

            sz1 = 32 + 5 * space + 88;
            pUpload.Top = sz1 - 88; 

        }

        public void LoadImagesName(bool select)
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.GetFraisMensuelImage;
            func.iparam1 = MensualView.self.CurrentPers;// Workflow.pid;
            func.iparam2 = D2000.T(date);

            var dataImages = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataImages.b1 = select;
            dataImages.OnSuccess += DataImages_OnSuccess;
        }

        private void DataImages_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);

            cboFoto.Clear();

            for (var i = 0; i < csv[0].strings.Count; i++)
            {
                var n = csv[0][i];
                var we = WithoutExt(n);
                var lettre = we.Substring(we.Length - 1, 1);
                //var lettre = n.Substring(n.Length - 6, 1);
                cboFoto.Add(i, "[" + lettre + "]  " + n);
            }

            if (data.b1)
                cboFoto.SelectByIdx(cboFoto.Count - 1);


            var lst = new List<int>();
            for (var i = 0; i < cboFoto.Count; i++)
            {
                var s = cboFoto.Items[i];
                if (s.Text.Length < 3) continue;


                var we = WithoutExt(s.Text);
                var lettre = we.Substring(we.Length - 1, 1);
                var c = (int)lettre[0];

                lst.Add(c);
            }

            for (var i = 0; i < 5; i++)
            {
                var current = cboDocu[i].SelectedId;

                cboDocu[i].Clear();
                cboDocu[i].Add(0, "----");

                for (var j = 0; j < lst.Count; j++)
                {
                    cboDocu[i].Add(lst[j] - 64, ((char)lst[j]).ToString());
                }

                //cboDocu[i].Add(99, "xxxx");

                cboDocu[i].SelectById(current);
            }

        }

        string WithoutExt(string s)
        {
            var r = "";

            for (var i = s.Length - 1; i >= 0; i--)
            {
                if (s[i] == '.')
                {
                    r = s.Substring(0, i);
                    return r;
                }
            }

            return r;
        }


        public void Init()
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.get_table_frais;
            func.iparam1 = MensualView.self.CurrentPers;//Workflow.pid;
            func.iparam2 = Workflow.wfid_su;
            func.iparam10 = Res.LgCom;
            func.param1 = string.Format("{0} {1}",Res.LgCom,Res.CurrentLg);

            var dataGetTableFrais = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataGetTableFrais.OnSuccess += DataGetTableFrais_OnSuccess;
        }

        private void DataGetTableFrais_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var split = result.return_s1.Split(';');

            dico_debours.Clear();

            for (var i = 0; i < 5; i++)
            {
                cboType[i].Clear();
                cboType[i].Add(0, "----");
            }

            for (var i = 0; i < split.Length - 1; i += 6)
            {
                if (i >= split.Length) break;
int id;

                int.TryParse(split[i + 0], out id);
                var deb_name = split[i + 1];
int min;
                int.TryParse(split[i + 2], out min);
int seuil;
                int.TryParse(split[i + 3], out seuil);
int max;
                int.TryParse(split[i + 4], out max);
int repas;
                int.TryParse(split[i + 5], out repas);

                var debours = new Debours();
                debours.id = id;
                debours.min = min;
                Console.WriteLine("db max:" + max);
                if(max == 66600)
                {
                    debours.max = 0;              
                    debours.km = true;
                }
                else
                {
                    debours.max = max;
                }
                debours.seuil = seuil;
                debours.name = deb_name;             

                if (!dico_debours.ContainsKey(id))
                    dico_debours.Add(id, debours);

                for (var j = 0; j < 5; j++)
                {
                    cboType[j].Add(id, id + ". " + deb_name);
                }
            }



        }

        public void Save(Api.API_Data_Day dat)
        {
        }

        public bool Check()
        {
            bool ok = true;

            for (var i = 0; i < 5; i++)
            {

                if (fValue[i] == null) continue;
                if (cboType[i] == null) continue;

                //Console.WriteLine($"tid: {i} {"A"}");

                if (cboType[i].Selected == null) continue;

                //Console.WriteLine($"tid: {i} {"B"}");

                if (cboDocu[i].id1 == 99 || cboDocu[i].id1 == 100)
                {
                    cboDocu[i].Visible = Visibility.Hidden;
                    cboJust[i].Visible = Visibility.Hidden;
                    continue;
                }

                var tid = cboType[i].SelectedId;
                if (tid == 0) continue;
                Debours d = null;
                if (dico_debours.ContainsKey(tid)) d = dico_debours[tid];

                var montant = fValue[i].ValueI;

                fValue[i].BackgroundColor = Color.White;


                if (d != null)
                {
                    if ((d.min > 0 && montant < d.min) || (d.max > 0 && montant > d.max))
                    {
                        fValue[i].BackgroundColor = Color.Red;
                        ok = false;
                    }

                    //if (montant >= d.seuil && cboDocu[i].SelectedId == 0)
                    if (d.seuil > 0 && montant >= d.seuil && cboDocu[i].SelectedId == 0)
                    {
                        ok = false;
                    }

                    //Console.WriteLine($"{d.name} {d.seuil} {montant}");

                    //if (montant >= d.seuil)
                    if (d.seuil > 0 && montant >= d.seuil)
                    {
                        cboDocu[i].Visible = Visibility.Inherit;
                        cboJust[i].Visible = Visibility.Inherit;
                    }

                }



            }

            if (ok) lError.Visible = Visibility.Hidden;
            else lError.Visible = Visibility.Inherit;

            return ok;
        }

        void SetState(bool v)
        {
            bSave.Enabled = v;
            bCancel.Enabled = v;
            _bUpDown.Enabled = !v;

            if (v)
                _pHeader.BackgroundColor = new Color(200, 25, 25);
            else
                _pHeader.BackgroundColor = Color.Mobatime2;
        }

    }
}