using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class PSwapConfirmation : pSwapOption
    {
        Panel pCurrentOrdre = null;

        Label[] labels = new Label[5];

        public PSwapConfirmation(pPunchWKSwap parent) : base(parent)
        {
            Height = 56 + 200 + 96;

            pCurrentOrdre = new Panel(16, 56, Width - 32, 200, new Color(220, 255, 220), this);
            var l = new Label("Ordre en cours", 8, 8, pCurrentOrdre.Width, 16, "left", pCurrentOrdre);

            for(var i = 0; i < labels.Length; i++)
            {
                l = labels[i] = new Label("", 16, 40 + i * 32, pCurrentOrdre.Width - 32, 16, "left", pCurrentOrdre);
            }

            var x = Width - 16 - 96;
            var y = Height - 48;
            
            var b = new Button(x, y, 96, 32, "", this, true);
            b.Text = "Annuler";
            b.OnButtonClick += (aa, bb) =>
            {
                parent.CloseOption();
            };

            x -= 96 + 8;
            b = new Button(x, y, 96, 32, "", this, true);
            b.Text = "Confirmer";
            b.OnButtonClick += (aa, bb) =>
            {
                Apply();
            };

        }

        public override void Show(int type = 0)
        {
            base.Show(type);

            if(type == 0)
            {
                lTitle.Text = "Confirmez votre départ en pause.";
            }
            else if (type == 1)
            {
                lTitle.Text = "Confirmez votre retour de pause.";
            }
            else if (type == 3)
            {
                lTitle.Text = "Confirmez la fin de votre journée.";
            }
            else if (type == 4)
            {
                lTitle.Text = "Confirmez la fin de l'activité en cours.";
            }
        }

        protected override void Apply()
        {
            

            Api.ApiFunction func = null;

            if (type == 0 || type == 2)   // début pause
            {
                func = new Api.ApiFunction(Api.ApiFuncType.SwapStartPause);
                func.iparam1 = Workflow.pid;
            }
            else if (type == 1)   // retour pause
            {
                func = new Api.ApiFunction(Api.ApiFuncType.SwapStopPause);
                func.iparam1 = Workflow.pid;
            }
            else if (type == 3)  // stop ordre
            {
                func = new Api.ApiFunction(Api.ApiFuncType.SwapStop);
                func.iparam1 = Workflow.pid;
            }


            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, ret) =>
            {
                if (ret.return_b1)
                    Bridge.Html5.Window.Alert("Opération effectuée");
                else
                    Bridge.Html5.Window.Alert("Erreur !");

                parent.CloseOption(true);
            };
        }

        public void SetOrdre(string s)
        {
            ClearOrdre();
            if (s == null) return;
            if (s.Length < 3) return;

            var csv = new CSVTool(s)[0];

            for(var i = 0; i < labels.Length; i++)
            {
                labels[i].Text = csv[1 + i];
            }
        }

        void ClearOrdre()
        {
            for (var i = 0; i < 5; i++)
                labels[i].Text = "";
        }

    }
}
