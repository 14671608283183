using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using Bridge.Html5;
using Mobatime.JSControl;
using Window = Bridge.Html5.Window;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisieKor : PanelSaisie
    {

        Combo cboKor = null;
        Field fValue = null;

        public PanelSaisieKor(Panel pParent) : base(pParent)
        {
            id = 5;

            lsKey = "pKorrClosed";

            _lHeader.Text = Res.Get(600, "Correction directe");
            _pBack.Size = new Size(600 - 32, 96);
            sz1 = 96;
            int w = _pBack.Width / 2 - 32;

            var l = new Label(Res.Get(601, "Type de correction"), 32, 24, 380, 16, "left", _pBack);
            l.FontSize = 9;

            cboKor = new Combo();
            cboKor.Place(l.Left, l.Bottom, l.Width, 32, _pBack);
            cboKor.OnChange += CboKor_OnChange;
            cboKor.Add(0, "----");

            l = new Label(Res.Get(602, "Valeur"), l.Right + 32, 24, 75, 16, "left", _pBack);
            l.FontSize = 9;

            fValue = new Field(l, 75, Field.FieldFormat.HQtyAbs);
            fValue.Place(l.Left, l.Bottom, 75, fValue.Height);
            fValue.Value = 0;

            cboKor.SelectById(0);

            fValue.Input.OnChange = FValue_OnChange;

            Close();

        }

        private void CboKor_OnChange(Combo combo)
        {

            fValue.Value = 0;
            Modified = true;

            fValue.Focus();
        }

        private void FValue_OnChange(Event<HTMLInputElement> e)
        {
            Modified = true;
        }

        public void Update(Api.API_Data_Day dat, Right right, DateTime date)
        {
            var blocked = MensualView.Verrou[date.Day];

            cboKor.Clear();
            cboKor.Add(0, "----");


            for (var i = 0; i < right.kor_texts.Count; i++)
            {
                var s = right.kor_texts[i];
                if (s != "") cboKor.Add(i + 1, s);
            }

            bool ok = false;
            for(var i = 0; i < right.kor_texts.Count; i++)
            {
                if(dat.kor1_typeA > 0 && dat.kor1_typeA == Workflow.RightSU.kor_typeA[i] &&
                    dat.kor1_typeB == Workflow.RightSU.kor_typeB[i])
                {
                    cboKor.SelectById(i + 1);
                    fValue.Value = dat.kor1_valueA;
                    ok = true;
                }
            }

            if(!ok)
            {
                cboKor.SelectById(0);
                fValue.Value = 0;
            }

            cboKor.List.Disabled = dat.ctrId <= 0 || blocked;
            fValue.Input.ReadOnly = blocked;

            ToCheck = ok;



        }

        public void Init(int wfid)
        {

        }

        public void Save(Api.API_Data_Day dat)
        {

            if (cboKor.SelectedId == 0) fValue.Value = 0;

            dat.kor1_valueA = fValue.ValueI;
            dat.kor1_valueB = fValue.ValueI;

            var id = cboKor.SelectedId;

            var typeA = 0;
            var typeB = 0;
            var repA = 0;
            var repB = 0;


            if (id > 0)
            {
                typeA = Workflow.RightSU.kor_typeA[id - 1];
                typeB = Workflow.RightSU.kor_typeB[id - 1];
                repA = Workflow.RightSU.kor_repA[id - 1];
                repB = Workflow.RightSU.kor_repB[id - 1];
            }

            dat.kor1_typeA = typeA % 100;
            dat.kor1_typeB = typeB % 100;
            dat.kor1_repA = repA;
            dat.kor1_repB = repB;


            if (typeA >= 100) dat.kor1_valueA *= -1;
            if (typeB >= 100) dat.kor1_valueB *= -1;

            if (typeA == 0) dat.kor1_valueA = 0;
            if (typeB == 0) dat.kor1_valueB = 0;

        }

    }
}