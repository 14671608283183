using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using Mobatime.JSControl;
using System.IO;


namespace Workflow_bridge.MensualView
{
    class ActivityView : Panel
    {
private bool IamChef
{
    get
    {
        return (cboPers.Visible != Visibility.Hidden && cboWF.SelectedId > 0);
    }
}        private int CurrentWF
        {
            get
            {
                if (IamChef && cboWF.SelectedId != -1) return Workflow.WFProfil[cboWF.SelectedId];
                else return Workflow.wfid_su;
            }
        }


        Button bClose = null, bSave, bCancel, bNew, bTrash;
        MensualView view;

        Combo cboWF, cboPers;

        Label lDate1 = null, lDate2 = null;
        Button bPer1, bPer2, bPer3;
        Label[] lHebdo = new Label[6 * 9];

        DateTime lundi = DateTime.Now;

        Field fQty, fT1, fT2, fT3, fT4;
        Field[] fFrais = new Field[4];
        Label[] lFrais = new Label[4];

        Panel pGridQty;
        Button bDef, bDefK, bK1;
        Combo cboCF, cboAct, cboOpe;
        CheckBox[] chkDay = new CheckBox[7];
        //CheckBox[] cLD = new CheckBox[7];

        List<Panel> pWeek = new List<Panel>();

        Combo cbo;

        Panel Selected = null;
        int pid = 13;

        List<Panel> panel2 = new List<Panel>();

        Right right = null;
        Right rightRe = null;

        int[] verrou = new int[7];

        public ActivityView(MensualView view, List<Tuple<int, string>> lstWF, List<Tuple<int, string>> lstPers, int cboWFID, int cboPersID, Right right)
        {
            this.view = view;
            Size = new Size(1264, 810);
            this.right = right;

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = Color.Mobatime1;

            var lTitle = new Label("JBM activity", 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle);
            bClose.OnButtonClick += BClose_OnButtonClick;

            bPer1 = new Button(68, 0, 32, 32, "images/arrow_left.png", pTitle);
            bPer2 = new Button(68+40, 0, 32, 32, "images/now.png", pTitle);
            bPer3 = new Button(68+80, 0, 32, 32, "images/arrow_right.png", pTitle);

            bPer1.id1 = 0;
            bPer2.id1 = 1;
            bPer3.id1 = 2;
            bPer1.OnButtonClick += BPer_OnButtonClick;
            bPer2.OnButtonClick += BPer_OnButtonClick;
            bPer3.OnButtonClick += BPer_OnButtonClick;

            lDate1 = new Label("du 1 septembre 2019 au 31 décembre 2019", 196, 0, 400, 16, "left", pTitle);
            lDate1.Color = Color.White;
            lDate1.FontSize = 9;
            lDate2 = new Label("du 1 septembre 2019 au 31 décembre 2019", 196, 16, 400, 16, "left", pTitle);
            lDate2.Color = Color.White;
            lDate2.FontSize = 9;

            BackgroundColor = new Color(245);

            lundi = DateTime.Now;
            var dayid = (int)lundi.DayOfWeek;
            if (dayid == 0) dayid = 7;
            dayid--;
            lundi = lundi.AddDays(-dayid);


            //cbo.OnChange += Cbo_OnChange;

            CreateHebdo();
            CreateWeekQty();
            CreateSaisie();

            cboWF = new Combo();
            cboWF.Place(this.Width - 520 - 128, 0, 256, 32, this);
            cboWF.OnChange += CboWF_OnChange;
            cboWF.Style.FontSize = "11pt";
            cboPers = new Combo();
            cboPers.Place(cboWF.Right + 8, 0, 256, 32, this);
            cboPers.OnChange += CboPers_OnChange;
            cboPers.Style.FontSize = "11pt";

            if(lstWF == null)
            {
                cboWF.Visible = Visibility.Hidden;
                cboPers.Visible = Visibility.Hidden;
            }
            else
            {
                foreach(var wf in lstWF)
                    cboWF.Add(wf.Item1, wf.Item2);

                cboWF.SelectById(cboWFID);
            }

            if (lstPers == null || lstPers.Count == 0)
                cboPers.Visible = Visibility.Hidden;
            else if(lstPers != null)
            {
                foreach (var pers in lstPers)
                    cboPers.Add(pers.Item1, pers.Item2);

                cboPers.SelectById(cboPersID, true);
            }

            if (cboPersID <= 0)
                pid = Workflow.pid;


            BPer_OnButtonClick(bPer2, null);

            FillCboCF(true);

            SetState(false);

            //ApplyRight();
        }

        public void SetRight()
        {
            //this.right = right;
            LoadRight();
            ApplyRight();
        }

        private void ApplyRight()
        {
            if(IamChef)
            {
                if (right.ChefModifActivite)
                {
                    bNew.Visible = Visibility.Inherit;
                }
                else
                {
                    bNew.Visible = Visibility.Hidden;
                }

                bSave.Visible = bNew.Visible;
                bCancel.Visible = bNew.Visible;



            }
        }

        private void CboWF_OnChange(Combo combo)
        {
            if(combo.SelectedId == 0)
            {
                cboPers.Visible = Visibility.Hidden;
                pid = Workflow.pid;
                FillHebdo();
                FillWeekQty();

                LoadRight();
                ApplyRight();
            }
            else
            {
                
                cboPers.Visible = Visibility.Visible;

                LoadRight();

                FillCBOPers();
            }
        }

        private void CboPers_OnChange(Combo combo)
        {
            pid = combo.SelectedId;

            FillHebdo();
            FillWeekQty();
        }

        private void BPer_OnButtonClick(Button c, MouseEvent arg)
        {
            switch(c.id1)
            {
                case 0:
                    lundi = lundi.AddDays(-7);
                    break;

                case 1:
                    lundi = DateTime.Now;
                    var dayid = (int)lundi.DayOfWeek;
                    if (dayid == 0) dayid = 7;
                    dayid--;
                    lundi = lundi.AddDays(-dayid);
                    break;

                case 2:
                    lundi = lundi.AddDays(7);
                    break;
            }

            var d1 = lundi;
            var d2 = lundi.AddDays(6);

            lDate1.Text = d1.Day + " " + Res.months[d1.Month - 1] + " " + d1.Year;
            lDate2.Text = d2.Day + " " + Res.months[d2.Month - 1] + " " + d2.Year;

            FillHebdo();
            FillWeekQty();

            Clear();
        }

        void CreateHebdo()
        {
            var p = new Panel(this);
            p.Place(16, 48, 700, 6 * 32);
            p.SetBorderStyle(1, "solid", new Color(200));
            p.BackgroundColor = new Color(240);

            int[] colsz = { 100, 75, 75, 75, 75, 75, 75, 75, 75 };
            int rowH = 32;

            var x = 0;
            var y = 0;

            panel2.Clear();

            for (var i = 0; i < 9; i++)
            {
                y = 0;

                for (var j = 0; j < 6; j++)
                {
                    var p2 = new Panel(p);
                    p2.Place(x, y, colsz[i] - 1, rowH - 1);
                    p2.id1 = 0;

                    if(j == 0)
                    {
                        p2.Div.OnMouseDown = pHebdo_MouseDown;
                        p2.SetAttribute("cid", i.ToString());
                        panel2.Add(p2);
                    }

                    if (j > 0)
                        p2.BackgroundColor = new Color(255, 255, 200);
                    else
                        p2.BackgroundColor = new Color(255, 255, 100);


                    var l = lHebdo[i * 6 + j] = new Label("", 0, 8, colsz[i] - 4, 16, "center", p2);
                    l.FontSize = 9;
                    if (j == 5 && i > 0 && i < 8)
                    {
                        l.FontSize = 8;
                        l.Top -= 6;
                    }
                    if(j == 0)
                    {
                        l.SetAttribute("cid", i.ToString());
                    }
                    
                    y += rowH;
                }

                x += colsz[i];
            }

            lHebdo[48].Text = Res.Get(1530, "Totaux");
            lHebdo[48].FontSize = 9;

            string[] sn = { "Absence Trav.", "Présence", "Temps saisi", "Ecart", "Abs" };

            for (var i = 0; i < 5; i++)
                sn[i] = Res.Get(1520 + i, sn[i]);

            for (var i = 0; i < sn.Length; i++)
            {
                lHebdo[1 + i].Text = sn[i];
                lHebdo[1 + i].FontSize = 9;
                lHebdo[1 + i].Align = "right";

                if (i == 0)
                {
                    lHebdo[1].Color = new Color(127);
                }
            }
        }

        void pHebdo_MouseDown(MouseEvent<HTMLDivElement> me)
        {


            if (cboPers.Visible == Visibility.Hidden && !right.ActiviteNeedValidation)
                return;

            if(cboPers.Visible != Visibility.Hidden)
            {
                if (!right.ChefValidActivite) return;
            }
int cid;

            int.TryParse(me.Target.GetAttribute("cid"), out cid);
            var p = panel2[cid];

            if (cid == panel2.Count - 1)
                return;

            if (cboPers.Visible != Visibility.Hidden)
            {
                if (cid == 0)
                {
                    var nextvalue = 0;

                    for (var i = 1; i < panel2.Count - 1; i++)
                    {
                        var id = panel2[i].id1;
                        if (id == 1 || id == 3) nextvalue = 7;
                        else if (id == 7) nextvalue = 15;
                        else if (id == 15) nextvalue = 1;

                        if (nextvalue > 0)
                            break;
                    }

                    for(var i = 1; i < panel2.Count - 1; i++)
                    {
                        var id = panel2[i].id1;
                        if(id > 0)
                            panel2[i].id1 = nextvalue;
                    }

                }
                else
                {
                    if (p.id1 == 0)
                        return;
                    else if (p.id1 == 1 || p.id1 == 3)
                        p.id1 = 7;
                    else if (p.id1 == 7)
                        p.id1 = 15;
                    else if (p.id1 == 15)
                        p.id1 = 1;
                }
            }
            else
            {

                if (cid == 0)
                {
                    var id = panel2[0].id1;
                    if (id == 0)
                        panel2[0].id1 = 1;
                    else if (id == 1)
                        panel2[0].id1 = 0;

                    for (var i = 1; i < panel2.Count - 1; i++)
                    {
                        p = panel2[i];

                        if (id == 0 || id == 2)
                            p.id1 = 1;
                        else if (id == 1)
                            p.id1 = 0;
                    }
                }
                else
                {
                    if (p.id1 == 0 || p.id1 == 2)
                        p.id1 = 1;
                    else if (p.id1 == 1)
                        p.id1 = 0;

                }
            }

            bool ok = true;
            for (var i = 2; i < panel2.Count - 1; i++)
                if (panel2[i].id1 != panel2[i - 1].id1)
                {
                    ok = false;
                    break;
                }

            if (ok)
                panel2[0].id1 = panel2[1].id1;
            else
                panel2[0].id1 = 0;

            for (var i = 0; i < panel2.Count - 1; i++)
                SetPanelColor(panel2[i]);

            var func = new Api.ApiFunction(Api.ApiFuncType.SetActivityFlag);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(lundi);
            func.array_int = new List<int>();
            for(var i = 0; i < 7; i++)
                func.array_int.Add(panel2[1 + i].id1);

            var dataFlag = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataFlag.OnSuccess += DataFlag_OnSuccess;
        }

        private void DataFlag_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            
        }

        void SetPanelColor(Panel p)
        {
            if(p.id1 == 0)
            {
                p.BackgroundColor = new Color(255, 255, 100);
            }
            else if(p.id1 == 1 ||p.id1 == 3)
            {
                p.BackgroundColor = new Color(255, 127, 0);
            }
            else if (p.id1 == 7)
            {
                p.BackgroundColor = new Color(0, 160, 0);
            }
            else if (p.id1 == 15)
            {
                p.BackgroundColor = new Color(255, 0, 50);
            }
        }

        void CreateWeekQty()
        {
            var p = new Panel(this);
            p.Place(16, 256, 700, this.Height - 256 - 16);
            p.SetBorderStyle(1, "solid", new Color(200));
            p.BackgroundColor = new Color(240);


            string[] sn = { "Date", "CF", "Activité", "Opération", "Qté", "" };
            int[] colsz = { 88, 167, 167, 167, 87, 24};

            for (var i = 0; i < 5; i++)
                sn[i] = Res.Get(1500 + i, sn[i]);

            var rowH = 32;
            var x = 0;
            var y = 0;

            for (var i = 0; i < sn.Length; i++)
            {
                var p2 = new Panel(p);
                p2.Place(x, y, colsz[i] - 1, rowH - 1);
                p2.BackgroundColor = new Color(150, 255, 150);

                var l = new Label(sn[i], 0, 6, colsz[i] - 1, 16, "center", p2);
                l.FontSize = 9;

                x += colsz[i];
            }

            var p3 = pGridQty = new Panel(p);
            p3.Place(0, 32, 700, p.Height - 32);
            p3.SetScrolling(Overflow.Hidden, Overflow.Scroll);
            p3.BackgroundColor = Color.White;
            
        }

        void CreateSaisie()
        {
            var p = new Panel(this);
            p.Place(732, 48, 516, this.Height - 16 - 48);
            p.SetBorderStyle(1, "solid", new Color(200));
            p.BackgroundColor = Color.White;

            var pTitle = new Panel(p);
            pTitle.Place(0, 0, p.Width, 32);
            pTitle.BackgroundColor = Color.Mobatime1;

            bNew = new Button();
            bNew.Place(8, 2, 32, 32, pTitle);
            bNew.SetImage("images/63_new_t.png", 32, 32);
            bNew.OnButtonClick += BNew_OnButtonClick;

            bSave = new Button();
            bSave.Place(bNew.Right + 8, 2, 32, 32, pTitle);
            bSave.SetImage("images/63save_t.png", 32, 32);
            bSave.OnButtonClick += BSave_OnButtonClick;

            bCancel = new Button();
            bCancel.Place(bSave.Right + 8, 2, 32, 32, pTitle);
            bCancel.SetImage("images/64annule_t.png", 32, 32);
            bCancel.OnButtonClick += BCancel_OnButtonClick;

            bTrash = new Button();
            bTrash.Place(bCancel.Right + 32, 4, 32, 32, pTitle);
            bTrash.SetImage("images/trash@24px.png", 24, 24);
            bTrash.OnButtonClick += BTrash_OnButtonClick;

            var y = 80;
            var x = 16;// p.Width - 7 * 28 - 16;
            for (var i = 0; i < 7; i++)
            {
                var c = chkDay[i] = new CheckBox("chk" + i, "", "");
                c.Input.OnChange = FieldOnChange;
                c.Place(x + i * 28 + 6, y, 16, 16, p);
                //cLD[i] = c;

                var l = new Label(Res.days2[i], x + i * 28, 64, 28, 16, "center", p);
                l.FontSize = 9;
            }

            var bLV = new Button(x + 8, y + 20, 5 * 28 - 14, 4, "", p, true);
            bLV.OnButtonClick += BLV_OnButtonClick;

            bDef = new Button(p.Width - 216, y - 16, 96, 32, "", p, true);
            bDef.Text = Res.Get(1550, "Défaut");
            bDef.OnButtonClick += BK1_OnButtonClick;
            bDef.tag = 0;

            bDefK = new Button(bDef.Right + 8, y - 16, 96, 32, "", p, true);
            bDefK.Text = Res.Get(1551, "Défaut+K");
            bDefK.OnButtonClick += BK1_OnButtonClick;
            bDefK.tag = 0;

            y += 96;

            cboCF = new Combo();
            cboCF.Place(16, y, 234, 32, p);
            cboCF.OnChange += CboCF_OnChange; ;
            var lCF = new Label(Res.Get(1552, "Centre de frais"), cboCF.Left, cboCF.Top - 16, cboCF.Width, 16, "left", p);
            lCF.FontSize = 9;

            cboAct = new Combo();
            cboAct.Place(266, y, 234, 32, p);
            cboAct.OnChange += CboAct_OnChange;
            var lAct = new Label(Res.Get(1553, "Activité"), cboAct.Left, cboAct.Top - 16, cboAct.Width, 16, "left", p);
            lAct.FontSize = 9;

            y += 64;

            cboOpe = new Combo();
            cboOpe.Place(266, y, 234, 32, p);
            cboOpe.OnChange += CboOpe_OnChange;
            var lOpe = new Label(Res.Get(1554, "Opération"), cboOpe.Left, cboOpe.Top - 16, cboOpe.Width, 16, "left", p);
            lOpe.FontSize = 9;

            fQty = new Field();
            fQty.Place(16, y, 75, 32, p);
            fQty.Input.Style.FontSize = "14pt";
            fQty.Input.Style.TextAlign = "center";
            fQty.Format = Field.FieldFormat.HQty;
            fQty.Input.OnChange = FieldOnChange;
            var lQty = new Label(Res.Get(1558, "Quantité"), fQty.Left, fQty.Top - 16, fQty.Width, 16, "left", p);
            lQty.FontSize = 9;

            bK1 = new Button(fQty.Right + 8, fQty.Top, 32, 32, "", p, true);
            bK1.Text = "K";
            bK1.OnButtonClick += BK1_OnButtonClick;

            y += 80;

            var t1 = fT1 = new Field();
            t1.Place(16, y, p.Width - 32, 24, p);
            t1.Input.MaxLength = 80;
            t1.Input.OnChange = FieldOnChange;
            var lT1 = new Label(Res.Get(1555, "Message") + " 1", t1.Left, t1.Top - 16, t1.Width, 16, "left", p);
            t1.Linked = lT1;
            lT1.FontSize = 9;

            y += 64;

            var t2 = fT2 = new Field();
            t2.Place(16, y, p.Width - 32, 24, p);
            t2.Input.MaxLength = 80;
            t2.Input.OnChange = FieldOnChange;
            var lT2 = new Label(Res.Get(1555, "Message") + " 2", t2.Left, t2.Top - 16, t2.Width, 16, "left", p);
            t2.Linked = lT2;
            lT2.FontSize = 9;


            var pTitle2 = new Panel(p);
            pTitle2.Place(0, p.Height - 300, p.Width, 32);
            pTitle2.BackgroundColor = new Color(127);

            var lTitle2 = new Label(Res.Get(1557, "Informations journalières"), 0, 8, pTitle2.Width - 8, 16, "right", pTitle2);
            lTitle2.Color = Color.White;

            var t3 = fT3 = new Field();
            t3.Place(16, pTitle2.Bottom + 48, p.Width - 32, 24, p);
            t3.Input.MaxLength = 80;
            t3.Input.OnChange = FieldOnChange;
            var lT3 = new Label(Res.Get(1556, "Message journalier") + " 1", t3.Left, t3.Top - 16, t3.Width, 16, "left", p);
            lT3.FontSize = 9;
            t3.Linked = lT3;
            var t4 = fT4 = new Field();
            t4.Place(16, pTitle2.Bottom + 112, p.Width - 32, 24, p);
            t4.Input.MaxLength = 80;
            t4.Input.OnChange = FieldOnChange;
            var lT4 = new Label(Res.Get(1556, "Message journalier") + " 2", t4.Left, t4.Top - 16, t4.Width, 16, "left", p);
            lT4.FontSize = 9;
            t4.Linked = lT4;

            y = p.Height - 96;
            x = 16;                          
            for(var i = 0; i < 4; i++)
            {
                var l = lFrais[i] = new Label("frais", x, y, 234, 16, "left", p);
                var f = fFrais[i] = new Field();
                f.Place(l.Left, l.Bottom, 75, 24, p);
                f.Format = Field.FieldFormat.EntierMillieme;
                f.Input.OnChange = FieldOnChange;
                f.Value = 0;
                x += 120;
            }


        }

        private void BTrash_OnButtonClick(Button c, MouseEvent arg)
        {
            var days = 0;
            for (var i = 0; i < 7; i++)
            {
                if (chkDay[i].Checked)
                    days += (int)Math.Pow(2, i);
            }

            var vi = -1;
            var vj = -1;

            if (Selected == null) return;

            int.TryParse(Selected.GetAttribute("vi"), out vi);
            int.TryParse(Selected.GetAttribute("vj"), out vj);

            var func = new Api.ApiFunction(Api.ApiFuncType.SaveActivityEntry);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(lundi);
            func.iparam3 = Workflow.wfid_su;
            func.iparam4 = Workflow.wfid_re;
            func.iparam5 = 0;
            func.iparam8 = vi;
            func.iparam9 = vj;

            var idCF = 0;
            var idAct = 0;
            var idOpe = 0;
            var qty = 0;
            
            func.array_int.Add(days);
            func.array_int.Add(qty);
            func.array_int.Add(idCF);
            func.array_int.Add(idAct);
            func.array_int.Add(idOpe);


            func.array_int.Add(0);
            func.array_int.Add(0);
            func.array_int.Add(0);
            func.array_int.Add(0);

            func.param1 = "";
            func.param2 = "";
            func.param3 = "";
            func.param4 = "";

            var dataSave = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataSave.OnSuccess += DataSave_OnSuccess;

            SetState(false);
        }

        private void BNew_OnButtonClick(Button c, MouseEvent arg)
        {
            Clear();
            Selected = null;

            foreach (var pp in pWeek)
            {
                pp.id1 = 0;
                pp.BackgroundColor = new Color(220, 255, 220); 
            }

            LockSaisie(false);
            SetState(true);
        }

        private void BK1_OnButtonClick(Button c, MouseEvent arg)
        {
            if (c == bDef)
            {
                if (c.id1 == 0)
                {
                    c.id1 = 1;
                    c.SetBorderColor(Color.Red);
                    cboCF.SelectByIdx(0);
                    cboAct.SelectByIdx(0);
                    cboOpe.SelectByIdx(0);
                    cboCF.List.Disabled = true;
                    cboAct.List.Disabled = true;
                    cboOpe.List.Disabled = true;

                }
                else
                {
                    c.id1 = 0;
                    cboCF.SelectByIdx(0);
                    cboAct.SelectByIdx(0);
                    cboOpe.SelectByIdx(0);
                    cboCF.List.Disabled = false;
                    cboAct.List.Disabled = false;
                    cboOpe.List.Disabled = false;
                    c.ResetColor();
                }
            }
            else if (c == bDefK)
            {
                if (bK1.id1 != bDef.id1)
                    BK1_OnButtonClick(bK1, null);

                BK1_OnButtonClick(bDef, null);
                BK1_OnButtonClick(bK1, null);
            }
            else
            {
                if (c.id1 == 0)
                {
                    c.id1 = 1;
                    c.SetBorderColor(Color.Red);
                    fQty.Input.Value = "";
                    fQty.Input.ReadOnly = true;
                    fQty.BackgroundColor = new Color(230);
                }
                else
                {
                    c.id1 = 0;
                    fQty.Input.ReadOnly = false;
                    fQty.BackgroundColor = Color.White;
                    c.ResetColor();
                }
            }

            SetState(true);
        }

        private void BSave_OnButtonClick(Button c, MouseEvent arg)
        {
            var days = 0;
            for(var i = 0; i < 7; i++)
            {
                if (chkDay[i].Checked)
                    days += (int)Math.Pow(2, i);
            }

            var vi = -1;
            var vj = -1;

            if(Selected != null)
            {
                int.TryParse(Selected.GetAttribute("vi"), out vi);
                int.TryParse(Selected.GetAttribute("vj"), out vj);
            }

            var func = new Api.ApiFunction(Api.ApiFuncType.SaveActivityEntry);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(lundi);
            func.iparam3 = Workflow.wfid_su;
            func.iparam4 = Workflow.wfid_re;

            Console.WriteLine("b");


            var vdef = 0;
            if (bK1.id1 > 0) vdef = 1;
            if (bDef.id1 > 0) vdef += 2;

            func.iparam5 = vdef;
            func.iparam8 = vi;
            func.iparam9 = vj;

            var idCF = 0;
            var idAct = 0;
            var idOpe = 0;
            var qty = 0;
            if (fQty.Value != null) qty = (int)fQty.ValueI;
            Console.WriteLine("c");
            
            if (cboCF.Selected != null) idCF = cboCF.SelectedId;
            if (cboAct.Selected != null) idAct = cboAct.SelectedId;
            if (cboOpe.Selected != null) idOpe = cboOpe.SelectedId;
            Console.WriteLine("d");

            func.array_int.Add(days);
            func.array_int.Add(qty);
            func.array_int.Add(idCF);
            func.array_int.Add(idAct);
            func.array_int.Add(idOpe);

            Console.WriteLine("e");


            func.array_int.Add(fFrais[0].ValueI);
            func.array_int.Add(fFrais[1].ValueI);
            func.array_int.Add(fFrais[2].ValueI);
            func.array_int.Add(fFrais[3].ValueI);
            Console.WriteLine("f");

            func.param1 = fT1.Input.Value;
            func.param2 = fT2.Input.Value;
            func.param3 = fT3.Input.Value;
            func.param4 = fT4.Input.Value;

            Console.WriteLine("g");

            var dataSave = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataSave.OnSuccess += DataSave_OnSuccess;

            SetState(false);
        }

        private void BCancel_OnButtonClick(Button c, MouseEvent arg)
        {
            Clear();

            if(Selected != null)
            {
                SelectRow(Selected);
            }

            SetState(false);
        }

        private void DataSave_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            FillHebdo();
            FillWeekQty();
        }

        void Clear()
        {
            for (var i = 0; i < 7; i++)
            {
                chkDay[i].Checked = false;
            }

            cboCF.SelectById(0);
            cboAct.SelectById(0);
            cboOpe.SelectById(0);

            fQty.Input.Value = "";
            fQty.Input.ReadOnly = false;
            fQty.BackgroundColor = Color.White; ; ;
            fT1.Input.Value = "";
            fT2.Input.Value = "";
            fT3.Input.Value = "";
            fT4.Input.Value = "";

            cboCF.List.Disabled = false;
            cboAct.List.Disabled = false;
            cboOpe.List.Disabled = false;

            for (var i = 0; i < 4; i++)
                fFrais[i].Input.Value = "";

        }

        void FieldOnChange(Event<HTMLInputElement> ev)
        {
            SetState(true);
        }

        private void CboOpe_OnChange(Combo combo)
        {
            SetState(true);
        }

        private void CboAct_OnChange(Combo combo)
        {
            FillCboOpe();
            SetState(true);
        }

        private void CboCF_OnChange(Combo combo)
        {
            FillCboAct(true);
            SetState(true);
        }


        private void BLV_OnButtonClick(Button c, MouseEvent arg)
        {
            var v = chkDay[0].Checked;

            for (var i = 0; i < 5; i++)
            {
                if (verrou[i] > 0)
                {
                    chkDay[i].Checked = false;
                }
                else
                    chkDay[i].Checked = !v;
            }
        }

        void FillHebdo()
        {
            var wid = Tool.ISOWeekNumber(lundi);

            lHebdo[0].Text = "w" + wid + " " + Res.month4[lundi.Month - 1] + " " + lundi.Year;
            lHebdo[0].FontSize = 9;

            for (var i = 0; i < 7; i++)
            {
                var idx = (i + 1) * 6;
                lHebdo[idx].Text = Res.days2[i][0].ToString().ToUpper() + Res.days2[i][1] + " " + lundi.AddDays(i).Day;
                lHebdo[idx].FontSize = 9;
            }

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.GetActivityHebdo;
            func.iparam1 = pid;
            func.iparam2 = D2000.T(lundi);
            func.iparam3 = 1;
            func.iparam4 = Workflow.wfid_su;
            var dataHebdo2 = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataHebdo2.OnSuccess += DataHebdo_OnSuccess;
        }

        private void DataHebdo_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);
            var dat = csv[0];

            lFrais[0].Text = result.return_s2;
            lFrais[1].Text = result.return_s3;
            lFrais[2].Text = result.return_s4;
            lFrais[3].Text = result.return_s5;

            if (result.return_i1 == 0) fT1.Visible = Visibility.Hidden; else fT1.Visible = Visibility.Inherit;
            if (result.return_i2 == 0) fT2.Visible = Visibility.Hidden; else fT2.Visible = Visibility.Inherit;
            if (result.return_i3 == 0) fT3.Visible = Visibility.Hidden; else fT3.Visible = Visibility.Inherit;
            if (result.return_i4 == 0) fT4.Visible = Visibility.Hidden; else fT4.Visible = Visibility.Inherit;

            fT1.Linked.Visible = fT1.Visible;
            fT2.Linked.Visible = fT2.Visible;
            fT3.Linked.Visible = fT3.Visible;
            fT4.Linked.Visible = fT4.Visible;

            var count = 7;

            var locked = new bool[7];
            
            for (var i = 0; i < 7; i++)
            {
                var abstrav = dat[i * count + 0];
                var pres = dat[i * count + 1];
                var temps = dat[i * count + 2];
                var ecart = dat[i * count + 3];
                var abs1 = dat[i * count + 4];
                var abs2 = dat[i * count + 5];
                var flag = dat.I32(i * count + 6);

                if (flag == 3) flag = 1;
                //else if (flag == 7) flag = 2;

                if (flag == 7) locked[i] = true;

                panel2[i + 1].id1 = flag;

                var idx = (i + 1) * 6 + 1;
                lHebdo[idx + 0].Text = abstrav;
                lHebdo[idx + 1].Text = pres;
                lHebdo[idx + 2].Text = temps;
                lHebdo[idx + 3].Text = ecart;
                lHebdo[idx + 4].Text = abs1 + "<br/>" + abs2;

                if(ecart.StartsWith("-"))
                {
                    lHebdo[idx + 3].Color = Color.Red;
                }
                else
                {
                    lHebdo[idx + 3].Color = Color.LabelTxt;
                }
            }

            bool ok = true;
            for (var i = 2; i < panel2.Count - 1; i++)
            {
                if (panel2[i].id1 != panel2[i - 1].id1)
                {
                    ok = false;
                    break;
                }
            }
            if (ok)
                panel2[0].id1 = panel2[1].id1;
            else
                panel2[0].id1 = 0;

            for(var i = 0; i < panel2.Count -1; i++)
                SetPanelColor(panel2[i]);

            var idxVerrou = dat.Count - 12;
            var countV = 0;
            for (var i = 0; i < 7; i++)
            {
                verrou[i] = dat.I32(idxVerrou + i);

                if (verrou[i] == 1 || locked[i])
                {
                    verrou[i] = 1;  // on en remet une couche à cause du locked
                    lHebdo[(i + 1) * 6].Style.TextDecoration = "underline";
                    chkDay[i].Input.Disabled = true;
                    chkDay[i].Checked = false;

                    countV++;
                }
                else
                {
                    lHebdo[(i + 1) * 6].Style.TextDecoration = "";
                    chkDay[i].Input.Disabled = false;
                }

            }

            bNew.Enabled = countV < 7;

            var idx2 = dat.Count - 5;
            for(var i = 0; i < 5; i++)
                lHebdo[8 * 6 + 1 + i].Text = dat[idx2 + i];
        }

        void FillWeekQty()
        {
            Selected = null;

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.GetActivityHebdo;
            func.iparam1 = pid;
            func.iparam2 = D2000.T(lundi);
            func.iparam3 = 0;
            var dataHebdo = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataHebdo.OnSuccess += DataWeekQty_OnSuccess;

            Console.WriteLine("Hebdo ask");
        }

        private void DataWeekQty_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            int[] colsz = { 88, 167, 167, 167, 87, 24 };

            var p = pGridQty;
            p.ClearChildren();

            if (result.return_s1 == "")
                return;

            var rowH = 24;
            var x = 0;
            var y = 0;

            var csv = new CSVTool();
            csv.LoadData(result.return_s1);
            var dat = csv[0];
            var dc = 7;

            var count = dat.Count / dc;

            pWeek.Clear();
            Console.WriteLine("Hebdo clear");

            for (var i = 0; i < count; i++)
            {
                x = 0;

                var dte = dat.Date(i * dc + 0);
                var did = (int)dte.DayOfWeek;
                if (did == 0) did = 7;
                did--;
                var vi = dat.I32(i * dc + 1);
                var vj = dat.I32(i * dc + 2);
                var cf = dat[i * dc + 3];
                var act = dat[i * dc + 4];
                var ope = dat[i * dc + 5];
                var dur = dat[i * dc + 6];

                for (var j = 0; j < colsz.Length; j++)
                {
                    var p2 = new Panel(p);
                    p2.Place(x, y, colsz[j] - 1, rowH - 1);
                    p2.BackgroundColor = new Color(220, 255, 220);
                    p2.Div.OnMouseEnter += WeekOnMouseEnter;
                    p2.Div.OnMouseLeave += WeekOnMouseLeave;
                    p2.Div.OnMouseDown += WeekOnMouseDown;
                    p2.SetAttribute("rowid", pWeek.Count.ToString());
                    p2.SetAttribute("vi", vi.ToString());
                    p2.SetAttribute("vj", vj.ToString());
                    p2.SetScrolling(Overflow.Hidden, Overflow.Hidden);
                    pWeek.Add(p2);

                    var t = "";
                    if (j == 0) t = Res.days2[did] + " " + dte.Day;
                    else if (j == 1) t = cf;
                    else if (j == 2) t = act;
                    else if (j == 3) t = ope;
                    else if (j == 4) t = dur;

                    var l = new Label(t, 0, 4, p2.Width, p2.Height, "center", p2);
                    l.Paragraph.Title = t;
                    l.SetAttribute("rowid", (pWeek.Count - 1).ToString());
                    l.FontSize = 9;

                    x += colsz[j];
                }

                y += rowH;
            }
        }

        void WeekOnMouseDown(MouseEvent<HTMLDivElement> arg)
        {
int rid;
            int.TryParse(arg.Target.GetAttribute("rowid"), out rid);
            var p = pWeek[rid];
            var fid = (rid / 6 * 6);

            foreach(var pp in pWeek)
            {
                pp.id1 = 0;
                pp.BackgroundColor = new Color(220, 255, 220);
            }

            //p.id1 = 1;
            for (var i = fid; i < fid + 6; i++)
                pWeek[i].BackgroundColor = new Color(50, 200, 50);

            SelectRow(pWeek[fid]);
        }

        void WeekOnMouseEnter(MouseEvent<HTMLDivElement> arg)
        {
int rid;
            int.TryParse(arg.Target.GetAttribute("rowid"), out rid);
            var p = pWeek[rid];
            var fid = (rid / 6 * 6);
            if (pWeek[fid] == Selected) return;

            for (var i = fid; i < fid + 6; i++)
            {
                pWeek[i].BackgroundColor = new Color(120, 255, 120);
            }
        }
        void WeekOnMouseLeave(MouseEvent<HTMLDivElement> arg)
        {
int rid;
            int.TryParse(arg.Target.GetAttribute("rowid"), out rid);
            var p = pWeek[rid];
            var fid = (rid / 6 * 6);
            var p2 = pWeek[fid];

            for (var i = fid; i < fid + 6; i++)
            {
                if (p2.id1 == 0)
                    pWeek[i].BackgroundColor = new Color(220, 255, 220);
                else
                    pWeek[i].BackgroundColor = new Color(50, 200, 50);
            }

        }

        void SelectRow(Panel p)
        {
            Selected = p;
            Selected.id1 = 1;
int vi;

            int.TryParse(p.GetAttribute("vi"), out vi);
int vj;
            int.TryParse(p.GetAttribute("vj"), out vj);
int dayid;
            int.TryParse(p.GetAttribute("dayid"), out dayid);

            Selected.id2 = vi;

            for (var i = 0; i < chkDay.Length; i++)
                chkDay[i].Checked = false;
            chkDay[vi].Checked = true;

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityEntry);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(lundi.AddDays(vi));
            func.iparam3 = Workflow.wfid_su;
            func.iparam4 = vj;
            var dataEntry = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataEntry.OnSuccess += DataEntry_OnSuccess;

            bTrash.Enabled = true;
        }

        private void DataEntry_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);
            var dat = csv[0];

            var cf = dat.I32(0);
            var act = dat.I32(1);
            var ope = dat.I32(2);
            var dur = dat.I32(3);

            cboCF.SelectById(cf);
            FillCboAct(true, act, ope);

            fQty.Value = dur;

            fFrais[0].Value = result.return_i1;
            fFrais[1].Value = result.return_i2;
            fFrais[2].Value = result.return_i3;
            fFrais[3].Value = result.return_i4;


            fT1.Input.Value = result.return_s2;
            fT2.Input.Value = result.return_s3;
            fT3.Input.Value = result.return_s4;
            fT4.Input.Value = result.return_s5;

            bool locked = verrou[Selected.id2] > 0;

            LockSaisie(locked);

        }

        private void LockSaisie(bool v)
        {
            bDef.Enabled = !v;
            bDefK.Enabled = !v;

            cboCF.List.Disabled = v;
            cboAct.List.Disabled = v;
            cboOpe.List.Disabled = v;
            fQty.Input.Disabled = v;
            bK1.Enabled = !v;
            fT1.Input.Disabled = v;
            fT2.Input.Disabled = v;
            fT3.Input.Disabled = v;
            fT4.Input.Disabled = v;

            for (var i = 0; i < fFrais.Length; i++)
                fFrais[i].Input.Disabled = v;

            if (Selected != null)
                bTrash.Enabled = !v;
        }


        private void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            view.CloseLock();
        }

        void SetState(bool v)
        {
            bNew.Enabled = !v;
            bSave.Enabled = v;
            bCancel.Enabled = v;
            bPer1.Enabled = !v;
            bPer2.Enabled = !v;
            bPer3.Enabled = !v;

            bTrash.Enabled = false;
        }

        #region fill combo

        void FillCboCF(bool resetOther = false)
        {
            cboCF.Clear();
            cboCF.Add(0, "----");

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityTB);
            func.iparam1 = Workflow.pid;
            func.iparam2 = 100;   // cf activity
            func.Get<Api.ApiReturn>(Workflow.api).OnSuccess += (ret, result) =>
            {
                var csv = new CSVTool();
                csv.LoadData(result.return_s1);

                var tb = csv[0];

                for (var i = 0; i < tb.Count; i += 2)
                {
int idx;
                    int.TryParse(tb[i], out idx);

                    cboCF.Add(idx, tb[i + 1]);
                }

                cboCF.Sort();
                cboCF.SelectByIdx(0);

                if(resetOther)
                {
                    FillCboAct(resetOther);
                }
            };

        }

        void FillCboAct(bool resetOther = false, int idxAct = 0, int idxOpe = 0)
        {
            cboAct.Clear();
            cboAct.Add(0, "----");

            var cfid = cboCF.SelectedId;

            if (cfid > 0)
            {
                var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityTB);
                func.iparam1 = Workflow.pid;
                func.iparam2 = 101;   // act activity
                func.iparam3 = cboCF.SelectedId;
                func.Get<Api.ApiReturn>(Workflow.api).OnSuccess += (ret, result) =>
                {

                    if (result != null && result.return_s1 != null)
                    {
                        var csv = new CSVTool();
                        csv.LoadData(result.return_s1);
                        var tb = csv[0];

                        if (tb.Count >= 2)
                        {
                            for (var i = 0; i < tb.Count; i += 2)
                            {
int idx;
                                int.TryParse(tb[i], out idx);

                                cboAct.Add(idx, tb[i + 1]);
                            }

                            cboAct.Sort();
                            cboAct.SelectById(idxAct);

                        }

                        if (resetOther)
                        {
                            FillCboOpe(idxOpe);
                        }
                    }
                };
            }
            else
                FillCboOpe();

        }

        void FillCboOpe(int idxOpe = 0)
        {
            cboOpe.Clear();
            cboOpe.Add(0, "----");

            var cfid = cboCF.SelectedId;

            if (cfid > 0)
            {
                var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityTB);
                func.iparam1 = Workflow.pid;
                func.iparam2 = 102;   // ope activity
                func.iparam3 = cboAct.SelectedId;
                func.Get<Api.ApiReturn>(Workflow.api).OnSuccess += (ret, result) =>
                {

                    if (result != null && result.return_s1 != null)
                    {
                        var csv = new CSVTool();
                        csv.LoadData(result.return_s1);

                        var tb = csv[0];
                        if (tb.Count >= 2)
                        {
                            for (var i = 0; i < tb.Count; i += 2)
                            {
int idx;
                                int.TryParse(tb[i], out idx);

                                cboOpe.Add(idx, tb[i + 1]);
                            }

                            cboOpe.Sort();
                            cboOpe.SelectById(idxOpe);
                        }

                    }
                };
            }

        }

        private void FillCBOPers()
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.get_workflow_pop;
            func.iparam1 = cboWF.SelectedId;
            func.iparam2 = D2000.T(lundi);
            func.iparam3 = D2000.T(lundi.AddDays(6));

            var dataGetPers = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataGetPers.OnSuccess += DataGetPers_OnSuccess;
        }

        void DataGetPers_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);

            var count = result.return_i1;

            cboPers.Clear();
            for (var i = 0; i < count; i++)
            {
                var id = csv[0].I32(i * 2 + 0);
                var name = csv[0].String(i * 2 + 1);

                cboPers.Add(id, name);
            }

            cboPers.SelectByIdx(0);

            pid = cboPers.SelectedId;
            FillHebdo();
            FillWeekQty();

        }


        #endregion


        private void LoadRight()
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.get_workflow_right;
            func.token = Workflow.token;
            func.iparam1 = Workflow.pid;
            func.iparam2 = CurrentWF;
            func.iparam3 = IamChef ? 0 : 1;
            func.iparam4 = D2000.T(lundi);
            func.iparam5 = cboWF.SelectedId;

            Workflow.wfid_re = CurrentWF;

            var data = new DataApi<Right>(Workflow.api, func);
            data.OnSuccess += Data_OnSuccess;
        }

        private void Data_OnSuccess(Data<Right> data, Right result)
        {
            this.right = result;
            ApplyRight();
        }





    }
}

