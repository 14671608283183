using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using Bridge.Html5;
using Mobatime.JSControl;
using Window = Bridge.Html5.Window;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisieAnomalie : PanelSaisie
    {
        private Label[] _labels = new Label[15];
        private CheckBox[] _checks = new CheckBox[15];
        private CheckBox[] _cZones = new CheckBox[8];
        private Label[] _lZones = new Label[8];
        private Combo cboHJExc, cboJSp;
        private Label lHJExc, lJSp;
        private Combo _cPiquet;

        public PanelSaisieAnomalie(Panel pParent) : base(pParent)
        {
            lsKey = "pAnoClosed";

            id = 3;
            sz1 = 232;

            _lHeader.Text = Res.Get(400, "Anomalies & Exceptions");
            _pBack.Size = new Size(600 - 32, sz1);

            int w = _pBack.Width / 2 - 32;
            int y = 0;
            int k = 0;
            foreach (var s in Res.anomalies)
            {
                int x = 32;
                y = 12 + k * 21;



                var l = _labels[k] = new Label(s, x, y, w, 16, "left", _pBack);
                l.Color = new Color(128, 128, 128);

                var c = new CheckBox("", "", "");
                c.Place(l.Left - 20, l.Top, 16, 16, _pBack);
                _checks[k] = c;
                c.Input.OnChange = c_OnChange;

                if (k == 5)
                {
                    l.Visible = Visibility.Hidden;
                    c.Visible = Visibility.Hidden;
                }
                    
                k++;
            }

            k = 0;
            foreach (var s in Res.exception)
            {
                if (k == 4) continue;   // on ne veut plus du piquet comme exception, mais dans un combo
                int x = 32 + w + 32;
                y = 12 + k * 21;

                if (s.Length > 2)
                {
                    var l = _labels[6 + k] = new Label(s, x, y, w, 16, "left", _pBack);
                    l.Color = new Color(128, 128, 128);

                    var c = new CheckBox("", "", "");
                    c.Place(l.Left - 20, l.Top, 16, 16, _pBack);
                    _checks[6 + k] = c;
                    c.Input.OnChange = c_OnChange;
                }

                
                k++;
            }

            _cPiquet = new Combo("Piquet", w + 32, 128, w - 32, 24, _pBack);
            _cPiquet.OnChange += c => { c_OnChange(null); };
            _cPiquet.Add(0, "----");
            var piquetName = Res.Get(1221, "Piquet 1");
            if (piquetName.Length > 2)
                _cPiquet.Add(1, piquetName);
            piquetName = Res.Get(1222, "Piquet 2");
            if (piquetName.Length > 2)
                _cPiquet.Add(2, piquetName);


            k = 0;
            foreach (var s in Res.stat)
            {
                int x = 32;
                y = 12 + 7 * 21;

                var l = _labels[11] = new Label(s, x, y, w, 16, "left", _pBack);
                l.Color = new Color(128, 128, 128);

                k++;
            }

            y = 0;

            for(var i = 0; i < 4; i++)
            {
                int x = 32 + w + 32;
                y = 96 + i * 21;

                var l = _lZones[i] = new Label("9:00", x, y, 70, 16, "left", _pBack);
                var c = _cZones[i] = new CheckBox("cz" + i, "", "");
                c.Place(l.Left - 20, l.Top, 16, 16, _pBack);
                c.Input.OnChange = c_OnChange;
                c.Visible = Visibility.Hidden;
                l.Visible = Visibility.Hidden;

                l = _lZones[i + 4] = new Label("10:00", x + 70, y, 75, 16, "right", _pBack);
                c = _cZones[i + 4] = new CheckBox("cz" + i, "", "");
                c.Place(l.Right + 4, l.Top, 16, 16, _pBack);
                c.Input.OnChange = c_OnChange;
                c.Visible = Visibility.Hidden;
                l.Visible = Visibility.Hidden;
            }

            y += 21;

            lHJExc = new Label(Res.Get(435, "HJ Exception"), 64 + w, y, 100, 16, "left", _pBack);
            lHJExc.Visible = Visibility.Hidden;
            lHJExc.Color = new Color(128, 128, 128);
            lHJExc.FontSize = 9;
            cboHJExc = new Combo();
            cboHJExc.Place(lHJExc.Left, lHJExc.Bottom, 40, 24, _pBack);
            cboHJExc.Add(0, "----");
            cboHJExc.Add(1, "A");
            cboHJExc.Add(2, "B");
            cboHJExc.Add(3, "C");
            cboHJExc.Add(4, "D");
            cboHJExc.SelectById(0);
            cboHJExc.Visible = Visibility.Hidden;
            cboHJExc.OnChange += CboHJExc_OnChange;

            lJSp = new Label(Res.Get(436, "Jour spécial"), 64 + w + 100, y, 100, 16, "left", _pBack);
            lJSp.Visible = Visibility.Hidden;
            lJSp.Color = new Color(128, 128, 128);
            lJSp.FontSize = 9;
            cboJSp = new Combo();
            cboJSp.Place(lJSp.Left, lJSp.Bottom - 2, 100, 24, _pBack);
            cboJSp.Visible = Visibility.Hidden;
            cboJSp.OnChange += CboHJExc_OnChange;

            Close();
        }

        private void CboHJExc_OnChange(Combo combo)
        {
            c_OnChange(null);
        }

        public void Update(Api.API_Data_Day dat, DateTime d, bool chef, Right right)
        {
            bool tc = false;

            for (var i = 0; i < 6; i++)
            {
                if(i == 5)
                {
                    _labels[i].Visible = Visibility.Hidden;
                    _checks[i].Visible = Visibility.Hidden;
                }

                if (dat.anomalies[i] == 2)
                {
                    _labels[i].Color = new Color(0, 0, 255);

                    if (i == 5)
                    {
                        _labels[i].Visible = Visibility.Inherit;
                        //_checks[i].Visible = Visibility.Inherit;
                    }
                }
                else if (dat.anomalies[i] == 1)
                {
                    _labels[i].Color = new Color(255, 0, 0);
                    if (i == 5)
                    {
                        _labels[i].Visible = Visibility.Inherit;
                        //_checks[i].Visible = Visibility.Inherit;
                    }
                    tc = true;
                }
                else _labels[i].Color = new Color(128, 128, 128);

                _labels[i].id1 = dat.anomalies[i];

                _checks[i].Checked = dat.anomalies[i] == 2;

                if (chef && !MensualView.Verrou[d.Day] && dat.ctrId > 0)
                {
                    if (right.ValidAno)
                    {
                        _checks[i].Visible = Visibility.Inherit;
                        if (i == 5)
                            _checks[i].Visible = _labels[i].Visible;
                    }
                    else
                        _checks[i].Visible = Visibility.Hidden;
                }
                else
                    _checks[i].Visible = Visibility.Hidden;
            }


            for (var i = 1; i < 4; i++)
            {
                if (dat.exc[i] == 1)
                {
                    _labels[6 + i - 1].Color = new Color(0, 160, 0);
                    tc = true;
                }
                else _labels[6 + i - 1].Color = new Color(128);

                _labels[6 + i - 1].id1 = dat.exc[i];

                _checks[6 + i - 1].Checked = dat.exc[i] == 1;


                if (chef && !MensualView.Verrou[d.Day] && dat.ctrId > 0)
                {
                    bool ok = false;

                    if (i < 3 && right.OpenHJ) ok = true;
                    else if (i == 3 && right.OpenPause) ok = true;

                    if (ok) _checks[6 + i - 1].Visible = Visibility.Inherit;
                    else _checks[6 + i - 1].Visible = Visibility.Hidden;
                }
                else
                {
                    _checks[6 + i - 1].Visible = Visibility.Hidden;
                }
            }

            //_checks[10].Checked = dat.piquetV > 0;
            _cPiquet.SelectById(dat.piquetV);
            _cPiquet.Visible = right.Piquet == 1 ? Visibility.Inherit : Visibility.Hidden;

            //_checks[10].Visible = right.Piquet == 1 ? Visibility.Inherit : Visibility.Hidden;
            //_labels[10].Visible = (right.Piquet == 1 || dat.piquetV > 0) ? Visibility.Inherit : Visibility.Hidden;
            //_labels[10].Color = dat.piquetV > 0 ? Color.Red : Color.Mobatime1;

            //            if(right.OpenZone)
            bool ro = !right.OpenZone || MensualView.Verrou[d.Day];

            for (var z = 0; z < 4; z++)
            {
                if (dat.zone_de[z] > 0)
                {
                    _lZones[z].Text = Fmt.Ptg(dat.zone_de[z]);
                    _lZones[z].Visible = Visibility.Inherit;
                    _cZones[z].Visible = Visibility.Inherit;
                    _lZones[z + 4].Text = Fmt.Ptg(dat.zone_a[z]);
                    _lZones[z + 4].Visible = Visibility.Inherit;
                    _cZones[z + 4].Visible = Visibility.Inherit;

                    _cZones[z].Input.Disabled = ro;
                    _cZones[z + 4].Input.Disabled = ro;
                }
                else
                {
                    _lZones[z].Visible = Visibility.Hidden;
                    _cZones[z].Visible = Visibility.Hidden;
                    _lZones[z + 4].Visible = Visibility.Hidden;
                    _cZones[z + 4].Visible = Visibility.Hidden;
                }

                var p1 = (int)Math.Pow(2, z);
                var p2 = (int)Math.Pow(2, z + 4);
                _cZones[z].Checked = (dat.openZone & p1) > 0;
                _cZones[z + 4].Checked = (dat.openZone & p2) > 0;
            }
            

            if (dat.stat[0] == 1)
            {
                tc = true;
                _labels[11].Color = Color.Red;
            }
            else _labels[11].Color = new Color(128);

            
            if (right.HJExc || Workflow.perm_su_hjexc)
            {
                lHJExc.Visible = Visibility.Inherit;
                cboHJExc.Visible = Visibility.Inherit;

                cboHJExc.List.Disabled = MensualView.Verrou[d.Day];
            }
            else
            {
                if (dat.hjexc == 0)
                {
                    lHJExc.Visible = Visibility.Hidden;
                    cboHJExc.Visible = Visibility.Hidden;
                }
                else
                {
                    lHJExc.Visible = Visibility.Inherit;
                    cboHJExc.Visible = Visibility.Inherit;

                }

                cboHJExc.List.Disabled = true;
            }

            cboHJExc.SelectById(dat.hjexc);

            int[] jsp = { right.JS1, right.JS2, right.JS3, right.JS4 };
            string[] jspname = { right.JS1Name, right.JS2Name, right.JS3Name, right.JS4Name };

            var jspcount = 0;
            cboJSp.Clear();
            cboJSp.Add(0, "----");
            for(var i = 0; i < jsp.Length; i++)
            {
                if (jsp[i] > 0 && jspname[i] != "")
                {
                    cboJSp.Add(jsp[i], jspname[i]);
                    jspcount++;
                }
            }

            if(jspcount == 0)
            {
 
                lJSp.Visible = Visibility.Hidden;
                cboJSp.Visible = Visibility.Hidden;
                cboJSp.SelectById(dat.jsp);
            }
            else
            {
                lJSp.Visible = Visibility.Inherit;
                cboJSp.Visible = Visibility.Inherit;
                cboJSp.SelectById(dat.jsp);
            }

            //Console.WriteLine(tc);
            ToCheck = tc;



        }
            
        public void Save(Api.API_Data_Day dat)
        {
            dat.anomalies.Clear();

            for (var i = 0; i < 5; i++)
            {
                dat.anomalies.Add(0);
                if (_checks[i].Checked) dat.anomalies[i] = 2;
                else dat.anomalies[i] = 0;
            }
            dat.anomalies.Add(0);

            if (_checks[5].Visible == Visibility.Inherit)
            {
                if (_checks[5].Checked) dat.anomalies[5] = 2;
                else dat.anomalies[5] = 0;
            }

            for (var i = 0; i < 3; i++)
            {
                dat.exc.Add(0);
                if (_checks[6 + i].Checked) dat.exc[i] = 1;
                else dat.exc[i] = 0;
            }

            dat.openZone = 0;
            for (var i = 0; i < 4; i++)
            {
                if (_cZones[i].Checked)
                    dat.openZone += (int)Math.Pow(2, i);
                if (_cZones[i + 4].Checked)
                    dat.openZone += (int)Math.Pow(2, i + 4);
            }

            dat.hjexc = cboHJExc.SelectedId;
            dat.jsp = cboJSp.SelectedId;
            dat.piquetV = _cPiquet.SelectedId;// _checks[10].Checked ? 1 : 0;
        }

        public void c_OnChange(Event<HTMLInputElement> arg)
        {
            Modified = true;
        }

    }
}