using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    class ListPers : Panel
    {

        public delegate void dOnBSelPersClick(int pid, string s);
        public event dOnBSelPersClick OnBSelPersClick = null;

        public static ListPers self = null;
        public static bool NO_Update = false;

        Control parent = null;
        ListBox lst;
        Label lTitle, lCount;
public ListBox List
{
    get
    {
        return lst;
    }
}
        Button b1, b2, b3;
        List<Bridge.Html5.HTMLOptionElement> all = new List<Bridge.Html5.HTMLOptionElement>();
public int Count
{
    get
    {
        return lst.Count;
    }
}
        int selPid = 0;

        public ListPers(Control parent) : base(parent)
        {
            self = this;
            this.parent = parent;

            Place(0, 48, parent.Width, 400);

            lst = new ListBox();
            lst.Place(32, 32, Width - 64 - 48, Height - 40, this);
            lst.List.Multiple = true;
            lst.List.OnMouseDown = lst_OnMouseDown;
            

            lTitle = new Label(Res.Get(5004, "Personne(s) concernée(s)"), lst.Left, lst.Top - 20, lst.Width - 70, 16, "left", this);
            lCount = new Label("13/21", lst.Right - 64, lTitle.Top, 64, 16, "right", this);

            lTitle.Color = lCount.Color = new Color(63);


            b1 = new Button(lst.Right + 12, lst.Top, 34, 34, "", this);
            b2 = new Button(b1.Left, b1.Bottom + 8, b1.Width, b1.Height, "", this);
            b3 = new Button(b1.Left, lst.Bottom - b1.Height, b1.Width, b1.Height, "images/pers_anchor.png", this);
            b1.SetImage("images/cart_out_black.png", 32, 32);
            b2.SetImage("images/cart_in_black.png", 32, 32);
            b3.SetImage("images/pers_anchor.png", 32, 32);

            b1.OnButtonClick += B1_OnButtonClick;
            b2.OnButtonClick += B2_OnButtonClick;
            b3.OnButtonClick += B3_OnButtonClick;

            b3.Visible = Bridge.Html5.Visibility.Hidden;

            UpdateCounter();

        }

        public void Resize()
        {
            lst.Place(32, 32, Width - 64 - 48, Height - 40);
            lCount.Place(lst.Right - 64, lTitle.Top, 64, 16);
            b1.Place(lst.Right + 12, lst.Top, 34, 34);
            b2.Place(b1.Left, b1.Bottom + 8, b1.Width, b1.Width);
        }

        private void B2_OnButtonClick(Button c, Bridge.Html5.MouseEvent arg)
        {
            lst.Clear();
            foreach(var opt in all)
            {
                lst.Add(opt);
            }

            UpdateCounter();
        }

        private void B3_OnButtonClick(Button c, Bridge.Html5.MouseEvent arg)
        {

            var pid = lst.SelectedId;
            var s = lst.Selected.Text;


            if (pid == selPid)
            {
                pid = 0;
                s = "";
                //lst.Selected.Style.FontWeight = "normal";
                //lst.Selected.Style.FontStyle = Bridge.Html5.FontStyle.Normal;
            }
            else
            {
                //lst.Selected.Style.FontWeight = "bold";
                //lst.Selected.Style.FontStyle = Bridge.Html5.FontStyle.Italic;
            }

            selPid = pid;

            OnBSelPersClick!=null?global::Bridge.Script.FromLambda(()=>OnBSelPersClick.Invoke(pid, s)):null;
        }

        private void B1_OnButtonClick(Button c, Bridge.Html5.MouseEvent arg)
        {
            MakeSelection();
        }

        public void lst_OnMouseDown(Bridge.Html5.MouseEvent<Bridge.Html5.HTMLSelectElement> arg)
        {
            if(arg.Button == 2)
            {
                MakeSelection();
            }
        }

        void MakeSelection()
        {
            var options = new List<Bridge.Html5.HTMLOptionElement>();

            foreach (var opt in lst.List.Options)
            {
                if (opt.Selected)
                    options.Add(opt);
            }

            if (options.Count > 0)
            {
                Clear(false);

                foreach (var opt in options)
                {
int pid;
                    int.TryParse(opt.Value, out pid);
                    AddPers(pid, opt.Text, false);
                }
            }

            UpdateCounter();
        }

        public void Clear(bool full = true)
        {
            if (full) all.Clear();
            lst.Clear();

            UpdateCounter();
        }

        public void AddPers(int pid, string nom, bool full = true)
        {
            var opt = lst.Add(pid, nom);
            if (full) all.Add(opt);

            UpdateCounter();

        }

        public void Sort()
        {
            lst.Sort();

        }

        public void UpdateCounter()
        {
            var c1 = lst.Items.Length;
            var c2 = all.Count;
            lCount.Text = c1 + "/" + c2;
        }

        public void SetMode(int m)
        {
            if (m == 0) b3.Visible = Bridge.Html5.Visibility.Hidden;
            else b3.Visible = Bridge.Html5.Visibility.Inherit;
        }

    }
}

