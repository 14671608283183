using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;

namespace Workflow_bridge
{
    class PanelOFCreation : Panel
    {
        Button bClose, bNew, bModify, bSave, bCancel;
        MensualView.MensualView view = null;
        Dictionary<string, Control> fields = new Dictionary<string, Control>();
        Combo cboOF;
        Label lst = null;

        Panel pLstOrdre = null;

        string[] ostatus = { "", "Ordre nouveau", "Ordre modifié", "", "Ordre Terminé", "", "Indépendant ouvert", "Indépendant fermé", "Fixe en cours", "Fixe terminé" };
        string[] otype = { "Simple", "Passage", "Forfait réf. A", "Forfait réf. B", "", "", "", "", "", "", "Projet ouvert", "", "", "", "Projet fermé" };

        Dictionary<int, string> ofs = new Dictionary<int, string>();
        Dictionary<int, string> cfs = new Dictionary<int, string>();
        Dictionary<int, string> ordres = new Dictionary<int, string>();
        Dictionary<int, string> pers = new Dictionary<int, string>();

        Field date1, date2;
        CheckBox cInv;
        ListBox lstP1, lstP2;
        Panel lstEntries;
        Button bAddOrder;

        List<Panel> lstPEntries = new List<Panel>();
        Dictionary<int, string> dicoPers = new Dictionary<int, string>();

        bool createNew = false;

        Color[] validColor = new Color[32];

        public PanelOFCreation(MensualView.MensualView view)
        {
            this.view = view;
            BackgroundColor = GetColor("window.back");
            Size = new Size(1270, 850);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = GetColor("toolbar.back");

            var lTitle = new Label(Res.Get(11000, "OF / Affaires / Projets"), 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            var bOrdre = new Button(512, 0, 32, 32, "images/manufacturing-process-2-gears.png", pTitle, false, 0, 28, 28);
            bOrdre.OnButtonClick += (a, b) =>
            {
                var po = new PanelOrdreCreation(view);
                AddChild(po);
                po.Reset();
                po.FromOF(2, 0, -1, this);
                po.Visible = Bridge.Html5.Visibility.Inherit;
                po.Style.ZIndex = "1000";
            };

            var x = 4;
            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle, false, 99);
            bClose.OnButtonClick += BMenu_Click; x += 64;

            bNew = new Button(x, 0, 32, 32, "images/63_new_t.png", pTitle, false, 1);
            bNew.OnButtonClick += BMenu_Click; x += 40;
            bModify = new Button(x, 0, 32, 32, "images/63modifie_t.png", pTitle, false, 2);
            bModify.OnButtonClick += BMenu_Click; x += 40;
            bSave = new Button(x, 0, 32, 32, "images/63save_t.png", pTitle, false, 3);
            bSave.OnButtonClick += BMenu_Click; x += 40;
            bCancel = new Button(x, 0, 32, 32, "images/64annule_t.png", pTitle, false, 4);
            bCancel.OnButtonClick += BMenu_Click; x += 40;

            validColor[0] = Color.Blue;
            validColor[1] = Color.Green;
            validColor[2] = Color.Red;
            validColor[4] = new Color(255, 127, 0, 255);
            validColor[8] = new Color(255, 127, 0, 255);
            validColor[16] = Color.Green;

            PlaceObj1();
            PlaceObj2();
            PlaceObj3();

            SetState(false);
        }

        private void BMenu_Click(Button c, Bridge.Html5.MouseEvent arg)
        {
            switch(c.id1)
            {
                case 99:
                    view.CloseLock();
                    break;

                case 1:
                    New();
                    SetState(true);
                    break;
                case 2:
                    SetState(true);
                    break;
                case 3:
                    Save();
                    SetState(false);
                    break;
                case 4:
                    Cancel();
                    SetState(false);
                    break;
            }
        }

        public void Reset()
        {
            createNew = false;
            LoadOFs();
        }

        void PlaceObj1()
        {
            var p = new Panel(16, 48, 600, 192, Color.White, this);
            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(11010, "Information générale"), 8, 8, p2.Width - 8, 16, "left", p2);
            l.Color = Color.White;

            cboOF = new Combo("", p2.Width - 258, 2, 250, 28, p2);
            cboOF.OnChange += CboOF_OnChange;

            string[] txtlabel = { "Id code", "Description", "GPS" };
            string[] ids = { "idc", "desc", "gps" };

            txtlabel[0] = Res.Get(11011, txtlabel[0]);
            txtlabel[1] = Res.Get(11012, txtlabel[1]);
            txtlabel[2] = Res.Get(11013, txtlabel[2]);

            var labels = new Label[txtlabel.Length];

            var x = 16;
            var y = 64;
            for(var i = 0; i < txtlabel.Length; i++)
            {
                labels[i] = new Label(txtlabel[i] + ":", x, y, 170, 16, "right", p);
                y += 32;
            }


            fields[ids[0]] = new Field(labels[0], 250); // idc
            fields[ids[1]] = new Field(labels[1], 250); // desc
            fields[ids[2]] = new Field(labels[2], 350); // gps



            //manufacturing-process-2-gears.png


        }

        void PlaceObj2()
        {
            var p = new Panel(16, 256, 600, Height - 272, Color.White, this);
            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(11050, "Ordres / activités"), 8, 8, p2.Width - 8, 16, "left", p2);
            l.Color = Color.White;

            pLstOrdre = new Panel(0, 48, p.Width, p.Height - 48, new Color(220), p);
            pLstOrdre.SetScrolling(Bridge.Html5.Overflow.Hidden, Bridge.Html5.Overflow.Scroll);
            var line = new Panel(pLstOrdre.Left, pLstOrdre.Top - 1, pLstOrdre.Width, 1, pLstOrdre.BackgroundColor, p);

            bAddOrder = new Button(p.Width - 40, 0, 32, 32, "images/63_new_t.png", p);
            bAddOrder.OnButtonClick += (a, b) =>
            {
                var po = new PanelOrdreCreation(view);
                AddChild(po);
                po.FromOF(0, cboOF.SelectedId, -1, this);
                po.Reset();
                po.Visible = Bridge.Html5.Visibility.Inherit;
                po.Style.ZIndex = "1000";
            };

        }

        void PlaceObj3()
        {
            var p = new Panel(632, 48, Width - 648, Height - 64, Color.White, this);
            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(11100, "Dernières saisies"), 8, 8, p2.Width - 16, 16, "right", p2);
            l.Color = Color.White;

            fields["date"] = l = new Label("", 8, 1, p2.Width - 16, 16, "left", p2);
            l.Color = Color.White;
            l.FontSize = 9;

            lstEntries = new Panel(0, 48, p.Width, p.Height - 48, new Color(220), p);
            lstEntries.SetScrolling(Bridge.Html5.Overflow.Hidden, Bridge.Html5.Overflow.Scroll);
        }

        void SetState(bool v)
        {
            bClose.Enabled = !v;
            bNew.Enabled = !v;
            bModify.Enabled = !v;
            bSave.Enabled = v;
            bCancel.Enabled = v;
            cboOF.List.Disabled = v;

            bAddOrder.Visible = v ? Bridge.Html5.Visibility.Hidden : Bridge.Html5.Visibility.Inherit;

            if (cboOF.Count == 0) bModify.Enabled = false;

            foreach (var c in fields.Values)
            {
                if (c is Field) ((Field)c).Input.ReadOnly = !v;
                else if (c is Combo) ((Combo)c).List.Disabled = !v;
            }

            if (v)
            {
                Bridge.Html5.Window.SetTimeout((global::System.Action)(() => { ((Field)fields["idc"]).Focus(); }), 10);
            }
        }

        private void CboOF_OnChange(Combo combo)
        {
            var id = cboOF.SelectedId;
            
            pLstOrdre.ClearChildren();

            var func = new Api.ApiFunction(Api.ApiFuncType.OrdreCreateOrdre);
            func.iparam1 = id;
            func.iparam2 = 1;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                Clear();

                if (b.return_s1.Length > 0)
                {
                    var csv = new CSVTool(b.return_s1)[0];

                    List<Panel> lstP = new List<Panel>();

                    ((Field)fields["idc"]).Value = csv[0];
                    ((Field)fields["desc"]).Value = csv[1];
                    ((Field)fields["gps"]).Value = csv[2];

                    var y = 0;
                    for (var i = 0; i < 50; i += 3)
                    {
                        var oid = csv.I32(3 + i);
                        var d1 = csv.Date(3 + i + 1);
                        var d2 = csv.Date(3 + i + 2);
                        if (oid == 0) continue;
                        var otxt = "";
                        if (ordres.ContainsKey(oid)) otxt = ordres[oid];

                        var np = new Panel(1, y, pLstOrdre.Width, 32, Color.White, pLstOrdre, lstP);
                        np.id1 = oid;
                        var lid = new Label((i / 3 + 1).ToString() + ".", 0, 10, 40, 16, "right", np);
                        var l = new Label(otxt, 48, 10, np.Width - 48, 16, "left", np);
                    
                        var ldate = new Label(Fmt.DateComplete(d1) + "<br />" + Fmt.DateComplete(d2), 
                                                0, 1, np.Width - 24, 16, "right", np);
                        ldate.FontSize = 9;

                        np.Div.OnDblClick += e =>
                        {
                            if (!bClose.Enabled) return;
                            var po = new PanelOrdreCreation(view);
                            AddChild(po);
                            po.Reset();
                            po.FromOF(1, cboOF.SelectedId, np.id1, this);
                            po.Visible = Bridge.Html5.Visibility.Inherit;
                            po.Style.ZIndex = "1000";
                        };


                        y += 33;
                    }

                }
            };

            LoadEntries(id);
        }

        void LoadOFs(int wanted = -1)
        {
            Console.WriteLine("wantedB: " + wanted);

            var func = new Api.ApiFunction(Api.ApiFuncType.OrdreCreateOrdres);
            func.iparam1 = 1;   // only of
            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                cboOF.Clear();
                ofs.Clear();
                ordres.Clear();
                cfs.Clear();
                pers.Clear();

                if (b.return_s1.Length > 1)
                {
                    var csvOrdre = new CSVTool(b.return_s1)[0];
                    var csvOF = new CSVTool(b.return_s2)[0];
                    var csvCF = new CSVTool(b.return_s3)[0];
                    var csvPers = new CSVTool(b.return_s4)[0];

                    var count = csvOrdre.I32(0);
                    var idx = 1;

                    for (var i = 0; i < count; i++)
                    {
                        var oid = csvOrdre.I32(idx++);
                        var idc = csvOrdre[idx++];
                        var name = csvOrdre[idx++];

                        ordres.Add(oid, idc + " " + name);

                    }

                    count = csvOF.I32(0);
                    idx = 1;
                    for (var i = 0; i < count; i++)
                    {
                        var ofid = csvOF.I32(idx++);
                        var desc = csvOF[idx++];
                        var idcode = csvOF[idx++];
                        ofs[ofid] = desc;
                        cboOF.Add(ofid, idcode + " " + desc);
                    }
                    cboOF.Sort();


                    if (wanted <= 0)
                        cboOF.SelectByIdx(0, true);
                    else
                        cboOF.SelectById(wanted, true);

                    if (bClose.Enabled && !bModify.Enabled && cboOF.Count > 0)
                        bModify.Enabled = true;
                }
            };
        }

        void New()
        {
            Clear();
            createNew = true;
        }

        void Save()
        {

            var func = new Api.ApiFunction(Api.ApiFuncType.OrdreCreateOFSave);
            func.iparam1 = createNew ? 1 : 0;
            func.iparam2 = cboOF.Selected != null ? cboOF.SelectedId : -1;

            var csv = new CSVTool();
            
            csv.A(((Field)fields["idc"]).Input.Value);
            csv.A(((Field)fields["desc"]).Input.Value);
            csv.A(((Field)fields["gps"]).Input.Value);
            for (var i = 0; i < 10; i++)
                csv.A("");

            func.param1 = csv.ToString();


            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                var rid = b.return_i1;
                var txt = b.return_s1;

                Clear();
                if (createNew)
                {
                    LoadOFs(rid);
                }
                else
                {
                    LoadOFs(cboOF.SelectedId);
                    //cboOF.SelectById(cboOF.SelectedId, true);
                }

                createNew = false;
            };

            

        }

        void Cancel()
        {
            Clear();
            if (cboOF.Selected != null)
                cboOF.SelectById(cboOF.SelectedId, true);

            createNew = false;
        }

        void Clear()
        {
            ((Field)fields["idc"]).Value = "";
            ((Field)fields["desc"]).Value = "";
            ((Field)fields["gps"]).Value = "";
            pLstOrdre.ClearChildren();
        }

        void LoadEntries(int ofid)
        {
            lstEntries.ClearChildren();
            lstPEntries.Clear();

            ((Label)fields["date"]).Text = string.Format("{0}<br />{1}",Fmt.DateComplete(DateTime.Now.AddDays(-40)),Fmt.DateComplete(DateTime.Now));

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityQties2);
            func.iparam1 = Workflow.pid;
            func.iparam2 = D2000.T(DateTime.Now);
            func.iparam3 = 40;
            func.iparam4 = 99;
            func.iparam5 = 40;
            func.iparam6 = 0;
            func.iparam8 = ofid;

            var sbPid = new StringBuilder();
            foreach (var item in view.CboWF.Items)
                if (item.Value != "0")
                {
                    sbPid.Append(item.Value);
                    sbPid.Append(';');
                }
            func.param1 = sbPid.ToString();

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                if (b.return_s1.Length > 0)
                {
                    var csv = new CSVTool(b.return_s1)[0];

                    int y = 0;
                    for (var i = 0; i < csv.Count; i += 28)
                    {
                        var q = new PanelValidationQ.Qty(csv, i);
                        y = AddQ(q, y);
                    }
                }

            };
        }

        int AddQ(PanelValidationQ.Qty qty, int y)
        {
            var p = new Panel(0, y, lstEntries.Width, 24, Color.White, lstEntries, lstPEntries);
            p.tag = qty;

            var l = new Label(Fmt.DateComplete(qty.date), 20, 4, p.Width - 44, p.Height - 8, "left", p);
            l = new Label(qty.name, p.Width / 2, 4, p.Width - 44, p.Height - 8, "left", p);

            l = new Label(Fmt.HHmm(qty.duree), 20, 4, p.Width - 44, p.Height - 8, "right", p);
            l.FontSize = 12;


            var pV = new Panel(0, 0, 8, p.Height, Color.White, p);
            pV.id1 = 100;
            pV.BackgroundColor = validColor[qty.validation];

            qty.pRow = p;
            qty.pValid = pV;

            return p.Bottom + 1;
        }

        public void Update(int ofid)
        {
            LoadOFs(ofid);
        }

    }
}
