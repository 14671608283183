using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using System.Linq;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class pSplit
    {

        Panel back = null;
        pShowQty parent = null;
        Button bok, bcancel;

        Label lOrdre = null;
        Label ltime = null;
        Field ftime = null;
        Label ltime2 = null;
        Field ftime2 = null;
        GridWkData.pRow selected;
        PanelWkData wkData;
        WkSelectTable selectTable;
        public bool Visible { get; private set; }

        public pSplit(PanelWkData wkData, WkSelectTable selectTable, pShowQty parent)
        {
            this.parent = parent;
            this.wkData = wkData;
            this.selectTable = selectTable;
            selectTable.OnSelect += SelectTable_OnSelect;

            back = new Panel(40, 0, parent.Width - 40, parent.Height, Color.White, parent);

            Label l;


            ltime = l = new Label("", 0, 64, 300, 26, "right", back);
            ftime = new Field(l, 60, Field.FieldFormat.Ptg);

            ltime2 = l = new Label(Res.Get(9153, "Temps intercallaire") + ": ", 0, 96, 300, 26, "right", back);
            ftime2 = new Field(l, 60, Field.FieldFormat.HQty);

            var lt1 = l = new Label(Res.Get(9154, "Ordre sélectionné") + ":", 0, 128, 300, 16, "right", back);
            lOrdre = new Label("----", 304, lt1.Top, back.Width - 304 - 32, 16, "left", back);
            lOrdre.Style.Border = "1px solid #ccc";
            lOrdre.Paragraph.OnMouseDown += ((e) => { selectTable.Show(0); });
            lOrdre.Style.Overflow = Overflow.Hidden;

            bok = new Button(ftime.Right + 16, 88, 32, 32, "images/63modifie.png", back, false, 1, 32, 32);
            bcancel = new Button(bok.Right + 8, 88, 32, 32, "images/64annule.png", back, false, 2, 32, 32);

            bok.OnButtonClick += Bok_OnButtonClick;
            bcancel.OnButtonClick += Bok_OnButtonClick;
            bok.Visible = Visibility.Hidden;
            bcancel.Visible = Visibility.Hidden;

            var lst = back.GetChildren();
            foreach (var c in lst)
            {
                if (c is Label) ((Label)c).FontSize = 9;
                else if (c is Field)
                {
                    var f = (Field)c;
                    f.Style.FontSize = "9pt";
                    f.Height = 20;
                    f.Top += 2;
                }
            }

            var lTitle = l = new Label(Res.Get(9150, "Splitting"), 40, 4, back.Width - 44, 16, "right", back);
            l.Color = new Color(200);
        }

        private void Bok_OnButtonClick(Button c, MouseEvent arg)
        {
            if(c.id1 == 1)
            {

                var func = new Api.ApiFunction(Api.ApiFuncType.WkSplit);
                func.iparam1 = selected.pid;
                func.iparam2 = D2000.T(selected.d1);
                func.iparam3 = selected.blockIdx;
                func.iparam4 = selected.idx;
                func.iparam5 = ftime.ValueI;
                func.iparam6 = ftime2.ValueI;
                func.iparam7 = lOrdre.id1;

                var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
                data.OnSuccess += ((data2, result) =>
                {
                    wkData.UpdateGrid();
                });

            }
            
            parent.SetState(false);
        }

        public void Show(GridWkData.pRow selected)
        {
            this.selected = selected;
            back.Visible = Visibility.Inherit;

            ftime.Value = 0;
            ftime2.Value = 0;

            var h1 = selected.d1.Hour + ":" + selected.d1.Minute.ToString().PadLeft(2, '0');
            var h2 = selected.d2.Hour + ":" + selected.d2.Minute.ToString().PadLeft(2, '0');

            ltime.Text = Res.Get(9151, "Heure du split, entre") + " " + h1 + " " + Res.Get(9152) + " " + h2 + ": ";

            var ordre = Workflow.ordres[selected.ordre];
            lOrdre.Text = ordre.idcode + ", " + ordre.desc;
            lOrdre.id1 = selected.ordre;

            Visible = true;
        }

        public void Hide()
        {
            back.Visible = Visibility.Hidden;
            Visible = false;
        }

        public void Save()
        {

            var func = new Api.ApiFunction(Api.ApiFuncType.WkSplit);
            func.iparam1 = selected.pid;
            func.iparam2 = D2000.T(selected.d1);
            func.iparam3 = selected.blockIdx;
            func.iparam4 = selected.idx;
            func.iparam5 = ftime.ValueI;
            func.iparam6 = ftime2.ValueI;
            func.iparam7 = lOrdre.id1;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += ((data2, result) =>
            {
                wkData.UpdateGrid();
            });
        }

        public void Cancel()
        {
            parent.SetState(false);
        }

        private void SelectTable_OnSelect(WkSelectTable grid, int type, int idx)
        {
            var l = lOrdre;
            l.id1 = idx;

            if (type == 0)
            {
                var ordre = Workflow.ordres[idx];
                l.Text = ordre.idcode + ", " + ordre.desc;
            }

        }

    
private bool __Property__Initializer__Visible=false;}
}
