using System.Collections.Generic;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using System;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisieMultiValid : PanelSaisie
    {

        public delegate void dOnNeedRefresh(PanelSaisieMultiValid panelSaisieMulti);
        public event dOnNeedRefresh OnNeedRefresh = null;

        public static PanelSaisieMultiValid Self = null;

        Combo cboRole = null;

        Button[] bValid = new Button[3];
        Button[] bCValid = new Button[2];
        Button[] bCCValid = new Button[2];

        Image imgAction = null;

        Panel pbv = null, pbcv = null, pbccv = null;
        Label lAction = null;
        Label lSel = null;
        Label lValid = null;

        Button bSave, bCancel;

        int pid = 0;
        int wfid = 0;

        int type = -1;

        Dictionary<int, string> text = new Dictionary<int, string>();

        public PanelSaisieMultiValid(Panel pParent) : base(pParent)
        {
            Self = this;



            lsKey = "pMultiValid";

            text.Add(3, Res.Get(920, "Indéterminé"));
            text.Add(7, Res.Get(920, "Validé"));
            text.Add(15, Res.Get(920, "Refusé"));
            text.Add(23, Res.Get(920, "Indéterminé"));
            text.Add(55, Res.Get(920, "Contre validé"));
            text.Add(24, Res.Get(920, "Indéterminé"));
            text.Add(56, Res.Get(920, "CContre validé"));

            this.id = 6;

            _lHeader.Text = Res.Get(910, "Validation multiple");
            SetHeaderHeight(32);

            _pBack.Size = new Size(600 - 32, 256);
            sz1 = 256;

            this.OnOpenClose += PanelSaisieMultiValid_OnOpenClose;

            var p = new Panel(_pBack);
            p.Place(16, 32, _pBack.Width - 32, 200);
            p.SetBorderStyle(1, "solid", new Color(200), 4);

            cboRole = new Combo();
            cboRole.Place(32, 32, 200, 32, p);
            cboRole.Style.FontSize = "11pt";
            cboRole.OnChange += CboRole_OnChange;

            

            var l = new Label(Res.Get(911, "Choix du rôle"), cboRole.Left, cboRole.Top - 16, cboRole.Width, 16, "left", p);
            l.FontSize = 8;
            l.Color = new Color(150);

            lAction = l = new Label(Res.Get(912, "Sélection d'une action"), cboRole.Right + 64, cboRole.Top - 16, cboRole.Width, 16, "left", p);
            l.FontSize = 8;
            l.Color = new Color(150);
            l.Visible = Visibility.Hidden;

            pbv = new Panel(p);
            pbv.Place(cboRole.Right + 64, cboRole.Top, 176, 48);
            pbv.BackgroundColor = Color.White;

            string[] sn = { "Neu_001_48.png", "Yes_001_48.png", "No_001_48.png" };
            int[] id = { 3, 7, 15 };

            for (var i = 0; i < bValid.Length; i++)
            {
                var b = bValid[i] = new Button(0 + i * 64, 0, 48, 48, "images/" + sn[i], pbv);
                b.id1 = id[i];
                b.tag = sn[i];
                b.OnButtonClick += B_OnButtonClick;
            }

            pbv.Visible = Visibility.Hidden;



            pbcv = new Panel(p);
            pbcv.Place(cboRole.Right + 64, cboRole.Top, 112, 48);
            pbcv.BackgroundColor = Color.White;

            string[] sn2 = { "Neu_001_48.png", "Yes_001_48.png" };
            int[] id2 = { 23, 55 };

            for (var i = 0; i < bCValid.Length; i++)
            {
                var b = bCValid[i] = new Button(0 + i * 64, 0, 48, 48, "images/" + sn2[i], pbcv);
                b.tag = sn2[i];
                b.id1 = id2[i];
                b.OnButtonClick += B_OnButtonClick;
            }

            pbcv.Visible = Visibility.Hidden;

            pbccv = new Panel(p);
            pbccv.Place(cboRole.Right + 64, cboRole.Top, 112, 48);
            pbccv.BackgroundColor = Color.White;

            string[] sn3 = { "Neu_001_48.png", "Yes_001_48.png" };
            int[] id3 = { 24, 56};

            for (var i = 0; i < bCCValid.Length; i++)
            {
                var b = bCCValid[i] = new Button(0 + i * 64, 0, 48, 48, "images/" + sn3[i], pbccv);
                b.tag = sn3[i];
                b.id1 = id3[i];
                b.OnButtonClick += B_OnButtonClick;
            }

            pbccv.Visible = Visibility.Hidden;


            imgAction = new Image("");
            imgAction.Place(cboRole.Left, 112, 32, 32, p);
            imgAction.Visible = Visibility.Hidden;

            lSel = new Label(Res.Get(913, "Sélection") + ": ", imgAction.Left, imgAction.Bottom + 8, 200, 16, "left", p);
            lSel.Visible = Visibility.Hidden;

            lValid = new Label("", imgAction.Right + 16, imgAction.Top + 8, 200, 16, "left", p);
            lValid.Visible = Visibility.Hidden;


            bSave = new Button(p.Width - 160, p.Height - 64, 48, 48, "images/64ok.png", p, false);
            bSave.OnButtonClick += BSave_OnButtonClick;
            bCancel = new Button(bSave.Right + 16, p.Height - 64, 48, 48, "images/64annule.png", p, false);
            bCancel.OnButtonClick += BCancel_OnButtonClick;

            bSave.Enabled = false;
            bCancel.Enabled = false;




        }

        private void DataChefType_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            type = result.return_i1;

            cboRole.Clear();
            cboRole.Add(0, "." + Res.Get(914, "Sélection d'un rôle"));

            if ((int)(result.return_i1 & 4) > 0)
                cboRole.Add(3, Res.Get(917, "Valideur Pointage"));

            if ((int)(result.return_i1 & 1) > 0)
                cboRole.Add(1, Res.Get(915, "Valideur Absence"));

            if ((int)(result.return_i1 & 2) > 0)
                cboRole.Add(2, Res.Get(916, "Contre-Valideur Absence"));

            if ((int)(result.return_i1 & 8) > 0)
                cboRole.Add(4, "+" + Res.Get(916, "Contre-Valideur Absence") + "+");



            if (cboRole.Count == 2)
            {
                cboRole.SelectByIdx(1, true);
            }
            else cboRole.SelectByIdx(0, true);
            cboRole.List.Disabled = !(cboRole.Count >= 3);
        }

        private void BCancel_OnButtonClick(Button c, MouseEvent arg)
        {
            cboRole.SelectByIdx(0, true);
            OnNeedRefresh!=null?global::Bridge.Script.FromLambda(()=>OnNeedRefresh.Invoke(this)):null;
        }

        private void BSave_OnButtonClick(Button c, MouseEvent arg)
        {


            var csv = new CSVTool();

            var pdays = MensualView.Grid.Selected;
            var dates = new List<DateTime>();
            foreach (var day in pdays)
            {
                if (!day.StatusNotOK)
                    dates.Add(day.Date);
            }

            csv.A(dates.Count);
            foreach (var d in dates)
            {
                csv.A(d);
            }
            var code = imgAction.id1;
            if (cboRole.SelectedId != 3)
                csv.A(500 + code);
            else
                csv.A(800 + code);

            csv.A(0);
            csv.A(0);
            csv.A(0);

            var flag = 0;

            var s = csv.ToString();
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.WFValidSU;
            func.iparam1 = pid;
            func.iparam2 = flag;
            func.iparam3 = wfid;
            func.iparam4 = Workflow.pid;
            func.param1 = s;
            var dataSave = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataSave.OnSuccess += DataSave_OnSuccess;


        }

        private void DataSave_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            cboRole.SelectByIdx(0, true);
            OnNeedRefresh!=null?global::Bridge.Script.FromLambda(()=>OnNeedRefresh.Invoke(this)):null;
        }

        public void Init(int wfid, int pid, int selCount)
        {
            

            this.wfid = wfid;
            this.pid = pid;

            if (selCount <= 2) type = -1;

            if (type == -1)
                cboRole.SelectByIdx(0, true);

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.GetChefType;
            func.iparam1 = Workflow.pid;
            func.iparam2 = wfid;
            
            if (type == -1)
            {
                var dataChefType = new DataApi<Api.ApiReturn>(Workflow.api, func);
                dataChefType.OnSuccess += DataChefType_OnSuccess;
            }
        }

        private void B_OnButtonClick(Button c, MouseEvent arg)
        {
            var s = c.tag.ToString();
            imgAction.SetImage("images/" + s);
            imgAction.Visible = Visibility.Inherit;
            imgAction.id1 = c.id1;

            Console.WriteLine("c.id1: " + c.id1);

            //saisie.BlockSister((aid + 1) % 2);

            var grid = MensualView.Grid;
            var selected = grid.Selected;

            grid.ResetStatusOK();


            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.verif_absences;
            func.iparam1 = Workflow.pid;
            func.iparam2 = wfid;
            func.iparam3 = pid;
            if (cboRole.SelectedId != 3)
                func.iparam6 = 100 + c.id1;
            else
                func.iparam6 = 200;

            var csv = new CSVTool();
            csv.A(selected.Count);
            foreach (var day in selected)
            {
                csv.A(day.Date);
            }

            func.param1 = csv.ToString();

            var dataVerifAbs = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataVerifAbs.OnSuccess += DataVerifAbs_OnSuccess;
            dataVerifAbs.id1 = c.id1;



        }

        private void DataVerifAbs_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var grid = MensualView.Grid;
            var selected = grid.Selected;

            var csv = new CSVTool();
            csv.LoadData(result.return_s1);

            var count = csv[0].I32(0);
            int countFinal = 0;
            for (var i = 0; i < count; i++)
            {
                var status = csv[0].I32(i + 1);
                if (status == 0)
                {
                    selected[i].StatusNotOK = true;
                }
                else
                {
                    countFinal++;
                }
            }

            lSel.Visible = Visibility.Inherit;
            lSel.Text = Res.Get(913, "Sélection") + " { " + countFinal + " }";
            lValid.Visible = Visibility.Hidden;
            lValid.Text = text[data.id1];

            //lValid.Visible = Visibility.Inherit;

            bSave.Enabled = false;
            bCancel.Enabled = false;
            if (countFinal > 0)
            {
                bSave.Enabled = true;
                bCancel.Enabled = true;
            }
        }

        private void CboRole_OnChange(Combo combo)
        {
            lAction.Visible = Visibility.Hidden;
            imgAction.Visible = Visibility.Hidden;
            lValid.Visible = Visibility.Hidden;
            lSel.Visible = Visibility.Hidden;
            bSave.Enabled = false;
            bCancel.Enabled = false;

            pbv.Visible = Visibility.Hidden;
            pbcv.Visible = Visibility.Hidden;
            pbccv.Visible = Visibility.Hidden;

            var grid = MensualView.Grid;
            var selected = grid.Selected;

            foreach (var day in selected)
            {
                day.StatusNotOK = false;
            }

            switch (combo.SelectedId)
            {
                case 0:
                    //pbv.Visible = Visibility.Hidden;
                    //pbcv.Visible = Visibility.Hidden;
                    //pbccv.Visible = Visibility.Hidden;
                    break;
                case 1:
                case 3:
                    pbv.Visible = Visibility.Inherit;
                    //pbcv.Visible = Visibility.Hidden;
                    //pbccv.Visible = Visibility.Hidden;
                    lAction.Visible = Visibility.Inherit;
                    break;
                case 2:
                    //pbv.Visible = Visibility.Hidden;
                    pbcv.Visible = Visibility.Inherit;
                    //pbccv.Visible = Visibility.Inherit;
                    lAction.Visible = Visibility.Inherit;
                    break;
                case 4:
                    //pbv.Visible = Visibility.Hidden;
                    //pbcv.Visible = Visibility.Hidden;
                    pbccv.Visible = Visibility.Inherit;
                    lAction.Visible = Visibility.Inherit;
                    break;
            }
        }

        private void PanelSaisieMultiValid_OnOpenClose(PanelSaisie p)
        {
            MensualView.SaisieMultiSetY();
            //PanelSaisieMultiValid.Self.SetY(16);
            //PanelSaisieMultiAbs.Self.SetY(PanelSaisieMultiValid.Self.Bottom + 16);
            //PanelSaisieMultiExc.Self.SetY(PanelSaisieMultiAbs.Self.Bottom + 16);

            //SetY(16);

            //PanelSaisieMultiAbs.Self.SetY(this.Bottom + 32);
            //PanelSaisieMultiExc.Self.SetY(PanelSaisieMultiAbs.Self.Bottom + 32);

            //var pnl = PanelSaisieMultiAbs.Self;

            //if (this.Closed)
            //{
            //    pnl.Open();
            //    pnl.Init(wfid);
            //}
            //else
            //{
            //    pnl.Close();
            //}

            //pnl.SetY(p.Bottom + 32);
        }
    }
}