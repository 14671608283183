using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using Bridge.Html5;
using Bridge.jQuery2;
using Mobatime.JSControl;
using Point = Mobatime.JSControl.Point;
using Window = Bridge.Html5.Window;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisiePtg : PanelSaisie
    {
        private Label [] _labels = new Label[15 * 9];
        private Label [] _lId = new Label[15];
        private List<Panel> backPtg = new List<Panel>();

        private Panel _pPtg = null;
        private Field fPtg1, fPtg2;
        private Combo cboInt1, cboInt2, cboCF;

        private int _selectedId = -1;

        private Panel pModif = null;

        int mode = 0, modeCpy = 0;
        int sz1 = 270, sz2 = 270 - 96;

        Button bValid, bRefuse;
        bool chef = false;


        public PanelSaisiePtg(Panel pParent) : base(pParent)
        {
            id = 1;

            lsKey = "pPtgClosed";

            _lHeader.Text = Res.Get(300, "Pointages");

            _pBack.Height = 332;
            
            CreateGridPtg();
            
            SelectRow(0);
            ChoixPtg(0);
        }

        private void CreateGridPtg()
        {
            int[] sz = {55, 62, 62, 50, 55, 60, 50, 50, 50};
            string[] txt = {"", "", "", "", "", "", "", "", ""};
            string[] align = {"center", "center", "center", "center", "center", "center", "center", "right", "right"};
            
            var x = 36;
            var y = 6;
          
            var lEntree = new Label(Res.Get(301, "In"), x, y, sz[0], 16, "center", _pBack);
            var lIn1 = new Label(Res.Get(302, "Inter."), lEntree.Right, y, sz[1], 16, "center", _pBack);
            var lCF = new Label(Res.Get(303, "CF"), lIn1.Right, y, sz[2], 16, "center", _pBack);
            var lL1 = new Label(Res.Get(308, "Lieu"), lCF.Right, y, sz[3], 16, "center", _pBack);
            var lSortie = new Label(Res.Get(304, "Out"), lL1.Right, y, sz[4], 16, "center", _pBack);
            var lIn2 = new Label(Res.Get(302, "Inter."), lSortie.Right, y, sz[5], 16, "center", _pBack);
            var lL2 = new Label(Res.Get(308, "Lieu"), lIn2.Right, y, sz[6], 16, "center", _pBack);

            lEntree.Color = new Color(150);
            lIn1.Color = new Color(150);
            lCF.Color = new Color(150);
            lSortie.Color = new Color(150);
            lIn2.Color = new Color(150);
            lL1.Color = new Color(150);
            lL2.Color = new Color(150);

            lEntree.FontSize = 8;
            lIn1.FontSize = 8;
            lCF.FontSize = 8;
            lSortie.FontSize = 8;
            lIn2.FontSize = 8;
            lL1.FontSize = 8;
            lL2.FontSize = 8;

            var szTotal = 0;
            for (var i = 0; i < sz.Length; i++)
                szTotal += sz[i];
            szTotal += 24;

            _pPtg = new Panel();
            _pPtg.Place(16, 22, 540, 124, _pBack);
            _pPtg.BackgroundColor = ResT.GetColor("panel.back");// Color.White;
            _pPtg.SetScrolling(Overflow.Hidden, Overflow.Scroll);
            _pPtg.SetBorderStyle(1, "solid", new Color(230, 230, 230), 0);

            Label l = null;
            for (var i = 0; i < Workflow.PtgCount / 2; i++)
            {
                var yy = 4 + i * 20;
                
                var p = new Panel();
                p.Place(0, yy - 4, szTotal + 20, 20, _pPtg);
                p.BackgroundColor = ResT.GetColor("panel.back");// new Color(255, 255, 255);
                p.Div.OnMouseEnter += OnMouseEnter;
                p.Div.OnMouseLeave += OnMouseLeave;
                p.Div.OnMouseDown += OnMouseDown;
                p.id1 = i;
                p.SetAttribute("tag", i.ToString());
                p.SetAttribute("selected", "false");
                backPtg.Add(p);
                SetBackColor(i, false);

                p.SetAttribute("ptgin", "0");
                p.SetAttribute("ptgint1", "0");
                p.SetAttribute("ptgcf1", "0");
                p.SetAttribute("ptgout", "0");
                p.SetAttribute("ptgint2", "0");
                
                _lId[i] = new Label((i + 1).ToString() + ".", 0, 4, 24, 16, "right", p);
                _lId[i].FontSize = 8;
                _lId[i].Color = new Color(255, 0, 0);

                l = null;
                var xx = 24;
                for (var j = 0; j < sz.Length; j++)
                {
                    l = _labels[i * 9 + j] = new Label(txt[j], xx, 2, sz[j], 16, align[j], p);
                    l.FontSize = 8;

                    if (j >= 7) 
                    {
                        //l.FontSize = 8;
                        l.Color = new Color(120, 120, 120);
                    }
                    else
                    {
                        //if (j == 1) l.FontSize = 8;
                        //else if (j == 5) l.FontSize = 8;
                        l.Paragraph.SetAttribute("modified", "false");
                    }
                    
                    xx += sz[j];
                }

            }

            var ps = pModif = new Panel();
            ps.Position = new Point(_pPtg.Left, _pPtg.Bottom + 16);
            ps.Size = new Size(_pPtg.Width, 88);
            ps.BackgroundColor = ResT.GetColor("panel.back");// new Color(250, 250, 250);
            ps.SetBorderStyle(1, "solid", new Color(230), 0);
            _pBack.AddChild(ps);
            
            l = new Label(Res.Get(305, "Pointages"), 75, 4, 64, 16, "center", ps);  l.FontSize = 8; l.Color = new Color(150);
            l = new Label(Res.Get(306, "Entrée :"), 0, 24, 75, 16, "right", ps);
            fPtg1 = new Field(l, 60, Field.FieldFormat.Ptg);
            fPtg1.SetAttribute("tabid", "1");
            l = new Label(Res.Get(307, "Sortie :"), 0, 56, 75, 16, "right", ps);
            fPtg2 = new Field(l, 60, Field.FieldFormat.Ptg);
            fPtg2.SetAttribute("tabid", "2");
            fPtg1.NextToFocus = fPtg2;
            
            
            fPtg1.OnValidateEnter += FPtg2OnOnValidateEnter;
            fPtg2.OnValidateEnter += FPtg2OnOnValidateEnter;
            fPtg1.Input.OnKeyDown = FPtg2OnKeyDown;
            fPtg2.Input.OnKeyDown = FPtg2OnKeyDown;

            cboCF = new Combo();
            cboCF.Place(fPtg1.Right + 16, fPtg1.Top - 2, 150, 32, fPtg1.Parent);
            cboCF.id2 = 1;
            cboCF.OnChange += CboCF_OnChange;
            cboCF.Add(0, "----");
            //for(var i = 1; i < Workflow.CFAbrev.Length; i++)
            //{
            //    var s = Workflow.CFAbrev[i];
            //    if (s == null || s.Length <= 0) continue;
            //    cboCF.Add(i, s);
            //}
            foreach(var k in Workflow.CFAbrevSorted.Keys)
            {
                var s = Workflow.CFAbrevSorted[k];
                if (s == null || s.Length <= 0) continue;
                cboCF.Add(k, s);
            }
            cboCF.SelectById(0);

            cboInt1 = new Combo();
            cboInt1.Place(fPtg1.Right + 16, fPtg1.Top - 2, 150, 32, fPtg1.Parent);
            cboInt1.id2 = 1;
            cboInt1.OnChange += CboInt1_OnChange;
            cboInt1.Add(0, "----");
            for(var i = 0; i < Workflow.CatAbs.name.Count; i++)
            {
                var s = Workflow.CatAbs.name[i];
                if (s == null || s.Length <= 1) continue;
                if (Workflow.CatAbs.interrupt[i] == 0) continue;
                cboInt1.Add(i, s);
            }

            cboInt2 = new Combo();
            cboInt2.Place(fPtg2.Right + 16, fPtg2.Top - 2, 150, 32, fPtg2.Parent);
            cboInt2.id2 = 2;
            cboInt2.OnChange += CboInt1_OnChange;
            cboInt2.Add(0, "----");
            for (var i = 0; i < Workflow.CatAbs.name.Count; i++)
            {
                var s = Workflow.CatAbs.name[i];
                if (s == null || s.Length <= 1) continue;
                if (Workflow.CatAbs.interrupt[i] == 0) continue;
                cboInt2.Add(i, s);
            }

            bValid = new Button(ps.Width - 64, 20, 48, 48, "images/Neu_001_48.png", ps, true);
            bValid.id1 = 3;
            bValid.OnButtonClick += BValid_OnButtonClick;
            bValid.Visible = Visibility.Hidden;
        }

        private void FPtg2OnKeyDown(KeyboardEvent<HTMLInputElement> e)
        {
            var id = e.Target.GetAttribute("tabid");
            if(e.KeyCode == 9)
            {
                Field f = fPtg1;
                if (id == "2") f = fPtg2;

                f.ValidSaisie();
                FPtg2OnOnValidateEnter(f);
            }
        }

        private void CboCF_OnChange(Combo combo)
        {
            var id = combo.id1;

            var val = combo.SelectedId;

            backPtg[id].SetAttribute("ptgcf", val.ToString());

            _labels[id * 7 + 1].SetAttribute("modified", "true");

            ShowPtg(id);

            Modified = true;

            Change();
        }

        private void CboInt1_OnChange(Combo combo)
        {
            var id = combo.id1;

            var val = combo.SelectedId;

            if (combo.id2 == 1)
            {
                backPtg[id].SetAttribute("ptgint1", val.ToString());

                _labels[id * 7 + 1].SetAttribute("modified", "true");

                ShowPtg(id);
            }
            else if (combo.id2 == 2)
            {
                backPtg[id].SetAttribute("ptgint2", val.ToString());
                _labels[id * 7 + 4].SetAttribute("modified", "true");

                ShowPtg(id);
            }

            Modified = true;


            Change();
        }

        private void BValid_OnButtonClick(Button c, MouseEvent arg)
        {
            var v = c.id1;

            if (v == 3) v = 7;
            else if (v == 7) v = 15;
            else if (v == 15) v = 3;

            SetButtonValid(v);

            Modified = true;
            Change();
        }

        private void FPtg2OnOnValidateEnter(Field field)
        {
            var id = field.id1;
            var val = field.ValueI;



            if (field.id2 == 1)
            {
                backPtg[id].SetAttribute("ptgin", val.ToString());

                _labels[id * 7 + 0].SetAttribute("modified", "true");
                
                ShowPtg(id);
            }
            else if(field.id2 == 2)
            {
                backPtg[id].SetAttribute("ptgout", val.ToString());
                _labels[id * 7 + 3].SetAttribute("modified", "true");
                
                ShowPtg(id);

                if (cboCF.Visible == Visibility.Inherit)
                {
                    cboCF.Focus();
                }
                else
                {
                    _selectedId++;
                    if (_selectedId >= 15) _selectedId = 0;
                    SelectRow(_selectedId);
                    ChoixPtg(_selectedId);
                }
            }

            Modified = true;

        }

        private void OnMouseDown(MouseEvent<HTMLDivElement> arg)
        {
            var tag = arg.CurrentTarget.GetAttribute("tag");
int id;
            int.TryParse(tag, out id);
            
            SelectRow(id);
            ChoixPtg(id);
        }

        private void OnMouseLeave(MouseEvent<HTMLDivElement> arg)
        {
            var tag = arg.CurrentTarget.GetAttribute("tag");
int id;
            int.TryParse(tag, out id);            
         
            SetBackColor(id, false);

        }

        private void OnMouseEnter(MouseEvent<HTMLDivElement> arg)
        {
            var tag = arg.CurrentTarget.GetAttribute("tag");
int id;
            int.TryParse(tag, out id);
            
            SetBackColor(id, true);
        }

        private void SelectRow(int id)
        {
            _selectedId = id;
            
            foreach (var p in backPtg)
            {
                p.SetAttribute("selected", "false");
                SetBackColor(p.id1);
            }
            

            backPtg[id].SetAttribute("selected", "true");
            SetBackColor(id, false);
            

        }

        public void SetBackColor(int id, bool focus = false)
        {
            var p = backPtg[id];
            var selected = p.GetAttribute("selected") == "true";

            for (var i = 0; i < 9; i++)
            {
                var l = _labels[id * 9 + i];
                if (l != null)
                {
                    var modified = l.GetAttributeBool("modified");
                    if (modified) l.Color = new Color(255, 0, 0);
                    else
                    {
                        if (Workflow.Dark) l.Color = Color.White;
                        else l.Color = Color.Black;
                    }
                }
                if (_lId[id] != null) _lId[id].Color = new Color(150);
            }

            if (selected)
            {
                p.BackgroundColor = Color.Selected;
                for (var i = 0; i < 9; i++)
                {
                    var l = _labels[id * 9 + i];
                    var modified = l.GetAttributeBool("modified");
                    if (modified) l.Color = new Color(255, 0, 0);
                    else l.Color = Color.White;
                    _lId[id].Color = Color.White;
                }
            }
            else if (focus) p.BackgroundColor = ResT.GetColor("grid.over");// Color.Over;
            else
            {
                if (Workflow.Dark)
                {
                    if (id % 2 == 0) p.BackgroundColor = new Color(40);
                    else p.BackgroundColor = new Color(50);
                }
                else
                {
                    if (id % 2 == 0) p.BackgroundColor = new Color(250, 250, 250);
                    else p.BackgroundColor = new Color(245, 245, 245);
                }
            }
        }

        private void ChoixPtg(int id)
        {
            var p = backPtg[id];

            var ptgIn = p.GetAttributeInt("ptgin");
            var ptgOut = p.GetAttributeInt("ptgout");
            var ptgInt1 = p.GetAttributeInt("ptgint1");
            var ptgInt2 = p.GetAttributeInt("ptgint2");
            var ptgCf = p.GetAttributeInt("ptgcf");

            fPtg1.Value = ptgIn;
            fPtg2.Value = ptgOut;
            cboInt1.SelectById(ptgInt1);
            cboInt2.SelectById(ptgInt2);
            cboCF.SelectById(ptgCf);

            fPtg1.id1 = id;
            fPtg1.id2 = 1;
            fPtg2.id1 = id;
            fPtg2.id2 = 2;
            cboInt1.id1 = id;
            cboInt2.id1 = id;
            cboInt1.id2 = 1;
            cboInt2.id2 = 2;
            cboCF.id1 = id;

            cboCF.Visible = Visibility.Hidden;

            //Console.WriteLine("modePTG: " + mode);

            if(mode == 15)
            {
                cboInt1.Visible = Visibility.Inherit;
                cboInt2.Visible = Visibility.Inherit;
                cboCF.Visible = Visibility.Inherit;
            }
            else if (mode != 10 && mode != 11)
            {
                cboInt1.Visible = Visibility.Hidden;
                cboInt2.Visible = Visibility.Hidden;

                if (mode == 3)
                {
                    cboCF.Visible = Visibility.Inherit;
                }
            }
            else
            {

                if (id == 0 || mode == 11)
                {
                    cboInt1.Visible = Visibility.Inherit;
                    cboInt2.Visible = Visibility.Inherit;
                }
                else
                {
                    cboInt1.Visible = Visibility.Hidden;
                    cboInt2.Visible = Visibility.Inherit;
                }
            }

            if (cboInt1.Visible != Visibility.Hidden) cboCF.Left = cboInt1.Right + 8;
            else cboCF.Left = fPtg1.Right + 16;

            Window.SetTimeout((global::System.Action)(() => fPtg1.Focus()), 10);
            
            //fPtg1.Focus();
        }

        private void ShowPtg(int id)
        {
            var p = backPtg[id];

            var ptgIn = p.GetAttributeInt("ptgin");
            var ptgOut = p.GetAttributeInt("ptgout");
            var ptgInt1 = p.GetAttributeInt("ptgint1");
            var ptgInt2 = p.GetAttributeInt("ptgint2");
            var ptgCf = p.GetAttributeInt("ptgcf");
            var ptgReel1 = p.GetAttributeInt("ptgreel1");
            var ptgReel2 = p.GetAttributeInt("ptgreel2");
            var lieu1 = p.GetAttribute("ptglieu1");
            var lieu2 = p.GetAttribute("ptglieu2");

            var id2 = id * 9;

            var i1 = "";
            var i2 = "";
            var cf = "";

            if (ptgInt1 > 0) i1 = Workflow.CatAbs.abrev[ptgInt1];
            if (ptgInt2 > 0) i2 = Workflow.CatAbs.abrev[ptgInt2];
            if (ptgCf > 0 && Workflow.CFAbrev[ptgCf] != null) cf = Workflow.CFAbrev[ptgCf];

            _labels[id2 + 0].Text = Fmt.Ptg(ptgIn);
            _labels[id2 + 1].Text = i1;        // int 1
            _labels[id2 + 2].Text = cf;        // cf
            _labels[id2 + 3].Text = lieu1;        // lieu
            _labels[id2 + 4].Text = Fmt.Ptg(ptgOut);
            _labels[id2 + 5].Text = i2;        // int 2
            _labels[id2 + 6].Text = lieu2;        // lieu
            _labels[id2 + 7].Text = Fmt.Ptg(ptgReel1);        // reel in
            _labels[id2 + 8].Text = Fmt.Ptg(ptgReel2);        // reel out


            for (var i = 0; i < 7; i++)
            {
                var l = _labels[id * 9 + i];
                var modified = l.GetAttributeBool("modified");
                if (modified) l.Color = new Color(255, 0, 0);
                else l.Color = Color.Black;
            }
        }
        
        public void Update(Api.API_Data_Day dat, bool chef, Right right, DateTime date)
        {
            Console.WriteLine("panelPtg: " + date.ToString());

            Modified = false;
            this.chef = chef;

            if (chef && Workflow.RightSU.ValidPtg && dat.flags[0] > 0) bValid.Visible = Visibility.Inherit;     // cas exceptionel où l'on force la visibilité
            else bValid.Visible = Visibility.Hidden;
            //bValid.Visible = Visibility.Visible;

            for (var i = 0; i < Workflow.PtgCount; i += 2)
            {
                var p = backPtg[i / 2];

                p.SetAttribute("ptgin", dat.ptg_HE[i].ToString());
                p.SetAttribute("ptgout", dat.ptg_HE[i + 1].ToString());

                p.SetAttribute("ptgint1", dat.ptg_C1[i].ToString());
                p.SetAttribute("ptgint2", dat.ptg_C1[i + 1].ToString());

                if (i % 2 == 0)
                    p.SetAttribute("ptgcf", dat.ptg_CF[i].ToString());

                p.SetAttribute("ptgnat1", dat.ptg_Nat[i].ToString());
                p.SetAttribute("ptgnat2", dat.ptg_Nat[i + 1].ToString());

                p.SetAttribute("ptgreel1", dat.ptg_Reel[i].ToString());
                p.SetAttribute("ptgreel2", dat.ptg_Reel[i + 1].ToString());

                p.SetAttribute("ptglieu1", dat.ptg_lieu[i]);
                p.SetAttribute("ptglieu2", dat.ptg_lieu[i + 1]);
            }

            for (var i = 0; i < backPtg.Count; i++)
                ShowPtg(i);


            for (var i = 0; i < _labels.Length; i++)
            {
                if (_labels[i] != null)
                    _labels[i].SetAttribute("modified", "false");
            }

            SelectRow(0);
            ChoixPtg(0);

            _pPtg.Div.ScrollTop = 0;

            Console.WriteLine(string.Format("TEST: {0} {1} {2} {3}",dat.flags[0],dat.ctrId,right.LimitPtgMin,right.LimitPtgMax));

            if (MensualView.Verrou[date.Day] || dat.ctrId <= 0 || (!chef && dat.flags[0] == 7)) Mode(0, true);
            else
            {

                if (Workflow.RightSU.ModifyPtg) Mode(modeCpy, false); else Mode(0, false);
            }
                         
            if (!chef && (date < right.LimitPtgMin || date > right.LimitPtgMax))
            {
                Console.WriteLine("ptgDate " + date + " " + right.LimitPtgMin + " " + right.LimitPtgMax + "  chef:" + chef);

                Mode(0, true);
            }

            if (bValid.Visible == Visibility.Inherit || bValid.Visible == Visibility.Visible)
            {
                var v = dat.flags[0];
                SetButtonValid(v);

                //bValid.Top = bValid.Parent.Height - bValid.Height - 8;
            }


        }

        void SetButtonValid(int v)
        {
            var c = bValid;
            c.id1 = v;
            
            if (v == 3) c.SetImage("images/Neu_001_48.png");
            else if (v == 7) c.SetImage("images/Yes_001_48.png");
            else if (v == 15) c.SetImage("images/No_001_48.png");
        }

        public void Save(Api.API_Data_Day dat)
        {
            dat.ptg_HE.Clear();
            for (var i = 0; i < Workflow.PtgCount; i++)
            {
                dat.ptg_HE.Add(0);
                dat.ptg_C1.Add(0);
                dat.ptg_CF.Add(0);
                dat.ptg_Nat.Add(0);
                dat.ptg_Dur.Add(0);
            }
            
            for (var i = 0; i < backPtg.Count; i++)
            {
                var p = backPtg[i];
                
                var ptgIn = p.GetAttributeInt("ptgin");
                var ptgOut = p.GetAttributeInt("ptgout");

                var ptgInt1 = p.GetAttributeInt("ptgint1");
                var ptgInt2 = p.GetAttributeInt("ptgint2");

                var ptgCf = p.GetAttributeInt("ptgcf");
                //var ptgCf2 = p.GetAttributeInt("ptgcf2");

                var ptgNat1 = p.GetAttributeInt("ptgnat1");
                var ptgNat2 = p.GetAttributeInt("ptgnat2");

                dat.ptg_HE[i * 2 + 0] = ptgIn;
                dat.ptg_HE[i * 2 + 1] = ptgOut;

                dat.ptg_C1[i * 2 + 0] = ptgInt1;
                dat.ptg_C1[i * 2 + 1] = ptgInt2;

                dat.ptg_CF[i * 2 + 0] = ptgCf;
                dat.ptg_CF[i * 2 + 1] = 0;// ptgCf2;

                dat.ptg_Nat[i * 2 + 0] = ptgNat1;
                dat.ptg_Nat[i * 2 + 1] = ptgNat2;
            }

            if (!chef)
                dat.flags[0] = 300;
            else if (Workflow.RightSU.ValidPtg)
            {

                if (bValid.Visible == Visibility.Inherit || bValid.Visible == Visibility.Visible)
                    dat.flags[0] = bValid.id1;
                else
                    dat.flags[0] = 7;

            }

                        //if (Workflow.RightSU.ValidPtgAuto) dat.flags[0] = 3;
        }

        public void Mode(int mode, bool verrou)
        {
            //Console.WriteLine("Mode: " + mode);

            if (!verrou)
                this.modeCpy = mode;

            this.mode = mode;

            //Console.WriteLine("ptg mode: " + mode);

            _pPtg.Height = 7 * 20;

            if (mode == 0 || mode == 9)
            {
                pModif.Visible = Visibility.Hidden;
                var sz = GetHeight();
                //_pPtg.Height = sz - 48;
                _pBack.Height = sz;
            }
            else
            {
                pModif.Visible = Visibility.Inherit;
                _pBack.Height = sz1;

                if (mode != 15)
                {
                    if (mode != 10 && mode != 11)
                    {
                        cboInt1.Visible = Visibility.Hidden;
                        cboInt2.Visible = Visibility.Hidden;
                    }

                    if (mode != 3)
                    {
                        cboCF.Visible = Visibility.Hidden;
                    }
                }
            }

            
        }

        protected override int GetHeight()
        {
            if (mode == 0) return sz2;
            else return sz1;
        }

    }
}