using System.Collections.Generic;
using Mobatime.JSControl;

namespace Workflow_bridge
{
    public class Res
    {
        static Dictionary<string, Color> color = null;


        public delegate void dOnLoaded();
        public static event dOnLoaded OnLoaded = null;
        public static Dictionary<int, List<string>> texts = new Dictionary<int, List<string>>();
        public static int CurrentLg = 1;
        public static int LgCom = 1;
        public static string[] days2 = {"Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"};
        public static string PathCas { get; set; }
        public static string source = "";

        public static string[] days =
        {
            "Lundi", "Mardi", "Mercredi",
            "Jeudi", "Vendredi", "Samedi", "Dimanche"
        };

        public static string[] month4 =
        {
            "Janv", "Fév", "Mars", "Avril", "Mai", "Juin", "Juil", "Août", "Sept",
            "Oct", "Nov", "Déc"
        };

        public static string[] months =
        {
            "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre",
            "octobre", "novembre", "décembre"
        };        
        

        public static string[] anomalies =
        {
            "Pointage(s) dans un temps block", "Nombre de pointages impair", "Pauses non respectées",
            "Justification insuffisante", "Dépassement d'horaire", "Contrainte de saisie non respectée"
        };

        public static string[] exception =
        {
            "Ouverture début d'horaire", "Ouverture fin d'horaire", "Pauses plage variable annulée", "", ""
        };

        public static string[] stat =
        {
            "Plage sans pause"
        };

        public static void LoadFromString(string s, bool clearDico = true)
        {
            if (clearDico)
            {
                texts = new Dictionary<int, List<string>>();
                source = s;
            }
            var splitted = s.Split('\n');
            
            foreach (var spl in splitted)
            {
                var spl2 = spl.Split(';');
                if (spl2.Length < 4) continue;
int tid;

                int.TryParse(spl2[0], out tid);

                if (texts.ContainsKey(tid) && clearDico) continue;

                var lst = new List<string>();
                texts[tid] = lst;
                for (var i = 1; i < spl2.Length; i++)
                    lst.Add(spl2[i]);
            }

            FillDaysMonths();
        }
        
        public static void LoadColor()
        {
            ResT.colorDark["window.back"] = new Color(70);
            ResT.colorDark["window.back2"] = new Color(60);
            ResT.colorDark["toolbar.back"] = new Color(0);
            ResT.colorDark["toolbar.back2"] = new Color(40);
            ResT.colorDark["toolbar.back3"] = new Color(50);
            ResT.colorDark["panel.back"] = new Color(60);
            ResT.colorDark["panel.border"] = new Color(30);
            ResT.colorDark["label.text"] = new Color(255);
            ResT.colorDark["field.back"] = new Color(100);
            ResT.colorDark["field.border"] = new Color(204);
            ResT.colorDark["field.borderfocus"] = new Color(00, 170, 255);
            ResT.colorDark["field.text"] = new Color(255);
            ResT.colorDark["grid.back"] = new Color(60);
            ResT.colorDark["grid.grid"] = new Color(100);
            ResT.colorDark["grid.selected"] = Color.Selected;
            ResT.colorDark["grid.over"] = new Color(150);// Color.Over;
            ResT.colorDark["grid.header1"] = new Color(40);
            ResT.colorDark["grid.header2"] = new Color(30);
            ResT.colorDark["button.back"] = new Color(40);
            ResT.colorDark["button.back2"] = new Color(0);
            ResT.colorDark["button.sel1"] = new Color(40);
            ResT.colorDark["button.sel2"] = new Color(0);
            ResT.colorDark["button.border1"] = new Color(40);
            ResT.colorDark["button.border2"] = new Color(80);

            ResT.colorNormal["window.back"] = new Color(240);
            ResT.colorNormal["window.back2"] = new Color(230);
            ResT.colorNormal["toolbar.back"] = Color.Mobatime1;
            ResT.colorNormal["toolbar.back2"] = Color.Mobatime2;
            ResT.colorNormal["toolbar.back3"] = new Color(50);
            ResT.colorNormal["panel.back"] = new Color(255);
            ResT.colorNormal["panel.border"] = new Color(221);
            ResT.colorNormal["label.text"] = Color.Mobatime1;
            ResT.colorNormal["field.back"] = new Color(255);
            ResT.colorNormal["field.border"] = new Color(204);
            ResT.colorNormal["field.borderfocus"] = new Color(00, 170, 255);
            ResT.colorNormal["field.text"] = new Color(0);
            ResT.colorNormal["grid.back"] = new Color(255);
            ResT.colorNormal["grid.grid"] = new Color(200);
            ResT.colorNormal["grid.selected"] = Color.Selected;
            ResT.colorNormal["grid.over"] = Color.Over;
            ResT.colorNormal["grid.header1"] = new Color(107, 166, 208);
            ResT.colorNormal["grid.header2"] = new Color(77, 136, 178);
            ResT.colorNormal["button.back"] = new Color(245);
            ResT.colorNormal["button.back2"] = new Color(200);
            ResT.colorNormal["button.sel1"] = Color.Mobatime2;
            ResT.colorNormal["button.sel2"] = Color.Mobatime1;
            ResT.colorNormal["button.border1"] = new Color(200);
            ResT.colorNormal["button.border2"] = new Color(245);

            if (Workflow.Dark) ResT.SetCurrentColorSchema(ResT.colorDark);
            else ResT.SetCurrentColorSchema(ResT.colorNormal);
            color = ResT.GetCurrentColorSchema();
        }
        
        public static void Load()
        {
            LoadColor();

            var f = new Api.ApiFunction();
            f.function = Api.ApiFuncType.get_res;

            var d = new DataApi<Api.ApiReturn>(Workflow.api, f);
            d.OnSuccess += D_OnSuccess;
        }

        private static void D_OnSuccess(Mobatime.JSControl.Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            System.Console.WriteLine(CurrentLg + "  " + LgCom);

            if (CurrentLg == -1 && LgCom == -1)
            {
                var lg = result.return_i1;

                if (lg == 0) CurrentLg = LgCom = 1;
                else if(lg == 1) CurrentLg = LgCom = 2;
            }

            System.Console.WriteLine(CurrentLg + "  " + LgCom);


            var s = result.return_s1;
            LoadFromString(s);
            if (result.return_s2.Length > 0)
            {
                System.Console.WriteLine("Load custom res");
                LoadFromString(result.return_s2, false);
            }

            PathCas = result.return_s5;

            OnLoaded!=null?global::Bridge.Script.FromLambda(()=>OnLoaded.Invoke()):null;
        }

        public static void FillDaysMonths()
        {
            for (var i = 0; i <= 6; i++)
            {
                days[i] = Get(100 + i, days[i]);
                days2[i] = Get(110 + i, days2[i]);
                Mobatime.JSControl.ResT.days[i] = days[i];
                Mobatime.JSControl.ResT.Day2[i] = days2[i];
            }

            for (var i = 0; i <= 11; i++)
            {
                months[i] = Get(130 + i, months[i]);
                month4[i] = Get(150 + i, month4[i]);
                Mobatime.JSControl.ResT.months[i] = months[i];
                Mobatime.JSControl.ResT.months[i] = month4[i];
            }

            for(var i = 0; i < 6; i++)
            {
                anomalies[i] = Get(410 + i, anomalies[i]);
            }

            for (var i = 0; i <= 4; i++)
            {
                exception[i] = Get(420 + i, exception[i]);
            }

            for(var i = 0; i <= 0; i++)
            {
                stat[i] = Get(430 + i, stat[i]);
            }

        }

        public static string Get(int id, string def = "")
        {
            if (!texts.ContainsKey(id)) return "~" + def;

            var lst = texts[id];
            if (lst.Count <= CurrentLg) return "~" + def;

            var s = lst[CurrentLg];
            if (s.Length == 0) s = "~" + def;

            return s;
        }


    
private static string __Property__Initializer__PathCas="";}
}