using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using Bridge.Html5;
using Mobatime.JSControl;
using Text = Bridge.Html5.Text;
using Window = Bridge.Html5.Window;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisieMsg : PanelSaisie
    {
        private Mobatime.JSControl.Text tMsg1, tMsg2;

        public bool LockModification = false;
public string TxtSu
{
    get
    {
        return tMsg1.TextArea.Value;
    }
}
        public PanelSaisieMsg(Panel pParent) : base(pParent)
        {
            id = 2;

            lsKey = "pMsgClosed";

            _lHeader.Text = Res.Get(700, "Messages journaliers");
            _pBack.Size = new Size(600 - 32, 74);
            sz1 = 74;

            tMsg1 = new Mobatime.JSControl.Text();
            tMsg2 = new Mobatime.JSControl.Text();

            tMsg1.Place(8, 8, _pBack.Width / 2 - 16, _pBack.Height - 18, _pBack);
            tMsg2.Place(tMsg1.Right + 8, tMsg1.Top, tMsg1.Width, tMsg1.Height, _pBack);

            tMsg1.BackgroundColor = new Color(255, 255, 200);
            tMsg2.BackgroundColor = new Color(220, 255, 220);

            tMsg1.TextArea.MaxLength = 128;
            tMsg2.TextArea.MaxLength = 128;

            tMsg1.Style.FontFamily = "Arial";
            tMsg2.Style.FontFamily = "Arial";

            tMsg1.OnChange += TMsg1OnOnChange;
            tMsg2.OnChange += TMsg1OnOnChange;

            //tMsg2.ReadOnly = true;

            Closed = true;
            _bUpDown.id1 = 1;
            UpdateButton();



        }

        private void TMsg1OnOnChange(Mobatime.JSControl.Text text)
        {
            if (LockModification) return;
            
            Modified = true;
        }

        public void Update(Api.API_Data_Day dat, bool chef, DateTime d)
        {
            LockModification = true;
            tMsg1.TextArea.Value = dat.msg1;
            tMsg2.TextArea.Value = dat.msg2;

            tMsg1.ReadOnly = chef;
            tMsg2.ReadOnly = !chef;

            tMsg1.TextArea.Disabled = chef;
            tMsg2.TextArea.Disabled = !chef;

            tMsg1.TextArea.Disabled = MensualView.Verrou[d.Day] || dat.ctrId <= 0;
            tMsg2.TextArea.Disabled = MensualView.Verrou[d.Day] || dat.ctrId <= 0;

            if (chef && dat.msg1.Length > 0) ToCheck = true;
            else if (!chef && dat.msg2.Length > 0) ToCheck = true;


            LockModification = false;
        }

        public void Save(Api.API_Data_Day dat)
        {
            dat.msg1 = tMsg1.TextArea.Value;
            dat.msg2 = tMsg2.TextArea.Value;
        }

        public void Focus()
        {
            tMsg1.Focus();
        }

    }
}