using System.Collections.Generic;

namespace Workflow_bridge
{
    public partial class Api
    {

        #region json function / result

        public enum ApiFuncType
        {
            none = 0,
            login = 1,
            get_server_time = 2,
            login_pass = 3,
            GetLoginType = 4,
            login_new_pass = 5,
            CASCMD = 7,
            CheckMASCode = 8,
            LoadPerm = 9,
            punch = 10,
            offline_punch = 11,
            GetInterruption = 12,
            get_messages = 20,
            get_last_punch = 21,
            get_day_info = 22,
            get_day_ano = 23,
            get_day_data = 25,
            save_day_data = 26,
            get_4weeks_info = 27,
            openHJ = 28,
            get_workflow_right = 50,
            get_workflow_pop = 51,
            get_workflow_todo = 55,
            WFValidSU = 75,
            GetChefType = 80,
            get_grid_cfg = 100,
            get_grid_data = 110,
            get_catabsence = 200,
            get_CFs = 201,
            get_absences = 210,
            get_absences_su = 215,
            verif_absences = 216,
            save_absence = 220,
            SaveMultiExc = 250,
            get_daypunches = 300,
            save_daypunches = 310,
            get_daymsg = 400,
            save_daymsg = 410,
            save_daymsg1 = 411,
            save_daymsg2 = 412,
            GetWFJumbo = 450,
            GetWFIndiv = 451,
            WFPrintJumbo = 455,
            save_frais = 501,
            WFGetFraisMonth = 505,
            WFVerrouFrais = 506,
            GetFraisMensuelImage = 507,
            CopyFotoTempo = 508,
            WFGetFraisResumeDay = 509,
            SetFraisRefus = 510,
            GetWFSoldeCrit = 520,
            getset_frais_km = 550,
            get_res = 700,
            mkQR = 800,
            QueryPDF = 1500,
            GetQueryWF = 1501,
            WFGetDeleg = 1503,
            WFSetDeleg = 1504,
            hw_connect = 2000,
            get_messages_file = 2001,
            get_pers_resume = 2002,
            punch_amano = 2003,
            get_config = 2004,
            
            GetPlan4Week = 3000,

            get_table_machine = 4000,
            get_table_frais = 4001,
            // work 5000+
            job_get_ordres = 5000,
            job_get_day_status = 5001,
            job_start_gt = 5100,
            job_stop_gt = 5101,
            job_swap = 5102,
            job_pause_out = 5103,
            job_get_last_saisie = 5200,
            job_get_ordre = 5300,
            job_get_ordre_detail = 5310,
            job_get_qties = 5350,
            job_save_trajet = 5800,
            GetWorkActWeek = 6000,
            GetWorkActQtyDetail = 6001,
            GetWorkActTB = 6002,
            GetWorkActQtyModify = 6003,
            GetWorkActOrdreOF = 6004,
            GetWorkActSchema = 6020,
            WkSplit = 6050,
            pip = 12000,
            PrintPip = 12010,

            GetActivityTB = 14010,
            GetActivityHebdo = 14050,
            GetActivityEntry = 14100,
            SaveActivityEntry = 14200,
            SetActivityFlag = 14300,

            SaveDataEntry = 14500,
            
            SwapStartDay = 14600,
            SwapSwap = 14601,
            SwapStartPause = 14602,
            SwapStopPause = 14603,
            SwapStop = 14604,
            SwapGetOrdres = 14700,
            SwapGetResumeDay = 14800,

            GetCockpitCfg = 15000,

            jumbo_get_filtre = 16101,

            OrdreCreateOrdres = 17000,
            OrdreCreateOrdre = 17005,
            OrdreCreateSave = 17010,
            OrdreCreateOFSave = 17020,

            GetActivityHebdo2 = 17500,
            GetActivityDetail2 = 17505,
            GetActivityOFs2 = 17510,
            GetActivityOrdres2 = 17515,
            GetActivityOrdresModel = 17516,
            GetActivitySave2 = 17520,
            GetActivityQties2 = 17525,
            SaveActivityValidation2 = 17530,
            CheckOrdreValidity = 17535,
            GetActivityTTs2 = 17540,

            pingpong = 20000,
        }

        public class ApiFunction
        {
            public long token = 0;
            public ApiFuncType function = Workflow_bridge.Api.ApiFuncType.none;

            public string param1 = "";
            public string param2 = "";
            public string param3 = "";
            public string param4 = "";
            public string param5 = "";

            public int iparam1 = 0;
            public int iparam2 = 0;
            public int iparam3 = 0;
            public int iparam4 = 0;
            public int iparam5 = 0;
            public int iparam6 = 0;
            public int iparam7 = 0;
            public int iparam8 = 0;
            public int iparam9 = 0;
            public int iparam10 = 0;
            public int iparam11 = 0;
            public int iparam12 = 0;
            public int iparam13 = 0;
            public int iparam14 = 0;
            public int iparam15 = 0;

            public long lparam1 = 0;
            
            public List<int> array_int = new List<int>();

            

            public ApiFunction()
            { }

            public ApiFunction(ApiFuncType func)
            {
                function = func;
            }

            public DataApi<T> Get<T>(string api)
            {
                var data = new DataApi<T>(api, this);
                return data;
            }

        }

        public class ApiReturn
        {
            public long token = 0;
            public ApiFuncType return_type = Workflow_bridge.Api.ApiFuncType.none;

            public int year = 0, month = 0, day = 0, hour = 0, minute = 0, second = 0;

            public string return_s1 = "";
            public string return_s2 = "";
            public string return_s3 = "";
            public string return_s4 = "";
            public string return_s5 = "";
            public string return_s6 = "";
            public string return_s7 = "";

            public int return_i1 = 0;
            public int return_i2 = 0;
            public int return_i3 = 0;
            public int return_i4 = 0;
            public int return_i5 = 0;
            public int return_i6 = 0;
            public int return_i7 = 0;
            public int return_i8 = 0;

            public bool return_b1 = false;
            public bool return_b2 = false;
            public bool return_b3 = false;
            public bool return_b4 = false;
            public bool return_b5 = false;

            public long return_l1 = 0;
            public long return_l2 = 0;
            public long return_l3 = 0;
            public long return_l4 = 0;
            public long return_l5 = 0;

            //public ulong return_ul1 = 0;

            public short[] data = null;

            public ApiReturn()
            {
            }

            public ApiReturn(long token, ApiFuncType rtype)
            {
                this.token = token;
                this.return_type = rtype;
            }
        }

        #endregion

    }
}