using System.Collections.Generic;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using System;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisieMultiAbs : PanelSaisie
    {

        public class PSAbs : Panel
        {
            int aid = 0;

            public Combo cboMotif, cboType;
            Label lA, lMotif, lType;
            Api.API_AbsSU absSu = null;

            Button bVerif = null, bSave, bCancel;

            Panel pDP, pDEA, pPerc;

            Field fDP, fDe, fA;
            Combo cboPerc;

            int abs_id = 0, abs_type = 0, abs_dur = 0, abs_intro = 0;

            PanelSaisieMultiAbs saisie = null;

            bool chef = false;
            //bool modified = false;
            int wfid = 0;

            Api.Absence_SU selected = null;

            public PSAbs(int aid, int wfid, Panel parent, PanelSaisieMultiAbs saisie, Api.API_AbsSU absSu, bool chef)
            {
                this.chef = chef;
                this.aid = aid;
                this.absSu = absSu;
                this.saisie = saisie;
                this.wfid = wfid;

                Label l;
                
                var w = (parent.Width - 48) / 2;
                Place(16, 32, w, parent.Height - 48, parent);

                if (aid == 1)
                {
                    Left += w + 16;
                }

                SetBorderStyle(1, "solid", new Color(200), 4);

                var title = "A";
                if (aid == 1) title = "B";


                lMotif = l = new Label(Res.Get(501, "Motif d'absence"), 29, 8, Width - 32, 16, "left", this);
                l.FontSize = 8;
                lMotif.Color = new Color(150);
                cboMotif = new Combo();
                cboMotif.Place(lMotif.Left, l.Top + 14, Width - 48, 32, this);


                lA = new Label(title, 8, cboMotif.Top + 4, 28, 32, "left", this);
                lA.FontSize = 14;



                cboMotif.OnChange += CboMotif_OnChange;
                cboMotif.Style.FontSize = "11pt";


                lType = l = new Label(Res.Get(502, "Type de durée"), lMotif.Left, cboMotif.Bottom + 12, Width - 32, 16, "left", this);
                lType.FontSize = 8;
                lType.Color = new Color(150);
                cboType = new Combo();
                cboType.Place(lType.Left, l.Top + 14, cboMotif.Width, 32, this);
                cboType.Style.FontSize = "11pt";
                cboType.Add(-1, "----");
                cboType.OnChange += CboType_OnChange1;

                cboType.OnChange += CboType_OnChange;

                pDP = new Panel(this);
                pDP.Place(16, cboType.Bottom + 16, Width - 32, 64);
                pDP.Visible = Visibility.Hidden;

                l = new Label(Res.Get(540, "Durée") + ": ", 8, 16, 75, 16, "right", pDP);
                fDP = new Field(l, 50, Field.FieldFormat.HQty);
                fDP.Input.OnKeyDown = fDP_OnKeydown;
                fDP.Value = 0;

                pDEA = new Panel(this);
                pDEA.Place(16, cboType.Bottom + 16, Width - 32, 64);
                pDEA.Visible = Visibility.Hidden;

                l = new Label(Res.Get(541, "De") + ": ", 8, 16, 40, 16, "right", pDEA);
                fDe = new Field(l, 50, Field.FieldFormat.HQty);
                fDe.Input.OnKeyDown = fDP_OnKeydown;
                fDe.Value = 0;

                l = new Label(Res.Get(542, "A") + ": ", 115, 16, 40, 16, "right", pDEA);
                fA = new Field(l, 50, Field.FieldFormat.HQty);
                fA.Input.OnKeyDown = fDP_OnKeydown;
                fA.Value = 0;

                pPerc = new Panel(this);
                pPerc.Place(16, cboType.Bottom + 16, Width - 32, 64);
                pPerc.Visible = Visibility.Hidden;

                l = new Label("% : ", 8, 16, 40, 16, "right", pPerc);
                cboPerc = new Combo();
                cboPerc.Place(l.Right + 8, l.Top - 8, 50, 24, pPerc);
                cboPerc.Add(0, "----");
                for (var i = 0; i < Workflow.CatAbs.percent_id.Count; i++)
                {
                    var id = Workflow.CatAbs.percent_id[i];
                    var txt = Workflow.CatAbs.percent_txt[i];
                    if (id > 0)
                        cboPerc.Add(id, txt);
                }
                cboPerc.SelectById(0);
                cboPerc.OnChange += CboPerc_OnChange;

                bVerif = new Button(16, Height - 64, 48, 48, "images/63_apercu.png", this, false);
                bVerif.OnButtonClick += BVerif_OnButtonClick;
                bVerif.Enabled = false;

                bSave = new Button(Width - 128, Height - 64, 48, 48, "images/64ok.png", this, false);
                bSave.OnButtonClick += BSave_OnButtonClick;
                bCancel = new Button(Width - 64, Height - 64, 48, 48, "images/64annule.png", this, false);
                bCancel.OnButtonClick += BCancel_OnButtonClick;

                bSave.Enabled = false;
                bCancel.Enabled = false;
            }

            private void CboPerc_OnChange(Combo combo)
            {
                fDP_OnKeydown(null);
            }

            public void Update(int wfid, Api.API_AbsSU absSU, bool chef)
            {
                this.wfid = wfid;
                this.absSu = absSU;
                this.chef = chef;

                cboMotif.Clear();

                var opts = cboMotif.Add(-1, "." + Res.Get(580, "Sélectionnez un motif"));
                opts.Style.Color = new Color(150).ToHex();
                cboMotif.Add(0, "[" + Res.Get(581, "effacer") + "]");

                foreach (var abs in absSu.motif)
                {
                    if (chef && !abs.can_intro) continue;
                    var absname = Workflow.CatAbs.name[abs.id];
                    if (absname.Length == 0) absname = "{" + abs.id + "}";
                    var opt = cboMotif.Add(abs.id, absname);
                    opt.Style.FontSize = "11pt";

                }
                cboMotif.Sort();
                cboMotif.SelectByIdx(0);
            }

            private void BCancel_OnButtonClick(Button c, MouseEvent arg)
            {
                bVerif.Enabled = true;
                bSave.Enabled = false;
                bCancel.Enabled = false;

                saisie.UnblockSister((aid + 1) % 2);
                saisie.RefreshAll();
            }

            private void BSave_OnButtonClick(Button c, MouseEvent arg)
            {
                var csv = new CSVTool();

                var pdays = MensualView.Grid.Selected;
                var dates = new List<DateTime>();
                foreach (var day in pdays)
                {
                    if (!day.StatusNotOK)
                        dates.Add(day.Date);
                }

                csv.A(dates.Count);
                foreach (var d in dates)
                {
                    csv.A(d);
                }
                csv.A(100);

                if (abs_id > 0) csv.A(aid + 1);
                else csv.A(aid + 3);
                csv.A(abs_id);
                csv.A(abs_type);

                var flag = 0;

                var s = csv.ToString();
                var func = new Api.ApiFunction();
                func.function = Api.ApiFuncType.WFValidSU;
                func.iparam1 = saisie.pid;
                func.iparam2 = flag;
                func.iparam3 = wfid;
                func.iparam4 = Workflow.pid;
                func.iparam10 = Workflow.wfid_su;
                func.iparam6 = fDP.ValueI;
                func.iparam7 = fDe.ValueI;
                func.iparam8 = fA.ValueI;
                func.iparam9 = cboPerc.SelectedId;

                func.param1 = s;
                var dataSave = new DataApi<Api.ApiReturn>(Workflow.api, func);
                dataSave.OnSuccess += DataSave_OnSuccess;
            }

            private void DataSave_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
            {
                bVerif.Enabled = true;
                bSave.Enabled = false;
                bCancel.Enabled = false;

                saisie.RefreshAll();
                saisie.UnblockSister((aid + 1) % 2);
            }

            private void BVerif_OnButtonClick(Button c, MouseEvent arg)
            {
                saisie.BlockSister((aid + 1) % 2);

                var grid = MensualView.Grid;
                var selected = grid.Selected;

                grid.ResetStatusOK();


                var func = new Api.ApiFunction();
                func.function = Api.ApiFuncType.verif_absences;
                func.iparam1 = Workflow.pid;
                func.iparam2 = Workflow.wfid_su;
                func.iparam3 = saisie.pid;
                func.iparam4 = aid;
                func.iparam5 = cboMotif.SelectedId;

                var csv = new CSVTool();
                csv.A(selected.Count);
                foreach (var day in selected)
                {
                    csv.A(day.Date);
                }

                func.param1 = csv.ToString();

                var dataVerifAbs = new DataApi<Api.ApiReturn>(Workflow.api, func);
                dataVerifAbs.OnSuccess += DataVerifAbs_OnSuccess;


            }

            private void DataVerifAbs_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
            {
                var grid = MensualView.Grid;
                var selected = grid.Selected;

                var csv = new CSVTool();
                csv.LoadData(result.return_s1);

                var count = csv[0].I32(0);
                Console.WriteLine(count);
                for (var i = 0; i < count; i++)
                {
                    var status = csv[0].I32(i + 1);
                    if (status == 0)
                    {
                        selected[i].StatusNotOK = true;
                    }
                }


                bVerif.Enabled = false;
                bSave.Enabled = true;
                bCancel.Enabled = true;

            }

            private void fDP_OnKeydown(Bridge.Html5.KeyboardEvent<Bridge.Html5.HTMLInputElement> e)
            {
                Console.WriteLine(fDP.Value);
                //saisie.Modified = true;
                //modified = true;
            }

            private void CboType_OnChange1(Combo combo)
            {
                var tid = combo.SelectedId;

                pDP.Visible = Visibility.Hidden;
                pDEA.Visible = Visibility.Hidden;
                pPerc.Visible = Visibility.Hidden;

                if (tid == 10)
                {
                    pDP.Visible = Visibility.Inherit;
                }
                else if (tid == 9)
                {
                    pDEA.Visible = Visibility.Inherit;
                }
                else if(tid == 15)
                {
                    pPerc.Visible = Visibility.Inherit;
                }
            }

            void CboMotif_OnChange(Combo combo)
            {
                Console.WriteLine("AbsMotif " + combo.Selected.Value);

                abs_id = cboMotif.SelectedId;

                FillCboType();
                cboType.SelectByIdx(1, true);

                if (abs_id > -1)
                {
                    bVerif.Enabled = true;
                    saisie.BlockSister((aid + 1) % 2);
                    this.Style.BorderColor = Color.Red.ToHex();
                }
                else
                {
                    bVerif.Enabled = false;
                    saisie.UnblockSister((aid + 1) % 2);
                    SetBorderStyle(1, "solid", new Color(200), 4);
                }

                FindAbs();


                saisie.Change();

            }

            void FindAbs()
            {
                if (cboMotif.SelectedId == 0) selected = null;
                else
                {
                    foreach (var abs in absSu.motif)
                    {
                        if (abs.id == cboMotif.SelectedId)
                        {
                            selected = abs;
                            break;
                        }
                    }
                }
            }

            void FillCboType()
            {
                var absid = cboMotif.SelectedId;

                cboType.Clear();
                cboType.Add(-1, "----");

                foreach (var abs in absSu.motif)
                {
                    if (abs.id == absid)
                    {

                        if (!abs.hn7)
                        {
                            if (abs.d_HN > 0) cboType.Add(0, Res.Get(520, "Heures dues"));
                            if (abs.d_HalfHN > 0) cboType.Add(2, Res.Get(521, "1/2 des heures dues"));
                            if (abs.d_Compl > 0) cboType.Add(1, Res.Get(522, "Complément aux heures dues"));
                        }
                        else
                        {
                            if (abs.d_HN > 0) cboType.Add(20, Res.Get(520, "Heures dues"));
                            if (abs.d_HalfHN > 0) cboType.Add(22, Res.Get(521, "1/2 des heures dues"));
                            if (abs.d_Compl > 0) cboType.Add(21, Res.Get(522, "Complément aux heures dues"));
                        }

                        if (abs.d_DP > 0) cboType.Add(10, Res.Get(523, "Durée particulière"));
                        if (abs.d_DeA > 0) cboType.Add(9, Res.Get(524, "De...A"));
                        if (abs.d_AmPm > 0) { cboType.Add(50, "AM"); cboType.Add(51, "PM"); }
                        if(abs.d_Percent > 0) { cboType.Add(15, "%"); }

                        //if (abs.d_Ptj > 0) cboType.Add(5, "Ptj");
                        //if (abs.d_PtjCpl > 0) cboType.Add(7, "Ptj Cpl");
                        //if (abs.d_RefHJToc > 0) cboType.Add(35, "RefHJToc");
                        //if (abs.d_RefHJTocHalf > 0) cboType.Add(36, "RefHJTocHalf");

                        if (abs.d_Ptj > 0) cboType.Add(5, Res.Get(525, "Ptj"));
                        if (abs.d_Ptj > 0) cboType.Add(6, Res.Get(525, "Ptj") + "-Pause");
                        if (abs.d_PtjCpl > 0) cboType.Add(7, Res.Get(526, "Ptj Cpl"));
                        if (abs.d_PtjCpl > 0) cboType.Add(8, Res.Get(526, "Ptj Cpl") + "-Pause");

                        if (abs.d_RefHJ > 0) cboType.Add(30, Res.Get(529, "RefHJ"));
                        if (abs.d_RefHJ > 0) cboType.Add(32, Res.Get(530, "1/2 RefHJ"));
                        if (abs.d_RefHJ > 0) cboType.Add(31, Res.Get(531, "RefHJ compl."));

                        if (abs.d_RefHJToc > 0) cboType.Add(35, Res.Get(527, "RefHJToc"));
                        if (abs.d_RefHJTocHalf > 0) cboType.Add(36, Res.Get(528, "RefHJTocHalf"));

                        if (abs.d_propre > 0) cboType.Add(12, Res.Get(532, "Dur.Propre"));

                        break;
                    }
                }
            }

            void CboType_OnChange(Combo combo)
            {
                saisie.Change();

                abs_type = cboType.SelectedId;


                //saisie.Modified = true;
                //modified = true;
            }


            public void Block(bool v)
            {
                cboMotif.List.Disabled = v;
                cboType.List.Disabled = v;
                bVerif.Enabled = false;

                cboMotif.SelectByIdx(0);
                cboType.SelectByIdx(0);
            }

        }


        public static PanelSaisieMultiAbs Self = null;

        public delegate void dOnNeedRefresh(PanelSaisieMultiAbs panelSaisieMulti);
        public event dOnNeedRefresh OnNeedRefresh = null;

        int old_year = 0, old_pid = 0;

        private Label _lAction = new Label();
        private Label _lMotif = new Label();
        private Label _lDuree = new Label();
        
        private Combo _cboAction = new Combo();
        private Combo _cboMotif = new Combo();
        private Combo _cboDuree = new Combo();
        
        private List<Api.Absence_SU> abs_su = new List<Api.Absence_SU>();
        private Api.API_CatAbs _catAbs = null;

        private List<System.DateTime> dates = new List<System.DateTime>();

        Button bConfirmPtg, bConfirmAbs, bValidMois;
        Button bValidPtg, bValidAbs, bRefusAbs;
        int wfid = 0;

        Label[] lSoldeT = new Label[4];
        Label[] lSoldeV = new Label[4];

        Panel critique = null;
        int AbsCount = 0;
        PSAbs[] pAbs = new PSAbs[2];
        public int pid = 0;
        bool chef = false;

        public int next_wfid = 0;

        public bool ForceUpdate
        {
            get
            {
                bool v = false;

                for(var i = 0; i < pAbs.Length; i++)
                {
                    if (pAbs[i] == null) continue;
                    if (pAbs[i].cboMotif.Count == 0)
                    {
                        v = true;
                        break;
                    }
                }

                Console.WriteLine("v:" + v);

                return v;
            }
        }

        public PanelSaisieMultiAbs(Panel pParent) : base(pParent)
        {
            Self = this;
            id = 6;

            lsKey = "pMultiAbsClosed";

            _lHeader.Text = Res.Get(900, "Introduction d'absences multiple");
            SetHeaderHeight(32);

            _pBack.Size = new Size(600 - 32, 320);
            sz1 = 320;

            OnOpenClose += PanelSaisieMultiAbs_OnOpenClose;


            var max = 2;
            AbsCount = max;

            for (var i = 0; i < max; i++)
            {
                if (pAbs[i] != null)
                {
                    _pBack.RmvChild(pAbs[i]);
                }
                pAbs[i] = new PSAbs(i, 0, _pBack, this, null, false);
            }

        }

        private void PanelSaisieMultiAbs_OnOpenClose(PanelSaisie p)
        {
            //var pnlA = PanelSaisieMultiValid.Self;
            //var pnlB = PanelSaisieMultiExc.Self;

            ////if (this.Closed)
            ////{
            ////    pnlA.Open();
            ////}
            ////else
            ////{
            ////    //pnlA.Close();
            ////}

            //pnlA.SetY(16);
            //SetY(pnlA.Bottom + 32);
            //pnlB.SetY(this.Bottom + 32);

            //PanelSaisieMultiValid.Self.SetY(16);
            //PanelSaisieMultiAbs.Self.SetY(PanelSaisieMultiValid.Self.Bottom + 16);
            //PanelSaisieMultiExc.Self.SetY(PanelSaisieMultiAbs.Self.Bottom + 16);

            MensualView.SaisieMultiSetY();

            Init(next_wfid);
        }

        public void Init(int wfid = -1)
        {
            var func = new Api.ApiFunction();

            if (wfid == -1)
            {
                func.iparam1 = Workflow.wfid_su;
                func.iparam3 = Workflow.pid;
            }
            else
            {
                func.iparam1 = Workflow.WFProfil[wfid];
                func.iparam2 = 1;
                func.iparam3 = Workflow.pid;
            }
            func.function = Api.ApiFuncType.get_absences_su;
            var data = new DataApi<Api.API_AbsSU>(Workflow.api, func);
            data.OnSuccess += Data_OnSuccess;
            data.id1 = wfid;
            data.b1 = func.iparam2 == 1;
        }

        void Data_OnSuccess(Data<Api.API_AbsSU> data, Api.API_AbsSU result)
        {
            var max = 2;
            if (result.only_abs_1) max = 1;
            AbsCount = max;

            for (var i = 0; i < max; i++)
            {
                pAbs[i].Update(data.id1, result, data.b1);

                pAbs[i].Visible = Visibility.Inherit;
                if (AbsCount == 1 && i == 1)
                    pAbs[i].Visible = Visibility.Hidden;
            }

            var w = this._pBack.Width / 8;
            chef = data.b1;

            for (var i = 0; i < 4; i++)
            {
                if (lSoldeT[i] != null) continue;
                lSoldeT[i] = new Label("solde 1", i * w * 2, 8, w, 16, "right", _pBack);
                lSoldeT[i].FontSize = 10;
                lSoldeV[i] = new Label("- 1000:00", lSoldeT[i].Right, 8, w - 20, 16, "right", _pBack);
                lSoldeV[i].FontSize = 10;

                //lSoldeT[i].Visible = Visibility.Hidden;
                //lSoldeV[i].Visible = Visibility.Hidden;
            }

            LoadSoldes();
        }


        void LoadSoldes()
        {
            var function = new Api.ApiFunction();
            function.function = Api.ApiFuncType.GetWFSoldeCrit;
            function.iparam1 = pid;
            function.iparam2 = wfid;
            function.iparam3 = 2019;// date.Year;
            function.iparam4 = Workflow.pid;

            var dataCrit = new DataApi<Api.ApiReturn>(Workflow.api, function);
            dataCrit.OnSuccess += DataCrit_OnSuccess;
        }

        private void DataCrit_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);

            

            for (var i = 0; i < 4; i++)
            {
                lSoldeT[i].Text = "";
                lSoldeV[i].Text = "";
            }

            if (csv[0].strings.Count < 3) return;

            var k = 0;
            for (var i = 0; i < csv[0].strings.Count; i += 3)
            {
                var t1 = csv[0].strings[i];
                var t2 = csv[0].strings[i + 1];
                var color = csv[0].I32(i + 2);

                lSoldeT[k].Text = t1 + " :";
                lSoldeV[k].Text = t2;

                if (color == 1) lSoldeT[k].Color = Color.Red;
                else if (color == 2) lSoldeT[k].Color = Color.Red;
                else lSoldeT[k].Color = Color.LabelTxt;

                k++;
            }
        }


        void B_OnButtonClick(Button c, MouseEvent arg)
        {
            var csv = new CSVTool();

            csv.A(dates.Count);

            foreach (var d in dates)
            {
                csv.A(d);
            }
            csv.A(c.id1);

            switch (c.id1)
            {
                case 0:
                    break;

                case 1:
                    break;
                
                case 100:
                    csv.A(_cboAction.SelectedId);
                    csv.A(_cboMotif.SelectedId);
                    csv.A(_cboDuree.SelectedId);
                    break;

                case 150:
                    break;
                case 151:
                    break;
                case 152:
                    break;
                case 160:
                    break;

            }

            //System.Console.WriteLine("motif: " + _cboMotif.SelectedId + " " + _cboMotif.Selected.Text);

            var flag = 0;
            //if (bValidPtg.Visible == Visibility.Inherit) flag = 1;

            var s = csv.ToString();
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.WFValidSU;
            func.iparam1 = pid;
            func.iparam2 = flag;
            func.iparam3 = wfid;
            func.iparam4 = Workflow.pid;
            func.param1 = s;
            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += Data_OnSuccess;
        }

        void Data_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            OnNeedRefresh!=null?global::Bridge.Script.FromLambda(()=>OnNeedRefresh.Invoke(this)):null;
        }

        public void RefreshAll()
        {
            OnNeedRefresh!=null?global::Bridge.Script.FromLambda(()=>OnNeedRefresh.Invoke(this)):null;
        }

        private void CboMotifOnOnChange(Combo combo)
        {
            _cboDuree.Clear();

            var aid = _cboMotif.SelectedId;
            if (aid > 0)
            {
                _cboDuree.Visible = Visibility.Inherit;

                var k = aid - 1;
                var duree = abs_su[k];
                
                if(duree.d_HN > 0) _cboDuree.Add(0, Res.Get(520, "Heures dues"));
                if(duree.d_HalfHN > 0) _cboDuree.Add(1, Res.Get(521, "1/2 des heures dues"));
                if(duree.d_Compl > 0) _cboDuree.Add(2, Res.Get(522, "Complément aux heures dues"));
                if(duree.d_DP > 0) _cboDuree.Add(10, Res.Get(523, "Durée particulière"));
                if (duree.d_AmPm > 0) { _cboDuree.Add(50, "AM"); _cboDuree.Add(51, "PM"); }
            }

            _lDuree.Visible = _cboDuree.Visible;
        }

        private void CatAbs_Success(Data<Api.API_CatAbs> data, Api.API_CatAbs result)
        {
            _catAbs = result;
            
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.get_absences_su;
            func.iparam1 = Workflow.wfid_su;
            func.iparam3 = Workflow.pid;
            func.token = Workflow.token;
            var data2 = new DataApi<Api.API_AbsSU>(Workflow.api, func);
            data2.OnSuccess += AbsSU_Success;
        }

        private void AbsSU_Success(Data<Api.API_AbsSU> data, Api.API_AbsSU result)
        {
            _cboMotif.Clear();
            abs_su = result.motif;

            _cboMotif.Add(0, "----");
            foreach (var asu in abs_su)
            {
                var tot = asu.d_Compl + asu.d_DP + asu.d_HalfHN + asu.d_HN + asu.d_AmPm;

                if (tot > 0)
                {
                    var name = _catAbs.name[asu.id];
                    if (name.Length == 0) name = "{" + asu.id + "}";
                    _cboMotif.Add(asu.id, asu.id + ". " + name);
                }
            }
        }

        private void CboActionOnOnChange(Combo combo)
        {
            bool showMotif = combo.SelectedId == 1 || combo.SelectedId == 2;

            if (showMotif)
            {
                _cboMotif.Visible = Visibility.Inherit;
                _lMotif.Visible = Visibility.Inherit;
                _cboDuree.Visible = Visibility.Hidden;
                _lDuree.Visible = Visibility.Hidden;
            }
            else
            {
                _cboMotif.Visible = Visibility.Hidden;
                _lMotif.Visible = Visibility.Hidden;
                _cboDuree.Visible = Visibility.Hidden;
                _lDuree.Visible = Visibility.Hidden;
            }
        }

        public override void ChangeVisibility()
        {
            base.ChangeVisibility();

            if (_pBack.Visible == Visibility.Hidden)
            {
                _cboMotif.Visible = _pBack.Visible;
                _cboDuree.Visible = _pBack.Visible;
                _lMotif.Visible = _pBack.Visible;
                _lDuree.Visible = _pBack.Visible;

                //bConfirmPtg.Visible = _pBack.Visible;
                //bConfirmAbs.Visible = _pBack.Visible;
                //bValidMois.Visible = _pBack.Visible;

                //bValidPtg.Visible = _pBack.Visible;
                //bValidAbs.Visible = _pBack.Visible;
                ////bValidMois2.Visible = _pBack.Visible;
                //bRefusAbs.Visible = _pBack.Visible;
            }

        }

        public void SetDays(List<pDay> days, bool chef, int pid, int wfid)
        {
            this.wfid = wfid;
            this.pid = pid;
        }

        public void BlockSister(int aid)
        {
            pAbs[aid].Block(true);

            PanelSaisieMultiValid.Self.BlockUpDown(true);
            BlockUpDown(true);
        }
        public void UnblockSister(int aid)
        {
            pAbs[aid].Block(false);
            PanelSaisieMultiValid.Self.BlockUpDown(false);
            BlockUpDown(false);
        }

        public void InitShow(int year)
        {
            
        }

                   

    }
}