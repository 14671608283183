using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;
using Workflow_bridge.WorkActivity;

namespace Workflow_bridge
{
    class PanelActivity : Panel
    {
        public Action OnHide = null;
        public Action OnSave = null;

        Button bClose, b1, b2, b3;
        Button bNew, bModify, bSave, bSave2, bCancel, bDel;

        List<string> filtreTxt = new List<string>();

        MensualView.MensualView view = null;

        string[] t1 = { "Prés", "Abs T.", "T/A", "+/-" };
        string[] t2 = { "ID code saisie", "ID code numérique", "Description", "Description courte", "Dénomination" };
        string[] t2c = { "idc", "idn", "desc", "descc", "denom" };

        Button[] bFiltresOF = new Button[3];
        Button[] bFiltresOrdre = new Button[3];
        Button bRmvFiltreOf, bRmvFiltreOrdre;

        DateTime d1 = DateTime.MinValue;

        Label lw, lhPres, lhAbsT, lhTA, lhEcart, lDate;
        Panel lstQties, lstOF, lstOrdre;

        Dictionary<string, Label> labels = new Dictionary<string, Label>();
        Dictionary<string, Field> fields = new Dictionary<string, Field>();
        Dictionary<string, Combo> combos = new Dictionary<string, Combo>();

        Dictionary<int, string> TTs = new Dictionary<int, string>();

        List<Panel> lstPQties = new List<Panel>();
        List<Panel> lstPDays = new List<Panel>();
        Panel selectPanel = null;
        Panel pEdit = null, pListOF, pListOrdre;

        Button bSelOF, bSelOrdreFixe, bSelOrdre;

        Button bCpl;
        Button[] bDays = new Button[8];

        int selectOF = 0;
        int selectOrdre = 0;

        Color[] validColor = new Color[64];

        int currentPid = -1;
        DateTime currentDate = DateTime.MinValue;
        int currentRowIdx = -1;
        int currentBlock = -1;
        bool createNew = false;

        int currentValid = -1;

        int typeOrdreWanted = 0;

        int currentModel = 0;

        Panel p3 = null, p22, pTitle, p3TB;

        Dictionary<int, SchemaPrometerre> schemas = null;

        int mode = 0;

        public PanelActivity(MensualView.MensualView view)
        {

            for (var i = 0; i < t1.Length; i++)
                t1[i] = Res.Get(12350 + i, t1[i]);

            for (var i = 0; i < t2.Length; i++)
                t2[i] = Res.Get(12051 + i, t2[i]);

            this.view = view;
            BackgroundColor = GetColor("window.back");
            Size = new Size(1270, 850);

            pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = GetColor("toolbar.back");

            var lTitle = new Label(Res.Get(12000, "Saisie temps d'acitivtés"), 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            var x = 4;
            bClose = new Button(x, 0, 32, 32, "images/63_porte_t.png", pTitle, false, 99); x += 48;
            bClose.OnButtonClick += BMenu_Click;

            b1 = new Button(x, 0, 32, 32, "images/arrow_left.png", pTitle, false, 1); x += 40;
            b2 = new Button(x, 0, 32, 32, "images/now.png", pTitle, false, 2); x += 40;
            b3 = new Button(x, 0, 32, 32, "images/arrow_right.png", pTitle, false, 3); x += 40;
            b1.OnButtonClick += BMenu_Click;
            b2.OnButtonClick += BMenu_Click;
            b3.OnButtonClick += BMenu_Click;

            validColor[0] = Color.Blue;
            validColor[1] = Color.Green;
            validColor[2] = Color.Red;
            validColor[4] = new Color(255, 127, 0, 255);
            validColor[8] = new Color(255, 127, 0, 255);
            validColor[16] = Color.Green;

            PlaceObj1();
            PlaceObj2();
            PlaceObj3();
            PlaceListOF();
            PlaceListOrdre();

            SetState(false);
        }

        void PlaceObj1()
        {
            var p = new Panel(16, 64, 600, 128, Color.White, this);

            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(12010, "Résumé de la semaine"), 8, 8, p2.Width - 8, 16, "left", p2);
            l.Color = Color.White;

            lDate = new Label("", 0, 8, p2.Width - 8, 16, "right", p2);
            lDate.Color = Color.White;

            var y = 56;

            l = new Label(t1[0] + ":", 64, y, 100, 16, "right", p);
            lhPres = new Label("", l.Right + 4, l.Top, 70, 16, "right", p);

            l = new Label(t1[1] + ":", 64, y + 32, 100, 16, "right", p);
            lhAbsT = new Label("", l.Right + 4, l.Top, 70, 16, "right", p);

            l = new Label(t1[2] + ":", 256, y, 100, 16, "right", p);
            lhTA = new Label("", l.Right + 4, l.Top, 70, 16, "right", p);

            l = new Label(t1[3] + ":", 256, y + 32, 100, 16, "right", p);
            lhEcart = new Label("", l.Right + 4, l.Top, 70, 16, "right", p);

            lw = new Label("w32", 0, 70, 64, 16, "center", p);
            lw.FontSize = 14;
        }

        void PlaceObj2()
        {
            var y = 64 + 128 + 16;
            var p = p22 = new Panel(16, y, 600, Height - y - 16, Color.White, this);

            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(12020, "Saisies hebdomadaires"), 8, 8, p2.Width - 8, 16, "left", p2);
            l.Color = Color.White;

            var b1 = new Button(p2.Width - 84, 0, 32, 32, "images/ok.png", p2, false, 4);
            var b3 = new Button(p2.Width - 44, 0, 32, 32, "images/arrow-left-right.png", p2, false, 0);

            b1.OnButtonClick += BValid_OnClick;
            b3.OnButtonClick += BValid_OnClick;

            lstQties = new Panel(0, 48, p.Width, p.Height - 48, Color.White, p);
            lstQties.SetScrolling(Bridge.Html5.Overflow.Hidden, Bridge.Html5.Overflow.Scroll);
        }

        private void BValid_OnClick(Button c, Bridge.Html5.MouseEvent arg)
        {
            var r = CheckValidation();
            if (r)
            {
                switch (c.id1)
                {
                    case 0:
                        foreach (var p in lstPQties)
                            if (p.id6 == 4 && p.id8 < 100)
                                p.id6 = 0;
                        break;

                    case 4:
                        foreach (var p in lstPQties)
                            if (p.id6 == 0 && p.id8 < 100)
                                p.id6 = 4;
                        break;
                }

                SaveValidation(false);
            }
            else
            {
                Window.Alert(Res.Get(12375, "Veuillez compléter toutes vos journées!"));
            }
        }

        void PlaceObj3()
        {
System.Action SetDayButtonColor = null;
            var p = p3 = new Panel(632, 64, Width - 632 - 16, Height - 80, Color.White, this);
            pEdit = p;

            var p2 = p3TB = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(12050, "Edition / Création"), 8, 8, p2.Width - 16, 16, "right", p2);
            l.Color = Color.White;

            var x = 4;
            bNew = new Button(x, 0, 32, 32, "images/63_new_t.png", p2, false, 10); x += 40;
            bModify = new Button(x, 0, 32, 32, "images/63modifie_t.png", p2, false, 11); x += 40;
            bSave = new Button(x, 0, 32, 32, "images/63save_t.png", p2, false, 12); x += 40;
            bSave2 = new Button(x, 0, 32, 32, "images/63save_plus.png", p2, false, 14); x += 40;
            if (!Workflow.show_saveAktMore) { bSave2.Visible = Bridge.Html5.Visibility.Hidden; x -= 40; }
            bCancel = new Button(x, 0, 32, 32, "images/64annule_t.png", p2, false, 13); x += 40;
            bDel = new Button(244, 0, 32, 32, "images/trash@32px.png", p2, false, 66); x += 40;

            bNew.OnButtonClick += BMenu_Click;
            bModify.OnButtonClick += BMenu_Click;
            bSave.OnButtonClick += BMenu_Click;
            bSave2.OnButtonClick += BMenu_Click;
            bCancel.OnButtonClick += BMenu_Click;
            bDel.OnButtonClick += BMenu_Click;

            bDel.Visible = Bridge.Html5.Visibility.Hidden;

            var y = 56;

            labels["date"] = new Label("", 16, y, p.Width - 32, 16, "right", p);
            labels["date"].FontSize = 14;

            labels["pers"] = new Label("", 16, y, p.Width - 32, 16, "left", p);
            labels["pers"].FontSize = 14;

            y += 80;

            for(var i = 0; i < t2.Length; i++)
            {
                l = new Label(t2[i] + ":", 16, y, 170, 16, "right", p);
                labels[t2c[i]] = new Label("", l.Right + 4, l.Top, 250, 16, "left", p);

                y += 48;


                if(l.Text.Length <= 2)
                {
                    l.Visible = Bridge.Html5.Visibility.Hidden;
                    labels[t2c[i]].Visible = Bridge.Html5.Visibility.Hidden;
                }
            }

            y += 32;
            var yDuree = y;

            var f = fields["duree"] = new Field(32, y, 60, Res.Get(12056, "Durée") + ":", p, Field.FieldFormat.HQty);
            fields["duree"].Input.ReadOnly = true;

            bCpl = new Button(f.Right + 8, f.Top - 4, 32, 32, "", p, true);
            bCpl.Text = Res.Get(12060, "C");
            bCpl.OnButtonClick += (a, bb) =>
            {
                bCpl.id1++;
                bCpl.id1 %= 2;
                bCpl.SelectMe(bCpl.id1 == 1);
                if (bCpl.id1 == 1)
                {
                    fields["duree"].Input.Value = "----";
                    fields["duree"].Input.ReadOnly = true;
                }
                else
                {
                    fields["duree"].Value = 0;
                    fields["duree"].Input.ReadOnly = false;
                }                                                                
            };

            var c = combos["tt"] = new Combo(Res.Get(12059, "Type Travail") + ":", bCpl.Right + 16, y, 200, 24, p);
            c.OnChange += a => 
            {
                var item = c.Selected;
                var att = item.GetAttribute("id2");

                fields["timeDe"].Visible = Bridge.Html5.Visibility.Hidden;
                fields["timeA"].Visible = Bridge.Html5.Visibility.Hidden;

                fields["duree"].Visible = Bridge.Html5.Visibility.Hidden;
                fields["qty"].Visible = Bridge.Html5.Visibility.Hidden;


                Console.WriteLine(currentModel);

                if (currentModel == 0 || currentModel == 1 || currentModel == 3) fields["duree"].Visible = Bridge.Html5.Visibility.Inherit;
                if (currentModel == 0 || currentModel == 2 || currentModel == 3) fields["qty"].Visible = Bridge.Html5.Visibility.Inherit;
                bCpl.Visible = fields["duree"].Visible;

                if (att != null && att.Length > 0)
                {
int codeclav;
                    int.TryParse(att, out codeclav);
                    if(codeclav < 0)
                    {
                        fields["duree"].Visible = Bridge.Html5.Visibility.Hidden;
                        fields["qty"].Visible = Bridge.Html5.Visibility.Hidden;
                        fields["timeDe"].Visible = Bridge.Html5.Visibility.Inherit;
                        fields["timeA"].Visible = Bridge.Html5.Visibility.Inherit;
                        bCpl.Visible = Bridge.Html5.Visibility.Hidden;
                    }
                }
            };
            c.List.Disabled = true;
            c.Visible = Bridge.Html5.Visibility.Hidden;

            y += 48;

            f = fields["qty"] = new Field(32, y, 60, Res.Get(12058, "Qté") + ":", p, Field.FieldFormat.EntierCentieme);
            fields["qty"].Input.ReadOnly = true;

            f = fields["timeDe"] = new Field(32, yDuree, 60, "De" + ":", p, Field.FieldFormat.Ptg);
            fields["timeDe"].Input.ReadOnly = true;

            f = fields["timeA"] = new Field(32, y, 60, "A" + ":", p, Field.FieldFormat.Ptg);
            fields["timeA"].Input.ReadOnly = true;

            fields["text"] = new Field(bCpl.Right + 16, y, p.Width - bCpl.Right - 48, Res.Get(12057, "Commentaire") + ":", p, Field.FieldFormat.Text);
            fields["text"].Input.ReadOnly = true;

            bSelOF = new Button(p.Width - 160, 128, 128, 32, "", p, true);
            bSelOF.Text = Res.Get(12061, "OF / Aff / Proj");
            bSelOF.Visible = Bridge.Html5.Visibility.Hidden;
            bSelOF.OnButtonClick += (a, b) => 
            {
                OpenListOF();
            };

            bSelOrdre = new Button(p.Width - 160, 128 - 40, 128, 32, "", p, true);
            bSelOrdre.Text = Res.Get(12063, "Ordre");
            bSelOrdre.Visible = Bridge.Html5.Visibility.Hidden;
            bSelOrdre.OnButtonClick += (a, b) =>
            {
                OpenListOrdre(selectOF, 0);
            };

            if (!Workflow.akt_hidefixebutton)
            {
                bSelOrdreFixe = new Button(p.Width - 160, 160, 128, 32, "", p, true);
                bSelOrdreFixe.Text = Res.Get(12062, "Fixe");
                bSelOrdreFixe.Visible = Bridge.Html5.Visibility.Hidden;
                bSelOrdreFixe.OnButtonClick += (a, b) =>
                {
                    OpenListOrdre(-1, 8);
                };
            }

            y += 64;
            var w = (p.Width - 64) / 4;
            var w2 = w - 16;
SetDayButtonColor = () =>
{
    for (var i = 1; i < bDays.Length; i++)
    {
        var b = bDays[i];
        b.SelectMe(b.id2 == 1);
    }
}

;
            for(var i = 0; i < 8; i++)
            {
                var xx = i % 4;
                var yy = i / 4;
                xx = 40 + xx * w;
                yy = y + yy * 64;

                var b = bDays[i] = new Button(xx, yy, w2, 48, "", p, true);
                b.id1 = i;
                if (i == 0) b.Text = string.Format("{0} - {1}",ResT.Day2[0],ResT.Day2[4]);
                else
                {
                    b.label.Top = 8;
                }

                b.OnButtonClick += (a, bb) => 
                {
                    if (b.id1 == 0)
                    {
                        var first = bDays[1].id2;
                        first = (first + 1) % 2;
                        for (var j = 1; j <= 5; j++)
                        {
                            bDays[j].id2 = bDays[j].Enabled ? first : 0;
                        }
                        bDays[6].id2 = 0;
                        bDays[7].id2 = 0;
                    }
                    else
                    {
                        b.id2++;
                        b.id2 %= 2;
                    }
                    SetDayButtonColor();
                };
            }


            

        }

        void PlaceListOF()
        {
            var p = new Panel(632, 64, Width - 632 - 16, Height - 80, Color.White, this);
            p.Visible = Bridge.Html5.Visibility.Hidden;
            pListOF = p;

            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(12100, "Sélection d'un OF/Affaire/Projet"), 8, 8, p2.Width - 16, 16, "right", p2);
            l.Color = Color.White;

            var bBack = new Button(4, 0, 32, 32, "images/64annule_t.png", p);
            bBack.OnButtonClick += (a, b) =>
            {
                selectOF = -1;
                selectOrdre = -1;
                p.Visible = Bridge.Html5.Visibility.Hidden;
            };

            var filtre = fields["filtre"] = new Field(16, 52, 100, Res.Get(12300, "filtre"), p);
            fields["filtre"].OnValidateEnter += (e) => 
            {
                GetOFs();
            };

            bRmvFiltreOf = new Button(132, 50, 28, 28, "", p, true);
            bRmvFiltreOf.Text = "X";
            bRmvFiltreOf.OnButtonClick += (a, bb) =>
            {
                filtre.Input.Value = "";
                GetOFs();
            };

            for (var i = 0; i < 3; i++)
            {
                var b = bFiltresOF[i] = new Button(bRmvFiltreOf.Right + 32 + i * 88, 50, 80, 28, "", p, true, i);
                b.OnButtonClick += (a, bb) =>
                {
                    filtre.Input.Value = b.Text;
                    GetOFs();
                };
            }

            var p3 = new Panel(0, 87, p.Width, 1, new Color(200), p);
            lstOF = new Panel(0, 88, p.Width, p.Height - 88, new Color(200), p);
            lstOF.SetScrolling(Bridge.Html5.Overflow.Hidden, Bridge.Html5.Overflow.Scroll);
        }

        void PlaceListOrdre()
        {
            var p = new Panel(632, 64, Width - 632 - 16, Height - 80, Color.White, this);
            p.Visible = Bridge.Html5.Visibility.Hidden;
            pListOrdre = p;
            
            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(12200, "Sélection d'un Ordre/activité"), 8, 8, p2.Width - 16, 16, "right", p2);
            l.Color = Color.White;

            var bBack = new Button(4, 0, 32, 32, "images/64annule_t.png", p);
            bBack.OnButtonClick += (a, b) =>
            {
                selectOF = -1;
                selectOrdre = -1;
                p.Visible = Bridge.Html5.Visibility.Hidden;
            };

            var filtre = fields["filtreO"] = new Field(16, 52, 100, Res.Get(12300, "filtre"), p);
            fields["filtreO"].OnValidateEnter += (e) =>
            {
                GetOrdres(selectOF, typeOrdreWanted, true);
            };

            bRmvFiltreOrdre = new Button(132, 50, 28, 28, "", p, true);
            bRmvFiltreOrdre.Text = "X";
            bRmvFiltreOrdre.OnButtonClick += (a, bb) =>
            {
                filtre.Input.Value = "";
                GetOrdres(selectOF, typeOrdreWanted, true);
            };

            for (var i = 0; i < 3; i++)
            {
                var b = bFiltresOrdre[i] = new Button(bRmvFiltreOrdre.Right + 32 + i * 88, 50, 80, 28, "", p, true, i);
                b.OnButtonClick += (a, bb) =>
                {
                    filtre.Input.Value = b.Text;
                    GetOrdres(selectOF, typeOrdreWanted, true);
                };
            }

            var p3 = new Panel(0, 87, p.Width, 1, new Color(200), p);
            lstOrdre = new Panel(0, 88, p.Width, p.Height - 88, new Color(200), p);
            lstOrdre.SetScrolling(Bridge.Html5.Overflow.Hidden, Bridge.Html5.Overflow.Scroll);
        }

        private void BMenu_Click(Button c, Bridge.Html5.MouseEvent arg)
        {
            var oldd1 = d1;

            switch(c.id1)
            {
                case 99:
                    view.CheckQtyDifference();
                    view.CloseLock();
                    break;
                
                case 1:
                    d1 = d1.AddDays(-7);
                    break;
                case 2:
                    d1 = Fmt.GetMonday(DateTime.Now);
                    break;
                case 3:
                    d1 = d1.AddDays(7);
                    break;

                case 10:
                    createNew = true;
                    Clear(false);
                    EditSetState(true);
                    labels["pers"].Text = Workflow.nom;
                    labels["date"].Text = "";
                    bSave.Visible = Bridge.Html5.Visibility.Inherit;
                    bCancel.Visible = Bridge.Html5.Visibility.Inherit;
                    break;
                
                case 11:
                    EditSetState(true);
                    break;
                
                case 12:
                    var r1 = Save();
                    if (r1)
                    {
                        EditSetState(false);
                        createNew = false;
                        currentValid = -1;
                    }
                    break;

                case 13:
                    EditSetState(false);
                    if (currentPid > 0)
                        LoadQtyDetail(currentPid, currentDate, currentRowIdx, currentBlock);
                    else
                        Clear();

                    currentValid = -1;
                    createNew = false;

                    if (mode == 1)
                    {
                        this.Visible = Bridge.Html5.Visibility.Hidden;
                        OnHide!=null?global::Bridge.Script.FromLambda(()=>OnHide.Invoke()):null;
                    }

                    break;

                case 14:
                    var r2 = Save(false, false);
                    if (r2)
                    {
                        createNew = true;
                        
                        fields["duree"].Value = 0;
                        fields["qty"].Value = 0;
                        fields["text"].Input.Value = "";

                        labels["pers"].Text = Workflow.nom;
                        labels["date"].Text = "";
                        bSave.Visible = Bridge.Html5.Visibility.Inherit;
                        bCancel.Visible = Bridge.Html5.Visibility.Inherit;
                    }
                    break;

                case 66:
                    var r3 = Save(true);
                    if (r3)
                    {
                        EditSetState(false);
                        createNew = false;
                    }
                    break;
            }

            if (oldd1 != d1 || c.id1 == 2)
                LoadWeek();
        }

        void SetState(bool v)
        {
            bClose.Enabled = !v;

        }

        public void Show()
        {
            d1 = Fmt.GetMonday(DateTime.Now);

            fields["duree"].Visible = Bridge.Html5.Visibility.Hidden;
            fields["qty"].Visible = Bridge.Html5.Visibility.Hidden;
            fields["timeDe"].Visible = Bridge.Html5.Visibility.Hidden;
            fields["timeA"].Visible = Bridge.Html5.Visibility.Hidden;
            bCpl.Visible = fields["duree"].Visible;

            if (Workflow.mode_prometerre)
            {
                var funcS = new Api.ApiFunction(Api.ApiFuncType.GetWorkActSchema);
                funcS.iparam1 = Workflow.pid;
                var dataS = new DataApi<Dictionary<int, SchemaPrometerre>>(Workflow.api, funcS);
                dataS.OnSuccess += (a, r) =>
                {
                    schemas = r;
                    LoadWeek();
                };
            }
            else
            {
                LoadWeek();
            }

            EditSetState(false);

        }

        public void ShowFrom(int x, int w, int h, PanelValidationQ pvq, int pid, DateTime date, int rowidx, int block, Api.ApiReturn b)
        {
            mode = 1;

            Left = x - 16;
            Top = pvq.Top + 48;
            Height = h + 32;
            p3.Left = 16;
            p3.Top = 16;
            p3.Width = w;
            p3.Height = h;
            p3TB.Width = p3.Width;
            Width = w + 32;// p3.Width + 32;

            bNew.Visible = Bridge.Html5.Visibility.Hidden;
            bModify.Visible = Bridge.Html5.Visibility.Hidden;
            pTitle.Visible = Bridge.Html5.Visibility.Hidden;
            p22.Visible = Bridge.Html5.Visibility.Hidden;
            bSave.Left = 4;
            bCancel.Left = 44;

            bModify.Enabled = false;
            //bSave.Enabled = true;
            //bCancel.Enabled = true;
            bDel.Visible = Bridge.Html5.Visibility.Hidden;

            pListOF.Left = p3.Left;
            pListOF.Top = p3.Top;
            pListOrdre.Left = p3.Left;
            pListOF.Top = p3.Top;


            SetBorderStyle(0, "none", Color.White);

            LoadQtyDetail(pid, date, rowidx, block, b, true);

            EditSetState(true);

            Visible = Bridge.Html5.Visibility.Inherit;
        }

        void LoadWeek(bool keepSel = false, bool clearAll = true)
        {
            //Console.WriteLine("loadweek " + createNew);

            var oldcpid = currentPid;
            var oldcdate = currentDate;
            var oldcrowidx = currentRowIdx;
            var oldcblock = currentBlock;

            currentDate = DateTime.MinValue;
            currentPid = -1;
            currentRowIdx = -1;
            currentBlock = -1;

            if (clearAll)
            {
                Clear();
                EditSetState(false);
            }

            lDate.Text = string.Format("{0} - {1}",Fmt.DateNumeric(d1),Fmt.DateNumeric(d1.AddDays(6)));
            lw.Text = string.Format("w{0}",Tool.ISOWeekNumber(d1));

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityHebdo2);
            func.iparam1 = Workflow.pid;        // user que l'on veut voir
            func.iparam3 = Workflow.pid;        // user pour profil de saisie
            func.iparam2 = D2000.T(d1);

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                lstQties.ClearChildren();

                filtreTxt.Clear();

                var csvH = new CSVTool(b.return_s1)[0];
                var csv = new CSVTool(b.return_s2)[0];
                var csvPS = new CSVTool(b.return_s3)[0];

                if (b.return_s3.Length > 0)
                    for (var i = 0; i < csvPS.Count; i++)
                    {
                        var s = csvPS[i].Trim();
                        if (s.Length > 0) filtreTxt.Add(s.Trim());
                    }
                                       
                lhPres.Text = Fmt.HHmm(csvH.I32(0));
                lhTA.Text = Fmt.HHmm(csvH.I32(1));
                lhAbsT.Text = Fmt.HHmm(csvH.I32(2));
                var ecartH = csvH.I32(4);
                lhEcart.Text = Fmt.HHmm(ecartH);
                lhEcart.Color = ecartH < 0 ? Color.Red : Color.Mobatime1;

                lstPQties.Clear();
                lstPDays.Clear();

                var idx = 0;
                var y = 0;
                for (var i = 0; i < 7; i++)
                {
                    var date = csv.Date(idx++);
                    var pres = csv.I32(idx++);
                    var ta = csv.I32(idx++);
                    var absT = csv.I32(idx++);
                    var abs = csv.I32(idx++);
                    var ecart = csv.I32(idx++);
                    //var ecart2 = csv.I32(idx++);   
                    var count = csv.I32(idx++);

                    var pday = new Panel(0, y, lstQties.Width, 40 + count * 73 - 1, new Color(220), lstQties);
                    pday.id7 = ecart;
                    lstPDays.Add(pday);

                    var pQties = new Panel(0, 40, pday.Width, count * 73 - 1, new Color(200), pday);

                    var l = new Label(Fmt.DateComplete(date), 4, 12, 200, 16, "left", pday);
                    Label lv;

                    l = new Label(t1[0] + ":", 200, 3, 70, 16, "right", pday);
                    lv = new Label(Fmt.HHmm(pres), l.Right + 4, l.Top, 50, 16, "right", pday);
                    l = new Label(t1[1] + ":", 200, 21, 70, 16, "right", pday);
                    lv = new Label(Fmt.HHmm(absT), l.Right + 4, l.Top, 50, 16, "right", pday);
                    l = new Label(t1[2] + ":", 340, 3, 70, 16, "right", pday);
                    lv = new Label(Fmt.HHmm(ta), l.Right + 4, l.Top, 50, 16, "right", pday);
                    l = new Label(t1[3] + ":", 340, 21, 70, 16, "right", pday);
                    lv = new Label(Fmt.HHmm(ecart), l.Right + 4, l.Top, 50, 16, "right", pday);
                    lv.Color = ecart < 0 ? Color.Red : Color.Mobatime1;

                    y += pday.Height;

                   
                    for (var j = 0; j < count; j++)
                    {
                        var rowid = csv.I32(idx++);
                        var block = csv.I32(idx++);
                        var pid = csv.I32(idx++);
                        var oid = csv.I32(idx++);
                        var status = csv.I32(idx++);
                        var duree = csv.I32(idx++);
                        var qty1 = csv.I32(idx++);
                        var t1 = csv[idx++];
                        var t2 = csv[idx++];
                        var t3 = csv[idx++];                         
                        var t4 = csv[idx++];
                        var t5 = csv[idx++];

                        var valid = csv.I32(idx++);

                        var p = new Panel(0, j * 73, pQties.Width, 72, Color.White, pQties, lstPQties);
                        p.Div.TabIndex = j;
                        p.id1 = rowid;
                        p.id2 = i;
                        p.id3 = duree;
                        p.id4 = block;
                        p.id5 = pid;
                        p.id6 = valid;
                        p.id7 = ecart;
                        p.id8 = status;
                        p.OnSelected += e => { Select(e); };
                        p.Div.OnDblClick += e =>
                        {
                            if (status < 100)
                                ChangeValidation(p, true);
                        };
                        p.Div.OnKeyDown += e => 
                        {
                            if (e.KeyCode == 46)
                            {
                                if (valid == 0 || valid == 4)
                                {
                                    EditSetState(false);
                                    Save(true);
                                    createNew = false;
                                }
                            }
                        };

                        l = new Label(Fmt.HHmm(duree), p.Width - 88, 8, 64, 16, "right", p);
                        l.FontSize = 14;
                        l = new Label(Fmt.EntierCentieme(qty1), p.Width - 88, 40, 64, 16, "right", p);
                        if (qty1 == 0) l.Text = "";
                        l.FontSize = 14;

                        if (status >= 100 && status < 200) t1 = "*" + t1;

                        l = new Label(t1, 16, 4, 150, 16, "left", p);
                        l = new Label(t2, 16, 28, 300, 16, "left", p);
                        l = new Label(t3, 170, 4, 320, 16, "right", p);
                        l = new Label(t4, 16, 52, 320, 16, "left", p);
                        
                        var pv = new Panel(0, 0, 8, p.Height, validColor[valid], p);

                    }

                    y += 1;
                }

                if(keepSel)
                {
                    var dayIdx = (int)(oldcdate - d1).TotalDays;

                    foreach (var p in lstPQties)
                        if (p.id4 == oldcblock && p.id1 == oldcrowidx && p.id2 == dayIdx && p.id5 == oldcpid)
                        {
                            p.SelectMe();
                            break;
                        }
                }

            };
        }

        void Select(Panel p)
        {
            Clear();

            var date = d1.AddDays(p.id2);
            labels["date"].Text = Fmt.DateComplete(date);

            pEdit.Visible = Bridge.Html5.Visibility.Inherit;

            LoadQtyDetail(Workflow.pid, date, p.id1, p.id4);

            createNew = false;
            EditSetState(false);
        }

        public void LoadQtyDetail(int pid, DateTime date, int rowidx, int block, Api.ApiReturn b, bool fromOther = false)
        {
            currentDate = date;
            currentPid = pid;
            currentRowIdx = rowidx;
            currentBlock = block;

            var csv = new CSVTool(b.return_s1)[0];
            var idx = 0;
            rowidx = csv.I32(idx++);
            block = csv.I32(idx++);
            var persid = csv.I32(idx++);
            var name = csv[idx++];
            var ofid = csv.I32(idx++);
            var oid = csv.I32(idx++);
            var idc = csv[idx++];
            var idn = csv[idx++];
            var desc = csv[idx++];
            var descc = csv[idx++];
            var denom = csv[idx++];
            var duree = csv.I32(idx++);
            var valid = csv.I32(idx++);
            currentValid = valid;
            var status = csv.I32(idx++);
            idx += 4;
            var qty = csv.I32(idx++);
            var tt = csv.I32(idx++);
            var t1 = csv[idx++];
            var t2 = csv[idx++];
            var t3 = csv[idx++];
            var t4 = csv[idx++];
            var t5 = csv[idx++];

            if (status >= 100 && status < 200)
                t1 = "*" + t1;

            labels["idc"].Text = t1;
            labels["idn"].Text = t2;
            labels["desc"].Text = t3;
            labels["descc"].Text = t4;
            labels["denom"].Text = t5;
            fields["duree"].Value = duree;
            fields["qty"].Value = qty;
            labels["pers"].Text = name;

            fields["text"].Input.Value = b.return_s5;

            selectOrdre = oid;




            if ((valid == 0 || valid == 4 || valid == 2) && status < 100)
            {
                bDel.Visible = Bridge.Html5.Visibility.Inherit;
            }

            var vok = (valid == 0 || valid == 4 || valid == 2) && status < 100;

            bModify.Visible = vok ? Bridge.Html5.Visibility.Inherit : Bridge.Html5.Visibility.Hidden;
            bSave.Visible = vok ? Bridge.Html5.Visibility.Inherit : Bridge.Html5.Visibility.Hidden;
            bCancel.Visible = vok ? Bridge.Html5.Visibility.Inherit : Bridge.Html5.Visibility.Hidden;


            fields["duree"].Visible = Bridge.Html5.Visibility.Hidden;
            fields["qty"].Visible = Bridge.Html5.Visibility.Hidden;
            fields["timeDe"].Visible = Bridge.Html5.Visibility.Hidden;
            fields["timeA"].Visible = Bridge.Html5.Visibility.Hidden;

            bCpl.Visible = fields["duree"].Visible;
            var funcMod = new Api.ApiFunction(Api.ApiFuncType.GetActivityOrdresModel);
            funcMod.iparam1 = oid;
            var dataMod = new DataApi<Api.ApiReturn>(Workflow.api, funcMod);
            dataMod.OnSuccess += (a, b2) =>
            {
                var m = b2.return_i1;
                currentModel = m;

                if (m == 0 || m == 1 || m == 3) fields["duree"].Visible = Bridge.Html5.Visibility.Inherit;
                if (m == 0 || m == 2 || m == 3) fields["qty"].Visible = Bridge.Html5.Visibility.Inherit;

                //bCpl.Visible = fields["duree"].Visible;

                Console.WriteLine("model: " + m + " " + currentModel);
            };

            if (b.return_s2.Length > 10)
            {
                TTs.Clear();
                combos["tt"].Clear();
                combos["tt"].Add(0, "----");
                var csvtt = new CSVTool(b.return_s2)[0];
                for (var i = 0; i < csvtt.Count; i += 2)
                {
                    var ttid = csvtt.I32(i);
                    var stt = csvtt[i + 1];
                    TTs[ttid] = stt;

                    if (Workflow.mode_prometerre)
                    {
                        if (schemas.Count > 0 && schemas.ContainsKey(ofid))
                        {
                            var proj = schemas[ofid];
                            if (proj.tt.Contains(ttid))
                                combos["tt"].Add(ttid, stt);
                        }
                    }
                    else
                    {
                        combos["tt"].Add(ttid, stt);
                    }
                }
            }

            combos["tt"].SelectById(tt);
            combos["tt"].Visible = combos["tt"].Count <= 1 ? Bridge.Html5.Visibility.Hidden : Bridge.Html5.Visibility.Inherit;

            if (bSave.Enabled) combos["tt"].List.Disabled = false;
            if(fromOther)
            {
                bModify.Enabled = false;
                bModify.Visible = Bridge.Html5.Visibility.Hidden;
                bDel.Visible = Bridge.Html5.Visibility.Hidden;
                bSave.Visible = Bridge.Html5.Visibility.Inherit;
                bCancel.Visible = Bridge.Html5.Visibility.Inherit;
            }
        }

        void LoadQtyDetail(int pid, DateTime date, int rowidx, int block)
        {
            currentDate = date;
            currentPid = pid;
            currentRowIdx = rowidx;
            currentBlock = block;

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityDetail2);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(date);
            func.iparam3 = rowidx;
            func.iparam4 = block;

            combos["tt"].List.Disabled = true;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                LoadQtyDetail(pid, date, rowidx, block, b);
            };
        }


        void Clear(bool clearPers = true)
        {
            //Console.WriteLine(createNew);

            if (clearPers)
            {
                labels["pers"].Text = "";
                labels["date"].Text = "";
            }

            labels["idc"].Text = "--";
            labels["idn"].Text = "--";
            labels["desc"].Text = "--";
            labels["descc"].Text = "--";
            labels["denom"].Text = "--";
            fields["duree"].Value = 0;
            fields["qty"].Value = 0;
            fields["text"].Input.Value = "";
            fields["timeDe"].Value = 0;
            fields["timeA"].Value = 0;
            combos["tt"].SelectByIdx(0);

            bDel.Visible = Bridge.Html5.Visibility.Hidden;
        }

        
        void OpenListOF()
        {
            typeOrdreWanted = 0;
            selectOF = -1;
            selectOrdre = -1;

            fields["filtreO"].Value = "";
            fields["filtre"].Value = "";

            for (var i = 0; i < 3; i++)
            {
                if (i < filtreTxt.Count)
                {
                    bFiltresOF[i].Text = filtreTxt[i];
                    bFiltresOF[i].Visible = Bridge.Html5.Visibility.Inherit;
                }
                else
                    bFiltresOF[i].Visible = Bridge.Html5.Visibility.Hidden;
            }

            pListOrdre.Visible = Bridge.Html5.Visibility.Hidden;
            pListOF.Visible = Bridge.Html5.Visibility.Inherit;
            GetOFs();
        }
        void GetOFs()
        {
            lstOF.ClearChildren();

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityOFs2);
            func.iparam1 = Workflow.pid;
            func.param1 = fields["filtre"].Input.Value;

            var d1 = currentDate;
            var d2 = currentDate;
            if(createNew)
            {
                d1 = this.d1;
                d2 = d1.AddDays(6);
            }

            func.iparam4 = D2000.T(d1);
            func.iparam5 = D2000.T(d2);


            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
___AddOF_Delegate_0 AddOF = null;
AddOF = (CSVTool.CSVObject csv, ref int y, List<Panel> lst) =>
{
    for (var i = 0; i < csv.Count; i += 12)
    {
        var id = csv.I32(i);
        var oid = csv.I32(i + 1);
        var ofname = csv[i + 2];
        var desc = csv[i + 3];
        var descc = csv[i + 4];
        var oname = csv[i + 5];
        var ocount = csv.I32(i + 6);
        var t1 = csv[i + 7];
        var t2 = csv[i + 8];
        var t3 = csv[i + 9];
        var t4 = csv[i + 10];
        var t5 = csv[i + 11];
        var p = new Panel(0, y, pListOF.Width, 64, Color.White, lstOF, lst);
        p.id1 = id;
        p.id2 = ocount;
        p.id3 = oid;
        p.Div.OnDblClick += e =>
        {
            SelectOF(p.id1, p.id2, p.id3);
        }

        ;
        var l = new Label(t1, 4, 4, 200, 16, "left", p);
        l = new Label(t2, 4, 24, 200, 16, "left", p);
        l = new Label(t3, 250, 4, 200, 16, "left", p);
        l = new Label(t4, 250, 24, 200, 16, "left", p);
        y += 65;
    }
}

;
                
                if (b.return_s1.Length > 0)
                {
                    var lst = new List<Panel>();
                    var csv = new CSVTool(b.return_s1)[0];
                    var csv10 = new CSVTool(b.return_s2)[0];
                    var csv10OF = new CSVTool(b.return_s3)[0];
                    var y = 0;

                    var addOFTitle = false;

                    if (b.return_s3.Length > 0 && Workflow.mode_prometerre)
                    {
                        var l = new Label(Res.Get(12112, "10 dernièrs projets"), 4, 8, lstOF.Width - 8, 16, "center", lstOF);
                        y = 32;
                        Console.WriteLine("last 10 of :" + csv10OF.Count);
                        AddOF(csv10OF, ref y, lst);

                        addOFTitle = true;
                    }
                    else if (b.return_s2.Length > 0 && csv10.Count > 0 && func.param1.Length == 0 && !Workflow.mode_prometerre)
                    {
                        var l = new Label(Res.Get(12110, "10 dernières activités"), 4, 8, lstOF.Width - 8, 16, "center", lstOF);
                        y = 32;

                        for (var i = 0; i < csv10.Count; i += 17)
                        {
                            string[] td = new string[5];

                            var id = csv10.I32(i);
                            var idc = csv10[i + 1];
                            var idn = csv10[i + 2];
                            var desc = csv10[i + 3];
                            var descc = csv10[i + 4];
                            var oname = csv10[i + 5];
                            var ofid = csv10.I32(i + 6);
                            var t1 = csv10[i + 7];
                            var t2 = csv10[i + 8];
                            var t3 = csv10[i + 9];
                            var t4 = csv10[i + 10];
                            var t5 = csv10[i + 11];
                            td[0] = csv10[i + 12];
                            td[1] = csv10[i + 13];
                            td[2] = csv10[i + 14];
                            td[3] = csv10[i + 15];
                            td[4] = csv10[i + 16];


                            var p = new Panel(0, y, pListOF.Width, 64, Color.White, lstOF, lst);
                            p.id1 = id;
                            p.id2 = 1;
                            var currentI = i;
                            p.Div.OnDblClick += e =>
                            {
                                selectOF = ofid;
                                SelectOrdre(p.id1, td);
                            };

                            l = new Label(t1, 4, 4, 200, 16, "left", p);
                            l = new Label(t2, 4, 24, 200, 16, "left", p);
                            l = new Label(t3, 250, 4, 300, 16, "left", p);
                            l = new Label(t4, 4, 44, 500, 16, "left", p);

                            y += 65;
                        }

                        addOFTitle = true;
                        //l = new Label(Res.Get(12111, "OF/Affaire/Projet"), 4, y + 8, lstOF.Width - 8, 16, "center", lstOF);
                        //y += 32;
                    }

                    if (addOFTitle)
                    {
                        var l = new Label(Res.Get(12111, "OF/Affaire/Projet"), 4, y + 8, lstOF.Width - 8, 16, "center", lstOF);
                        y += 32;
                    }
                    AddOF(csv, ref y, lst);
                }

                

            };
        }
        void SelectOF(int id, int firstOrdre, int ordreCount)
        {
            pListOF.Visible = Bridge.Html5.Visibility.Hidden;
            
            selectOF = id;

            OpenListOrdre(selectOF, 0);
            //if (ordreCount > 1) OpenListOrdre(selectOF, 0);
            //else selectOrdre = firstOrdre;

            
        }


        void OpenListOrdre(int ofid, int ordreType)
        {
            typeOrdreWanted = ordreType;

            pListOF.Visible = Bridge.Html5.Visibility.Hidden;
            pListOrdre.Visible = Bridge.Html5.Visibility.Inherit;

            GetOrdres(ofid, ordreType);
            pListOrdre.Visible = Bridge.Html5.Visibility.Inherit;

            for (var i = 0; i < 3; i++)
            {
                if (i < filtreTxt.Count)
                {
                    bFiltresOrdre[i].Text = filtreTxt[i];
                    bFiltresOrdre[i].Visible = Bridge.Html5.Visibility.Inherit;
                }
                else
                    bFiltresOrdre[i].Visible = Bridge.Html5.Visibility.Hidden;
            }
        }
        void GetOrdres(int ofid, int ordreType = 0, bool noDirectSelection = false)
        {
            lstOrdre.ClearChildren();

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityOrdres2);
            func.iparam1 = Workflow.pid;
            func.param1 = fields["filtreO"].Input.Value;
            func.iparam2 = ofid;
            func.iparam3 = ordreType;

            var d1 = currentDate;
            var d2 = currentDate;
            if (createNew)
            {
                d1 = this.d1;
                d2 = d1.AddDays(6);
            }

            func.iparam4 = D2000.T(d1);
            func.iparam5 = D2000.T(d2);

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {

                if (b.return_s1.Length > 0)
                {
                    var csv = new CSVTool(b.return_s1)[0];
                    var lst = new List<Panel>();

                    var y = 0;
                    var count = 0;
                    Panel lastP = null;
                    int lastI = 0;

                    string[] lasttxts = new string[5];

                    var count2 = csv.Count / 16;

                    for (var i = 0; i < csv.Count; i += 16)
                    {
                        var id = csv.I32(i);
                        var idc = csv[i + 1];
                        var idn = csv[i + 2];
                        var desc = csv[i + 3];
                        var descc = csv[i + 4];
                        var oname = csv[i + 5];

                        var t1 = csv[i + 6];
                        var t2 = csv[i + 7];
                        var t3 = csv[i + 8];
                        var t4 = csv[i + 09];
                        var t5 = csv[i + 10];

                        string[] txts = new string[5];
                        lasttxts[0] = txts[0] = csv[i + 11];
                        lasttxts[1] = txts[1] = csv[i + 12];
                        lasttxts[2] = txts[2] = csv[i + 13];
                        lasttxts[3] = txts[3] = csv[i + 14];
                        lasttxts[4] = txts[4] = csv[i + 15];

                        var p = new Panel(0, y, pListOF.Width, 64, Color.White, lstOrdre, lst);
                        lastP = p;
                        p.id1 = id;
                        p.id3 = count2;
                        var currentI = i;
                        lastI = currentI;
                        p.Div.OnDblClick += e => { SelectOrdre(p.id1, txts, count2); };

                        var l = new Label(t1, 4, 4, 200, 16, "left", p);
                        l = new Label(t2, 4, 24, 200, 16, "left", p);
                        l = new Label(t3, 250, 4, 300, 16, "left", p);
                        l = new Label(t4, 4, 44, 500, 16, "left", p);

                        count++;
                        y += 65;
                    }

                    if(!noDirectSelection && count <= 1)
                        SelectOrdre(lastP.id1, lasttxts, 0);
                    
                }

            };
        }

        void SelectOrdre(int id, string[] txt, int count = 0)
        {
            pListOF.Visible = Bridge.Html5.Visibility.Hidden;
            pListOrdre.Visible = Bridge.Html5.Visibility.Hidden;

            labels["idc"].Text = txt[0];// csv[idx + 1];
            labels["idn"].Text = txt[1];//csv[idx + 2];
            labels["desc"].Text = txt[2];//csv[idx + 3];
            labels["descc"].Text = txt[3];//csv[idx + 4];
            labels["denom"].Text = txt[4];//csv[idx + 5];

            selectOrdre = id;


            fields["duree"].Visible = Bridge.Html5.Visibility.Hidden;
            fields["qty"].Visible = Bridge.Html5.Visibility.Hidden;
            fields["timeDe"].Visible = Bridge.Html5.Visibility.Hidden;
            fields["timeA"].Visible = Bridge.Html5.Visibility.Hidden;

            bCpl.Visible = fields["duree"].Visible;
            var funcMod = new Api.ApiFunction(Api.ApiFuncType.GetActivityOrdresModel);
            funcMod.iparam1 = id;
            var dataMod = new DataApi<Api.ApiReturn>(Workflow.api, funcMod);
            dataMod.OnSuccess += (a, b) =>
            {
                var m = b.return_i1;
                currentModel = m;

                if (m == 0 || m == 1 || m == 3) fields["duree"].Visible = Bridge.Html5.Visibility.Inherit;
                if(m == 0 || m == 2 || m == 3) fields["qty"].Visible = Bridge.Html5.Visibility.Inherit;

                bCpl.Visible = fields["duree"].Visible;

                Console.WriteLine("model: " + m);
            };
            

            var funcTT = new Api.ApiFunction(Api.ApiFuncType.GetActivityTTs2);
            var dataTT = new DataApi<Api.ApiReturn>(Workflow.api, funcTT);
            dataTT.OnSuccess += (a, b) =>
            {
                var csvtt = new CSVTool(b.return_s1)[0];

                TTs.Clear();
                combos["tt"].Clear();
                combos["tt"].Add(0, "----");
                
                for (var i = 0; i < csvtt.Count; i += 3)
                {                    
                    var ttid = csvtt.I32(i);
                    var stt = csvtt[i + 1];
                    var cc = csvtt[i + 2];
int icc;
                    int.TryParse(cc, out icc);
                                                                            
                    TTs[ttid] = stt;

                    if (Workflow.mode_prometerre)
                    {
                        if (schemas.Count > 0 && schemas.ContainsKey(selectOF))
                        {
                            var proj = schemas[selectOF];
                            if (proj.tt.Contains(ttid))
                                combos["tt"].Add(ttid, stt);
                        }
                    }
                    else
                    {                                
                        var ci = combos["tt"].Add(ttid, stt);
                        ci.SetAttribute("id2", icc.ToString());
                    }
                }

                combos["tt"].Visible = combos["tt"].Count > 0 ? Bridge.Html5.Visibility.Inherit : Bridge.Html5.Visibility.Hidden;

                Console.WriteLine("count: " + count);
                bSelOrdre.Visible = count > 1 ? Bridge.Html5.Visibility.Inherit : Bridge.Html5.Visibility.Hidden;
            };
                                                                    


            if (createNew)
            {
                var func = new Api.ApiFunction(Api.ApiFuncType.CheckOrdreValidity);
                func.iparam1 = Workflow.pid;
                func.iparam2 = id;
                var csv2 = new CSVTool();
                for (var i = 0; i < 7; i++)
                    csv2.A(d1.AddDays(i));
                func.param1 = csv2.ToString();

                var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
                data.OnSuccess += (a, b) =>
                {
                    var csv3 = new CSVTool(b.return_s1)[0];

                    bool full = true;

                    for (var i = 0; i < csv3.Count; i += 2)
                    {
                        var date = csv3.Date(i);
                        var r = csv3.Bool(i + 1);
                        bDays[1 + i / 2].Enabled = r;

                        if (!bDays[1 + i / 2].Enabled)
                        {
                            bDays[1 + i / 2].id2 = 0;
                            bDays[1 + i / 2].SelectMe(false);
                        }

                        if (!r) full = false;
                    }

                    bDays[0].Enabled = full;

                };
            }

        }


        void EditSetState(bool v)
        {
            Console.WriteLine("editsetstate: " + bSave.Visible);

            bClose.Enabled = !v;
            b1.Enabled = !v;
            b2.Enabled = !v;
            b3.Enabled = !v;

            bNew.Enabled = !v;
            bModify.Enabled = !v;
            bSave.Enabled = v;
            bSave2.Enabled = v;
            bCancel.Enabled = v;

            if (!createNew)
                bSave2.Enabled = false;

            bDel.Enabled = !v;

            bCpl.Visible = v ? Bridge.Html5.Visibility.Inherit : Bridge.Html5.Visibility.Hidden;
            bCpl.SelectMe(false);
            bCpl.id1 = 0;

            if (currentPid == -1 || currentRowIdx == -1) bModify.Enabled = false;

            bSelOF.Visible = v ? Bridge.Html5.Visibility.Inherit : Bridge.Html5.Visibility.Hidden;
            bSelOrdre.Visible = Bridge.Html5.Visibility.Hidden;

            if (!Workflow.akt_hidefixebutton)
                bSelOrdreFixe.Visible = bSelOF.Visible;

            fields["duree"].Input.ReadOnly = !v;
            fields["qty"].Input.ReadOnly = !v;
            fields["timeDe"].Input.ReadOnly = !v;
            fields["timeA"].Input.ReadOnly = !v;
            fields["text"].Input.ReadOnly = !v;
            combos["tt"].List.Disabled = !v;

            for (var i = 0; i < 8; i++)
            {
                bDays[i].Visible = v && createNew ? Bridge.Html5.Visibility.Inherit : Bridge.Html5.Visibility.Hidden;

                if (i > 0)
                {
                    var date = d1.AddDays(i - 1);
                    bDays[i].Text = string.Format("{0}<br/>{1} {2}",Res.days[i - 1],date.Day,Res.months[date.Month - 1]);
                    bDays[i].id2 = 0;
                    bDays[i].SelectMe(false);
                }
            }



        }

        bool Save(bool del = false, bool keepSel = true)
        {
            var daySelected = 0;
            for (var i = 1; i < 8; i++)
            {
                if (bDays[i].id2 == 0) continue;
                var pow = (int)Math.Pow(2, i - 1);
                daySelected += pow;
            }

            var qty = fields["duree"].ValueI;

            var modeDEA = fields["timeDe"].Visible == Bridge.Html5.Visibility.Inherit ? 1 : 0;
            var timeDe = 0;
            var timeA = 0;

            if (modeDEA > 0)
            {
                timeDe = fields["timeDe"].ValueI;
                timeA = fields["timeA"].ValueI;
            }

            if (!del)
            {
                if (selectOrdre <= 0)
                {
                    Window.Alert(Res.Get(12370, "Veuillez choisir une activité!"));
                    return false;
                }
                else if (createNew && daySelected == 0)
                {
                    Window.Alert(Res.Get(12371, "Veuillez choisir un ou plusieurs jours!"));
                    return false;
                }
                else if (Workflow.mode_prometerre && combos["tt"].SelectedId == 0 && combos["tt"].Visible == Bridge.Html5.Visibility.Inherit)
                {
                    Window.Alert(Res.Get(12372, "Veuillez choisir un type de travail"));
                    return false;
                }
                else if (Workflow.qty_no_zero && qty == 0 && bCpl.id1 == 0 && modeDEA == 0)
                {
                    Window.Alert(Res.Get(12373, "Veuillez choisir une durée différente de zéro"));
                    return false;
                }
                else if (qty >= 24 * 60 && Workflow.qty_less_than_24h && modeDEA == 0)
                {
                    Window.Alert(Res.Get(12374, "Veuillez choisir une durée plus petite que 24h"));
                    return false;
                }
                else if(modeDEA > 0 && timeDe >= timeA)
                {
                    Window.Alert("Veuillez choisir une plage de temps correcte");
                    return false;
                }
            }
                                               

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivitySave2);
            func.iparam1 = createNew ? Workflow.pid : currentPid;
            func.iparam2 = createNew ? D2000.T(d1) : D2000.T(currentDate);
            func.iparam3 = currentRowIdx;
            func.iparam4 = currentBlock;
            func.iparam5 = createNew ? 1 : 0;
            func.iparam6 = bCpl.id1;       // complément
            func.iparam8 = del ? 1 : 0;
            func.iparam7 = daySelected;
            func.iparam9 = -1;
            func.iparam10 = modeDEA;

            if (currentValid == 2) func.iparam9 = 0;

            var csv = new CSVTool();
            csv.A(fields["duree"].ValueI);
            csv.A(fields["qty"].ValueI);
            csv.A(combos["tt"].SelectedId);
            csv.A(selectOrdre);
            csv.A(selectOF);
            if (func.iparam10 > 0)
            {
                csv.A(timeDe);
                csv.A(timeA);
            }

            func.param1 = csv.ToString();
            func.param2 = fields["text"].Input.Value;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                currentPid = b.return_i1;
                currentDate = new DateTime(2000, 1, 1).AddDays(b.return_i2).Date;
                currentRowIdx = b.return_i3;
                currentBlock = b.return_i4;

                if (mode == 1)
                {
                    this.Visible = Bridge.Html5.Visibility.Hidden;
                    OnSave!=null?global::Bridge.Script.FromLambda(()=>OnSave.Invoke()):null;
                }
                else
                    LoadWeek(keepSel, keepSel);

                //LoadQtyDetail(currentPid, currentDate, currentRowIdx, currentBlock);
            };

            return true;
        }


        void ChangeValidation(Panel p, bool oneQty)
        {
            var v = p.id6;
            if (v == 0) v = 4;
            else if (v == 4) v = 0;
            
            p.id6 = v;

            SaveValidation(oneQty, p);
        }


        bool CheckValidation()
        {
            if (Workflow.mode_prometerre) return true;
            var ok = true;
            foreach(var p in lstPDays)
            {
                if(p.id7 < 0)
                {
                    ok = false;
                    break;
                }
            }

            return ok;
        }

        void SaveValidation(bool oneQty, Panel p = null)
        {
            var r = CheckValidation() || oneQty;

            if(!r)
            {
                Window.Alert(Res.Get(12375, "Veuillez compléter toutes vos journées!"));
                return;
            }

            var func = new Api.ApiFunction(Api.ApiFuncType.SaveActivityValidation2);
            func.iparam1 = Workflow.pid;
            var csv = new CSVTool();

            if (p == null)
            {
                foreach (var p2 in lstPQties)
                {
                    csv.A(p2.id5);                   // pid
                    csv.A(d1.AddDays(p2.id2));       // date
                    csv.A(p2.id4);                   // block
                    csv.A(p2.id1);                   // row
                    csv.A(p2.id6);                   // validation
                }
            }
            else
            {
                csv.A(p.id5);                   // pid
                csv.A(d1.AddDays(p.id2));       // date
                csv.A(p.id4);                   // block
                csv.A(p.id1);                   // row
                csv.A(p.id6);                   // validation
            }
            func.param1 = csv.ToString();

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                LoadWeek(false);
            };

        }
private delegate void ___AddOF_Delegate_0(CSVTool.CSVObject csv, ref int y, List<Panel> lst);
    }
}
                                   