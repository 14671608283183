using System;
using Bridge.Html5;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class pDay
    {

        public delegate void DOnSelected(pDay pDay);

        public event DOnSelected OnSelected = null;
        
        private readonly Panel _pParent = null;
        private readonly Panel _pBack = null;
        private readonly Panel _pSunday = null;
        private readonly DateTime _date = DateTime.MinValue;
public DateTime Date
{
    get
    {
        return _date;
    }
}        private readonly int _did = 0;
        private readonly MensualGrid _mg = null;

        private bool _enabled = true;

        public bool Enabled
{
    get
    {
        return _enabled;
    }

    
    set
    {
        _enabled = value;
    }
}        
        private DayCol[] _columns = null;

        private bool statusNOk = false;
        public bool StatusNotOK
{
    get
    {
        return statusNOk;
    }

    
    set
    {
        statusNOk = value;
        SetColor(false);
    }
}
        private bool _selected = false;
        public bool Selected 
{
    get
    {
        return _selected;
    }

    
    set
    {
        _selected = value;
        SetColor(false);
    }
}public DayCol this[int x]
{
    get
    {
        return _columns[x];
    }
}public int Count
{
    get
    {
        return _columns.Length;
    }
}        
        public pDay(MensualGrid mg, Panel pParent, DateTime date)
        {
            _mg = mg;
            _pParent = pParent;
            _date = date;
            
            _pBack = new Panel();
            _pBack.BackgroundColor =  ResT.GetColor("panel.back");// new Color(255, 255, 255);
            _pParent.AddChild(_pBack);


            if (date.DayOfWeek == DayOfWeek.Sunday)
            {
                _pSunday = new Panel();
                _pSunday.Place(0, 0, 100, 2, _pBack);
                _pSunday.BackgroundColor = new Color(100, 200, 100);
                _pSunday.ZIndex = "99";
            }

            _did = (int) date.DayOfWeek;
            if (_did == 0) _did = 7;
            _did--;

            _pBack.Div.OnMouseEnter += OnMouseEnter;
            _pBack.Div.OnMouseLeave += OnMouseLeave;
            _pBack.Div.OnMouseDown += OnMouseDown;
            _pBack.Div.OnMouseMove += OnMouseMove;

            
        }

        public void Remove()
        {
            _pParent.RmvChild(_pBack);
        }
        
        private void OnMouseMove(MouseEvent<HTMLDivElement> arg)
        {
            if (!_enabled) return;
            if (arg.Buttons == 1)
            {
                Select();
            }
        }

        private void OnMouseDown(MouseEvent<HTMLDivElement> arg)
        {
            if (!_enabled) return;
            
            if (!arg.CtrlKey)
                _mg.ResetDaySelected();
            
            Select();
        }

        public void Select()
        {
            _selected = true;
            SetColor(true);
            
            OnSelected!=null?global::Bridge.Script.FromLambda(()=>OnSelected.Invoke(this)):null;
        }
        
        private void OnMouseLeave(MouseEvent<HTMLDivElement> arg)
        {
            if (!_enabled) return;
            SetColor(false);
        }

        private void OnMouseEnter(MouseEvent<HTMLDivElement> arg)
        {
            if (!_enabled) return;
            SetColor(true);
        }

        public void SetSize(int w, int h)
        {
            if (w == 0) w = _pBack.Width;
            if (h == 0) h = _pBack.Height;
            
            _pBack.Size = new Size(w, h);
            if (_pSunday != null)
            {
                _pSunday.Width = w;
                _pSunday.Top = h - 2;
            }
            
            if(_columns != null)
                for (var i = 0; i < _columns.Length; i++)
                {
                    _columns[i].SetHeight(h);
                }
        }

        public void SetPos(int x, int y)
        {
            _pBack.Position = new Point(x, y);
        }

        private void SetColor(bool focus)
        {
            if (focus)
            {
                _pBack.BackgroundColor = ResT.GetColor("grid.over");// Color.Over;
            }
            else
            {
                foreach (var col in _columns)
                    col.Color = ResT.GetColor("label.text");// new Color(50, 50, 128);


                if (!_selected) _pBack.BackgroundColor = ResT.GetColor("grid.back");// new Color(255, 255, 255);
                else
                {
                    if (statusNOk)
                    {
                        _pBack.BackgroundColor = new Color(230);
                    }
                    else
                    {
                        _pBack.BackgroundColor = ResT.GetColor("grid.selected");// Color.Selected;
                        foreach (var col in _columns)
                            if (col.InvColor)
                                col.Color = ResT.GetColor("label.text");//  Color.White;
                    }
                }

            }
        }

        public void SetColumnCount(int count)
        {
            _columns = new DayCol[count];
            
            for (var i = 0; i < _columns.Length; i++)
            {
                _columns[i] = new DayCol(this, _pBack, i * 60);
                _columns[i].SetWidth(60);
                _columns[i].SetHeight(_pBack.Height);
            }
            
            SetSize(0, 0);
        }
        
        public void Unselect()
        {
            _selected = false;
            SetColor(false);
        }

    }
}