using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;

namespace Workflow_bridge.TodoList
{
    class TodoRow : Panel
    {

        Panel[] panels = new Panel[10];
        Label[] labels = new Label[2];
        Label lNom, lDate;

        public int PId { get; private set; }
        public int WFId { get; private set; }
        public DateTime Date { get; private set; }

        public bool[] checks = new bool[10];
        public string name = "";

        

        public TodoRow(Control parent, int pid, string name, int wfid, DateTime date, bool[] checks) : base(parent)
        {

            this.PId = pid;
            this.Date = date;
            this.WFId = wfid;
            for (var i = 0; i < checks.Length; i++)
                this.checks[i] = checks[i];
            this.name = name;

            int[] sz = { 193, 193, 31, 31, 31, 31, 31, 31, 31, 31 };

            Height = 20;
            BackgroundColor = new Color(240);

            var x = 0;
            for(var i = 0; i < sz.Length; i++)
            {
                var p = panels[i] = new Panel(this);
                p.Place(x, 0, sz[i], Height);
                p.BackgroundColor = Color.White;
                p.Div.OnMouseEnter = OnMouseEnter;
                p.Div.OnMouseLeave = OnMouseLeave;
                p.Div.OnDblClick = OnDblClick;

                if (i < 2)
                {
                    var l = labels[i] = new Label("", 4, 2, p.Width - 8, 16, "left", p);
                    if (i == 0) l.Text = name;
                    else if (i == 1) l.Text = Fmt.DateComplete(date);
                }


                if(i > 1)
                {
                    if (checks[i - 2])
                        p.BackgroundColor = Color.Blue;
                }

                x += sz[i];
                x++;
            }



        }
        
        void OnMouseEnter(Bridge.Html5.MouseEvent<Bridge.Html5.HTMLDivElement> arg)
        {
            for(var i = 0; i < 2; i++)
            {
                panels[i].BackgroundColor = Color.Selected;
                if (labels[i] != null)
                    labels[i].Color = Color.White;
            }
        }

        void OnMouseLeave(Bridge.Html5.MouseEvent<Bridge.Html5.HTMLDivElement> arg)
        {
            for (var i = 0; i < 2; i++)
            {
                panels[i].BackgroundColor = Color.White;
                if (labels[i] != null)
                    labels[i].Color = Color.LabelTxt;
            }
        }

        void OnDblClick(Bridge.Html5.MouseEvent<Bridge.Html5.HTMLDivElement> arg)
        {
            var s = WFId.ToString().PadLeft(5, '0') + PId.ToString().PadLeft(5, '0');
            s += Date.Year.ToString();
            s += Date.Month.ToString().PadLeft(2, '0');
            s += Date.Day.ToString().PadLeft(2, '0');

            Bridge.Html5.Window.LocalStorage.SetItem("tododblclick", s);
            CurrentDoc.Cookie = "todo=" + s;
            var s2 = Bridge.Html5.Window.LocalStorage.GetItem("tododblclick");
        }

    }
}
