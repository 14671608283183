using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using System.Linq;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class GridWkData : Panel
    {
        public class pRow
        {
            Panel[] panels = new Panel[7];
            Label[] labels = new Label[7];
            Panel parent = null;

            public int pid = 0;
            public int status = 0;
            public int ordre = 0;
            public int machine = 0;
            public int cf = 0;
            public int motifRebus = 0;
            public int tt = 0;
            public int qtyA = 0, qtyB = 0;
            public DateTime d1, d2, dSave;
            public int duree = 0;
            public int blockIdx = 0, idx = 0;
            public string ordreDesc = "", ordreDenom = "", ordreIDSaisie = "";
            public int ano = 0;
public int Ordre
{
    get
    {
        return ordre;
    }
}
            public bool Over { get; set; }
            public bool Selected { get; set; }

            public pRow() { }

            public pRow(Panel parent, DateTime d, int pid, int rid)
            {
                this.parent = parent;

                int[] sz = { 32, 100, 250, 60, 60, 60, 250 };

                var x = 0;
                for (var i = 0; i < panels.Length; i++)
                {
                    var p = panels[i] = new Panel(x, rid * 24, sz[i] - 1, 23, Color.White, parent);
                    p.SetScrolling(Overflow.Hidden, Overflow.Hidden);
                    var l = labels[i] = new Label("----", 4, 4, p.Width - 8, 16, "left", p);
                    l.FontSize = 9;
                    x += sz[i];
                }

                labels[0].Align = "right";
                labels[3].Align = "right";
                labels[4].Align = "right";
                labels[5].Align = "right";
            }

            public void SetY(int y)
            {
                foreach (var p in panels)
                    p.Top = y;
            }

            public void Delete()
            {
                for (var i = 0; i < panels.Length; i++)
                    parent.RmvChild(panels[i]);
            }

            public void SetData(CSVTool.CSVObject r, int idx)
            {
                status = r.I32(idx);
                ordre = r.I32(idx + 1);

                labels[0].Text = r[idx];
                labels[1].Text = r[idx + 5];
                labels[2].Text = r[idx + 7];

                ordreIDSaisie = r[idx + 5];
                ordreDesc = r[idx + 7];
                ordreDenom = r[idx + 6];

                this.d1 = r.Date(idx + 2);
                this.d2 = r.Date(idx + 3);
                this.dSave = r.Date(idx + 8);
                this.pid = r.I32(idx + 9);
                this.duree = r.I32(idx + 4);
                var sduree = Fmt.HQty(r.I32(idx + 4));

                
                this.qtyA = Ctrl.EntierMillieme(r[idx + 12]);
                this.qtyB = Ctrl.EntierMillieme(r[idx + 13]);

                blockIdx = r.I32(idx + 10);
                this.idx = r.I32(idx + 11);

                ano = r.I32(idx + 14);

                labels[3].Text = d1.Hour + ":" + d1.Minute.ToString().PadLeft(2, '0');
                labels[4].Text = d2.Hour + ":" + d2.Minute.ToString().PadLeft(2, '0');

                if (status % 100 == 7)
                {
                    labels[3].Text = "";
                    labels[4].Text = "";
                }
                
                labels[5].Text = sduree;

                labels[6].Text = r[idx + 6];

                if (status >= 100)
                    panels[0].BackgroundColor = new Color(100, 255, 100);
                else
                    panels[0].BackgroundColor = Color.White;

                if(ano > 0)
                    panels[0].BackgroundColor = new Color(255, 100, 100);
            }

            public void UpdateColor()
            {
                for (var i = 1; i < panels.Length; i++)
                {
                    var p = panels[i];
                    var l = labels[i];

                    if (Over)
                    {
                        p.BackgroundColor = Color.Mobatime2;
                        l.Color = Color.White;
                    }
                    else if (Selected)
                    {
                        p.BackgroundColor = Color.Mobatime1;
                        l.Color = Color.White;
                    }
                    else
                    {
                        p.BackgroundColor = Color.White;
                        l.Color = Color.LabelTxt;
                    }
                }

            }
        }

        public delegate void dOnSelect(GridWkData grid, pRow row);
        public event dOnSelect OnSelect = null;

        Panel pGrid = null, pHeader = null;
        List<GridWkData.pRow> rows = new List<GridWkData.pRow>();

        PanelWkData pWkData = null;

        public bool Enabled { get; set; }

        public GridWkData(Panel parent, PanelWkData pWkData)
        {
            this.pWkData = pWkData;
            CreateGrid(parent);
        }

        private void CreateGrid(Panel parent)
        {
            var p = new Panel(16, 48, 700 - 32, 350, Color.White, parent);

            Label l;

            l = new Label(Res.Get(9000, "Liste des quantités"), 0, 4, p.Width - 4, 16, "right", p);
            l.Color = new Color(200);

            var p0 = new Panel(16, 32, p.Width - 32, p.Height - 48, new Color(200), p);
            var ph = new Panel(1, 1, p0.Width - 20, 28, new Color(240), p0);
            var p2 = new Panel(1, ph.Bottom + 1, p0.Width - 2, p0.Height - ph.Bottom - 2, new Color(240), p0);

            pHeader = ph;
            pGrid = p2;

            string[] sheader = { "S", "ID", "Description", "Début", "Fin", "Durée", "Dénomination" };
            int[] szHeader = { 32, 100, 250, 60, 60, 60, 250 };

            for (var i = 0; i < sheader.Length; i++)
                sheader[i] = Res.Get(9001 + i, sheader[i]);


            ph.SetScrolling(Overflow.Hidden, Overflow.Hidden);
            p2.SetScrolling(Overflow.Auto, Overflow.Auto);
            p2.Div.OnScroll = ((e) =>
            {
                var offset = p2.Div.ScrollLeft;
                ph.Div.ScrollLeft = offset;
            });
            p2.Div.OnMouseMove = ((e) =>
            {
                var rc = pGrid.Div.GetBoundingClientRect();
                GridMouseMove(e.PageX + p2.Div.ScrollLeft - (int)rc.Left, e.PageY + p2.Div.ScrollTop - (int)rc.Top);
            });
            p2.Div.OnMouseDown = ((e) =>
            {
                var rc = pGrid.Div.GetBoundingClientRect();
                GridMouseDown(e.PageX + p2.Div.ScrollLeft - (int)rc.Left, e.PageY + p2.Div.ScrollTop - (int)rc.Top);
            });

            var x = 0;
            for (var i = 0; i < sheader.Length; i++)
            {
                var s = sheader[i];
                var sz = szHeader[i];
                var np = new Panel(x, 0, sz - 1, ph.Height, new Color(245), ph);
                l = new Label(s, 0, 6, np.Width, 16, "center", np);
                l.FontSize = 10;

                x += sz;
            }

            

        }

        public void ClearRow()
        {
            foreach (var r in rows)
                r.Delete();
            rows.Clear();
        }

        public GridWkData.pRow AddRow()
        {
            var row = new GridWkData.pRow(pGrid, DateTime.Now, 1, rows.Count);
            rows.Add(row);

            if (rows.Count * 24 >= pGrid.Height) pHeader.Width = pGrid.Width - 18;
            else pHeader.Width = pGrid.Width;

            return row;
        }

        public void LoadGrid(int pid, DateTime curDate, TotalOrdres to)
        {
            ClearRow();

            var func = new Api.ApiFunction(Api.ApiFuncType.job_get_qties);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(curDate);

            var dataGetO = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataGetO.OnSuccess += ((data, result) =>
            {
                var csv = new CSVTool();
                csv.LoadData(result.return_s1);
                var r = csv[0];



                var count = r.I32(0);

                var k = 1;
                for (var i = 0; i < count; i++)
                {
                    var row = AddRow();
                    row.SetData(r, k);

                    k += 15;
                }
                
                SortRow();

                pWkData.SetGtData(result.return_s2);


                var csvT = new CSVTool(result.return_s3)[0];
                to.Update(csvT);

            });
        }

        private void GridMouseMove(int x, int y)
        {
            if (!Enabled) return;
            var ry = y / 24;

            for (var i = 0; i < rows.Count; i++)
            {
                if (ry == i)
                {
                    rows[i].Over = true;
                }
                else
                {
                    rows[i].Over = false;
                }

                rows[i].UpdateColor();
            }
        }

        private void GridMouseDown(int x, int y)
        {
            if (!Enabled) return;

            var ry = y / 24;
            if (ry < 0 || ry >= rows.Count) return;

            for (var i = 0; i < rows.Count; i++)
            {
                if (ry == i)
                {
                    rows[i].Selected = true;
                    OnSelect!=null?global::Bridge.Script.FromLambda(()=>OnSelect.Invoke(this, rows[i])):null;
                }
                else
                {
                    rows[i].Selected = false;
                }

                rows[i].UpdateColor();
            }
        }

        private void SortRow()
        {
            var dico = new SortedList<DateTime, pRow>();

            var count = 0;
            foreach(var row in rows)
            {
                dico.Add(row.d1.AddMilliseconds(count), row);
                count++;
            }

            rows.Clear();
            count = 0;
            foreach(var row in dico.Values)
            {
                row.SetY(count * 24);
                count++;

                rows.Add(row);
            }

        }


    
private bool __Property__Initializer__Enabled=true;}
}
