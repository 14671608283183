using System;
using Bridge;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class DayCol
    {
        public enum DayColFormat
        {
            Label = 1,
            Color = 2,
        }
        
        private readonly pDay _pDay = null;
        private readonly Panel _pBack = null;
        private Label _label = null;
        private DayColFormat _format = DayColFormat.Label;
        private Panel pColor = null;
        private int _x = 0;

        public bool InvColor = true;

        public Color BackgroundColor
{
    get
    {
        return _pBack.BackgroundColor;
    }

    
    set
    {
        _pBack.BackgroundColor = value;
    }
    
}        
        public Color Color
{
    get
    {
        return _label.Color;
    }

    
    set
    {
        _label.Color = value;
    }
    
}        
        public string Text
{
    get
    {
        return _label.Text;
    }

    
    set
    {
        _label.Text = value;
    }
    
}
        public DayCol(pDay pDay, Panel pBack, int x)
        {
            _pDay = pDay;
            _pBack = pBack;
            _x = x;
            
            
            _label = new Label();
            _label.Align = "center";
            _label.Position = new Point(x, 0);
            _label.Height = 16;
            _pBack.AddChild(_label);
            _label.FontSize = 11;
            _label.Color = ResT.GetColor("label.text");// new Color(50, 50, 128);
        }

        public void SetFontSize(float sz)
        {
            if (sz != 0)
            {
                _label.FontSize = sz;
                //Console.WriteLine(sz);
            }
            else _label.FontSize = 11;
        }

        public void SetFormat(DayColFormat format)
        {
            _format = format;
        }

        public void SetX(int x)
        {
            _label.Left = x;
            if (pColor != null)
                pColor.Left = _label.Left;
        }
        public void SetWidth(int w)
        {
            _label.Width = w;
            if (pColor != null) pColor.Width = w;
        }
        public void SetHeight(int h)
        {
            _label.Top = _pBack.Height / 2 - _label.Height / 2;

            if (pColor != null)
            {
                pColor.Height = _pBack.Height;
            }
        }

        public void AddBackColor(Color color)
        {
            Panel p = new Panel();
            p.Place(_label.Left, 0, _label.Width, _pBack.Height, _pBack);
            p.BackgroundColor = color;
            p.Style.ZIndex = "0";
            _label.Style.ZIndex = "1";
            pColor = p;
        }
    }
}