using Bridge.Html5;
using Mobatime.JSControl;
using System;
using System.Collections.Generic;

namespace Workflow_bridge.MensualView
{
    public class pDeleg : Panel
    {
        Button bClose = null;
        MensualView view = null;

        Label lPunch = null;
        bool saved = false;
        bool tpoint = false;

        Button bSave, bCancel, bClear;

        ListBox lstPers;
        Combo cboRole;
        Label lPers, lPers2, lPers3, lPers4;
        Label lDate1, lDate2;
        Field fD1, fD2;

        Dictionary<int, Role> roles = new Dictionary<int, Role>();

        bool noButton = false;

        class Role
        {
            public int wfid = 0;
            public string wf_name = "";
            public int rid = 0;
            public int supp = 0;
            public string supp_name = "";
            public DateTime supp_d1 = DateTime.MinValue;
            public DateTime supp_d2 = DateTime.MinValue;
        }


        public pDeleg(MensualView view)
        {
            this.view = view;

            this.Size = new Size(750, 500);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = Color.Mobatime1;

            var lTitle = new Label(Res.Get(6000, "Délégation"), 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle);
            bClose.OnButtonClick += BClose_OnButtonClick;

            cboRole = new Combo();
            cboRole.Place(32, 48, 300, 32, this);
            cboRole.OnChange += CboRole_OnChange;

            lstPers = new ListBox();
            lstPers.Place(32, 96, 300, Height - 96 - 16, this);
            lstPers.OnChange += LstPers_OnChange;

            var l = new Label(Res.Get(6001, "Délégation actuelle"), lstPers.Right + 32, lstPers.Top, 250, 16, "left", this);
            l.Color = new Color(150);

            lPers = new Label(Res.Get(6003, "Suppléant") + ": ", lstPers.Right + 32, lstPers.Top + 32, 75, 16, "left", this);
            lPers2 = new Label("", lPers.Right + 4, lPers.Top, 200, 16, "left", this);

            l = new Label(Res.Get(6004, "Du") + ": ", lstPers.Right + 32, lPers.Top + 32, 75, 16, "right", this);
            lDate1 = new Label("", l.Right + 8, l.Top, 250, 16, "left", this);
            l = new Label(Res.Get(6005, "Au") + ": ", lstPers.Right + 32, lPers.Top + 64, 75, 16, "right", this);
            lDate2 = new Label("", l.Right + 8, l.Top, 250, 16, "left", this);

            l = new Label(Res.Get(6002, "Nouvelle délégation"), lstPers.Right + 32, lstPers.Top + 160, 250, 16, "left", this);
            l.Color = new Color(150);

            lPers3 = new Label(Res.Get(6003, "Suppléant") + ": ", lstPers.Right + 32, lstPers.Top + 192, 75, 16, "left", this);
            lPers4 = new Label("", lPers3.Right + 4, lPers3.Top, 200, 16, "left", this);

            l = new Label(Res.Get(6004, "Du") + ": ", lstPers.Right + 32, lPers3.Top + 32, 75, 16, "right", this);
            fD1 = new Field(l, 200, Field.FieldFormat.DateComplete);
            l = new Label(Res.Get(6005, "Au") + ": ", lstPers.Right + 32, lPers3.Top + 64, 75, 16, "right", this);
            fD2 = new Field(l, 200, Field.FieldFormat.DateComplete);

            //fD1.OnValidate += FD1_OnValidate;
            //fD2.OnValidate += FD1_OnValidate;

            bSave = new Button(Width - 16 - 48 - 16 - 48, Height - 64, 48, 48, "images/64ok.png", this);
            bCancel = new Button(Width - 16 - 48, Height - 64, 48, 48, "images/64annule.png", this);
            bClear = new Button(lstPers.Right + 32, lstPers.Bottom - 48, 48, 48, "images/poubelleb.png", this);

            bSave.OnButtonClick += BSave_OnButtonClick;
            bCancel.OnButtonClick += BCancel_OnButtonClick;
            bClear.OnButtonClick += BClear_OnButtonClick;

            SetState(false);

            LoadDeleg();
        }

        private void BClear_OnButtonClick(Button c, MouseEvent arg)
        {
            SetState(true);

            lPers4.Text = "";
            lPers4.id1 = 0;
            fD1.Value = DateTime.MinValue;
            fD2.Value = DateTime.MinValue;

        }

        private void FD1_OnValidate(Field field)
        {
            SetState(true);
        }

        private void BSave_OnButtonClick(Button c, MouseEvent arg)
        {
            var key = cboRole.SelectedId;
            var role = roles[key];

            role.supp = lPers4.id1;
            role.supp_name = lPers4.Text;
            role.supp_d1 = fD1.ValueDate;
            role.supp_d2 = fD2.ValueDate;

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.WFSetDeleg;
            func.iparam1 = role.wfid;
            func.iparam2 = role.rid;
            func.iparam3 = role.supp;
            func.iparam4 = D2000.T(role.supp_d1);
            func.iparam5 = D2000.T(role.supp_d2);

            var dataSave = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataSave.OnSuccess += DataSave_OnSuccess;
        }

        private void DataSave_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var id = cboRole.SelectedId;
            cboRole.SelectById(id, true);
            SetState(false);
        }

        private void BCancel_OnButtonClick(Button c, MouseEvent arg)
        {
            var id = cboRole.SelectedId;
            cboRole.SelectById(id, true);
            SetState(false);
        }

        private void LstPers_OnChange(Combo combo)
        {
            var id = combo.SelectedId;
            lPers4.Text = combo.Selected.Text;
            lPers4.id1 = id;

            SetState(true);

        }

        private void CboRole_OnChange(Combo combo)
        {
            var id = combo.SelectedId;

            if(roles.ContainsKey(id))
            {
                var r = roles[id];

                lPers2.Text = r.supp_name;
                lPers2.id1 = r.supp;

                lDate1.Text = Fmt.DateComplete(r.supp_d1);
                lDate2.Text = Fmt.DateComplete(r.supp_d2);

                lPers4.Text = r.supp_name;
                lPers4.id1 = r.supp;

                fD1.Value = r.supp_d1;
                fD2.Value = r.supp_d2;
            }

            //SetState(true);

        }

        void LoadDeleg()
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.WFGetDeleg;
            func.iparam1 = Workflow.pid;

            var dataDeleg = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataDeleg.OnSuccess += DataDeleg_OnSuccess;
        }

        private void DataDeleg_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {

            var csv1 = new CSVTool();
            var csv2 = new CSVTool();
            csv1.LoadData(result.return_s1);
            csv2.LoadData(result.return_s2);

            var c1 = csv1[0].strings.Count;
            var c2 = csv2[0].strings.Count;

            //Console.WriteLine(c1 + " " + c2);

            roles.Clear();

            for (var i = 0; i < c1; i += 8)
            {
                var wfid = csv1[0].I32(i + 0);
                var rid = csv1[0].I32(i + 1);
                var wfname = csv1[0].strings[i + 2];
                var sid = csv1[0].I32(i + 4);
                var sname = csv1[0].strings[i + 5];
                var d1 = csv1[0].Date(i + 6);
                var d2 = csv1[0].Date(i + 7);

                var r = new Role();
                r.wfid = wfid;
                r.rid = rid;
                r.wf_name = wfname;
                r.supp = sid;
                r.supp_name = sname;
                r.supp_d1 = d1;
                r.supp_d2 = d2;


                roles.Add(wfid * 10 + rid, r);
                cboRole.Add(wfid * 10 + rid, wfname);
            }
            cboRole.Sort();

            for (var i = 0; i < c2; i += 3)
            {
                var id = csv2[0].I32(i);
                var name1 = csv2[0].strings[i + 1];
                var name2 = csv2[0].strings[i + 2];
                lstPers.Add(id, name1 + "  " + name2);
            }

            noButton = true;
            cboRole.SelectByIdx(0, true);
            noButton = false;
        }

        public void Open()
        {
            view.OpenLock(this);
        }

        void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            view.CloseLock();
            //view.SetDate(99, true);
        }
        //void DataPunch_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        //{

        //    if (result.return_b1)
        //    {
        //        lPunch.Text = "Pointage Sauvé";
        //        saved = true;
        //        bPunch.Visible = Visibility.Hidden;
        //        view.DisableBPunch();
        //        Bridge.Html5.Window.SetTimeout(view.EnableBPunch, 60000);
        //    }
        //    else
        //    {
        //        lPunch.Text = "Erreur";
        //    }

        //    Bridge.Html5.Window.SetTimeout(ClosePPunch, 2000);
        //}

        void ClosePDeleg()
        {
            view.CloseLock();
        }


        void SetState(bool v)
        {
            if (noButton) return;

            bSave.Enabled = v;
            bCancel.Enabled = v;
            cboRole.List.Disabled = v;
        }
    }
}
