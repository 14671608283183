using System;
using System.Collections.Generic;
using System.Runtime.InteropServices;
using Bridge.Html5;
using Mobatime.JSControl;
using Window = Bridge.Html5.Window;

namespace Workflow_bridge.MensualView
{
    public class MensualGrid
    {

        public delegate void DOnSelectChange(MensualGrid grid);

        public event DOnSelectChange OnSelectChange = null;
        
        private MensualView _mv = null;
        private readonly Panel _pParent = null;
        
        private readonly Panel _pGrid = null;
        private readonly Panel _pGrid2 = null;
        private readonly Panel _pHeader = null;
        
        public int NbDay = 31;
        private DateTime _date = new DateTime(2018, 9, 1);
public DateTime Date
{
    get
    {
        return _date;
    }
}        private pDay[] _pDays = new pDay[31];
        private pDayHeader[] _pDaysHeader = new pDayHeader[31];
        private Label[] _lHeader = null;
        private Panel[] _separators = null;

        public readonly List<pDay> Selected = new List<pDay>();

        private bool _enabled = true;

        public bool Enabled
{
    get
    {
        return _enabled;
    }

    
    set
    {
        _enabled = value;
        foreach (var p in _pDays)
            if (p != null)
                p.Enabled = value;
    }
}
        private int[] _columnSz = null;
        private float[] _fontSz = null;
        private string[] _columnHeader = null;
        
        private int _rowHeight = 0;
        private int _totalWidth = 0;

        private Panel pMonth = null;
        private Label lMonth = null;
public int DataCount
{
    get
    {
        return _columnHeader.Length;
    }
}public DayCol this[int x, int y]
{
    get
    {
        return _pDays[y][x];
    }
}
        public MensualGrid(MensualView mv, Panel pParent)
        {
            _mv = mv;
            _pParent = pParent;
            
            _pGrid = new Panel();
            _pGrid.Position = new Point(52, 0);
            _pGrid.SetScrolling(Overflow.Scroll, Overflow.Hidden);
            _pParent.AddChild(_pGrid);

            _pGrid2 = new Panel();
            _pGrid2.BackgroundColor = ResT.GetColor("grid.grid");// new Color(200, 200, 200);
            _pGrid2.Position = new Point(0, 32);
            _pGrid2.SetScrolling(Overflow.Hidden, Overflow.Hidden);
            _pGrid.AddChild(_pGrid2);
            
            
            _pHeader = new Panel();
            _pHeader.BackgroundColor = ResT.GetColor("grid.header1");// new Color(107, 166, 208);
            _pHeader.Position = new Point(0, 0);
            _pHeader.Size = new Size(2400, 32);
            _pGrid.AddChild(_pHeader);   
           
            pMonth = new Panel();
            pMonth.Place(0, 0, 52, 32, _pParent);
            pMonth.BackgroundColor = ResT.GetColor("grid.header2");// new Color(77, 136, 178);
            pMonth.Div.Style.ZIndex = "99";
            
            lMonth = new Label("Sept<br />18", 0, 2, 48, 32, "center", pMonth);
            lMonth.FontSize = 9;
            lMonth.FontWeight = "bold";
            lMonth.Color = Color.White;
            
            for (var i = 0; i < NbDay; i++)
            {
                var d = _date.AddDays(i);
                _pDays[i] = new pDay(this, _pGrid2, d);
                _pDaysHeader[i] = new pDayHeader(_pParent, d);
            }
            
            
        }
        
        public void SetRowSize()
        {
            var height = _pParent.Height;
            if (height < 20 * NbDay + 84) height = 20 * NbDay + 84;
            
            var h = (height - 32 - 32 - 20) / NbDay;        // 32 pour header, footer, et scroll
            _rowHeight = h;

            for (var i = 0; i < NbDay; i++)
            {
                if (_pDays[i].Date.DayOfWeek == DayOfWeek.Sunday)
                    _pDays[i].SetSize(_totalWidth, h);
                else
                    _pDays[i].SetSize(_totalWidth, h - 1);
                _pDays[i].SetPos(0, i * h);

                _pDaysHeader[i].SetSize(h - 1);
                _pDaysHeader[i].SetPos(0, 32 + i * h);
            }

            _pGrid2.Height = h * NbDay - 1;
        }        
        
        public void ResetDaySelected()
        {
            foreach (var p in _pDays)
                if (p != null)
                    p.Selected = false;

            OnOnSelected(null);
        }   
        public void SetSize(int w, int h)
        {
            _pGrid.Size = new Size(w, h);
            SetRowSize();

            
            if(_separators != null)
                foreach (var t in _separators)
                {
                    if (t == null) continue;
                    t.Height = NbDay * _rowHeight + 32;
                }
        }

        public void SetColumnCount(int count)
        {
            _columnHeader = new string[count];
            _columnSz = new int[count];
            _fontSz = new float[count];

            _separators = new Panel[count + 1];
            _lHeader = new Label[count];

            for (var i = 0; i < NbDay; i++)
            {
                _pDays[i].SetColumnCount(count);
            }

            for (var i = 0; i < count; i++)
                _lHeader[i] = new Label();
            
            foreach(var l in _lHeader)
            {
                _pHeader.AddChild(l);
                l.Height = 16;
                l.Top = _pHeader.Height / 2 - l.Height / 2;
                l.Style.Color = "#FFF";
                l.Align = "center";
                l.Style.FontWeight = "bold";
            }
        }

        public void SetColumn(int id, string header, int txtid, int size, float fontSz)
        {
            _columnHeader[id] = header;
            _columnSz[id] = size;
            _lHeader[id].Text = header;
            _fontSz[id] = fontSz;
            if (txtid > 0)
                _lHeader[id].Text = Res.Get(txtid, _lHeader[id].Text);
        }

        public void Apply()
        {
            int x = 0;
            int k = 0;
            _totalWidth = 0;
            
            for (var i = 0; i < _separators.Length; i++)
            {
                var s = _separators[i] = new Panel();

                var w = 1;
                if (i == 0) w = 3;
                
                s.Size = new Size(w, 3000);
                s.Position = new Point(x - 1, 0);
                s.BackgroundColor = _pGrid2.BackgroundColor;// Color.Red;// new Color(200, 200, 200);
                s.ZIndex = "199";
                s.Style.Opacity = "0.5";
                _pGrid.AddChild(s);

                if (i < _separators.Length - 1)
                {
                    k = 0;
                    foreach (var t in _pDays)
                    {
                        if (k >= NbDay) continue;

                        t[i].SetX(x);
                        t[i].SetWidth(_columnSz[i]);
                        t[i].SetFontSize(_fontSz[i]);
                        k++;
                    }

                    _lHeader[i].Left = x;
                    _lHeader[i].Width = _columnSz[i];

                    _totalWidth += _columnSz[i];
                    x += _columnSz[i];
                }
            }

            _pHeader.Width = _totalWidth;

            k = 0;
            foreach (var t in _pDays)
            {
                if (k >= NbDay) continue;
                t.SetSize(_totalWidth, 0);

                k++;
            }

            _pGrid2.Width = _totalWidth;

            //_pDays[0].Select();
            
        }

        public void Reset(DateTime date)
        {
            _date = date;

            lMonth.Text = Res.month4[_date.Month - 1] + " " + _date.Year;
            
            foreach (var p in _pDays)
                p!=null?global::Bridge.Script.FromLambda(()=>p.Remove()):null;

            foreach (var p in _pDaysHeader)
                p!=null?global::Bridge.Script.FromLambda(()=>p.Remove()):null;

            if (_separators != null)
                foreach (var p in _separators)
                    p!=null&&p.Parent!=null?global::Bridge.Script.FromLambda(()=>p.Parent.RmvChild(p)):null;

            if (_lHeader != null)
                foreach (var l in _lHeader)
                    l!=null&&l.Parent!=null?global::Bridge.Script.FromLambda(()=>l.Parent.RmvChild(l)):null;

            _pDays = new pDay[31];
            _pDaysHeader = new pDayHeader[31];
            
            for (var i = 0; i < NbDay; i++)
            {
                var d = _date.AddDays(i);
                _pDays[i] = new pDay(this, _pGrid2, d);
                _pDaysHeader[i] = new pDayHeader(_pParent, d);
                
                _pDays[i].OnSelected += OnOnSelected;
            }
            
            SetRowSize();

            //Console.WriteLine("finish reset grid");
        }

        private void OnOnSelected(pDay pday)
        {
            if (MensualView.self.force_select_date.Year > 1920 && MensualView.self.force_select_date != pday.Date)
                return;

            Selected.Clear();

            foreach (var p in _pDays)
                if (p != null && p.Selected)
                    Selected.Add(p);

            if (pday != null)
                Console.WriteLine("Date Selected: " + pday.Date + " (OnOnSelected)");

            OnSelectChange!=null?global::Bridge.Script.FromLambda(()=>OnSelectChange.Invoke(this)):null;
        }

        public void SelectDate(DateTime d, bool reset = true)
        {
            Console.WriteLine("Date Selected: " + d + "  reset: " + reset);

            if (reset)
            {
                ResetDaySelected();
            }
            for (var i = 0; i < _pDays.Length; i++)
            {
                var p = _pDays[i];
                if (p == null) continue;
                if (p.Date == d)
                {
                    p.Select();
                }
                else p.Unselect();
            }
        }
        
        public void SetHorsContrat(int did)
        {

        }

        public void SetVerrou(int did, bool verrou)
        {
            if (verrou)
                _pDaysHeader[did]._pBack.BackgroundColor = new Color(220);
            else
                _pDaysHeader[did]._pBack.BackgroundColor = Color.White;
        }

        public void SetMessage(int did)
        {
            _pDaysHeader[did].Message(true);
        }

        public void ResetStatusOK()
        {
            foreach (var day in _pDays)
            {
                if (day == null) continue;
                day.StatusNotOK = false;
            }
        }

        public void ShowHide(bool show)
        {
            if(show)
            {
                _pParent.Visible = Visibility.Inherit;
            }
            else
            {
                _pParent.Visible = Visibility.Hidden;
            }
        }

    }
}