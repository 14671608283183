using System;
using Bridge.Html5;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class pDayHeader
    {

        private readonly Panel _pParent = null;
        public readonly Panel _pBack = null;
        private readonly DateTime _date = DateTime.MinValue;
        private readonly Panel _pSunday = null;
        
        private readonly Panel _pSep = null;
        
        private Label _lDayName, _lDayNr;

        private readonly int _w = 48;
        
        public pDayHeader(Panel pParent, DateTime date)
        {
            _pParent = pParent;
            _date = date;
            
            _pBack = new Panel();
            _pBack.BackgroundColor = ResT.GetColor("panel.back");// new Color(255, 255, 255);
            _pParent.AddChild(_pBack);

            if (date.DayOfWeek == DayOfWeek.Sunday)
            {
                _pSunday = new Panel();
                _pSunday.Place(0, 0, 100, 2, _pBack);
                _pSunday.BackgroundColor = new Color(100, 200, 100);
                _pSunday.ZIndex = "99";
            }
            
            var did = (int) date.DayOfWeek;
            if (did == 0) did = 7;
            did--;

            _lDayName = new Label("&nbsp;" + Res.days2[did], 2, 0, 26, 20, "left", _pBack);
            _lDayNr = new Label(_date.Day.ToString(), _lDayName.Right - 2, 0, 20, 20, "right", _pBack);

            //            _pSep = new Panel();
            //            _pSep.Position = new Point(47, 0);
            //            _pSep.Size = new Size(2, 32);
            //            _pSep.BackgroundColor = new Color(200, 200, 200);
            //            _pSep.ZIndex = "99";
            //            _pBack.AddChild(_pSep);

            _lDayName.Style.Color = ResT.GetColor("label.text").ToHex();// "#002157";
            _lDayNr.Style.Color = ResT.GetColor("label.text").ToHex();//"#002157";

            if (date.Date == DateTime.Now.Date)
            {
                _lDayName.Style.FontWeight = "bold";
                _lDayNr.Style.FontWeight = "bold";
            }
            


        }

        public void SetSize(int h)
        {
            _pBack.Size = new Size(_w, h);

            _lDayName.Top = h / 2 - _lDayName.Height / 2;
            _lDayNr.Top = _lDayName.Top;
            
            if (_pSunday != null)
            {
                _pSunday.Width = 52;
                _pSunday.Top = h - 1;
            }
            
            //_pSep.Size = new Size(2, h);
        }

        public void SetPos(int x, int y)
        {
            _pBack.Position = new Point(x, y);
        }
        
        public void Remove()
        {
            _pParent.RmvChild(_pBack);
        }
        
        public void Message(bool v)
        {
            if (v)
            {
                //_lDayName.Color = new Color(0, 200, 0);
                _lDayName.FontWeight = "bold";
                _lDayName.BackgroundColor = Color.Mobatime2;
                _lDayName.Color = Color.White;
                //_lDayName.Text = "&nbsp;" + _lDayName.Text;

            }
            else
            {
                _lDayName.Color = Color.LabelTxt;
                _lDayName.FontWeight = "normal";
            }
            //_lDayNr.Color = _lDayName.Color;
            //_lDayNr.FontWeight = _lDayName.FontWeight;

        }

    }
}