using System;
using System.Collections.Generic;

namespace Workflow_bridge
{
    public partial class Api
    {
        [System.Serializable]
        public class GridData_Day
        {
            public bool verrou_chef = false;
            public bool verrou_salaire = false;
            public int ctrId = 0;
            public List<string> datas = new List<string>();


            public bool anyMsgSU = false;
            public bool anyMsgChef = false;
        }

        [System.Serializable]
        public class API_GridData
        {
            public long token = 0;
            public Api.ApiFuncType return_type = Api.ApiFuncType.none;
            public bool show = true;
            public List<GridData_Day> days = new List<GridData_Day>();
        }

        [System.Serializable]
        public class API_GridColumn
        {
            public string title = "";
            public int size = 50;
            public int id = 0;
            public float fontSize = 0;
            public int txtid = 0;
        }

        [System.Serializable]
        public class API_GridCfg
        {
            public long token = 0;
            public Api.ApiFuncType return_type = Api.ApiFuncType.none;

            public List<API_GridColumn> columns = new List<API_GridColumn>();

            public void add(int id, string title, int size = 50)
            {
                var c = new API_GridColumn();
                c.title = title;
                c.size = size;
                c.id = id;
                columns.Add(c);
            }
        }

        [System.Serializable]
        public class GridCfg_Definition
        {
            public class Column_Def
            {
                public string title_a = "";
                public int size = 50;
                public int id = 0;
            }

            public Dictionary<int, Column_Def> definitions = new Dictionary<int, Column_Def>();

            public GridCfg_Definition()
            {
                add(100, "", 10, "anomalie");
                add(10, "HN", 50);
                add(13, "HN7", 50);
                add(11, "Prés", 50);
                add(19, "Just", 50);
                add(22, "Inter.", 50);
                add(14, "+/-", 50, "ecart");
                add(12, "Pont", 50);

                add(15, "Prés.N.", 50);
                add(16, "Prés.N+", 50);
                add(17, "Prés.Brute", 50);
                add(18, "Pause", 50, "Pause réelle");

                add(20, "Eq X", 50, "Equipe X");
                add(21, "Repas X", 50);
                add(23, "HNB", 50);
                add(24, "Type JS", 50);
                add(30, "C", 16, "cat jr 1ere lettre");

                add(50, "Abs 1", 150);
                add(51, "Abs 2", 150);

                for (int i = 0; i < 30; i++)
                    add(300 + i, "P " + (i + 1).ToString(), 70, "Pointage X");
            }

            public void add(int id, string title_a, int size, string tech = "")
            {
                var def = new GridCfg_Definition.Column_Def();

                def.id = id;
                def.size = size;
                def.title_a = title_a;

                definitions.Add(id, def);
            }

            public Column_Def get(int id)
            {
                return definitions[id];
            }
        }

        [Serializable]
        public class API_Data_Day
        {
            public int pid = 0;
            public int date = 0;
            public int ctrId = 0;

            public List<int> ptg_HE = new List<int>();
            public List<int> ptg_C1 = new List<int>();
            public List<int> ptg_CF = new List<int>();
            public List<int> ptg_Nat = new List<int>();
            public List<int> ptg_Dur = new List<int>();
            public List<int> ptg_Reel = new List<int>();
            public List<string> ptg_lieu = new List<string>();

            public int PresSeco = 0;

            public List<int> jr_AC = new List<int>();
            public List<int> jr_AD = new List<int>();
            public List<int> jr_AT = new List<int>();
            public List<int> jr_A_DE = new List<int>();
            public List<int> jr_A_A = new List<int>();
            public List<int> jr_A_Compl = new List<int>();
            public List<int> jr_A_PID = new List<int>();

            public int kor1_typeA = 0;
            public int kor1_typeB = 0;
            public int kor1_repA = 0;
            public int kor1_repB = 0;
            public int kor1_valueA = 0;
            public int kor1_valueB = 0;

            public List<int> anomalies = new List<int>();
            public List<int> exc = new List<int>();
            public List<int> stat = new List<int>();
            public int hjexc = 0;
            public int openZone = 0;
            public int jsp = 0;

            public string msg1 = "";
            public string msg2 = "";

            public List<int> flags = new List<int>();
            
            public bool modify_ptg = false;
            public bool modify_msg = false;
            public bool modify_abs = false;
            public bool modify_abs1 = false;
            public bool modify_abs2 = false;
            public bool modify_kor = false;
            public bool modify_seco = false;
            public bool modify_ano = false;

            public bool piquet = false;
            public int piquetV = 0;

            public List<int> ext_debours = new List<int>();
            public List<int> debours_docu = new List<int>();
            public List<int> debours_wk = new List<int>();
            public List<string> debours_wkText = new List<string>();

            public List<int> zone_de = new List<int>();
            public List<int> zone_a = new List<int>();

            public Dictionary<int, string> day_Ordre = new Dictionary<int, string>();
            public Dictionary<int, string> day_OF = new Dictionary<int, string>();
        }

        [System.Serializable]
        public class Absence_SU
        {
            public int id = 0;

            public bool to_validate = false;
            public bool to_cvalidate1 = false;
            public bool to_cvalidate2 = false;
            public bool need_msg = false;

            public bool hn7 = false;
            public bool can_intro = false;
            public int d_HN = 1;
            public int d_HalfHN = 1;
            public int d_Compl = 0;
            public int d_DP = 0;
            public int d_DeA = 0;
            public int d_AmPm = 0;
            public int d_Percent = 0;

            public int d_Ptj = 0;
            public int d_PtjCpl = 0;
            public int d_RefHJ = 0;
            public int d_RefHJToc = 0;
            public int d_RefHJTocHalf = 0;
            public int d_propre = 0;
        }        
        
        [System.Serializable]
        public class API_AbsSU
        {
            public List<Absence_SU> motif = new List<Absence_SU>();
            public bool only_abs_1 = false;
        }        
     
        [System.Serializable]
        public class API_CatAbs
        {
            public List<string> name = new List<string>();
            public List<string> abrev = new List<string>();
            public List<int> ico_id = new List<int>();
            public List<int> interrupt = new List<int>();
            public List<int> color = new List<int>();
            public List<string> txt_info = new List<string>();
            public List<int> percent_id = new List<int>();
            public List<string> percent_txt = new List<string>();
        }

        [System.Serializable]
        public class API_WFRightSU
        {
            public bool Ptg = false;
            public bool PtgInterruptOblig = false;

            public bool CorrPtg = false;
            public bool CorrPtgWMsg = false;
            public bool CorrPtgWValid = false;
            public bool CorrPtgVAuto = true;
            public bool Message = false;

            public bool Abs = false;
        }

    }
}