using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;

namespace Workflow_bridge.Jumbo
{
    class JumboRowPers : Panel
    {

        Label lNom = null;

        public JumboRowPers(int pid, string name)
        {
            Size = new Size(249, 32);
            lNom = new Label(name, 8, 8, Width - 1, 16, "left", this);
            lNom.FontSize = 9;
            BackgroundColor = Color.White;

            if(pid == Workflow.pid)
            {
                lNom.Color = new Color(0, 127, 0);
            }

        }

    }
}
