using System;
using System.Collections.Generic;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisieAbs : PanelSaisie
    {
        public class PSAbs : Panel
        {
            int aid = 0;

            int resume_aid = 0;
            Combo cboMotif, cboType;
            Label lA, lMotif, lType, lResume, lstatus;//, lResumeValid;
            Panel pResumeStatus;
            int status = 0;
            Api.API_AbsSU absSu = null;
            DateTime date;
            

            Panel pDP, pDEA, pPerc;

            Button bStatus1, bStatus2;

            Field fDP, fDe, fA;
            Combo cboPerc;

            int abs_id = 0, abs_type = 0, abs_dur = 0, abs_intro = 0;

            PanelSaisie saisie = null;

            int abs_status = 0;

            bool chef = false;
            bool modified = false;
            int wfid = 0;

            Api.Absence_SU selected = null;

            public PSAbs(int aid, int wfid, Panel parent, PanelSaisie saisie, Api.API_AbsSU absSu, bool chef)
            {
                this.chef = chef;
                this.aid = aid;
                this.absSu = absSu;
                this.saisie = saisie;
                this.wfid = wfid;

                Label l;

                var w = (parent.Width - 48) / 2;
                Place(16, 32, w, parent.Height - 48, parent);

                if(aid == 1)
                {
                    Left += w + 16;
                }

                SetBorderStyle(1, "solid", new Color(200), 4);

                var title = "A";
                if (aid == 1) title = "B";

                lstatus = new Label("", this.Width - 32, 0, 32, 16, "center", this);
                lstatus.FontSize = 8;
                lstatus.Color = new Color(150);

                lMotif = l = new Label(Res.Get(501, "Motif d'absence"), 29, 8, Width - 32, 16, "left", this);
                l.FontSize = 8;
                lMotif.Color = new Color(150);
                cboMotif = new Combo();
                cboMotif.Place(lMotif.Left, l.Top + 14, Width - 48, 32, this);
                cboMotif.Add(0, "----");

                lA = new Label(title, 8, cboMotif.Top + 4, 28, 32, "left", this);
                lA.FontSize = 14;


                foreach (var abs in absSu.motif)
                {
                    if (abs.id > 70) continue;
                    if (chef && !abs.can_intro) continue;
                    var absname = Workflow.CatAbs.name[abs.id];
                    if (absname.Length <= 0) continue;
                    //absname = absname.Length.ToString();
                    if (absname.Length == 0) absname = "{" + abs.id + "}";
                    var opt = cboMotif.Add(abs.id, absname);
                    opt.Style.FontSize = "11pt";

                    

                    //Console.WriteLine(abs.id + "  " + absname);
                }
                cboMotif.Sort();
                cboMotif.SelectById(0);
                cboMotif.OnChange += CboMotif_OnChange;
                cboMotif.Style.FontSize = "11pt";


                lType = l = new Label(Res.Get(502, "Type de durée"), lMotif.Left, cboMotif.Bottom + 12, Width - 32, 16, "left", this);
                lType.FontSize = 8;
                lType.Color = new Color(150);
                cboType = new Combo();
                cboType.Place(lType.Left, l.Top + 14, cboMotif.Width, 32, this);
                cboType.Style.FontSize = "11pt";
                cboType.Add(-1, "----");
                cboType.OnChange += CboType_OnChange1;

                cboType.OnChange += CboType_OnChange;

                lResume = new Label("", 8, Height - 80, Width - 16, 16, "center", this);
                lResume.FontSize = 12;

                pResumeStatus = new Panel(this);
                pResumeStatus.Place(lResume.Left + 32, lResume.Bottom + 8, lResume.Width - 64, 4);

                // *********************************************************************************************************
                pDP = new Panel(this);
                pDP.Place(16, cboType.Bottom + 16, Width - 32, 64);
                pDP.Visible = Bridge.Html5.Visibility.Hidden;

                l = new Label(Res.Get(540, "Durée:") + " ", 8, 16, 75, 16, "right", pDP);
                fDP = new Field(l, 50, Field.FieldFormat.HQty);
                fDP.Input.OnKeyDown = fDP_OnKeydown;

                // *********************************************************************************************************
                pDEA = new Panel(this);
                pDEA.Place(16, cboType.Bottom + 16, Width - 32, 64);
                pDEA.Visible = Bridge.Html5.Visibility.Hidden;

                l = new Label(Res.Get(541, "De:") + " ", 8, 16, 40, 16, "right", pDEA);
                fDe = new Field(l, 50, Field.FieldFormat.Ptg);
                fDe.Input.OnKeyDown = fDP_OnKeydown;

                l = new Label(Res.Get(542, "A:") + " ", 115, 16, 40, 16, "right", pDEA);
                fA = new Field(l, 50, Field.FieldFormat.Ptg);
                fA.Input.OnKeyDown = fDP_OnKeydown;

                // *********************************************************************************************************
                pPerc = new Panel(this);
                pPerc.Place(16, cboType.Bottom + 16, Width - 32, 64);
                pPerc.Visible = Bridge.Html5.Visibility.Hidden;
                
                l = new Label(Res.Get(540, "%:") + " ", 8, 16, 75, 16, "right", pPerc);
                
                cboPerc = new Combo();
                cboPerc.Place(l.Right + 4, l.Top - 6, 100, 28, pPerc);
                cboPerc.Add(0, "----");
                for (var i = 0; i < Workflow.CatAbs.percent_id.Count; i++)
                {
                    var id = Workflow.CatAbs.percent_id[i];
                    var txt = Workflow.CatAbs.percent_txt[i];
                    if (id > 0)
                        cboPerc.Add(id, txt);
                }
                cboPerc.OnChange += CboPerc_OnChange;

                bStatus1 = new Button(this.Width / 2 - 56, 144, 48, 48, "images/Neu_001_48.png", this);
                bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                bStatus1.OnButtonClick += BStatus1_OnButtonClick;

                bStatus2 = new Button(bStatus1.Right + 16, 144, 48, 48, "images/Neu_001_48.png", this);
                bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                bStatus2.OnButtonClick += BStatus2_OnButtonClick;

            }

            private void CboPerc_OnChange(Combo combo)
            {
                fDP_OnKeydown(null);
            }

            private void BStatus2_OnButtonClick(Button c, Bridge.Html5.MouseEvent arg)
            {
                abs_status = c.id1;
                SetButtonStatus();
                SetResumeStatusColor();

                saisie.Modified = true;
                modified = true;
            }

            private void BStatus1_OnButtonClick(Button c, Bridge.Html5.MouseEvent arg)
            {
                abs_status = c.id1;
                SetButtonStatus();
                SetResumeStatusColor();

                saisie.Modified = true;
                modified = true;
            }

            private void fDP_OnKeydown(Bridge.Html5.KeyboardEvent<Bridge.Html5.HTMLInputElement> e)
            {
                Console.WriteLine(fDP.Value);
                saisie.Modified = true;
                modified = true;
            }

            private void FDP_OnValidate(Field field)
            {
                saisie.Modified = true;
                modified = true;
            }

            private void CboType_OnChange1(Combo combo)
            {
                var tid = combo.SelectedId;

                pDP.Visible = Bridge.Html5.Visibility.Hidden;
                pDEA.Visible = Bridge.Html5.Visibility.Hidden;
                pPerc.Visible = Bridge.Html5.Visibility.Hidden;

                if (tid == 10)
                {
                    pDP.Visible = Bridge.Html5.Visibility.Inherit;
                }
                else if (tid == 9)
                {
                    pDEA.Visible = Bridge.Html5.Visibility.Inherit;
                }
                else if (tid == 15)
                {
                    pPerc.Visible = Bridge.Html5.Visibility.Inherit;
                }
            }

            void CboValid_OnChange(Combo combo)
            {
                saisie.Change();

                saisie.Modified = true;
                modified = true;


                combo.Style.Color = combo.Selected.Style.Color;
            }

            void CboMotif_OnChange(Combo combo)
            {
                Console.WriteLine("AbsMotif " + combo.Selected.Value);
                abs_status = 0;
                abs_id = cboMotif.SelectedId;

                FillCboType();
                cboType.SelectByIdx(1, true);



                FindAbs();

                //FillCboValid();

                //if (selected.to_validate) cboValid.SelectById(15);
                //else cboValid.SelectByIdx(0);

                


                if (!chef)
                {
                    if (cboMotif.SelectedId != 0 && selected.to_validate)
                    {
                        SetCboValid(3);
                        //cboValid.Visible = Bridge.Html5.Visibility.Inherit;
                    }
                    else
                    {
                        //cboValid.Visible = Bridge.Html5.Visibility.Hidden;
                    }
                }
                else
                {
                    if (cboMotif.SelectedId != 0 && selected.to_validate)
                    {
                        SetCboValid(7);
                        //cboValid.Visible = Bridge.Html5.Visibility.Inherit;
                    }
                    else
                    {
                        //cboValid.Visible = Bridge.Html5.Visibility.Hidden;
                    }
                }

                saisie.Change();

                saisie.Modified = true;
                modified = true;

                //if (!chef) SetValid(false);
                //else SetValid(true);


            }

            void SetCboValid(int v)
            {

                //if (cboValid.Count == 0)
                //{
                //    cboValid.Visible = Bridge.Html5.Visibility.Hidden;
                //    return;
                //}
                //else cboValid.Visible = Bridge.Html5.Visibility.Inherit;

                //if (resume_aid == 0 && chef)
                //    cboValid.Visible = Bridge.Html5.Visibility.Hidden;

                //if (!chef)
                //    cboValid.Visible = Bridge.Html5.Visibility.Hidden;

                //cboValid.SelectById(v);
                //cboValid.Style.Color = cboValid.Selected.Style.Color;
            }

            void FindAbs()
            {
                if (cboMotif.SelectedId == 0) selected = null;
                else
                {
                    foreach (var abs in absSu.motif)
                    {
                        if (abs.id == cboMotif.SelectedId)
                        {
                            selected = abs;
                            break;
                        }
                    }
                }
            }

            void FillCboType()
            {
                var absid = cboMotif.SelectedId;

                cboType.Clear();
                cboType.Add(-1, "----");

                foreach (var abs in absSu.motif)
                {
                    if (abs.id == absid)
                    {
                        if (!abs.hn7)
                        {
                            if (abs.d_HN > 0) cboType.Add(0, Res.Get(520, "Heures dues"));
                            if (abs.d_HalfHN > 0) cboType.Add(2, Res.Get(521, "1/2 des heures dues"));
                            if (abs.d_Compl > 0) cboType.Add(1, Res.Get(522, "Complément aux heures dues"));
                        }
                        else
                        {
                            if (abs.d_HN > 0) cboType.Add(20, Res.Get(520, "Heures dues"));
                            if (abs.d_HalfHN > 0) cboType.Add(22, Res.Get(521, "1/2 des heures dues"));
                            if (abs.d_Compl > 0) cboType.Add(21, Res.Get(522, "Complément aux heures dues"));
                        }

                        if (abs.d_DP > 0) cboType.Add(10, Res.Get(523, "Durée particulière"));
                        if (abs.d_DeA > 0) cboType.Add(9, Res.Get(524, "De...A"));
                        if (abs.d_AmPm > 0) { cboType.Add(50, "AM"); cboType.Add(51, "PM"); }
                        if (abs.d_Percent > 0) cboType.Add(15, "%");

                        if (abs.d_Ptj > 0) cboType.Add(5, Res.Get(525, "Ptj"));
                        if (abs.d_Ptj > 0) cboType.Add(6, Res.Get(525, "Ptj") + "-Pause");
                        if (abs.d_PtjCpl > 0) cboType.Add(7, Res.Get(526, "Ptj Cpl"));
                        if (abs.d_PtjCpl > 0) cboType.Add(8, Res.Get(526, "Ptj Cpl") + "-Pause");

                        if (abs.d_RefHJ > 0) cboType.Add(30, Res.Get(529, "RefHJ"));
                        if (abs.d_RefHJ > 0) cboType.Add(32, Res.Get(530, "1/2 RefHJ"));
                        if (abs.d_RefHJ > 0) cboType.Add(31, Res.Get(531, "RefHJ compl."));

                        if (abs.d_RefHJToc > 0) cboType.Add(35, Res.Get(527, "RefHJToc"));
                        if (abs.d_RefHJTocHalf > 0) cboType.Add(36, Res.Get(528, "RefHJTocHalf"));

                        if (abs.d_propre > 0) cboType.Add(12, Res.Get(532, "Dur.Propre"));

                        break;
                    }
                }
            }

            void FillCboValid()
            {
                //cboValid.Clear();

                //if (!chef)
                //{
                //    var o1 = cboValid.Add(1, Res.Get(551, "à confirmer"));
                //    var o3 = cboValid.Add(3, Res.Get(550, "à valider"));

                //    o1.Style.Color = Color.Blue.ToHex();
                //    o3.Style.Color = new Color(255, 128, 32).ToHex();
                //}
                //else
                //{
                //    bool v3 = false;
                //    bool v7_15 = false;
                //    bool v23_55 = false;
                //    bool v119_247 = false;

                //    var r = 0;

                //    if (abs_id > 0)
                //        r = Workflow.RightSU.ChefAbsRight[abs_id - 1];

                //    if(r == 1 || r == 3)
                //    {
                //        v3 = true;
                //        v7_15 = true;
                //    }

                //    if(r == 4)
                //    {
                //        v23_55 = true;
                //    }
                //    if (r == 8)
                //    {
                //        v119_247 = true;
                //    }

                //    //Console.WriteLine("A: " + (abs_id - 1) + "  " + r + "  " + v3 + "  " + v7_15 + "  " + v23_55 + "  " + v119_247 + "  " + Workflow.RightSU.CCAbs.Count);


                //    if (v3)
                //    {
                //        var o3 = cboValid.Add(3, Res.Get(550, "A valider"));
                //        o3.Style.Color = new Color(255, 128, 32).ToHex();
                //    }

                //    if (v7_15)
                //    {
                //        var v = 7;
                //        if (Workflow.RightSU.CCAbs[abs_id - 1]) v = 23;
                //        var o7 = cboValid.Add(v, "Valider");
                //        var o15 = cboValid.Add(15, "Refuser");
                //        if (v == 7) o7.Style.Color = Color.Green.ToHex();
                //        else if (v == 23) o7.Style.Color = new Color(192, 128, 0).ToHex();
                //        o15.Style.Color = Color.Red.ToHex();
                //    }


                //    if (v23_55)
                //    {
                //        var o23 = cboValid.Add(23, "Ne pas valider");
                //        o23.Style.Color = new Color(255, 128, 32).ToHex();

                //        var v = 55;
                //        if (Workflow.RightSU.CCCAbs[abs_id - 1]) v = 119;
                //        var o55 = cboValid.Add(v, "Valider");
                //        if (v == 55) o55.Style.Color = Color.Green.ToHex();
                //        else o55.Style.Color = new Color(192, 128, 0).ToHex();
                //    }


                //    if (v119_247)
                //    {
                //        var o119 = cboValid.Add(119, "Ne pas valider");
                //        o119.Style.Color = new Color(255, 128, 32).ToHex();

                //        var o247 = cboValid.Add(247, "Valider");
                //        o247.Style.Color = Color.Green.ToHex();
                //    }

                //}

                //if (cboValid.Count > 0)
                //{
                //    cboValid.SelectByIdx(0);
                //    cboValid.Style.Color = cboValid.Selected.Style.Color;
                //}

            }

            private void dataChefValid_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
            {
                
            }

            void CboType_OnChange(Combo combo)
            {
                saisie.Change();

                abs_type = cboType.SelectedId;

                saisie.Modified = true;
                modified = true;
            }

            public void Update(Api.API_Data_Day dat, DateTime d, Right right, bool chef, int userID)
            {
                this.chef = chef;
                date = d;
                if (dat == null) return;

                var ac = dat.jr_AC[aid];
                var ad = dat.jr_AD[aid];
                var at = dat.jr_AT[aid];
                var ade = dat.jr_A_DE[aid];
                var aa = dat.jr_A_A[aid];
                var v = 0;

                abs_id = ac;
                abs_type = at;
                abs_dur = ad;
                abs_intro = dat.jr_A_PID[aid];

                //Console.WriteLine("abs id: " + aid + "  " + abs_id );

                if (dat.flags != null && dat.flags.Count > 1 + aid)
                {
                    v = dat.flags[1 + aid];
                }
                abs_status = v;

                lstatus.Text = abs_status.ToString() + "_" + abs_intro;

                if (v == 0 && abs_id > 0) lA.Color = Color.Red;
                else lA.Color = ResT.GetColor("label.text");// Color.LabelTxt;

                cboMotif.SelectById(ac);
                FindAbs();
                FillCboType();
                FillCboValid();
                cboType.SelectById(at);

                pDP.Visible = Bridge.Html5.Visibility.Hidden;
                pDEA.Visible = Bridge.Html5.Visibility.Hidden;
                pPerc.Visible = Bridge.Html5.Visibility.Hidden;

                fDP.Value = 0;
                fDe.Value = 0;
                fA.Value = 0;

                if (at == 10)
                {
                    pDP.Visible = Bridge.Html5.Visibility.Inherit;
                    fDP.Value = dat.jr_AD[aid];
                }
                else if (at == 9)
                {
                    pDEA.Visible = Bridge.Html5.Visibility.Inherit;
                    fDe.Value = dat.jr_A_DE[aid];
                    fA.Value = dat.jr_A_A[aid];
                }
                else if(at == 15)
                {
                    pPerc.Visible = Bridge.Html5.Visibility.Inherit;
                    cboPerc.SelectById(dat.jr_A_Compl[aid]);
                }

                bool ok1 = true, ok2 = true;

                //Console.WriteLine("ctr id: " + dat.ctrId);

                if (ac != 0 && cboMotif.SelectedId == 0) ok1 = false;
                if (at != 0 && cboType.SelectedId == 0) ok2 = false;
                if (!chef && v == 7) ok1 = false;
                if (chef && v > 0) ok1 = false;
                if (chef && v > 0 && abs_intro > 0 && abs_intro != userID) ok1 = true;
                if (v > 15) ok1 = false;
                if (v == 23 && chef && abs_intro == Workflow.pid) ok1 = true;
                if (v == 0 && abs_id > 0 && abs_intro != Workflow.pid) ok1 = false;
                if (MensualView.Verrou[d.Day] || dat.ctrId <= 0) ok1 = false;
                if (!chef && (d < right.LimitAbsMin || d > right.LimitAbsMax)) ok1 = false;

                // très très très spécial pour MTSD
                if (Workflow.absintro_special.Contains(ac)) ok1 = true;

                if (ok1)
                {
                    var ok = false;
                    for (var i = 0; i < cboMotif.Items.Length; i++)
                    {
                        var opt = cboMotif.Items[i];
int id;
                        int.TryParse(opt.Value, out id);
                        if(id == abs_id)
                        {
                            ok = true;
                            break;
                        }
                    }

                    if (!ok) ok1 = false;
                  }

                if (!ok1 || !ok2)
                {
                    cboMotif.Visible = Bridge.Html5.Visibility.Hidden;
                    cboType.Visible = cboMotif.Visible;
                    lMotif.Visible = cboMotif.Visible;
                    lType.Visible = cboType.Visible;
                    lResume.Top = 28;
                    pDP.Visible = cboType.Visible;
                    pDEA.Visible = cboType.Visible;
                    pPerc.Visible = cboType.Visible;
                }
                else
                {
                    cboMotif.Visible = Bridge.Html5.Visibility.Inherit;
                    cboType.Visible = cboMotif.Visible;
                    lMotif.Visible = cboMotif.Visible;
                    lType.Visible = cboType.Visible;
                    lResume.Top = Height - 70;
                }

                pResumeStatus.Place(lResume.Left + 32, lResume.Bottom + 8, lResume.Width - 64, 4);
                UpdateResume(ac, ad, ade, aa, at);
                pResumeStatus.Visible = lResume.Visible;
                SetResumeStatusColor();

                if (MensualView.Verrou[d.Day] || dat.ctrId <= 0)
                {
                }
                else if (!chef)
                {
                    if (v == 1 || v == 3)
                    {
                        SetCboValid(v);
                    }
                }
                else
                {
                    if (v >= 3)
                    {
                        SetCboValid(v);
                    }
                }

                bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                if (chef)
                {
                    bool ok = false;

                    if (ac > 0) ok = true;

                    if (ok)
                    {
                        SetButtonStatus();
                    }
                }

                //Console.WriteLine("abs id: " + aid + "  " + abs_id);
            }

            void SetButtonStatus()
            {
                var r = 0;

                bStatus1.Left = Width / 2 - 56;
                bStatus2.Left = Width / 2 + 8;

                Console.WriteLine("AbsId: " + abs_id);

                if (abs_id > 0 && abs_id - 1 < 70)
                    r = Workflow.RightSU.ChefAbsRight[abs_id - 1];

                //bStatus1.Top = Height - 80;
                //bStatus2.Top = Height - 80;

                //Console.WriteLine("rs: " + r + " abs status: " + abs_status + "  " + Workflow.RightSU.CCCAbs[abs_id - 1]);

                var cc = false;

                if (abs_id - 1 < 70) cc = Workflow.RightSU.CCCAbs[abs_id - 1];

                if(r == 0)
                {
                    bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                    bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                }
                else
                {
                    bStatus1.Visible = Bridge.Html5.Visibility.Inherit;
                    bStatus2.Visible = Bridge.Html5.Visibility.Inherit;
                }

                if (r == 1 || r == 3)       // gestion de base n1
                {
                    if(abs_intro == Workflow.pid)
                    {
                        bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                        bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                    }

                    if (abs_status == 3)
                    {
                        bStatus1.SetImage("images/Yes_001_48.png");
                        bStatus2.SetImage("images/No_001_48.png");

                        bStatus1.id1 = 7;
                        if (Workflow.RightSU.CCAbs[abs_id - 1]) bStatus1.id1 = 23;
                        bStatus2.id1 = 15;
                    }
                    else if (abs_status == 7 || abs_status == 23)
                    {
                        bStatus1.SetImage("images/Neu_001_48.png");
                        bStatus2.SetImage("images/No_001_48.png");
                        bStatus1.id1 = 3;
                        bStatus2.id1 = 15;
                    }
                    else if (abs_status == 15)
                    {
                        bStatus1.SetImage("images/Neu_001_48.png");
                        bStatus2.SetImage("images/Yes_001_48.png");
                        bStatus1.id1 = 3;
                        bStatus2.id1 = 7;
                        if (Workflow.RightSU.CCAbs[abs_id - 1]) bStatus2.id1 = 23;
                    }
                    else if(abs_status == 55)
                    {
                        bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                        bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                    }
                }

                if (r == 4)
                {

                    if(abs_status < 23 || abs_status > 119)
                    {
                        bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                        bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                    }
                    bStatus2.Visible = Bridge.Html5.Visibility.Hidden;

                    if(abs_status == 23 && !cc)
                    {
                        bStatus1.SetImage("images/Yes_001_48.png");
                        bStatus1.id1 = 55;
                    }
                    else if(abs_status == 23 && cc)
                    {
                        bStatus1.SetImage("images/Yes_001_48.png");
                        bStatus1.id1 = 119;
                    }
                    else if(abs_status == 119)
                    {
                        bStatus1.SetImage("images/Neu_001_48.png");
                        bStatus1.id1 = 23;
                    }
                    else
                    {
                        bStatus1.SetImage("images/Neu_001_48.png");
                        bStatus1.id1 = 23;
                    }

                    bStatus1.Left = Width / 2 - 24;
                }
                else if(r == 8)
                {
                    if (abs_status < 119)
                    {
                        bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                        bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                    }
                    bStatus2.Visible = Bridge.Html5.Visibility.Hidden;

                    if (abs_status == 119)
                    {
                        bStatus1.SetImage("images/Yes_001_48.png");
                        bStatus1.id1 = 247;
                    }
                    else
                    {
                        bStatus1.SetImage("images/Neu_001_48.png");
                        bStatus1.id1 = 119;
                    }
                    
                }

                if (abs_status == 0)
                {
                    bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                    bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                }

                if(bStatus1.id1 == bStatus2.id1)
                {
                    bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                    bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                }

                if(MensualView.Verrou[date.Day])
                {
                    bStatus1.Visible = Bridge.Html5.Visibility.Hidden;
                    bStatus2.Visible = Bridge.Html5.Visibility.Hidden;
                }

            }

            void SetResumeStatusColor()
            {
                int r = 0;
                if (abs_id > 0 && Workflow.RightSU.ChefAbsRight.Count > abs_id)
                    r = Workflow.RightSU.ChefAbsRight[abs_id - 1];

                if (pResumeStatus == null) return;

                if (r == 1 || r == 3)
                {
                    if (abs_status < 3) pResumeStatus.BackgroundColor = Color.White;
                    if (abs_status == 3) pResumeStatus.BackgroundColor = new Color(255, 127, 32, 255);
                    if (abs_status == 7) pResumeStatus.BackgroundColor = new Color(100, 200, 100, 255);
                    if (abs_status == 15) pResumeStatus.BackgroundColor = Color.Red;
                    if (abs_status == 23) pResumeStatus.BackgroundColor = new Color(255, 255, 32, 255);
                    if (abs_status == 55) pResumeStatus.BackgroundColor = new Color(100, 200, 100, 255);
                    pResumeStatus.Height = 4;
                    if (abs_status == 55) pResumeStatus.Height = 8;
                }
                else if(r == 4)
                {
                    if (abs_status < 23) pResumeStatus.BackgroundColor = Color.White;
                    else if (abs_status == 23) pResumeStatus.BackgroundColor = new Color(255, 127, 32, 255);
                    else if (abs_status == 55) pResumeStatus.BackgroundColor = new Color(100, 200, 100, 255);
                    else if (abs_status == 119) pResumeStatus.BackgroundColor = new Color(255, 255, 32, 255);
                    pResumeStatus.Height = 8;
                }
                else if (r == 8)
                {
                    if (abs_status < 119) pResumeStatus.BackgroundColor = Color.White;
                    else if (abs_status == 119) pResumeStatus.BackgroundColor = new Color(255, 127, 32, 255);
                    else if (abs_status == 247) pResumeStatus.BackgroundColor = new Color(100, 200, 100, 255);
                    pResumeStatus.Height = 8;
                }


            }

            void UpdateResume(int aid, int duree, int de = 0, int a = 0, int at = 0)
            {
                resume_aid = aid;
                var absname = Workflow.CatAbs.name[aid];
                var absduree = Fmt.HHmm(duree);
                var absde = Fmt.Ptg(de);
                var absa = Fmt.Ptg(a);

                var dea = "";

                if(de != a)
                    dea = "<br/><br/>" + absde + " - " + absa;

                var ampm = "";
                if (at == 50) ampm = " AM ";
                else if (at == 51) ampm = " PM ";

                lResume.Text = absname + "  " + absduree + ampm + dea;

                if (aid != 0) lResume.Visible = Bridge.Html5.Visibility.Inherit;
                else lResume.Visible = Bridge.Html5.Visibility.Hidden;

                if (cboMotif.Visible == Bridge.Html5.Visibility.Inherit) lResume.Visible = Bridge.Html5.Visibility.Hidden;
            }

            //void UpdateResumeValid(int v)
            //{
            //    //if (v == 0) lResumeValid.Visible = Bridge.Html5.Visibility.Hidden;
            //    //else
            //    //{
            //    //    if (!chef)
            //    //    {
            //    //        lResumeValid.Visible = Bridge.Html5.Visibility.Inherit;
            //    //        if (v == 1) lResumeValid.Text = "à confirmer";
            //    //        else if (v == 3) lResumeValid.Text = "à valider";
            //    //        else if (v == 7) lResumeValid.Text = "validé";
            //    //        else if (v == 15) lResumeValid.Text = "refusé";

            //    //        if (v == 1) lResumeValid.Color = Color.Blue;
            //    //        else if (v == 3) lResumeValid.Color = new Color(255, 127, 32);
            //    //        else if (v == 7) lResumeValid.Color = Color.Green;
            //    //        else if (v == 15) lResumeValid.Color = Color.Red;
            //    //    }
            //    //}
            //}

            public void Save(Api.API_Data_Day dat)
            {
                if (aid == 0) dat.modify_abs1 = modified;
                else if (aid == 1) dat.modify_abs2 = modified;


                //if (abs_type < 0)
                //{
                //    abs_id = 0;
                //    abs_type = 0;
                //}

                dat.jr_AC[aid] = abs_id;
                dat.jr_AT[aid] = abs_type;
                dat.jr_A_PID[aid] = abs_intro;



                if (abs_type == 10)
                {
                    dat.jr_AD[aid] = fDP.ValueI;
                }
                else if(abs_type == 9)
                {
                    dat.jr_A_DE[aid] = fDe.ValueI;
                    dat.jr_A_A[aid] = fA.ValueI;
                }
                else if(abs_type == 15)
                {
                    dat.jr_A_Compl[aid] = cboPerc.SelectedId;
                }


                if (abs_id != 0 && !chef)
                {
                    bool need_validate = false;
                    foreach (var m in absSu.motif)
                    {
                        if (m.id == abs_id)
                        {
                            if (m.to_validate) need_validate = true;
                            break;
                        }
                    }

                    var valid = 0;
                    if (need_validate) valid = 3;
                    else dat.jr_A_PID[aid] = Workflow.pid;
                    dat.flags[1 + aid] = valid;
                }
                else if(chef)
                {
                    if (abs_status == 0 && abs_id > 0)
                    {
                        abs_status = 7;
                        if (Workflow.RightSU.CCAbs[abs_id - 1]) abs_status = 23;
                    }

                    if (cboMotif.Visible != Bridge.Html5.Visibility.Hidden)
                    {
                        dat.jr_A_PID[aid] = Workflow.pid;
                    }

                    dat.flags[1 + aid] = abs_status;

                    Console.WriteLine("abs_status: " + abs_status);

                }

            }

            //private void SetValid(bool visible)
            //{
            //    //if (visible)
            //    //{
            //    //    cboValid.Visible = Bridge.Html5.Visibility.Inherit;
            //    //    lResumeValid.Visible = Bridge.Html5.Visibility.Hidden;
            //    //}
            //    //else
            //    //{
            //    //    cboValid.Visible = Bridge.Html5.Visibility.Hidden;
            //    //    lResumeValid.Visible = Bridge.Html5.Visibility.Inherit;
            //    //}
            //}

            public void ChangeVisibility()
            {
                cboMotif.Visible = Parent.Visible;
                cboType.Visible = Parent.Visible;
                //cboValid.Visible = Parent.Visible;
                lMotif.Visible = Parent.Visible;
                lType.Visible = Parent.Visible;
                lResume.Visible = Parent.Visible;
                //lResumeValid.Visible = Parent.Visible;

            }

            public bool CheckIfMsg()
            {

                var motif = cboMotif.SelectedId;

                if (motif <= 0) return true;
                //motif--;

                for (var i = 0; i < absSu.motif.Count; i++)
                    if (absSu.motif[i].id == motif)
                    {
//                        Console.WriteLine("needMsg: " + absSu.motif[i].need_msg + " " + Workflow.CatAbs.name[absSu.motif[i].id]);
                        if (absSu.motif[i].need_msg) return false;
                    }



                return true;
            }
        }

        int AbsCount = 2;
        PSAbs[] pAbs = new PSAbs[2];
        Label[] lSoldeT = new Label[4];
        Label[] lSoldeV = new Label[4];

        bool chef = false;

        int pid, wfid;
        DateTime date;

        public PanelSaisieAbs(Panel pParent) : base(pParent)
        {
            id = 4;

            _lHeader.Text = Res.Get(500, "Absences");
            _pBack.Size = new Size(600 - 32, 250);
            sz1 = 250;

            lsKey = "pAbsClosed";


            //Init();
        }

        void Data_OnSuccess(Data<Api.API_AbsSU> data, Api.API_AbsSU result)
        {
            var max = 2;
            if (result.only_abs_1) max = 1;
            AbsCount = max;

            for (var i = 0; i < max; i++)
            {
                if(pAbs[i] != null)
                {
                    _pBack.RmvChild(pAbs[i]);
                }
                pAbs[i] = new PSAbs(i, data.id1, _pBack, this, result, data.b1);
            }

            var w = this._pBack.Width / 8;
            chef = data.b1;

            for (var i = 0; i < 4; i++)
            {
                if (lSoldeT[i] != null) continue;
                lSoldeT[i] = new Label("solde 1", i * w * 2, 8, w, 16, "right", _pBack);
                lSoldeT[i].FontSize = 10;
                lSoldeV[i] = new Label("- 1000:00", lSoldeT[i].Right, 8, w - 20, 16, "right", _pBack);
                lSoldeV[i].FontSize = 10;

                lSoldeT[i].Visible = Bridge.Html5.Visibility.Hidden;
                lSoldeV[i].Visible = Bridge.Html5.Visibility.Hidden;
            }
        }

        public void Init(bool b1, int id1, Api.API_AbsSU AbsSu)
        {
            var data = new DataApi<Api.API_AbsSU>();
            data.b1 = b1;
            data.id1 = id1;

            var result = AbsSu;
            Data_OnSuccess(data, result);
        }

        public void Init(int wfid = -1)
        {
            var func = new Api.ApiFunction();

            if (wfid == -1)
            {
                func.iparam1 = Workflow.wfid_su;
                func.iparam3 = Workflow.pid;
            }
            else
            {
                func.iparam1 = Workflow.WFProfil[wfid];
                func.iparam2 = 1;
                func.iparam3 = Workflow.pid;
            }
            func.function = Api.ApiFuncType.get_absences_su;
            var data = new DataApi<Api.API_AbsSU>(Workflow.api, func);
            data.OnSuccess += Data_OnSuccess;
            data.id1 = wfid;
            data.b1 = func.iparam2 == 1;
        }

        public void Update(Api.API_Data_Day dat, bool chef, int pid, int wfid, DateTime date, Right right)
        {
            this.pid = pid;
            this.wfid = wfid;
            this.date = date;


            if (!Visible) return;
            for (var i = 0; i < AbsCount; i++)
            {
                if (pAbs[i] != null)
                    pAbs[i].Update(dat, date, right, chef, pid);
            }


            for (var i = 0; i < 4; i++)
            {
                if (lSoldeT[i] == null) continue;

                if (!chef)
                {
                    lSoldeT[i].Visible = Bridge.Html5.Visibility.Hidden;
                    lSoldeV[i].Visible = Bridge.Html5.Visibility.Hidden;
                }
                else
                {
                    lSoldeT[i].Visible = Bridge.Html5.Visibility.Inherit;
                    lSoldeV[i].Visible = Bridge.Html5.Visibility.Inherit;

                    LoadSoldes();
                }
            }


            if (pAbs.Length > 1 && pAbs[1] != null)
            {
                if (right.LimitAbs1)
                {

                    pAbs[1].Visible = Bridge.Html5.Visibility.Hidden;
                }
                else
                {
                    pAbs[1].Visible = Bridge.Html5.Visibility.Inherit;
                }
            }

        }

        void LoadSoldes()
        {
             var function = new Api.ApiFunction();
            function.function = Api.ApiFuncType.GetWFSoldeCrit;
            function.iparam1 = pid;
            function.iparam2 = wfid;
            function.iparam3 = date.Year;
            function.iparam4 = Workflow.pid;

            var dataCrit = new DataApi<Api.ApiReturn>(Workflow.api, function);
            dataCrit.OnSuccess += DataCrit_OnSuccess;
        }

        private void DataCrit_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            
            var csv = new CSVTool();
            csv.LoadData(result.return_s1);

            //Console.WriteLine(csv[0].strings.Count);

            for (var i = 0; i < 4; i++)
            {
                lSoldeT[i].Text = "";
                lSoldeV[i].Text = "";
            }

            if (csv[0].strings.Count < 3) return;

            var k = 0;
            for (var i = 0; i < csv[0].strings.Count; i += 3)
            {
                var t1 = csv[0].strings[i];
                var t2 = csv[0].strings[i + 1];
                var color = csv[0].I32(i + 2);

                lSoldeT[k].Text = t1 + " :";
                lSoldeV[k].Text = t2;

                if (color == 1) lSoldeT[k].Color = Color.Red;
                else if (color == 2) lSoldeT[k].Color = Color.Red;
                else lSoldeT[k].Color = Color.LabelTxt;

                k++;

                //Console.WriteLine(t1 + "  " + t2 + "  " + color);
            }
        }


        public void Save(Api.API_Data_Day dat)
        {

            for (var i = 0; i < AbsCount; i++)
            {
                pAbs[i].Save(dat);
            }
        
        }

        public bool CheckIfMsg()
        {

            for(var i = 0; i < AbsCount; i++)
            {
                var v = pAbs[i].CheckIfMsg();
                if (!v) return false;
            }

            return true;
        }

        public override void ChangeVisibility()
        {
            base.ChangeVisibility();

            if (!Visible)
            {
                for (var i = 0; i < AbsCount; i++)
                {
                    if (pAbs[i] == null) continue;
                    pAbs[i].ChangeVisibility();
                }
            }
        }
    }
}