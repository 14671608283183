using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using Bridge.Html5;
using Mobatime.JSControl;
using Window = Bridge.Html5.Window;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisieSeco : PanelSaisie
    {

        static int nochange_count = 0;
        Field fValue = null;
        //Button SaveMore = null;

        public PanelSaisieSeco(Panel pParent) : base(pParent)
        {
            id = 5;

            lsKey = "pSecoClosed";

            _lHeader.Text = Res.Get(480, "Présence journalière");
            _pBack.Size = new Size(600 - 32, 96);
            sz1 = 64;
            int w = _pBack.Width / 2 - 32;

            var l = new Label(Res.Get(480, "Présence journalière") + " :", 32, 24, 200, 16, "right", _pBack);

            fValue = new Field(l, 75, Field.FieldFormat.HQtyAbs);
            fValue.Value = 0;
            fValue.Input.OnChange = FValue_OnChange;

            fValue.Input.OnKeyDown = OnKeyDown;

        }

        void OnKeyDown(KeyboardEvent<HTMLInputElement>e)
        {
            //Console.WriteLine(e.KeyCode);

            if (e.KeyCode == 35)
            {
                fValue.ValidSaisie();
                SaveMore_OnButtonClick(null, null);
            }
        }

        private void SaveMore_OnButtonClick(Button c, MouseEvent arg)
        {
            nochange_count = 2;
            Modified = true;
            MensualView.self.Save(1);
        }

        private void CboKor_OnChange(Combo combo)
        {

            fValue.Value = 0;
            Modified = true;

            fValue.Focus();
        }

        private void FValue_OnChange(Event<HTMLInputElement> e)
        {
            Modified = true;
        }

        public void Update(Api.API_Data_Day dat, DateTime date, Right right)
        {
            //
            fValue.Value = dat.PresSeco;

            if (nochange_count == 1)
            {
                //fValue.Focus();
                fValue.Input.Select();
            }


            fValue.Input.Disabled = MensualView.Verrou[date.Day];

            nochange_count--;
        }

        public void Init(int wfid)
        {

        }

        public void Save(Api.API_Data_Day dat)
        {
            dat.PresSeco = fValue.ValueI;

        }

    }
}