using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class pSwapOption : Panel
    {
        protected Label lTitle = null;

        protected pPunchWKSwap parent = null;

        protected int type = 0;

        public pSwapOption(pPunchWKSwap parent) : base()
        {
            this.parent = parent;

            BackgroundColor = Color.White;
            SetBorderStyle(1, "solid", new Color(240, 240, 240), 4);

            var x = 200;
            Place(x, 64, parent.Width - x - 32, parent.Height - 96, parent);

            lTitle = new Label("", 8, 8, Width - 16, 32, "left", this);
            lTitle.FontSize = 12;
        }

        public virtual void Show(int type = 0)
        {
            this.type = type;
            this.Visible = Visibility.Inherit;
        }

        public virtual void Hide()
        {
            this.Visible = Visibility.Hidden;
        }

        protected virtual void Apply()
        {

        }

    }
}
