using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using System.Linq;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class WkSelectTable : Panel
    {
        class Row
        {
            public int id = 0, idx = 0, type = 0;
            public string s1 = "";
            public string s2 = "";
            public string s3 = "";

            Panel parent = null;

            Panel[] ps = new Panel[4];
            Label[] ls = new Label[4];

            public bool Over { get; set; }
            public bool Selected { get; set; }

            public Row(Panel parent, int idx, int type, int id, string s1, string s2, string s3)
            {
                this.parent = parent;
                this.idx = idx;
                this.type = type;
                this.id = id;
                this.s1 = s1;
                this.s2 = s2;
                this.s3 = s3;

                var w = (parent.Width - 20 - 50 - 100) / 2;
                int[] sz = { 50, 100, w, w };
                string[] s = { id.ToString() + ".", s1, s2, s3 };

                var x = 0;
                for(var i = 0; i < 4; i++)
                {
                    ps[i] = new Panel(x, idx * 24, sz[i] - 1, 23, Color.White, parent);
                    ls[i] = new Label(s[i], 4, 4, ps[i].Width - 8, 16, "left", ps[i]);
                    ls[i].Style.Overflow = Overflow.Hidden;
                    ls[i].Paragraph.Title = ls[i].Text;
                    if (i == 0)
                    {
                        ls[i].FontSize = 8;
                        ls[i].Align = "right";
                        ls[i].Top += 2;
                    }
                    else
                    {
                        ls[i].FontSize = 9;
                        ls[i].Top += 1;
                    }
                    x += sz[i];
                }
            }
            
            public void Clear()
            {
                for(var i = 0; i < ps.Length; i++)
                    parent.RmvChild(ps[i]);
            }

            public void UpdateColor()
            {
                var bcolor = Color.White;
                var fcolor = Color.LabelTxt;

                if(Over)
                {
                    bcolor = Color.Mobatime2;
                    fcolor = Color.White;
                }
                else if(Selected)
                {
                    bcolor = Color.Mobatime1;
                    fcolor = Color.White;
                }


                for(var i = 0; i < ps.Length; i++)
                {
                    ps[i].BackgroundColor = bcolor;
                    ls[i].Color = fcolor;
                }

            }


        
private bool __Property__Initializer__Over=false;private bool __Property__Initializer__Selected=false;}


        public delegate void dOnSelect(WkSelectTable grid, int type, int idx);
        public event dOnSelect OnSelect = null;

        Label lT = null;
        Label lC = null;
        Field fSearch = null;
        List<Row> rows = new List<Row>();
        Panel pLst = null;
        int type = 0;

        DetailOrdre dordre = null;

        public WkSelectTable(Panel parent, DetailOrdre dordre) : base(700, 464, parent.Width - 716, parent.Height - 480, Color.White, parent)
        {
            Label l;

            lT = new Label("Gestion du temps", 0, 4, this.Width - 4, 16, "right", this);
            lT.Color = new Color(200);


            fSearch = new Field();
            fSearch.Place(16, 32, 150, 20, this);
            fSearch.Input.OnKeyDown += ((KeyboardEvent<Bridge.Html5.HTMLInputElement> e) => 
            {
                Console.WriteLine(e.KeyCode);

                if (e.KeyCode == 13) 
                {
                    Search(type, fSearch.Input.Value);
                } 
            });

            var p0 = new Panel(16, 64, this.Width - 32, this.Height - 80, new Color(240), this);
            var p = new Panel(1, 1, p0.Width - 2, p0.Height - 2, new Color(240), p0);
            p.SetScrolling(Overflow.Hidden, Overflow.Scroll);

            p.Div.OnMouseDown += ((e) => 
            {
                var rc = p.Div.GetBoundingClientRect();
                MouseDown(e.PageX - (int)rc.Left, e.PageY - (int)rc.Top + p.Div.ScrollTop);

            });
            p.Div.OnMouseMove += ((e) => 
            {
                var rc = p.Div.GetBoundingClientRect();
                MouseMove(e.PageX - (int)rc.Left, e.PageY - (int)rc.Top + p.Div.ScrollTop);
            });

            pLst = p;
            this.dordre = dordre;

            lC = new Label("", p0.Left + 150, p0.Top - 18, p0.Width - 150, 16, "right", this);
            lC.FontSize = 8;
            lC.Color = new Color(240);
        }


        public void Show(int type)
        {
            this.Visible = Visibility.Inherit;
            this.Style.ZIndex = "99";
            this.type = type;
            fSearch.Input.Value = "";

            Clear();

            Console.WriteLine("Type" + type);

            if (type == 0)
            {
                lT.Text = Res.Get(8101, "Sélection d'un Ordre");// "Sélection d'un ordre";

                Search(type, "");
            }
            else if(type == 1)
            {
                lT.Text = Res.Get(8102, "Sélection d'un type de travail");
            }
            else if(type == 2)
            {
                lT.Text = Res.Get(8103, "Sélection d'un centre de frais");
            }
            else if(type == 3)
            {
                lT.Text = Res.Get(8104, "Sélection d'un motif de rebut");
            }

            
        }

        public void Hide()
        {
            this.Visible = Visibility.Hidden;
        }

        void MouseMove(int x, int y)
        {
            var ry = y / 24;
            if (ry < 0 || ry >= rows.Count) return;

            for (var i = 0; i < rows.Count; i++)
            {
                rows[i].Over = i == ry;
                rows[i].UpdateColor();
            }
        }

        void MouseDown(int x, int y)
        {
            var ry = y / 24;
            if (ry < 0 || ry >= rows.Count) return;

            int sid = -1;

            for (var i = 0; i < rows.Count; i++)
            {
                var old = rows[i].Selected;
                rows[i].Selected = i == ry;

                if(old && rows[i].Selected)
                    sid = i;
                else if(rows[i].Selected && type == 0)
                {
                    dordre.ShowOrder(rows[i].id, PanelWkData.Self.CurPid, PanelWkData.Self.CurDate);
                }

                rows[i].UpdateColor();
            }

            if (sid > -1)
            {
                var r = rows[sid];
                OnSelect!=null?global::Bridge.Script.FromLambda(()=>OnSelect.Invoke(this, type, r.id)):null;
                Visible = Visibility.Hidden;
            }
        }

        void Clear()
        {
            foreach(var r in rows)
                r.Clear();
            rows.Clear();
        }
        Row AddRow(int id, string s1, string s2, string s3)
        {
            var r = new Row(pLst, rows.Count, type, id, s1, s2, s3);
            rows.Add(r);
            return r;
        }

        public void Search(int type, string text)
        {
            text = text.ToLower();

            Clear();
            
            Console.WriteLine(type +  " " + text +  "  " + Workflow.ordres.Count);

            if (type == 0)
            {
                var k = 0;
                foreach (var ordre in Workflow.ordres.Values)
                {
                    bool add = true;

                    //Console.WriteLine(ordre.idcode + " " + ordre.desc);

                    if(text != "")
                    {
                        bool ok = false;
                        if (ordre.idcode.ToLower().Contains(text)) ok = true;
                        if (ordre.desc.ToLower().Contains(text)) ok = true;
                        if (ordre.name.ToLower().Contains(text)) ok = true;

                        if (!ok) add = false;
                    }

                    if (add)
                    {
                        AddRow(ordre.id, ordre.idcode, ordre.desc, ordre.name);
                        k++;
                        if (k >= 500)
                            break;
                    }
                }

                lC.Text = rows.Count + " / " + Workflow.ordres.Count;
            }

        }

    }
}
