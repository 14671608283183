using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using System.Linq;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class TotalOrdres : Panel
    {
        Panel pLst = null;
      

        public TotalOrdres(PanelWkData parent)
        {
            var p = this;

            Label l;

            Place(700, 48, parent.Width - 700 - 16, 450, parent);

            BackgroundColor = Color.White;

            l = new Label("Totaux des ordres de la journée", 0, 4, p.Width - 4, 16, "right", this);
            l.Color = new Color(200);

            pLst = new Panel(16, 32, Width - 32, Height - 48, new Color(220), this);
            pLst.SetScrolling(Overflow.Hidden, Overflow.Scroll);
        }


        public void Update(CSVTool.CSVObject csv)
        {
            pLst.ClearChildren();

            var count = csv.I32(0);

            var tot = 0;
            var tot2 = 0;

            var idx = 1;
            var y = 33;
            for (var i = 0; i < count; i++)
            {
                var txt = csv[idx++];
                var q1 = csv.I32(idx++);
                var q2 = csv.I32(idx++);

                tot += q1;
                tot2 += q2;

                var ratio = q2 / (double)q1;
                ratio *= 10000;
                var iratio = (int)(ratio);

                var txt2 = Fmt.HQty(q1) + " / " + Fmt.HQty(q2) + " / " + Fmt.EntierCentieme(iratio) + "%";

                var p = new Panel(0, y, pLst.Width, 64, Color.White, pLst);
                var l = new Label(txt, 4, 4, pLst.Width - 32, 48, "left", p);
                var l2 = new Label(txt2, 4, 46, pLst.Width - 32, 16, "right", p);
                y += 65;
            }

            var ratio2 = tot2 / (double)tot;
            ratio2 *= 10000;
            var iratio2 = (int)(ratio2);
            var txt3 = Fmt.HQty(tot) + " / " + Fmt.HQty(tot2) + " / " + Fmt.EntierCentieme(iratio2) + "%";

            txt3 = "total / exporté / % : " + txt3;

            var p2 = new Panel(0, 0, pLst.Width, 32, Color.Mobatime2, pLst);
            var l3 = new Label(txt3, 4, 8, pLst.Width - 32, 28, "center", p2);
            l3.Color = Color.White;

        }


    }
}
