using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class PanelPlan : Panel
    {
        MensualView view = null;
        Button bClose = null, bPrec, bCur, bNext;
        DateTime date = DateTime.MinValue;
        int pid = 0;
        Label ldate1, ldate2, lPers;
        Panel pBack = null;

        public PanelPlan(MensualView view)
        {
            this.view = view;
            Size = new Size(1270, 850);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = Color.Mobatime1;

            var lTitle = new Label("Plan", 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle);
            bClose.OnButtonClick += BClose_OnButtonClick;

            bPrec = new Button(84, 0, 32, 32, "", pTitle, false, 1);
            bCur = new Button(124, 0, 32, 32, "", pTitle, false, 2);
            bNext = new Button(164, 0, 32, 32, "", pTitle, false, 3);

            bPrec.SetImage("images/arrow_left.png", 32, 32);
            bCur.SetImage("images/now.png", 32, 32);
            bNext.SetImage("images/arrow_right.png", 32, 32);

            bPrec.OnButtonClick += BPrec_OnButtonClick;
            bCur.OnButtonClick += BPrec_OnButtonClick;
            bNext.OnButtonClick += BPrec_OnButtonClick;

            pBack = new Panel(this);
            pBack.Place(16, 48, Width - 32, Height - 64);
            pBack.BackgroundColor = Color.White;

            ldate1 = new Label("", bNext.Right + 16, 0, 300, 16, "left", pTitle);
            ldate2 = new Label("", ldate1.Left, ldate1.Bottom, ldate1.Width, ldate1.Height, "left", pTitle);
            ldate1.FontSize = ldate2.FontSize = 9;
            ldate1.Color = ldate2.Color = Color.White;

            lPers = new Label("", ldate1.Right + 16, 8, 300, 16, "left", pTitle);
            lPers.Color = Color.White;
            lPers.FontSize = 12;
        }

        private void BPrec_OnButtonClick(Button c, MouseEvent arg)
        {
            switch (c.id1)
            {
                case 1:
                    date = date.AddDays(-7);
                    break;
                case 2:
                    date = DateTime.Now.Date;
                    var did = (int)date.DayOfWeek;
                    if (did == 0) did = 7;
                    did--;
                    date = date.AddDays(-did);
                    break;
                case 3:
                    date = date.AddDays(7);
                    break;
            }

            Update(date, pid);
        }

        private void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            view.CloseLock();
        }

        public void Update(DateTime date, int pid)
        {
            this.date = date;
            this.pid = pid;

            var func = new Api.ApiFunction(Api.ApiFuncType.GetPlan4Week);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(date);
            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) => { SetData(b); };

            ldate1.Text = Fmt.DateComplete(date);
            ldate2.Text = Fmt.DateComplete(date.AddDays(27));
            lPers.Text = Workflow.nom;
        }

        void SetData(Api.ApiReturn r)
        {
            pBack.ClearChildren();

            var w = pBack.Width / 7;
            var h = pBack.Height / 4;
            var marge = 8;
            var marge2 = marge * 2;
            var w2 = w - marge2;
            var h2 = h - marge2;
            var w3 = (w2 - 16) / 3;
            var count = 0;
            var daySz = 27;

            var csv = new CSVTool(r.return_s1)[0];

            for (var y = 0; y < 4; y++)
            {
                var weekid = Tool.ISOWeekNumber(date.AddDays(count + 3));

                for (var x = 0; x < 7; x++)
                {
                    var xx = x * w + marge;
                    var yy = y * h + marge;

                    var idx = count * daySz;

                    var p = new Panel(pBack);
                    p.Place(xx, yy, w2, h2);
                    p.BackgroundColor = Color.White;
                    p.SetBorderStyle(1, "solid", new Color(220), 4);

                    var d = date.AddDays(count);
                    var did = (int)d.DayOfWeek;
                    if (did == 0) did = 7;
                    did--;

                    var sdate = Res.days2[did] + " " + d.Day + " " + Res.month4[d.Month - 1] + " / " + weekid;
                    var ldate = new PanelLabel(sdate, 4, 0, w2 - 8, 20, "center", p);
                    ldate.FontSize = 9;
                    if (did == 6) p.BackgroundColor = new Color(240);

                    var py = 20;

                    var ptjName = csv[idx].Trim();
                    var ptjColor = csv.I32(idx + 1);
                    if (ptjName.Length > 0)
                    {
                        var pPtj = new PanelLabel(ptjName, 0, py, w2, 20, "center", p);
                        if (ptjColor > 0)
                            pPtj.BackgroundColor = Workflow.planColor16[ptjColor];

                        py += 24;
                    }

                    for(var z = 0; z < 4; z++)
                    {
                        var idx2 = idx + 2 + z * 5;

                        var lz1 = new Label(csv[idx2], 12, py, w3, 16, "right", p);
                        var lz2 = new Label(csv[idx2 + 1], 12 + w3, py, w3, 16, "right", p);
                        var lz3 = new Label(csv[idx2 + 2], 12 + w3 * 2, py, w3, 16, "right", p);

                        lz1.FontSize = lz2.FontSize = lz3.FontSize = 9;

                        if (csv.Bool(idx2 + 3))
                        {
                            var pshare = new Panel(p);
                            pshare.Place(4, py + 2, 8, 8);
                            pshare.BackgroundColor = Color.Mobatime2;
                        }

                        var msg = csv[idx2 + 4];
                        if(msg != "")
                        {
                            var lz4 = new Label(msg, 4, py + 16, w - 8, 16, "center", p);
                            lz4.FontSize = 8;
                        }

                        py += 32;

                    }

                    var msg2020 = csv[idx + 22];
                    if(msg2020.Trim().Length > 0)
                    {
                        var pmsg = new Panel(6, 6, 8, 8, Color.Green, p);
                        pmsg.SetBorderStyle(1, "solid", Color.Red, 4);
                        pmsg.Div.Title = msg2020;
                    }

                    py += 8;
                    var absX = 4;
                    for(var z = 0; z < 2; z++)
                    {
                        var idx2 = idx + 22 + 1 + z * 2;

                        var ac = csv.I32(idx2);
                        var dur = csv[idx2 + 1];

                        if (ac > 0)
                        {
                            var sabs = Workflow.CatAbs.abrev[ac];
                            var colid = Workflow.CatAbs.color[ac];
                            var color = Workflow.Colors16[colid];
                            var pabs = new Panel(p);
                            pabs.Place(absX, py + 3, 8, 8);
                            pabs.BackgroundColor = color;

                            var l = new Label(sabs + " " + dur, absX + 16, py, w3, 16, "left", p);
                            //l.BackgroundColor = Color.Red;
                            l.FontSize = 8;
                        }

                        absX += w2 / 2;
                    }

                    count++;
                }
            }
        }
    }
}
