using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;

namespace Workflow_bridge
{
    public class DataApi<T> : Data<T>
    {
        public DataApi() { }
        public DataApi(string url, Api.ApiFunction function)
        {
            System.Console.WriteLine("function: " + function.function.ToString());

            var s = JsonConvert.SerializeObject(function);

            Get(url, s, 0);
        }
    }

}