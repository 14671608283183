using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using Mobatime.JSControl;
using System.IO;

namespace Workflow_bridge.MensualView
{
    class FraisView : Panel
    {
        bool verrou_pose = false;
        bool full_verrou = false;

        DateTime currrentDate = DateTime.MinValue;

        Button bClose = null;
        MensualView view;
        Panel pGrid = null, pLeft, pRight, pResume;
        DateTime date = DateTime.MinValue;

        Button bVerrou, bSave, bCancel;

        int pid = 0;
        Label lMois;
        DateTime d1, d2;

        List<Label> ls1 = new List<Label>();
        List<Label> ls2 = new List<Label>();
        List<Panel> lsR = new List<Panel>();

        Label[,] labels = new Label[31, 14];
        Panel[,] panels = new Panel[31, 7];
        Button[,] buttonsRefus = new Button[31, 7];

        List<Panel> pRow = new List<Panel>();

        Label lrDate;
        Label[] lrInfo = new Label[3];
        Label[] lrPtg = new Label[8];
        Label[] lrChantier = new Label[5];
        Label[] lrChantierTitle = new Label[5];
        Panel pTitle;
        Label lTitle;
        

        Field[] fTraj = new Field[4];

        bool KMFrais = false;

        public FraisView(MensualView view, bool KMFrais)
        {
            this.view = view;
            Size = new Size(1440, 850);
            this.KMFrais = KMFrais;
            pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = Color.Mobatime1;

            lTitle = new Label(Res.Get(4000, "Contrôle des frais et débours"), 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle);
            bClose.OnButtonClick += BClose_OnButtonClick;

            lMois = new Label("janvier 2016", 16, 40, 500, 16, "left", this);
            lMois.FontSize = 12;

            pGrid = new Panel(this);
            pGrid.Place(16, 96, 1058, Height - 96 - 16);
            pGrid.BackgroundColor = new Color(240);
            pGrid.SetScrolling(Overflow.Hidden, Overflow.Visible);

            bVerrou = new Button(pGrid.Width - 160, 36, 160, 28, "", this, true);
            bVerrou.Text = Res.Get(4010, "Validation");
            bVerrou.label.Color = Color.Red;
            bVerrou.OnButtonClick += BVerrou_OnButtonClick;

            string[] sn = { "Débours 1", "Débours 2", "Débours 3", "Débours 4", "Débours 5", "Trajets" };
            for (var i = 0; i < sn.Length; i++)
                if (i < 5)
                    sn[i] = Res.Get(4001, "Débours") + " " + (i + 1);

            var x = pGrid.Left + 48;
            for (var i = 0; i < sn.Length; i++)
            {
                var w = 180;
                if (i == 0) w = 175;
                else if (i == sn.Length - 1)
                    w = 100;

                var l = new Label(sn[i], x, 76, w, 16, "center", this);
                l.FontSize = 12;
                l.Color = new Color(150);
                

                var p2 = new Panel(this);
                p2.Place(x + w, 70, 1, 96 - 70);
                p2.BackgroundColor = new Color(240);

                x += w;
            }

            var p3 = pLeft = new Panel(this);
            p3.Place(pGrid.Left, 70, 1, pGrid.Height - 70);
            p3.BackgroundColor = new Color(220);


            var p4 = pRight = new Panel(this);
            p4.Place(pGrid.Right, 70, 1, pGrid.Height - 70);
            p4.BackgroundColor = new Color(220);

            var p5 = new Panel(this);
            p5.Place(pGrid.Left, 70, pGrid.Width, 1);
            p5.BackgroundColor = new Color(220);

            var p6 = new Panel(this);
            p6.Place(pGrid.Left, pGrid.Top, pGrid.Width, 1);
            p6.BackgroundColor = new Color(220);

            var p7 = new Panel(this);
            p7.Place(pGrid.Left, pGrid.Bottom, pGrid.Width, 1);
            p7.BackgroundColor = new Color(220);

            pResume = new Panel(this);
            pResume.Place(pGrid.Right + 16, pGrid.Top - 28, Width - pGrid.Right - 32, pGrid.Height - 100);
            pResume.BackgroundColor = Color.White;
            pResume.SetBorderStyle(1, "solid", new Color(240));

            var pTraj = new Panel(this);
            pTraj.Place(pResume.Left, pResume.Bottom + 8, pResume.Width, 120);
            pTraj.BackgroundColor = Color.White;
            pTraj.SetBorderStyle(1, "solid", new Color(240));


            makeTrajet(pTraj);
            if (!KMFrais) pTraj.Visible = Visibility.Hidden;

            //var l2 = new Label(Res.Get(4100, "Aperçu journalier"), 0, 4, pResume.Width, 16, "center", pResume);

            //pResume.Visible = Visibility.Hidden;

            makeResume();

            BackgroundColor = new Color(245);
                       
        }

        private void BVerrou_OnButtonClick(Button c, MouseEvent arg)
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.WFVerrouFrais;
            func.iparam1 = pid;
            func.iparam2 = D2000.T(d1);
            func.iparam3 = D2000.T(d2);

            if (verrou_pose) func.iparam4 = 0;
            else func.iparam4 = 1;

            var dataVerrou = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataVerrou.OnSuccess += DataVerrou_OnSuccess;
        }

        private void DataVerrou_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            LoadData();     
        }

        void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            view.CloseLock();
        }

        public void Update(DateTime date, int pid, string nom)
        {
            this.date = date;
            d1 = new DateTime(date.Year, date.Month, 1);
            d2 = new DateTime(date.Year, date.Month, DateTime.DaysInMonth(date.Year, date.Month));
            this.pid = pid;

            lMois.Text = nom + " - " + Res.months[d1.Month - 1] + " " + d1.Year;



            LoadData();
            UpdateInfo(date);

            if (pid == Workflow.pid)
            {
                pResume.Visible = Visibility.Hidden;
                Width = 1100;
            }
            else
            {
                pResume.Visible = Visibility.Inherit;
                Width = 1440;
            }

            pTitle.Place(0, 0, this.Width, 32);
            lTitle.Place(0, 4, this.Width - 8, 20);
        }

        void CreateGrid()
        {
            ls1.Clear();
            ls2.Clear();
            lsR.Clear();
            var step = 40;

            pRow.Clear();

            var nbday = (int)(d2 - d1).TotalDays;
            nbday++;
            //pGrid.Height = 660;// step;
            pGrid.ClearChildren();
            pGrid.SetScrolling(Overflow.Hidden, Overflow.Scroll);

            pLeft.Height = pGrid.Bottom - 70;
            pRight.Height = pLeft.Height;
            pResume.Height = pGrid.Height - 100;

            var k = 0;
            var y = 0;
            for(var d = d1; d <= d2; d = d.AddDays(1))
            {
                var p = new Panel(pGrid);
                p.Place(0, y, pGrid.Width, step - 1);
                p.BackgroundColor = Color.White;
                p.Div.OnMouseEnter = Day_OnMouseEnter;
                p.Div.OnMouseLeave = Day_OnMouseLeave;
                p.Div.OnMouseDown = Day_OnMouseDown;
                p.SetAttribute("rowid", k.ToString());
                pRow.Add(p);

                if (d.DayOfWeek == DayOfWeek.Sunday)
                {
                    var p3 = new Panel(pGrid);
                    p3.Place(0, y + step - 1, pGrid.Width, 1);
                    p3.BackgroundColor = new Color(100, 100, 100);
                    p3.Style.ZIndex = "99";
                }

                var dayL = Fmt.DateDay2(d);
                if(dayL.Length > 1)
                {
                    dayL = dayL.Substring(0, 1).ToUpper() + dayL.Substring(1);
                }

                var l1 = new Label(dayL, 5, 6, 20, 16, "left", p);
                var l2 = new Label(d.Day.ToString(), l1.Right - 5, l1.Top, 20, 16, "right", p);
                var pR = new Panel(p);
                l1.Color = new Color(150);
                l2.Color = new Color(150);
                l1.FontSize = 9;
                l2.FontSize = 9;
                pR.Place(5, 24, 10, 10);
                pR.BackgroundColor = Color.White;// Color.Blue;

                lsR.Add(pR);
                ls1.Add(l1);
                ls2.Add(l2);

                var p2 = new Panel(p);
                p2.Place(44, 0, 1, p.Height);
                p2.BackgroundColor = new Color(240);
                p2.id5 = 1;

                var x = 48;
                for(var i = 0; i < 5; i++)
                {
                    var p3 = new Panel(p);
                    p3.Place(x - 2, 0, 176, p.Height);
                    p3.BackgroundColor = Color.White;
                    //p3.Div.OnDblClick = CellDblClick;
                    p3.SetAttribute("id1", k.ToString());
                    p3.SetAttribute("id2", i.ToString());
                    panels[k, i] = p3;

                    var b = buttonsRefus[k, i] = new Button(p3.Width - 18, p3.Height - 18, 16, 16, "", p3, true, 0);
                    b.Text = "X";
                    b.id1 = k;
                    b.id2 = i;
                    b.OnButtonClick += B_OnButtonClick;

                    var l = new Label("frais abcd", 2, 2, 120, 16, "left", p3);
                    labels[k, i * 2] = l;
                    labels[k, i * 2].FontSize = 9;
                    l.id1 = k;
                    l.id2 = i * 2;

                    l = new Label("30.45", 118, 2, 50, 16, "right", p3);
                    labels[k, i * 2 + 1] = l;
                    l.id1 = k;
                    l.id2 = i * 2 + 1;

                    p2 = new Panel(p);
                    p2.Place(x + 175, 0, 1, p.Height);
                    p2.BackgroundColor = new Color(240);
                    p2.id5 = 1;

                    x += 180;
                }

                var l3 = new Label("200'000 km", x, 2, 100, 16, "left", p);
                labels[k, 10] = l3;
                labels[k, 10].FontSize = 9;

                l3 = new Label("15'", x, 2, 90, 16, "right", p);
                labels[k, 11] = l3;
                labels[k, 11].FontSize = 9;

                l3 = new Label("225'000 km", x, 20, 100, 16, "left", p);
                labels[k, 12] = l3;
                labels[k, 12].FontSize = 9;

                l3 = new Label("16'", x, 20, 90, 16, "right", p);
                labels[k, 13] = l3;
                labels[k, 13].FontSize = 9;

                y += step;
                k++;
            }



        }

        private void B_OnButtonClick(Button c, MouseEvent arg)
        {
            if (verrou_pose) return;
            
            c.id3 += 1;
            c.id3 %= 2;
            c.Parent.id3 = c.id3;

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.SetFraisRefus;
            func.iparam1 = pid;
            func.iparam2 = D2000.T(d1.AddDays(c.id1));
            func.iparam3 = c.id2;
            func.iparam4 = c.id3;

            var dataFrais = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataFrais.OnSuccess += ((Data<Api.ApiReturn> data, Api.ApiReturn result) =>
            {
                if (c.id3 == 0) c.Parent.BackgroundColor = Color.White;
                else c.Parent.BackgroundColor = new Color(255, 220, 255, 255);
            });
        }

        private void CellDblClick(MouseEvent<HTMLDivElement> e)
        {
            if (verrou_pose) return;
            var div = e.CurrentTarget;
int id1;

            int.TryParse(div.GetAttribute("id1"), out id1);
int id2;
            int.TryParse(div.GetAttribute("id2"), out id2);

            var nodbl = e.Target.GetAttribute("nodblclick");
            if (nodbl != null && nodbl.Length > 2)
                return;

            var p = panels[id1, id2];
            if (p.id4 > 0)
            {
                p.id3 += 1;
                p.id3 %= 2;

                var func = new Api.ApiFunction();
                func.function = Api.ApiFuncType.SetFraisRefus;
                func.iparam1 = pid;
                func.iparam2 = D2000.T(d1.AddDays(id1));
                func.iparam3 = id2;
                func.iparam4 = p.id3;

                var dataFrais = new DataApi<Api.ApiReturn>(Workflow.api, func);
                dataFrais.OnSuccess += ((Data<Api.ApiReturn> data, Api.ApiReturn result) => 
                {
                    if (p.id3 == 0) p.BackgroundColor = Color.White;
                    else p.BackgroundColor = new Color(255, 220, 255, 255);
                });

            }       
        }

        void Day_OnMouseEnter(MouseEvent<HTMLDivElement> e)
        {
            ColorSelectedDay(e);
        }

        void ColorSelectedDay(MouseEvent<HTMLDivElement> e)
        {
            var div = e.CurrentTarget;
            div.Style.BackgroundColor = Color.Selected.ToHex();

            var att = div.GetAttribute("rowid");
int rid;
            int.TryParse(att, out rid);

            var row = pRow[rid];
            var children = row.GetChildren();
            foreach (var c in children)
            {
                if(c is Panel)
                {
                    if (c.id5 == 1) continue;
                    else if (c.id3 == 0)
                    {
                        c.BackgroundColor = Color.Selected;

                        var chidlren2 = c.GetChildren();
                        foreach (var c2 in chidlren2)
                            if (c2 is Label)
                                ((Label)c2).Color = Color.White;
                    }
                }
                else if (c is Label)
                {
                    ((Label)c).Color = Color.White;
                }
            }
        }


        void Day_OnMouseLeave(MouseEvent<HTMLDivElement> e)
        {
            var div = e.CurrentTarget;

            var att = div.GetAttribute("rowid");
int rid;
            int.TryParse(att, out rid);

            var d = date.AddDays(rid);

            if (d == currrentDate)
                return;

            div.Style.BackgroundColor = Color.White.ToHex();



            var row = pRow[rid];
            var children = row.GetChildren();
            foreach (var c in children)
            {
                if(c is Panel)
                {
                    var p = c as Panel;

                    if (p.id5 == 1) continue;
                    else if (p.id3 == 0)
                        p.BackgroundColor = Color.White;

                    var children2 = p.GetChildren();
                    foreach(var c2 in children2)
                    {
                        if (c2 is Label)
                        {
                            var l = c2 as Label;
                            if (l.Width >= 50)
                                l.Color = Color.LabelTxt;
                            else
                                l.Color = new Color(150);
                        }
                    }
                }
                else if(c is Label)
                {
                    var l = c as Label;
                    if (l.Width >= 50)
                        l.Color = Color.LabelTxt;
                    else
                        l.Color = new Color(150);
                }

            }
        }

        void Day_OnMouseDown(MouseEvent<HTMLDivElement> e)
        {
            if (bSave.Enabled) return;

            foreach(var row in pRow)
            {
                row.BackgroundColor = Color.White;

                var children = row.GetChildren();
                foreach (var c in children)
                {
                    if (c.id5 == 1) continue;

                    if(c is Panel)
                    {
                        var p = c as Panel;
                        if(p.id3 == 0)
                        {
                            p.BackgroundColor = Color.White;
                            var children2 = p.GetChildren();
                            foreach(var c2 in children2)
                            {
                                if (c2 is Label)
                                {
                                    var l = c2 as Label;
                                    if (l.Width >= 50)
                                        l.Color = Color.LabelTxt;
                                    else
                                        l.Color = new Color(150);
                                }
                            }
                        }
                    }
                    else if (c is Label)
                    {
                        var l = c as Label;
                        if (l.Width >= 50)
                            l.Color = Color.LabelTxt;
                        else
                            l.Color = new Color(150);
                    }
                }
            }

            ColorSelectedDay(e);

            var div = e.CurrentTarget;
            var att = div.GetAttribute("rowid");
int rid;
            int.TryParse(att, out rid);
            currrentDate = date.AddDays(rid);
            UpdateInfo(currrentDate);

        }

        void UpdateInfo(DateTime date)
        {
            lrDate.Text = Fmt.DateComplete(date);
            
            var func = new Api.ApiFunction(Api.ApiFuncType.WFGetFraisResumeDay);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(date);
            func.Get<Api.ApiReturn>(Workflow.api).OnSuccess += (data, result) =>
            {

                var csvInfo = new CSVTool();
                csvInfo.LoadData(result.return_s1);

                for(var i = 0; i < 3; i++)
                {
                    lrInfo[i].Text = csvInfo[0][i];
                }
                fTraj[0].Value = csvInfo[0][3];
                fTraj[1].Value = csvInfo[0][4];
                fTraj[2].Value = csvInfo[0][5];
                fTraj[3].Value = csvInfo[0][6];

                var csvPtg = new CSVTool();
                csvPtg.LoadData(result.return_s2);

                for(var i = 0; i < 8; i++)
                {
                    lrPtg[i].Text = csvPtg[0][i];
                }

                var csvChantier = new CSVTool();
                csvChantier.LoadData(result.return_s3);

                for (var i = 0; i < 5; i++)
                {
                    var s = lrChantier[i].Text = csvChantier[0][i].Trim();
                    if (s.Length == 0)
                        lrChantierTitle[i].Visible = Visibility.Hidden;
                    else
                        lrChantierTitle[i].Visible = Visibility.Inherit;
                }


            };
        }

        void LoadData()
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.WFGetFraisMonth;
            func.iparam1 = pid;
            func.iparam2 = D2000.T(d1);
            func.iparam3 = D2000.T(d2);
            func.iparam4 = Workflow.pid;
            func.iparam10 = Res.LgCom;

            var dataFrais = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataFrais.OnSuccess += DataFrais_OnSuccess;
        }

        private void DataFrais_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            CreateGrid();

            var csv = new CSVTool(true);
            csv.LoadData(result.return_s1);
            
            verrou_pose = false;
            full_verrou = false;

            var k = 0;
            for(var d = d1; d <= d2; d = d.AddDays(1))
            {
                var v = csv[k];

                for (var i = 0; i < 7; i++)
                {
                    labels[k, i * 2 + 0].Text = "";
                    labels[k, i * 2 + 0].SetAttribute("nodblclick", "no");

                    labels[k, i * 2 + 1].Text = "";
                    if (i >= 5) continue;
                    panels[k, i].id3 = 0;
                    panels[k, i].id4 = 0;
                    panels[k, i].BackgroundColor = Color.White;
                    buttonsRefus[k, i].id3 = 0;
                }


                var id = 0;
                for (var i = 0; i < v.strings.Count; i += 4)
                {
                    if (i < 20)
                    {
                        var s1 = v[i];//.strings[i + 0];

                        if (d != d1 && i == 0 && s1.Length > 3) s1 = s1.Substring(3);

                        //else s1 = "";
                        s1 = s1.Trim();

                        labels[k, id + 0].Text = s1;
                        labels[k, id + 1].Text = v.strings[i + 1];
                        var pidx = id / 2;

                        if (s1.Length > 0 && pidx < 7)
                        {
                            panels[k, pidx].id4 = 1;
                        }


                        if (s1.Length > 0 && !verrou_pose && !full_verrou)
                            buttonsRefus[k, pidx].Visible = Visibility.Inherit;
                        else
                            buttonsRefus[k, pidx].Visible = Visibility.Hidden;

                        if (pid == Workflow.pid) buttonsRefus[k, pidx].Enabled = false;

                        //buttonsRefus[k, pidx].Text = verrou_pose.ToString();

                        var photo = v.I32(i + 2);
                        var ext = v.I32(i + 3);
                        if (photo == 0) labels[k, id + 0].Style.TextDecoration = TextDecoration.None;
                        else if (photo == 1 || photo >= 20)
                        {
                            //Console.WriteLine("photo: " + photo);
                            labels[k, id + 0].Style.TextDecoration = TextDecoration.Underline;
                            labels[k, id + 0].id3 = photo;
                            labels[k, id + 0].OnDblClick += FraisView_OnDblClick;
                            labels[k, id + 0].id4 = ext;

                            labels[k, id + 0].SetAttribute("nodblclick", "yes");
                        }

                        labels[k, id + 0].id1 = id / 2;
                        labels[k, id + 0].id2 = k;

                        id += 2;
                    }
                    else if (i < 21)
                    {
                        if (v[i + 3] != "0") labels[k, 10].Text = v[i + 3] + " km";
                        if (v[i + 5] != "0") labels[k, 11].Text = v[i + 5] + "'";
                        if (v[i + 4] != "0") labels[k, 12].Text = v[i + 4] + " km";
                        if (v[i + 6] != "0") labels[k, 13].Text = v[i + 6] + "'";
                    }
                }

                var status = v.I32(20); // anciement 15
                var fullv = v.I32(21);
                var repas = v.I32(22);
                var refus = v.I32(27);

                if (status == 1)
                {
                    ls1[k].Style.TextDecoration = TextDecoration.Underline;
                    verrou_pose = true;
                }
                else
                {
                    ls1[k].Style.TextDecoration = TextDecoration.Inherit;
                }

                if (fullv > 0) full_verrou = true;


                if (repas == 0) lsR[k].BackgroundColor = new Color(255, 255, 255, 255);
                else if (repas == 1) lsR[k].BackgroundColor = new Color(0, 255, 0, 255);     // accepter automatisme
                else if ((int)(repas & 16) > 0) lsR[k].BackgroundColor = new Color(196, 0, 255, 255);                   // violet refusé débour
                else if ((int)(repas & 4) > 0) lsR[k].BackgroundColor = new Color(130, 230, 255, 255);                  // bleu demandé user (forcé user)

                else if ((int)(repas & 8) > 0) lsR[k].BackgroundColor = new Color(255, 0, 0, 255);                      // rouge = refusé user 
                else if ((int)(repas & 2) > 0) lsR[k].BackgroundColor = new Color(150, 150, 150, 255);                  // gris = autre

                for(var j = 0; j < 5; j++)
                {
                    var pow = (int)Math.Pow(2, j);
                    if((int)(refus & pow) > 0)
                    {
                        panels[k, j].BackgroundColor = new Color(255, 220, 255, 255);
                        panels[k, j].id3 = 1;
                        buttonsRefus[k, j].id3 = 1;
                    }
                }

                k++;
            }


            if (verrou_pose)
            {
                bVerrou.Text = Res.Get(554, "Dévalider");
                bVerrou.label.Color = Color.Red;
            }
            else
            {
                bVerrou.Text = Res.Get(553, "Valider");
                bVerrou.label.Color = Color.Blue;
            }

            //Console.WriteLine(full_verrou);

            if (full_verrou)
            {
                bVerrou.Enabled = false;
            }
            else
            {
                bVerrou.Enabled = true;
                if (d2 < DateTime.Now.Date) bVerrou.Visible = Visibility.Inherit;
                else bVerrou.Visible = Visibility.Hidden;
            }

            if (pid == Workflow.pid)
                bVerrou.Visible = Visibility.Hidden;

            for (var y = 0; y < 31; y++)
                for (var x = 0; x < 7; x++)
                {
                    var b = buttonsRefus[y, x];

                    if (b != null && (full_verrou || verrou_pose))
                        b.Visible = Visibility.Hidden;
                }


        }

        private void FraisView_OnDblClick(Label label, MouseEvent<HTMLParagraphElement> e)
        {
            var fid = label.id1;
            var k = label.id2;

            var day = date.Day + k;
            var mode = label.id3;
            if (label.id3 < 20) mode = 0;
            var extid = label.id4;

            string[] ext = { ".jpg", ".jpeg", ".png", ".pdf" };

            var filename = "";
            
            if (mode == 0)
            {
                filename = "public/tempo_" + Workflow.pid + "/frais_" + pid.ToString().PadLeft(5, '0');
                filename += "_" + date.Year + date.Month.ToString().PadLeft(2, '0') + day.ToString().PadLeft(2, '0');
                filename += fid.ToString().PadLeft(2, '0');
                filename += ext[extid];
            }
            else if(mode >= 20)
            {
                var lettre = "_" + ((char)(mode - 21 + 65)).ToString();
                filename = "public/tempo_" + Workflow.pid + "/frais_" + pid.ToString().PadLeft(5, '0');
                filename += "_" + date.Year + date.Month.ToString().PadLeft(2, '0') + "00";
                filename += "99";
                filename += lettre;
                filename += ext[extid];
            }

            if (filename.Length > 0)
            {
                var path = Workflow.BaseWebPath + "/" + filename;
                Bridge.Html5.Window.Open(path, "Ticket", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=800");
            }
        }

        void makeResume()
        {

            string[] txts = { Res.Get(4101, "Présence"), Res.Get(4102, "Abs 1"), Res.Get(4103, "Abs 2"), };
            string[] txts2 = { "", "", "", };
            Label l, l2, l3;

            lrDate = new Label("", 0, 8, pResume.Width, 16, "center", pResume);

            var y = 48;

            for (var i = 0; i < txts.Length; i++)
            {
                var sn = txts[i];

                l = new Label(sn + ":", 0, y, 150, 16, "right", pResume);
                lrInfo[i] = l2 = new Label(txts2[i], l.Right + 4, y, pResume.Width - l.Right - 12 - 75, 16, "right", pResume);

                y += 20;
            }

            y += 20;

            l = new Label(Res.Get(4104, "Pointages 1-8") + ":", 16, y, 250, 16, "left", pResume);
            l.FontSize = 8;
            l.FontWeight = "bold";
            y += 24;

            for (var i = 0; i < 4; i++)
            {
                l = new Label((i + 1) + ":", 0, y, 32, 16, "right", pResume);
                lrPtg[i * 2 + 0] = l2 = new Label("", 40, y, 60, 16, "right", pResume);
                lrPtg[i * 2 + 1] = l3 = new Label("", l2.Right + 12, y, 60, 16, "right", pResume);

                l2.FontSize = 9;
                l3.FontSize = 9;

                y += 20;
            }

            y += 20;

            for(var i = 0; i < 5; i++)
            {
                lrChantierTitle[i] = l = new Label(Res.Get(4105, "Chantier ") + (i + 1) + ":", 8, y, pResume.Width, 16, "left", pResume);
                l.FontWeight = "bold";
                l.FontSize = 8;

                lrChantier[i] = l2 = new Label("", l.Left, y + 16, pResume.Width - 16, 16, "left", pResume);
                l2.FontSize = 9;

                y += 80;
            }

        }

        void makeTrajet(Panel p)
        {
            var l = new Label("A", 16, 36, 50, 16, "right", p);
            l.FontSize = 9;
            l = new Label("B", 16, 68, 50, 16, "right", p);
            l.FontSize = 9;

            l = new Label("KM", 66, 12, 100, 16, "center", p);
            l.FontSize = 9;
            l = new Label("Temps", 166, 12, 100, 16, "center", p);
            l.FontSize = 9;


            fTraj[0] = new Field();
            fTraj[0].Position = new Point(76, 32);
            fTraj[0].Size = new Size(80, 20);
            p.AddChild(fTraj[0]);

            fTraj[1] = new Field();
            fTraj[1].Position = new Point(76, 64);
            fTraj[1].Size = new Size(80, 20);
            p.AddChild(fTraj[1]);

            fTraj[2] = new Field();
            fTraj[2].Position = new Point(188, 32);
            fTraj[2].Size = new Size(75, 20);
            p.AddChild(fTraj[2]);

            fTraj[3] = new Field();
            fTraj[3].Position = new Point(188, 64);
            fTraj[3].Size = new Size(75, 20);
            p.AddChild(fTraj[3]);

            for(var i = 0; i < fTraj.Length; i++)
            {
                fTraj[i].Style.TextAlign = "right";
                fTraj[i].Input.OnKeyDown = fTraj_OnKeydown;
            }
                      
            bCancel = new Button(p.Width - 40, p.Height - 56, 32, 32, "images/64annule.png", p);
            bSave = new Button(bCancel.Left, bCancel.Top - 40, 32, 32, "images/63save.png", p);
            bCancel.Enabled = false;
            bSave.Enabled = false;
            bCancel.OnButtonClick += BCancel_OnButtonClick;
            bSave.OnButtonClick += BSave_OnButtonClick;

        }

        private void BSave_OnButtonClick(Button c, MouseEvent arg)
        {

            var func = new Api.ApiFunction(Api.ApiFuncType.job_save_trajet);
            func.iparam1 = pid;
            func.iparam10 = D2000.T(currrentDate);

            int.TryParse(fTraj[0].Input.Value, out func.iparam2);
            int.TryParse(fTraj[1].Input.Value, out func.iparam3);
            int.TryParse(fTraj[2].Input.Value, out func.iparam4);
            int.TryParse(fTraj[3].Input.Value, out func.iparam5);

            func.Get<Api.ApiReturn>(Workflow.api).OnSuccess += (data, result) =>
            {
                LoadData();
                UpdateInfo(currrentDate);
            };

            bCancel.Enabled = false;
            bSave.Enabled = false;
        }

        private void BCancel_OnButtonClick(Button c, MouseEvent arg)
        {
            UpdateInfo(currrentDate);
            bCancel.Enabled = false;
            bSave.Enabled = false;
        }

        private void fTraj_OnKeydown(Bridge.Html5.KeyboardEvent<Bridge.Html5.HTMLInputElement> e)
        {
            bCancel.Enabled = true;
            bSave.Enabled = true;
        }

    }
}
