using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;
using Bridge.Html5;

namespace Workflow_bridge.Cockpit
{
    class CockpitView
    {
        Mobatime.JSControl.Window window = null;
        HtmlPage npage = null;
        Button bClose;
        Label lTitle;
        IFrame iframe = null;
        string baseUrl = "";
        string baseWF = "";

        public void Show(bool su = false)
        {

            //this.su = su;
            //d1 = new DateTime(DateTime.Now.Year, DateTime.Now.Month, 1);
            //d2 = d1.AddMonths(1).AddDays(-1);

            Workflow.SaveLocalData();

            //var id = 2000;
            //if (su) id = 2001;

            Bridge.Html5.Window.Open("index.html?func=" + 7000, "MobaWFJumbo", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1440, height=900");
        }

        public void Place(HtmlPage page)
        {
            npage = page;

            Document.Title = "Mobatime JBM workflow - Cockpit";

            CreateWindow();
        }

        void CreateWindow()
        {
            //Console.WriteLine("test time out A");

            //npage = new HtmlPage(win.Document, win.Document.Body);
            window = new Mobatime.JSControl.Window(npage, 40);

            window.Container.SetScrolling(Overflow.Auto, Overflow.Auto);

            bClose = window.Toolbar.AddButton(1000);
            bClose.OnButtonClick += BClose_OnButtonClick;

            var imgdir = Workflow.BaseWebPath + "/wf2018/";

            bClose.SetImage(imgdir + "images/63_porte_t.png", 32, 32);

            npage.OnBodyResize += Npage_OnBodyResize;

            var w = npage.Width;// win.InnerWidth;
            var h = npage.Height;// win.InnerHeight;


            var title = "Cockpit";
            lTitle = new Label(title, w - 200, 12, 180, 16, "right");
            window.Toolbar.AddChild(lTitle);
            lTitle.Color = Color.White;
            lTitle.FontSize = 14;

            var func = new Api.ApiFunction(Api.ApiFuncType.GetCockpitCfg);
            func.iparam1 = Workflow.pid;
            var dataCfg = new DataApi<List<string>>(Workflow.api, func);
            dataCfg.OnSuccess += DataCfg_OnSuccess;
        }

        private void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            Bridge.Html5.Window.Close();
        }

        private void DataCfg_OnSuccess(Data<List<string>> data, List<string> result)
        {
            Load(result, false);

        }

        public void Load(List<string> lines, bool general)
        {

            List<int> gpop = new List<int>();
            int qry = 0, type = 0, left = 0, top = 0, width = 0, height = 0, periode = 0;
            DateTime d1 = DateTime.MinValue, d2 = DateTime.MinValue;
            object web = null;
            int idx = 0;

            foreach (var line in lines)
            {
                if (line.StartsWith("workflow_address:"))
                {
                    var baseWF = line.Substring(17).Trim();
                    this.baseWF = baseWF;
                    if (!baseWF.EndsWith("/")) baseWF += "/";
                    baseWF += "sticky/sticky.aspx?";
                    baseUrl = baseWF;
                }
                else if (line.StartsWith("[new sticky]"))
                {
                    if (web != null)
                    {
                        CreateSticky(idx, qry, gpop, type, left, top, width, height, periode, d1, d2);
                        idx++;
                    }

                    web = new object();
                    qry = 0;
                    //gpop = 0;
                    gpop = new List<int>();
                    type = 0;
                    left = 0;
                    top = 0;
                    width = 0;
                    height = 0;
                    periode = 0;
                    d1 = DateTime.MinValue;
                    d2 = DateTime.MinValue;
                }
                else if (line.StartsWith("left:"))
                {
                    int.TryParse(line.Substring(5).Trim(), out left);
                }
                else if (line.StartsWith("top:"))
                {
                    int.TryParse(line.Substring(4).Trim(), out top);
                }
                else if (line.StartsWith("width:"))
                {
                    int.TryParse(line.Substring(6).Trim(), out width);
                }
                else if (line.StartsWith("height:"))
                {
                    int.TryParse(line.Substring(7).Trim(), out height);
                }
                else if (line.StartsWith("query_id:"))
                {
                    int.TryParse(line.Substring(9).Trim(), out qry);
                }
                else if (line.StartsWith("gpop:"))
                {
                    var spop = line.Substring(5);
                    var split = spop.Split(';');
                    for (var i = 0; i < split.Length; i++)
                    {
int gp;
                        int.TryParse(split[i], out gp);
                        if (gp > 0)
                            gpop.Add(gp);
                    }

                    //int.TryParse(line.Substring(5).Trim(), out gpop);
                }
                else if (line.StartsWith("type:"))
                {
                    int.TryParse(line.Substring(5).Trim(), out type);
                }
                else if (line.StartsWith("periode:"))
                {
                    int.TryParse(line.Substring(8).Trim(), out periode);
                }
                else if (line.StartsWith("date1:"))
                {
                    var sd = line.Substring(6);
                    var split = sd.Split('.');
int day;
                    int.TryParse(split[0], out day);
int month;
                    int.TryParse(split[1], out month);
int year;
                    int.TryParse(split[2], out year);

                    d1 = new DateTime(year, month, day);
                }
                else if (line.StartsWith("date2:"))
                {
                    var sd = line.Substring(6);
                    var split = sd.Split('.');
int day;
                    int.TryParse(split[0], out day);
int month;
                    int.TryParse(split[1], out month);
int year;
                    int.TryParse(split[2], out year);

                    d2 = new DateTime(year, month, day);
                }
            }

            CreateSticky(idx, qry, gpop, type, left, top, width, height, periode, d1, d2);
        }

        void CreateSticky(int idx, int qry, List<int> gpop, int type, int left, int top, int width, int height, int periode, DateTime d1, DateTime d2)
        {

            var iframe = new IFrame();
            iframe.Place(left, top, width, height, window.Container);
            iframe.Style.Position = "absolute";
            iframe.Style.Border = "none";
            iframe.iFrame.Scrolling = "no";

            var url = SetParamSticky(idx, gpop, 0, qry, type, "", "", periode, d1, d2, width, height);
            iframe.iFrame.Src = url;

        }

        public string SetParamSticky(int idx, List<int> gpop, int filtre, int qryid, int sd, string color1, string color2, int periode, DateTime d1, DateTime d2, int w, int h)
        {
            var url = "";
            bool online = false;

            if (qryid == 20000) online = true;  // tech
            else if (qryid == 12000) online = true;  // ano
            else if (qryid >= 10000 && qryid <= 10100) online = true;   // pip

            if (online)
            {
                var sgpop = gpop[0].ToString();
                for(var i = 1; i < gpop.Count; i++)
                {
                    sgpop += "_" + gpop[i];
                }
                //string s = "gpop=" + gpop.ToString();
                string s = "gpop=" + sgpop;
                s += "&filtre=" + filtre.ToString();
                s += "&qryid=" + qryid.ToString();
                s += "&dd=" + d1.Day + "_" + d1.Month + "_" + d1.Year;
                s += "&df=" + d2.Day + "_" + d2.Month + "_" + d2.Year;
                s += "&sd=" + sd;
                s += "&w=" + (w - 2);
                s += "&h=" + (h - 2);
                s += "&col1=" + color1;
                s += "&col2=" + color2;
                s += "&bgcolor=fff";
                s += "&tick=" + Environment.TickCount;

                url = baseUrl + s;
            }
            else
            {
                url = baseWF + "/public/tempo/" + Workflow.pid.ToString() + "/" + idx.ToString() + ".html";
            }

            return url;
        }

        private void Npage_OnBodyResize()
        {
            var w = npage.Width;
            var h = npage.Height;

            lTitle.Left = w - 200;

        }
    }
}
