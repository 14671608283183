using System;
using System.Collections.Generic;


namespace Workflow_bridge
{
    public class Right : Api.ApiReturn
    {
        public bool Chef = true;
        public int WFRe_Prof = 0;
        public bool Punch = false;
        public int PunchType = 0;
        public bool PunchWInterruption = false;

        public bool CanDelegate = true;

        public int ModifyPtgType = 0;
        public bool ModifyPtg = false;
        public bool ForceShowPtg = false;
        public bool ModifySeco = false;
        public bool MessageMandatory = false;
        public bool ValidPtg = false;
        public bool ValidPtgAuto = false;
        public bool Cockpit = false;
        public int LimitRetro = 0;
        public int LimiteAnticipe = 0;
        public DateTime LimitPtgMin = DateTime.MinValue;
        public DateTime LimitPtgMax = DateTime.MaxValue;

        public bool ValidAno = false;
        public bool OpenHJ = false;
        public bool OpenPause = false;
        public bool OpenZone = false;
        public bool HJExc = false;
        public int Piquet = 0;
        public int JS1 = 0;
        public int JS2 = 0;
        public int JS3 = 0;
        public int JS4 = 0;
        public string JS1Name = "";
        public string JS2Name = "";
        public string JS3Name = "";
        public string JS4Name = "";

        public bool MessageUser = false;
        public bool MessageChef = false;

        public bool CostControl = false;
        public bool SaisieDebours = false;
        public bool KMTimeControl = false;

        public bool Print = false;
        public bool Debours = false;
        public int DeboursWKType = 0;
        public bool Pip = false;

        public bool VueMensuel = true;
        public bool PrevMonth = true;
        public bool NextMonth = true;

        public bool Verrou = false;
        public bool JBMmobile = false;
        public bool QRCode = false;
        public string QRAddress = "";
        public int Team = 0;
        
        public bool JumboAbs = false;
        public bool JumboSelf = false;
        public bool VueAnnuel = false;
        public bool VueTeam = false;
        public bool VuePlan = false;

        public bool Absence = false;
        public bool LimitAbs1 = false;
        public DateTime LimitAbsMin = DateTime.MaxValue;
        public DateTime LimitAbsMax = DateTime.MaxValue;

        public List<int> ChefAbsRight = new List<int>();
        public List<bool> CCAbs = new List<bool>();
        public List<bool> CCCAbs = new List<bool>();

        //public bool SaisieCF = false;
        //public bool SaisieCFValidation = false;

        public bool GlobalValidation = false;
        public bool TodoAccess = true;

        public bool SaisieActivite = false;
        public int SaisieActiviteType = 0;
        public int SaisieWork = 0;
        public bool ActiviteNeedValidation = false;
        public bool ChefModifActivite = false;
        public bool ChefValidActivite = false;
        public bool ChefActivityWork = false;
        public bool ChefWorkData = false;
        public bool CreateOrdre = false;
        public int LimitActPrev = 0;
        public int LimitActNext = 0;

        public int SuExt = 0;

        //public string kor1 = "";
        //public string kor2 = "";
        //public int kor1_typeA = 0;
        //public int kor1_typeB = 0;
        //public int kor2_typeA = 0;
        //public int kor2_typeB = 0;
        //public int kor1_repA = 0;
        //public int kor1_repB = 0;
        //public int kor2_repA = 0;
        //public int kor2_repB = 0;

        public List<string> kor_texts = new List<string>();
        public List<int> kor_typeA = new List<int>();
        public List<int> kor_typeB = new List<int>();
        public List<int> kor_repA = new List<int>();
        public List<int> kor_repB = new List<int>();
        public bool Kor = false;

        public List<int> WFChefIDs = new List<int>();
        public List<string> WFChefNames = new List<string>();
        public List<DateTime> WFChefDate1 = new List<DateTime>();
        public List<DateTime> WFChefDate2 = new List<DateTime>();
        public List<int> WFChefProf = new List<int>();
    }
}