using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;

namespace Workflow_bridge
{
    public class PanelValidationQ : Panel
    {
        public class Qty
        {
            public int pid;
            public string name;
            public DateTime date;
            public int rowidx;
            public int block;
            public int statut;
            public int statut3;
            public int statut4;
            public int statut5;
            public int statutBase;
            public int validation;
            public int ordre;
            public int duree;
            public string text;

            public string idc;
            public string idn;
            public string desc;
            public string descc;
            public string denom;

            public string[] txt = new string[5];

            public Panel pRow;
            public Panel pValid;

            public Qty() { }
            public Qty (CSVTool.CSVObject csv, int idx)
            {
                pid = csv.I32(idx++);
                name = csv[idx++];
                date = csv.Date(idx++);
                block = csv.I32(idx++);
                rowidx = csv.I32(idx++);
                statut = csv.I32(idx++);
                statut3 = csv.I32(idx++);
                statut4 = csv.I32(idx++);
                statut5 = csv.I32(idx++);
                statutBase = csv.I32(idx++);
                validation = csv.I32(idx++);
                ordre = csv.I32(idx++);
                duree = csv.I32(idx++);
                text = csv[idx++];

                idc = csv[idx++];
                idn = csv[idx++];
                desc = csv[idx++];
                descc = csv[idx++];
                denom = csv[idx++];
                idx += 4;
                txt[0] = csv[idx++];
                txt[1] = csv[idx++];
                txt[2] = csv[idx++];
                txt[3] = csv[idx++];
                txt[4] = csv[idx++];

            }
        }

        List<Qty> qties = new List<Qty>();
        Dictionary<int, string> dicoPers = new Dictionary<int, string>();

        Button bClose, b1, b2, b3, bv1, bv2, bv3, bModify;
        Label lDate;
        DateTime d1;
        MensualView.MensualView view = null;

        Panel lstQties, pdetail, pquery;
        List<Panel> lstPQties = new List<Panel>();

        Combo cboValidation;
        Combo cboDate;
        Combo cboPers;

        Button bExc;
        Color[] validColor = new Color[64];

        PanelActivity pa = null;

        int role = 1;

        string[] t2 = { "ID code saisie", "ID code numérique", "Description", "Description courte", "Dénomination" };
        string[] t2c = { "idc", "idn", "desc", "descc", "denom" };
        Dictionary<string, Label> labels = new Dictionary<string, Label>();
        Dictionary<string, Field> fields = new Dictionary<string, Field>();

        int selPid, selRowIdx, selBlock;
        DateTime selDate;
        Api.ApiReturn selB;

        Dictionary<string, int[]> dicoDat = new Dictionary<string, int[]>();

        int scrollList = 0;

        public PanelValidationQ(MensualView.MensualView view)
        {

            for (var i = 0; i < t2.Length; i++)
                t2[i] = Res.Get(13101 + i, t2[i]);

            this.view = view;

            this.view = view;
            BackgroundColor = GetColor("window.back");
            Size = new Size(1270, 850);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = GetColor("toolbar.back");

            var lTitle = new Label(Res.Get(13000, "Contrôle et validation des saisies"), 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            var x = 4;
            bClose = new Button(x, 0, 32, 32, "images/63_porte_t.png", pTitle, false, 99); x += 48;
            bClose.OnButtonClick += BMenu_Click;

            b1 = new Button(x, 0, 32, 32, "images/arrow_left.png", pTitle, false, 1); x += 40;
            b2 = new Button(x, 0, 32, 32, "images/now.png", pTitle, false, 2); x += 40;
            b3 = new Button(x, 0, 32, 32, "images/arrow_right.png", pTitle, false, 3); x += 40;
            b1.OnButtonClick += BMenu_Click;
            b2.OnButtonClick += BMenu_Click;
            b3.OnButtonClick += BMenu_Click;

            lDate = new Label("", b3.Right + 16, 8, 400, 16, "left", pTitle);
            lDate.Color = Color.White;

            validColor[0] = Color.Blue;
            validColor[1] = Color.Green;
            validColor[2] = Color.Red;
            validColor[4] = new Color(255, 127, 0, 255);
            validColor[8] = new Color(255, 127, 0, 255);
            validColor[16] = Color.Green;

            PlaceObj1();
            PlaceObj2();
            PlaceObj3();



            //var wid = Tool.LSReadInt("validationQType", 0);
            //cboValidation.SelectById(wid);
            //wid = Tool.LSReadInt("validationQDate", 0);
            //cboDate.SelectById(wid, true);
        }

        void BMenu_Click(Control b, Bridge.Html5.MouseEvent e)
        {
            switch(b.id1)
            {
                case 99:
                    view.CheckQtyValidation();
                    view.CloseLock();
                    break;

                case 1:
                    d1 = d1.AddDays(-7);
                    LoadQties();
                    break;
                case 2:
                    d1 = Fmt.GetMonday(DateTime.Now).Date;
                    LoadQties();
                    break;
                case 3:
                    d1 = d1.AddDays(7);
                    LoadQties();
                    break;
            }

            SetDate();
        }

        void SetDate()
        {
            var dd1 = d1;
            var dd2 = DateTime.MinValue;

            var dtype = cboDate.SelectedId;

            switch (dtype)
            {
                case 0:
                    dd1 = Fmt.GetMonday(d1);
                    dd2 = dd1.AddDays(6);
                    break;
                case 10:
                    dd2 = DateTime.Now.Date;
                    dd1 = dd2.AddDays(-10);
                    break;
                case 40:
                    dd2 = DateTime.Now.Date;
                    dd1 = dd2.AddDays(-40);
                    break;
            }

            lDate.Text = Fmt.DateComplete(dd1) + " - " + Fmt.DateComplete(dd2);
        }

        void PlaceObj1()
        {
            var p = pquery = new Panel(16, 64, 500, 200, Color.White, this);

            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(13010, "Saisies à contrôler"), 8, 8, p2.Width - 8, 16, "left", p2);
            l.Color = Color.White;

            cboDate = new Combo(Res.Get(13011, "Période d'analyse"), 16, 56, 200, 24, p);
            cboDate.Add(0, Res.Get(13020, "1 semaine"));
            cboDate.Add(10, Res.Get(13021, "J-10"));
            cboDate.Add(20, "J-20");
            cboDate.Add(30, "J-30");
            cboDate.Add(40, Res.Get(13022, "J-40"));

            cboDate.OnChange += CboDate_OnChange;



            cboValidation = new Combo(Res.Get(13012, "Validation à contrôler"), 16, 112, 200, 24, p);
            cboValidation.Add(0, Res.Get(13030, "Sans validation"));
            cboValidation.Add(1, Res.Get(13031, "Sans validation N1"));
            //cboValidation.Add(2, Res.Get(13032, "Sans validation N2"));
            cboValidation.Add(50, Res.Get(13033, "Refusée"));
            cboValidation.Add(60, "Validé");
            cboValidation.Add(99, Res.Get(13034, "Sans contrôle"));
            cboValidation.SelectById(90);
            cboValidation.OnChange += c =>
            {
                Tool.LSWrite("validationQType", cboValidation.SelectedId);
            };



            bExc = new Button(p.Width - 128, 56, 96, 32, "", p, true);
            bExc.Text = Res.Get(13013, "Requête!");
            bExc.OnButtonClick += (a, b) =>
            {
                LoadQties();
            };


        }
        
        void PlaceObj2()
        {
            var p = new Panel(532, 64, Width - 548, Height - 80, Color.White, this);

            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(13200, "Résultats"), 8, 8, p2.Width - 16, 16, "right", p2);
            l.Color = Color.White;

            cboPers = new Combo("", 200, 4, 200, 24, p2);
            cboPers.Visible = Bridge.Html5.Visibility.Hidden;
            cboPers.OnChange += c => RefreshList();

            bv1 = new Button(4, 0, 32, 32, "images/ok.png", p2, false, 1);
            bv2 = new Button(44, 0, 32, 32, "images/cancel.png", p2, false, 2);
            bv3 = new Button(84, 0, 32, 32, "images/arrow-left-right.png", p2, false, 3);

            bv1.OnButtonClick += BValid_OnClick;
            bv2.OnButtonClick += BValid_OnClick;
            bv3.OnButtonClick += BValid_OnClick;

            bv1.Visible = bv2.Visible = bv3.Visible = Bridge.Html5.Visibility.Hidden;

            bModify = new Button(cboPers.Right + 80, 0, 32, 32, "images/63modifie_t.png", p2, false, 11);
            bModify.Visible = Bridge.Html5.Visibility.Hidden;
            bModify.OnButtonClick += (a, b) => 
            {
                if(pa == null)
                {
                    pa = new PanelActivity(view);
                    pa.ZIndex = "999";
                    pa.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
                    Parent.AddChild(pa);
                    pa.OnHide += () => 
                    {
                        pdetail.Visible = Bridge.Html5.Visibility.Inherit;
                        pquery.Visible = pdetail.Visible;
                    };
                    pa.OnSave += () =>
                    {
                        LoadQties();
                        pdetail.Visible = Bridge.Html5.Visibility.Inherit;
                        pquery.Visible = pdetail.Visible;
                    };
                }

                scrollList = lstQties.Div.ScrollTop;

                pa.ShowFrom(Left + p.Left, p.Width, p.Height, this, selPid, selDate, selRowIdx, selBlock, selB);
                pdetail.Visible = Bridge.Html5.Visibility.Hidden;
                pquery.Visible = pdetail.Visible;

            };

            var tempo = new Panel(0, 55, p.Width, 1, new Color(220), p);
            lstQties = new Panel(0, 56, p.Width, p.Height - 56, new Color(220), p);
            lstQties.SetScrolling(Bridge.Html5.Overflow.Hidden, Bridge.Html5.Overflow.Scroll);
        }

        void PlaceObj3()
        {
            var p = pdetail = new Panel(16, 280, 500, Height - 296, Color.White, this);

            var p2 = new Panel(0, 0, p.Width, 32, Color.Mobatime2, p);
            var l = new Label(Res.Get(13100, "Détails"), 8, 8, p2.Width - 8, 16, "left", p2);
            l.Color = Color.White;

            var y = 56;

            labels["date"] = new Label("", 16, y, p.Width - 32, 16, "right", p);
            labels["date"].FontSize = 14;

            labels["pers"] = new Label("", 16, y, p.Width - 32, 16, "left", p);
            labels["pers"].FontSize = 14;

            y += 56;

            for (var i = 0; i < t2.Length; i++)
            {
                l = new Label(t2[i] + ":", 16, y, 170, 16, "right", p);
                labels[t2c[i]] = new Label("", l.Right + 4, l.Top, 250, 16, "left", p);
                if (i == 1) y += 16;
                y += 32;

                if(l.Text.Length <= 2)
                {
                    l.Visible = Bridge.Html5.Visibility.Hidden;
                    labels[t2c[i]].Visible = Bridge.Html5.Visibility.Hidden;
                }
            }

            y += 32;

            var f = fields["duree"] = new Field(32, y, 60, Res.Get(13106, "Durée") + ":", p, Field.FieldFormat.HQty);
            fields["duree"].Input.ReadOnly = true;

            fields["text"] = new Field(f.Right + 16, y, p.Width - f.Right - 32, Res.Get(13107, "Commentaire") + ":", p, Field.FieldFormat.Text);
            fields["text"].Input.ReadOnly = true;

        }
                                                          
        private void CboDate_OnChange(Combo combo)
        {
            var vis = Bridge.Html5.Visibility.Inherit;
            if (cboDate.SelectedId != 0) vis = Bridge.Html5.Visibility.Hidden;

            b1.Visible = b2.Visible = b3.Visible = vis;

            if (cboDate.SelectedId >= 10 && cboDate.SelectedId <= 40) d1 = DateTime.Now.Date;

            SetDate();

            Tool.LSWrite("validationQDate", combo.SelectedId);

            if (cboDate.SelectedId == 0) LoadQties();
            else Clear();
        }

        void Clear()
        {
            qties.Clear();
            cboPers.Clear();
            dicoPers.Clear();
            lstPQties.Clear();
            lstQties.ClearChildren();
            cboPers.Visible = Bridge.Html5.Visibility.Hidden;
            bv1.Visible = bv2.Visible = bv3.Visible = Bridge.Html5.Visibility.Hidden;

            //Console.WriteLine("clear");
        }


        private void BValid_OnClick(Button c, Bridge.Html5.MouseEvent arg)
        {
System.Action<int> _Valid = null;
_Valid = (v) =>
{
    var pid = cboPers.SelectedId;
    foreach (var q in qties)
        if (q.validation != 0 && q.validation != 16 && (pid <= 0 || pid == q.pid))
            Valide(q, v);
}

;
            if (role == 1)
            {

                switch (c.id1)
                {
                    case 1:
                        //foreach (var q in qties)
                        //    if (q.validation != 0 && q.validation != 16)
                        //        Valide(q, 1);
                        _Valid(1);
                        break;

                    case 2:
                        //foreach (var q in qties)
                        //    if (q.validation != 0 && q.validation != 16)
                        //        Valide(q, 2);
                        _Valid(2);
                        break;

                    case 3:
                        //foreach (var q in qties)
                        //    if (q.validation != 0 && q.validation != 16)
                        //        Valide(q, 4);
                        _Valid(4);
                        break;
                }
            }

            


            SaveValidation();
        }

        public void Show()
        {
            d1 = DateTime.Now;
            lstQties.ClearChildren();

            var wid = Tool.LSReadInt("validationQType", 0);
            cboValidation.SelectById(wid);

            wid = Tool.LSReadInt("validationQDate", 0);
            cboDate.SelectById(wid, true);

            ResetDetails();
        }

        void LoadQties()
        {
            var pid = -1;
            if (cboPers.Visible != Bridge.Html5.Visibility.Hidden)
                pid = cboPers.SelectedId;

            
            Clear();

            var typePer = cboDate.SelectedId;
            var dayCount = 0;
            if (typePer == 10) dayCount = 10;
            else if (typePer == 40) dayCount = 40;
            else if (typePer == 20) dayCount = 20;
            else if (typePer == 30) dayCount = 30;

            if (typePer >= 10 && typePer <= 40) typePer = 40;    // 10 jour fonctionne comme 40 jours

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityQties2);
            func.iparam1 = Workflow.pid;
            func.iparam2 = D2000.T(d1);
            func.iparam3 = dayCount;
            func.iparam4 = cboValidation.SelectedId;
            func.iparam5 = typePer;

            var sbPid = new StringBuilder();

            foreach(var item in view.CboWF.Items)
            {
                if (item.Value != "0")
                {
                    sbPid.Append(item.Value);
                    sbPid.Append(';');
                }
            }

            func.param1 = sbPid.ToString();



            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                if (b.return_s1.Length > 0)
                {
                    var csv = new CSVTool(b.return_s1)[0];
                    var csv2 = new CSVTool(b.return_s2)[0];

                    for (var i = 0; i < csv.Count; i += 28)
                    {
                        var q = new Qty(csv, i);
                        qties.Add(q);

                        if (!dicoPers.ContainsKey(q.pid))
                            dicoPers.Add(q.pid, q.name);
                    }

                    dicoDat.Clear();
                    if (b.return_s2.Length > 1)
                    {
                        for (var i = 0; i < csv2.Count; i += 8)
                        {
                            var key = csv2[i];
                            dicoDat[key] = new int[5];
                            for (var j = 0; j < 5; j++)
                                dicoDat[key][j] = csv2.I32(i + 3 + j);
                        }
                    }
                }


                if (dicoPers.Count > 1)
                {
                    cboPers.Visible = Bridge.Html5.Visibility.Inherit;
                    cboPers.Add(0, Res.Get(13201, "Tout le monde"));
                    foreach (var pair in dicoPers)
                        cboPers.Add(pair.Key, pair.Value);
                    if (pid <= 0) cboPers.SelectById(0);
                    else cboPers.SelectById(pid);
                }
                else cboPers.Visible = Bridge.Html5.Visibility.Hidden;

                RefreshList();
            };

        }

        int AddDay(DateTime date, int pid, string name, int y)
        {
            var p = new Panel(0, y, lstQties.Width, 40, new Color(200), lstQties);
            var l = new Label(Fmt.DateComplete(date), 4, 4, p.Width, p.Height - 8, "left", p);
            l = new Label(name, 4, 4, p.Width - 44, p.Height - 8, "right", p);

            var sdate = string.Format("{0}{1}{2}",date.Year,date.Month.ToString().PadLeft(2, '0'),date.Day.ToString().PadLeft(2, '0'));
            var key = string.Format("{0}{1}",sdate,pid.ToString().PadLeft(6, '0'));

            Label lv;

            if (dicoDat.ContainsKey(key))
            {
                Console.WriteLine(key);
                var dat = dicoDat[key];

                l = new Label("Prés:", 150, 2, 75, 16, "right", p);
                lv = new Label(Fmt.HQty(dat[0]), l.Right + 4, l.Top, 50, 16, "right", p);
                l = new Label("AbsT:", 150, 22, 75, 16, "right", p);
                lv = new Label(Fmt.HQty(dat[1]), l.Right + 4, l.Top, 50, 16, "right", p);

                l = new Label("T/A:", 275, 2, 75, 16, "right", p);
                lv = new Label(Fmt.HQty(dat[3]), l.Right + 4, l.Top, 50, 16, "right", p);
                l = new Label("+-:", 275, 22, 75, 16, "right", p);
                lv = new Label(Fmt.HQty(dat[4]), l.Right + 4, l.Top, 50, 16, "right", p);

                if(dat[4] < 0)
                    l.Color = lv.Color = Color.Red;
            }

            return p.Bottom + 1;
        }

        int AddQ(Qty qty, int y)
        {
            var p = new Panel(0, y, lstQties.Width, 64, Color.White, lstQties, lstPQties);
            p.tag = qty;
            p.Div.OnMouseDown += (a) =>
            {
                LoadQtyDetail(qty.pid, qty.date, qty.rowidx, qty.block);
            };
            p.Div.OnDblClick += (a) => 
            {
                Valide(qty);
                SaveValidation();
            };

            var t1 = qty.txt[0];
            var status = qty.statut;
            if (status >= 100 && status < 200) t1 = "*" + t1;

            var l = new Label(t1, 20, 4, p.Width - 144, p.Height - 8, "left", p);
            l = new Label(qty.txt[2], 150, 4, p.Width - 150 - 112, p.Height - 8, "right", p);
            l = new Label(qty.txt[1], 20, 24, p.Width - 44, p.Height - 8, "left", p);
            l = new Label(qty.txt[3], 20, 44, p.Width - 144, p.Height - 8, "left", p);
            l = new Label(qty.txt[4], 150, 24, p.Width - 150 - 112, p.Height - 8, "right", p);

            l = new Label(Fmt.HHmm(qty.duree), p.Width - 96, 36, 70, p.Height - 8, "right", p);
            l.FontSize = 14;


            var pV = new Panel(0, 0, 8, p.Height, Color.White, p);
            pV.id1 = 100;
            pV.BackgroundColor = validColor[qty.validation];

            qty.pRow = p;
            qty.pValid = pV;

            if (qty.pid == selPid && qty.date.Date == selDate.Date && qty.rowidx == selRowIdx && qty.block == selBlock)
            {
                p.SelectMe();
                LoadQtyDetail(qty.pid, qty.date, qty.rowidx, qty.block);
            }

            return p.Bottom + 1;
        }

        void Valide(Qty qty, int nv = -1)
        {
            if (qty.statut >= 100) return;

            if (nv == -1)
            {
                if (role == 1)
                {
                    if (qty.validation == 4) nv = 1;
                    else if (qty.validation == 1) nv = 2;
                    else if (qty.validation == 2) nv = 4;
                }
            }

            if (nv == -1) return;
            //            qty.pValid.BackgroundColor = validColor[nv];

            scrollList = lstQties.Div.ScrollTop;

            qty.validation = nv;

        }

        void RefreshList()
        {
            bModify.Visible = Bridge.Html5.Visibility.Hidden;
            lstPQties.Clear();
            lstQties.ClearChildren();

            DateTime lastDate = DateTime.MinValue;
            int lastPid = 0;
            int y = 0;
            int count = 0;

            foreach (var q in qties)
            {
                var pid = q.pid;

                if (cboPers.Visible == Bridge.Html5.Visibility.Inherit && cboPers.SelectedId != 0 && cboPers.SelectedId != pid)
                    continue;

                var date = q.date;
                if (date != lastDate || pid != lastPid)
                {
                    y =  AddDay(date, pid, q.name, y);
                    lastDate = date;
                    lastPid = pid;
                }

                y = AddQ(q, y);
                count++;
            }

            bv1.Visible = bv2.Visible = bv3.Visible = count <= 0 ? Bridge.Html5.Visibility.Hidden : Bridge.Html5.Visibility.Inherit;

            lstQties.Div.ScrollTop = scrollList;
            scrollList = 0;
        }

        void SaveValidation()
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.SaveActivityValidation2);
            func.iparam1 = Workflow.pid;
            var csv = new CSVTool();

            var pid = cboPers.SelectedId;
            //Console.WriteLine("pid: " + pid);

            foreach(var q in qties)
            {
                if (pid <= 0 || pid == q.pid)
                {
                    csv.A(q.pid);
                    csv.A(q.date);
                    csv.A(q.block);
                    csv.A(q.rowidx);
                    csv.A(q.validation);
                }
            }
            func.param1 = csv.ToString();

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                LoadQties();
            };

        }

        void LoadQtyDetail(int pid, DateTime date, int rowidx, int block)
        {
            bModify.Visible = Bridge.Html5.Visibility.Hidden;

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityDetail2);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(date);
            func.iparam3 = rowidx;
            func.iparam4 = block;

            ResetDetails();

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                var csv = new CSVTool(b.return_s1)[0];
                var idx = 0;
                rowidx = csv.I32(idx++);
                block = csv.I32(idx++);
                var persid = csv.I32(idx++);
                var name = csv[idx++];
                var ofid = csv.I32(idx++);
                var oid = csv.I32(idx++);
                var idc = csv[idx++];
                var idn = csv[idx++];
                var desc = csv[idx++];
                var descc = csv[idx++];
                var denom = csv[idx++];
                var duree = csv.I32(idx++);
                var valid = csv.I32(idx++);
                var status = csv.I32(idx++);
                idx += 6;

                var t1 = csv[idx++];
                var t2 = csv[idx++];
                var t3 = csv[idx++];
                var t4 = csv[idx++];
                var t5 = csv[idx++];

                labels["idc"].Text = t1;
                labels["idn"].Text = t2;
                labels["desc"].Text = t3;
                labels["descc"].Text = t4;
                labels["denom"].Text = t5;
                fields["duree"].Value = duree;
                labels["pers"].Text = name;
                labels["date"].Text = Fmt.DateComplete(date);

                fields["text"].Input.Value = b.return_s5;

                selPid = pid;
                selDate = date;
                selRowIdx = rowidx;
                selBlock = block;
                selB = b;

                if ((valid == 1 || valid == 4) && status < 100 && Workflow.RightSU.ChefModifActivite)
                    bModify.Visible = Bridge.Html5.Visibility.Inherit;
            };
        }

        void ResetDetails()
        {
            labels["idc"].Text = "";
            labels["idn"].Text = "";
            labels["desc"].Text = "";
            labels["descc"].Text = "";
            labels["denom"].Text = "";
            fields["duree"].Value = 0;
            labels["pers"].Text = "";
            labels["date"].Text = "";

            fields["text"].Input.Value = "";
        }

    }


}
