using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;

namespace Workflow_bridge.Jumbo
{
    class JumboRow : Panel
    {

        int type = 0;
        Label lNom = null;

        public JumboRow(int pid, DateTime d1, DateTime d2, int type, CSVTool.CSVObject obj, int[] inDays)
        {
            BackgroundColor = new Color(240);
            var idx = 2;

            var nbDay = (int)(d2 - d1).TotalDays;
            nbDay++;

            var x = 0;
            this.type = type;
            if (type < 3)
            {
                var oldD = d1.AddMonths(-1);
                var k = 0;
                for (var d = d1; d <= d2; d = d.AddDays(1))
                {

                    if (oldD.Month != d.Month)
                    {
                        if (x > 0)
                            x += 3;

                        oldD = d;
                    }

                    var p = new Panel();
                    p.Place(x, 0, JumboView.ColWidth - 1, 32, this);
                    p.BackgroundColor = Color.White;

                    var ctr = obj.I32(idx++);
                    if (ctr <= 0) p.BackgroundColor = new Color(127);

                    var hn = obj.I32(idx++);
                    if (hn == 0) p.BackgroundColor = new Color(245);

                    if (d.DayOfWeek == DayOfWeek.Sunday) p.BackgroundColor = new Color(240);

                    bool anyAbs = false;
                    bool defColor = true;
                    var ac1 = obj.I32(idx + 0);
                    var ad1 = obj.I32(idx + 1);
                    var s1 = obj.I32(idx + 2);
                    var ac2 = obj.I32(idx + 3);
                    var ad2 = obj.I32(idx + 4);
                    var s2 = obj.I32(idx + 5);
                    idx += 6;

                    if ((ac1 > 0 && ac2 == 0) || (ac1 == 0 && ac2 > 0))
                    {
                        var ac = ac1;
                        var s = s1;
                        var ad = ad1;
                        var aid = 0;
                        if (ac2 != 0)
                        {
                            ac = ac2;
                            s = s2;
                            ad = ad2;
                            aid = 1;
                        }

                        var y = 0;
                        var h = 32;

                        if (ad < 5 * 60) h = 16;
                        if (h == 16 && aid > 0) y = 16;
                        DrawAbs(ac, s, p, type, y, h);
                    }
                    else if (ac1 != 0 && ac2 != 0)
                    {
                        p.Height = 16;
                        var p2 = new Panel(p.Parent);
                        p2.Place(p.Left, p.Bottom, p.Width, p.Height);

                        anyAbs = true;

                        var abrev = Workflow.CatAbs.txt_info[ac1];
                        var color = Workflow.CatAbs.color[ac1];
                        if (abrev.Length > 0)
                        {
                            if (s1 == 3) abrev = "(" + abrev + ")";
                            var l = new Label(abrev, 0, 2, 40, 16, "center", p);
                            if (s1 == 3) l.FontStyle = Bridge.Html5.FontStyle.Italic;
                            if (type == 2 && s1 == 15) l.Paragraph.Style.TextDecoration = "line-through";
                            l.FontSize = 9;
                            p.BackgroundColor = Workflow.Colors16[color];

                            if (color == 5) l.Color = Color.White;

                            defColor = false;
                        }


                        abrev = Workflow.CatAbs.txt_info[ac2];
                        color = Workflow.CatAbs.color[ac2];
                        if (abrev.Length > 0)
                        {
                            if (s2 == 3) abrev = "(" + abrev + ")";
                            var l = new Label(abrev, 0, 2, 40, 16, "center", p2);
                            if (s2 == 3) l.FontStyle = Bridge.Html5.FontStyle.Italic;
                            if (type == 2 && s2 == 15) l.Paragraph.Style.TextDecoration = "line-through";
                            l.FontSize = 9;

                            if (color == 5) l.Color = Color.White;


                            p2.BackgroundColor = Workflow.Colors16[color];
                            defColor = false;
                        }

                    }



                    if (anyAbs && defColor) p.BackgroundColor = new Color(220);

                    k++;
                    x += JumboView.ColWidth;
                }

                if (type == 2)
                {


                    for (var i = 0; i < 3; i++)
                    {
                        var v = obj.I32(idx++);

                        var p = new Panel();
                        p.Place(x, 0, JumboView.ColWidth * 2 - 1, 32, this);
                        p.BackgroundColor = new Color(230);

                        var l = new Label(Fmt.HHmm(v), 0, 12, p.Width, 16, "center", p);

                        x += JumboView.ColWidth * 2;
                    }
                }
            }
            else
            {
                var count = obj.strings.Count;
                
                for (var i = 2; i < count; i += 2)
                {
                    var cidx = i;
                    var inDay = inDays[(i - 2) / 2] == 1;

                    var p = new Panel();
                    p.Place(x, 0, JumboView.ColWidth * 3 - 1, 32, this);
                    p.BackgroundColor = Color.White;

                    var c = obj.I32(cidx);
                    var v = Fmt.HHmm(obj.I32(cidx + 1));
                    if (inDay) v = Fmt.Day(obj.I32(cidx + 1));

                    var l = new Label(v, 0, 8, p.Width, 16, "center", p);

                    if (c == 1) l.Color = Color.Red;
                    else if (c == 2) l.Color = new Color(0, 200, 0);

                    x += JumboView.ColWidth * 3;
                }
            }


            Size = new Size(x, 32);

        }

        void DrawAbs(int ac, int s, Panel parent, int type, int y, int h)
        {
            var p = new Panel(parent);
            p.Place(0, y, JumboView.ColWidth, h);

            var abrev = Workflow.CatAbs.abrev[ac];
            var txtinfo = Workflow.CatAbs.txt_info[ac];
            var txt = "";
            var color = Workflow.CatAbs.color[ac];

            var colorFinal = Workflow.Colors16[color];

            if(type == 1)
            {
                txt = txtinfo;
                if (txtinfo.Length <= 0)
                    colorFinal = new Color(230);
            }
            else if(type == 2)
            {
                txt = abrev;
            }

            //if ((type == 1 && abrev.Length > 0) || (type == 2))
            {
                if (s == 3 && txt.Length > 0) txt = "(" + txt + ")";
                var yl = 8;
                if (h < 32) yl = 0;
                var l = new Label(txt, 0, yl, 40, h, "center", p);

                if (s == 3) l.FontStyle = Bridge.Html5.FontStyle.Italic;
                if (type == 2 && s == 15) l.Paragraph.Style.TextDecoration = "line-through";
                l.FontSize = 8;

                p.BackgroundColor = colorFinal;// Workflow.Colors16[color];
                if (color == 5) l.Color = Color.White;

            }
        }

    }
}
