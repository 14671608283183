using System.Collections.Generic;
using Bridge.Html5;

namespace Workflow_bridge
{
    public class Workflow
    {
        public static int connectedPID = 0;
        public static int pid = 0;
        public static int wfid_su = 0;
        public static int wfid_re = 0;
        public static string nom = "";
        public static string api = "";
        public static long token = 0;
        public static string BaseWebPath = "";
        public static string empl = "";
        public static int pfunc = 0;
        public static int WFSU = 0;

        public static bool Dark = false;

        public static bool akt_hidefixebutton = false;
        public static bool wkdata_complement = false;
        public static bool perm_bValidMonth = true;
        public static bool perm_uploadCost = true;
        public static bool perm_todo_unlock = true;
        public static bool perm_su_hjexc = false;
        public static bool hide_outdated_pers = false;
        public static bool show_work_data = false;
        public static bool show_work_data_switch = true;
        public static bool activity_full_orders = false;
        public static bool activity_hide_compl = false;
        public static bool workdata_show_qty = false;
        public static bool wf_absrefused_alwaysred = false;
        public static bool show_todo_su = true;
        public static bool mode_prometerre = false;
        public static bool todo_piquet = false;
        public static bool todo_workopen = false;
        public static bool perm_HideOldQtyButton = false;
        //public static bool special_stefan = false;
        public static bool qty_no_zero = false;
        public static bool qty_less_than_24h = false;
        public static bool show_saveAktMore = false;

        public static List<int> absintro_special = new List<int>();

        public static Dictionary<int, WorkActivity.OF> OFs = null;
        public static Dictionary<int, WorkActivity.Ordre> ordres = null;

        public static bool MAS = false;
        public static bool ForceMASToFalse = false;

        public static int PtgCount { get; set; }

        public static Right RightSU = new Right();

        public static Api.API_CatAbs CatAbs = null;
        public static string[] CFAbrev = new string[2000];
        public static Dictionary<int, string> CFAbrevSorted = new Dictionary<int, string>();

        public static Dictionary<int, int> WFProfil = new Dictionary<int, int>();

        public static int TimerTodoID = 0;

        public static Mobatime.JSControl.Color[] Colors16 = new Mobatime.JSControl.Color[16];

        public static Mobatime.JSControl.Color[] planColor16 = new Mobatime.JSControl.Color[16];
        public static Mobatime.JSControl.Color[] planColor16Light = new Mobatime.JSControl.Color[16];
        public static Mobatime.JSControl.Color[] planColor16Dark = new Mobatime.JSControl.Color[16];

        public static void Init()
        {
            Colors16[0] = new Mobatime.JSControl.Color(220, 220, 220);
            Colors16[1] = new Mobatime.JSControl.Color(255, 0, 0);
            Colors16[2] = new Mobatime.JSControl.Color(255, 255, 0);
            Colors16[3] = new Mobatime.JSControl.Color(0, 255, 0);
            Colors16[4] = new Mobatime.JSControl.Color(0, 255, 255);
            Colors16[5] = new Mobatime.JSControl.Color(0, 0, 255);
            Colors16[6] = new Mobatime.JSControl.Color(255, 0, 255);
            Colors16[7] = new Mobatime.JSControl.Color(100, 60, 20);
            Colors16[8] = new Mobatime.JSControl.Color(180, 210, 150);
            Colors16[9] = new Mobatime.JSControl.Color(240, 160, 190);
            Colors16[10] = new Mobatime.JSControl.Color(230, 90, 15);
            Colors16[11] = new Mobatime.JSControl.Color(0, 150, 55);
            Colors16[12] = new Mobatime.JSControl.Color(0, 190, 230);
            Colors16[13] = new Mobatime.JSControl.Color(25, 40, 130);
            Colors16[14] = new Mobatime.JSControl.Color(105, 10, 90);
            Colors16[15] = new Mobatime.JSControl.Color(170, 130, 80);

            planColor16[0] = Mobatime.JSControl.Color.White;
            planColor16[1] = new Mobatime.JSControl.Color(192, 192, 192);
            planColor16[2] = new Mobatime.JSControl.Color(192, 192, 255);
            planColor16[3] = new Mobatime.JSControl.Color(255, 192, 192);
            planColor16[4] = new Mobatime.JSControl.Color(192, 255, 192);
            planColor16[5] = new Mobatime.JSControl.Color(255, 255, 192);
            planColor16[6] = new Mobatime.JSControl.Color(255, 192, 255);
            planColor16[7] = new Mobatime.JSControl.Color(192, 255, 255);
            planColor16[8] = new Mobatime.JSControl.Color(160, 160, 0);
            planColor16[9] = new Mobatime.JSControl.Color(198, 89, 17);
            planColor16[10] = new Mobatime.JSControl.Color(191, 143, 0);
            planColor16[11] = new Mobatime.JSControl.Color(47, 117, 181);
            planColor16[12] = new Mobatime.JSControl.Color(84, 130, 53);
            planColor16[13] = new Mobatime.JSControl.Color(255, 102, 0);
            planColor16[14] = new Mobatime.JSControl.Color(204, 51, 153);
            planColor16[15] = new Mobatime.JSControl.Color(246, 150, 121);

            for (var i = 0; i < planColor16.Length; i++)
            {
                planColor16Light[i] = Mobatime.JSControl.Color.Mul(planColor16[i], 1.25);
                planColor16Dark[i] = Mobatime.JSControl.Color.Mul(planColor16[i], 0.75);
            }
        }


        public static void InitializePath()
        {
            var p = Window.Location.Href;
            var count = 0;
            for (var i = p.Length - 1; i >= 0; i--)
            {
                if (p[i] == '/') count++;
                if (count == 2)
                {
                    BaseWebPath = p.Substring(0, i);
                    p = BaseWebPath + "/api/ajax_api.aspx";
                    break;
                }
            }

            api = p;
        }

        public static  void SaveLocalData()
        {
            var jrightsu = Newtonsoft.Json.JsonConvert.SerializeObject(RightSU);
            var jcatabs = Newtonsoft.Json.JsonConvert.SerializeObject(CatAbs);
            var jwfprofil = Newtonsoft.Json.JsonConvert.SerializeObject(WFProfil);

            System.Console.WriteLine("Write pid: " + Workflow.pid);

            Window.LocalStorage.SetItem("workflow_pid", Workflow.pid);
            Window.LocalStorage.SetItem("workflow_wfid_su", wfid_su);
            Window.LocalStorage.SetItem("workflow_nom", nom);
            Window.LocalStorage.SetItem("workflow_api", api);
            Window.LocalStorage.SetItem("workflow_token", token);
            Window.LocalStorage.SetItem("workflow_pfunc", pfunc);
            Window.LocalStorage.SetItem("workflow_basewebpath", BaseWebPath);

            Window.LocalStorage.SetItem("workflow_rightsu", jrightsu);
            Window.LocalStorage.SetItem("workflow_catabs", jcatabs);
            Window.LocalStorage.SetItem("workflow_wfprofil", jwfprofil);

            Window.LocalStorage.SetItem("CurrentLg", Res.CurrentLg);
            Window.LocalStorage.SetItem("LgCom", Res.LgCom);
            Window.LocalStorage.SetItem("workflow_res", Res.source);

            Window.LocalStorage.SetItem("perm_bValidMonth", perm_bValidMonth);
            Window.LocalStorage.SetItem("perm_uploadCost", perm_uploadCost);
            Window.LocalStorage.SetItem("perm_unlock", perm_todo_unlock);
            Window.LocalStorage.SetItem("perm_su_hjexc", perm_su_hjexc);
            Window.LocalStorage.SetItem("hide_outdated_pers", hide_outdated_pers);
            Window.LocalStorage.SetItem("show_work_data", show_work_data);
            Window.LocalStorage.SetItem("show_work_data_switch", show_work_data_switch);
            Window.LocalStorage.SetItem("activity_full_orders", activity_full_orders);
            Window.LocalStorage.SetItem("activity_hide_compl", activity_hide_compl);
            Window.LocalStorage.SetItem("mode_prometerre", mode_prometerre);
            Window.LocalStorage.SetItem("qty_less_than_24h", qty_less_than_24h);
            Window.LocalStorage.SetItem("todo_piquet", todo_piquet);
            Window.LocalStorage.SetItem("todo_workopen", todo_workopen);

        }

        public static void LoadLocalData()
        {

            Res.LoadColor();

            var s_pid = Window.LocalStorage.GetItem("workflow_pid") as string;
            var s_wfid_su = Window.LocalStorage.GetItem("workflow_wfid_su") as string;
            nom = Window.LocalStorage.GetItem("workflow_nom") as string;
            api = Window.LocalStorage.GetItem("workflow_api") as string;
            var s_token = Window.LocalStorage.GetItem("workflow_token") as string;
            BaseWebPath = Window.LocalStorage.GetItem("workflow_basewebpath") as string;



            var s_rightsu = Window.LocalStorage.GetItem("workflow_rightsu") as string;
            var s_catabs = Window.LocalStorage.GetItem("workflow_catabs") as string;
            var s_wfprofil = Window.LocalStorage.GetItem("workflow_wfprofil") as string;
            var s_pfunc = Window.LocalStorage.GetItem("workflow_pfunc") as string;
            var s_currentlg = Window.LocalStorage.GetItem("CurrentLg") as string;
            var s_lgcom = Window.LocalStorage.GetItem("LgCom") as string;
            var s_res = Window.LocalStorage.GetItem("workflow_res") as string;

            bool.TryParse(Window.LocalStorage.GetItem("perm_bValidMonth").ToString(), out perm_bValidMonth);
            bool.TryParse(Window.LocalStorage.GetItem("perm_uploadCost").ToString(), out perm_uploadCost);
            bool.TryParse(Window.LocalStorage.GetItem("perm_unlock").ToString(), out perm_todo_unlock);
            bool.TryParse(Window.LocalStorage.GetItem("perm_su_hjexc").ToString(), out perm_su_hjexc);
            bool.TryParse(Window.LocalStorage.GetItem("hide_outdated_pers").ToString(), out hide_outdated_pers);
            bool.TryParse(Window.LocalStorage.GetItem("show_work_data").ToString(), out show_work_data);
            bool.TryParse(Window.LocalStorage.GetItem("show_work_data_switch").ToString(), out show_work_data_switch);
            bool.TryParse(Window.LocalStorage.GetItem("activity_full_orders").ToString(), out activity_full_orders);
            bool.TryParse(Window.LocalStorage.GetItem("activity_hide_compl").ToString(), out activity_hide_compl);
            bool.TryParse(Window.LocalStorage.GetItem("mode_prometerre").ToString(), out mode_prometerre);
            bool.TryParse(Window.LocalStorage.GetItem("qty_less_than_24h").ToString(), out qty_less_than_24h);
            bool.TryParse(Window.LocalStorage.GetItem("todo_piquet").ToString(), out todo_piquet);
            bool.TryParse(Window.LocalStorage.GetItem("todo_workopen").ToString(), out todo_workopen);

            int.TryParse(s_pid, out pid);
            int.TryParse(s_wfid_su, out wfid_su);
            long.TryParse(s_token, out token);
            int.TryParse(s_currentlg, out Res.CurrentLg);
            int.TryParse(s_lgcom, out Res.LgCom);
            int.TryParse(s_pfunc, out pfunc);


            RightSU = Newtonsoft.Json.JsonConvert.DeserializeObject<Right>(s_rightsu);
            CatAbs = Newtonsoft.Json.JsonConvert.DeserializeObject<Api.API_CatAbs>(s_catabs);
            WFProfil = Newtonsoft.Json.JsonConvert.DeserializeObject<Dictionary<int, int>>(s_wfprofil);


            Init();


            Res.LoadFromString(s_res);

        }


    
private static int __Property__Initializer__PtgCount=30;}
}