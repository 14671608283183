using System.Collections.Generic;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using System;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisieMultiExc : PanelSaisie
    {

        public delegate void dOnNeedRefresh(PanelSaisieMultiExc panelSaisieMulti);
        public event dOnNeedRefresh OnNeedRefresh = null;

        public static PanelSaisieMultiExc Self = null;

        //CheckBox[] checks = new CheckBox[5];
        //Label[] labels = new Label[5];
        Combo[] cbos = new Combo[5];

        Button bSave, bCancel;

        int pid = 0;
        int wfid = 0;

        int type = -1;

        Dictionary<int, string> text = new Dictionary<int, string>();

        public PanelSaisieMultiExc(Panel pParent) : base(pParent)
        {
            Self = this;

            lsKey = "pMultiExc";

            this.id = 7;

            _lHeader.Text = Res.Get(1200, "Exceptions");
            SetHeaderHeight(32);

            _pBack.Size = new Size(600 - 32, 256);
            sz1 = 256;

            this.OnOpenClose += PanelSaisieMultiValid_OnOpenClose;

            var p = new Panel(_pBack);
            p.Place(16, 32, _pBack.Width - 32, 200);
            p.SetBorderStyle(1, "solid", new Color(200), 4);

            bSave = new Button(p.Width - 160, p.Height - 64, 48, 48, "images/64ok.png", p, false);
            bSave.OnButtonClick += BSave_OnButtonClick;
            bCancel = new Button(bSave.Right + 16, p.Height - 64, 48, 48, "images/64annule.png", p, false);
            bCancel.OnButtonClick += BCancel_OnButtonClick;

            bSave.Enabled = false;
            bCancel.Enabled = false;

            var x = 32;
            var y = 32;
            for(var i = 0; i < Res.exception.Length; i++)
            {
                var s = Res.exception[i];
                
                if (i > 2) x = p.Width / 2;

                var cbo = cbos[i] = new Combo(s, x, y, 200, 24, p);
                cbo.OnChange += Cbo_OnChange;
                cbo.Add(-1, Res.Get(1210, "Ne pas modifier"));
                if (i == 0 || i == 1 || i == 2)
                {
                    cbo.Add(0, Res.Get(1211, "Suppression"));
                    cbo.Add(1, Res.Get(1212, "Ouverture"));
                }
                else if (i == 4)
                {
                    cbo.Add(0, Res.Get(1220, "Supprimer piquet"));
                    cbo.Add(1, Res.Get(1221, "Poser piquet 1"));
                    cbo.Add(2, Res.Get(1222, "Poser piquet 2"));
                }

                if (s.Length <= 3)
                {
                    cbo.Visible = Visibility.Hidden;
                }

                y += 48;
                if (i == 2) y = 32;
            }

        }

        private void Cbo_OnChange(Combo combo)
        {
            bSave.Enabled = true;
            bCancel.Enabled = true;
        }

        void OnCheckChange()
        {
            bSave.Enabled = true;
            bCancel.Enabled = true;
        }

        private void BCancel_OnButtonClick(Button b, MouseEvent arg)
        {
            //foreach (var c in checks)
            //    c.Checked = false;
            foreach (var cbo in cbos)
                cbo.SelectByIdx(0);
        }

        private void BSave_OnButtonClick(Button c, MouseEvent arg)
        {
            var grid = MensualView.Grid;
            var selected = grid.Selected;

            var csv = new CSVTool();
            csv.A(selected.Count);
            foreach (var day in selected)
                csv.A(day.Date);

            var func = new Api.ApiFunction(Api.ApiFuncType.SaveMultiExc);
            func.iparam1 = pid;
            func.iparam2 = cbos[0].SelectedId;
            func.iparam3 = cbos[1].SelectedId;
            func.iparam4 = cbos[2].SelectedId;
            func.iparam5 = cbos[3].SelectedId;
            func.iparam6 = cbos[4].SelectedId;
            func.param1 = csv.ToString();


            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) => 
            {
                OnNeedRefresh!=null?global::Bridge.Script.FromLambda(()=>OnNeedRefresh.Invoke(this)):null;
            };
        }

        public void Init(int wfid, int pid, int selCount, Right right)
        {
            this.wfid = wfid;
            this.pid = pid;

            if (selCount <= 2) type = -1;

            BCancel_OnButtonClick(null, null);

            cbos[0].Visible = cbos[1].Visible = right.OpenHJ ? Visibility.Inherit : Visibility.Hidden;
            cbos[2].Visible = right.OpenPause ? Visibility.Inherit : Visibility.Hidden;
            cbos[4].Visible = right.Piquet > 0 ? Visibility.Inherit : Visibility.Hidden;

        }


        private void PanelSaisieMultiValid_OnOpenClose(PanelSaisie p)
        {
            MensualView.SaisieMultiSetY();
        }
    }
}