using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using System.Linq;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class DetailOrdre : Panel
    {

        Label[] lOrdre = new Label[20];
        Label lOrdreId = null;
        Label lT1, lT2, lT3, lT4;
        

        public DetailOrdre(PanelWkData parent)
        {
            var p = this;

            Label l;

            Place(700, 48, parent.Width - 700 - 16, 450, parent);

            BackgroundColor = Color.White;

            l = new Label(Res.Get(9030, "Description de l'ordre sélectionné"), 0, 4, p.Width - 4, 16, "right", this);
            l.Color = new Color(200);

            lOrdreId = new Label("{0}", 4, 4, 100, 16, "left", this);
            lOrdreId.Color = new Color(200);
            lOrdreId.FontSize = 8;

            string[] ls = {"ID Code de saisie", "Dénomination", "Courte description", "Description", "Ordre de fabrication", "Opération (gamme)",
                            "Date de création", "Date de la dernière saisie", "Modèle de saisie", "Centre de frais", "", "Responsable de",
                            "Temps alloués A", "B", "Réf. Qté 1", "2", "Qté libérée", "Temps", "Pièces", "Rebut"};


            for (var i = 0; i < ls.Length; i++)
                ls[i] = Res.Get(9031 + i, ls[i]);


            var y = 32;
            for (var i = 0; i < ls.Length - 3; i++)
            {
                var s = ls[i] + ":";

                var w = 150;
                var w2 = 100;
                var x = 1;
                if (i >= 1 && i <= 3)
                {
                    w2 = 300;
                }
                else if (i >= 13) w2 = 75;
                else w2 = 194;

                if (i == 13 || i == 15)
                {
                    x = 250;
                    w = 20;
                }


                if (s.Length > 1)
                {

                    l = new Label(s, x, y, w, 16, "right", this);
                    l.FontSize = 9;

                    lOrdre[i] = new Label("----", l.Right + 4, l.Top, w2, 16, "left", l.Parent);
                    lOrdre[i].FontSize = 9;
                    lOrdre[i].BackgroundColor = new Color(250);
                    lOrdre[i].Style.Overflow = Overflow.Hidden;
                }

                if (i != 12 && i != 14)
                    y += 24;
            }


            y = 32 + 96 + 96;
            l = new Label(Res.Get(9060, "cumul"), 350, y, p.Width - 350, 16, "center", this);
            l.FontSize = 9;
            l.FontWeight = "bold";
            y += 24;

            for (var i = ls.Length - 3; i < ls.Length; i++)
            {
                l = new Label(ls[i], 350, y, p.Width - 350, 16, "center", this);
                l.FontSize = 9;
                y += 24;

                lOrdre[i] = new Label("----", 350 + 8, y, l.Width - 16, 16, "center", l.Parent);
                lOrdre[i].FontSize = 9;
                lOrdre[i].BackgroundColor = new Color(250);

                y += 24;
            }

            y += 16;
            l = new Label("Total / Exporté:", 1, y, 150, 16, "right", this);
            var l1 = lT1 = new Label("0:00", l.Right + 8, l.Top, 55, 16, "right", this);
            var l2 = lT2 = new Label("0:00", l1.Right + 8, l1.Top, 55, 16, "right", this);
            l2.FontSize = l1.FontSize = l.FontSize = 9;

            y += 24;
            l = new Label("% / % Exporté:", 1, y, 150, 16, "right", this);
            l1 = lT3 = new Label("0%", l.Right + 8, l.Top, 55, 16, "right", this);
            l2 = lT4 = new Label("0%", l1.Right + 8, l1.Top, 55, 16, "right", this);
            l2.FontSize = l1.FontSize = l.FontSize = 9;

        }

        public void ShowOrder(int id, int pid, DateTime date)
        {
            ShowDetail(id, pid, date);
        }

        private void ShowDetail(int ordreId, int pid, DateTime date)
        {
            if (ordreId == 0)
            {

                for (var i = 0; i < lOrdre.Length; i++)
                    if (lOrdre[i] != null)
                        lOrdre[i].Text = "-";

                return;
            }

            var func = new Api.ApiFunction(Api.ApiFuncType.job_get_ordre_detail);
            func.iparam1 = ordreId;
            func.iparam2 = pid;
            func.iparam3 = D2000.T(date);

            lOrdreId.Text = ordreId.ToString();

            var dataGetO = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataGetO.OnSuccess += ((data, result) =>
            {
                var csv = new CSVTool();
                csv.LoadData(result.return_s1);
                var d = csv[0];

                lOrdre[0].Text = d[0];  // id code saisie
                lOrdre[1].Text = d[1];  // dénom
                lOrdre[2].Text = d[2];  // courte desc
                lOrdre[3].Text = d[3];  // desc
                lOrdre[4].Text = d[4];  // of
                lOrdre[5].Text = d[5];  // gamme op

                lOrdre[6].Text = d[7];  // date création
                lOrdre[7].Text = d[8];  // date last saisie
                lOrdre[8].Text = d[9];  // modèle saisie
                lOrdre[9].Text = d[10]; // cf

                lOrdre[11].Text = d[12];    // responsable
                lOrdre[12].Text = d[13];    // temps alloués A
                lOrdre[13].Text = d[14];    // temps alloués B
                lOrdre[14].Text = d[16];    // qty ref 1
                lOrdre[15].Text = d[17];    // qty ref 2
                lOrdre[16].Text = d[19];    // qty libérée
                lOrdre[17].Text = d[20];    // cumul temps
                lOrdre[18].Text = d[21];    // cumul pièces
                lOrdre[19].Text = d[22];    // cumul rebut

                lT1.Text = Fmt.HQty(result.return_i1);
                lT2.Text = Fmt.HQty(result.return_i2);
                lT3.Text = Fmt.EntierCentieme(result.return_i3) + "%";
                lT4.Text = Fmt.EntierCentieme(result.return_i4) + "%";

            });
        }



    }
}
