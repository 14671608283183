using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

using Mobatime.JSControl;
using Bridge.Html5;
                                                                         
namespace Workflow_bridge.WorkActivity
{
    #region class of ordre
    public class OF
    {
        public int id = 0;
        public string name = "";
        public int ordreid = 0;
        public int[] ordresid = new int[50];
        public int ordreCount = 0;
    }
    public class Ordre
    {
        public int id = 0;
        public string name = "";
        public string idcode = "";
        public string desc = "";
        public int ofid = 0;
        public string desc2 = "";
        public string op = "";
        public int tt = 0;

        public string[] complement = new string[5];
    }
    #endregion
 
    class WorkActivityView
    {
        Dictionary<int, Tuple<int, string, string, string>> ttravail = null;
        Dictionary<int, Tuple<int, string, string, int, int>> machines = null;
        Dictionary<int, Tuple<int, string, string, int, int>> divers = null;
                                         
        Color c1 = new Color(255, 220, 255);
        Color c2 = new Color(255, 230, 255);
        Color csel = new Color(150, 150, 255);
        Color cMachine = new Color(200, 255, 200);
        Color cDivers = new Color(255, 255, 200);

        Mobatime.JSControl.Window window = null;
        HtmlPage npage = null;
        Button bClose, b1, b2, b3, b4, b5;
        Button bNew, bDelete, bSave, bCancel;
        Button bC;
        Label lTitle, lDate;

        Panel back = null, pSaisie = null, pSelectOF = null, pListOF, pSelectOrdre, pListOrdre;
        Panel pDays = null, pCompl, tbComplement;
        ListBox listComplement;

        Field fQtyComplement;
        Label lTitleComplement, lItem;

        DateTime date = DateTime.Now.Date;
        DateTime d1 = DateTime.MinValue;
        DateTime d2 = DateTime.MinValue;
        DateTime oldD1 = DateTime.MinValue;
        int pid = 0;

        Label lAff, lNOrdre, lNom, lGammeOP, lDesc;
        Field fText, fDuree, fQty;
        Field fFiltreOF, fFiltreOrdre;
        Combo cboTT;
        CheckBox[] cDays = new CheckBox[7];
        string imgdir = "";

        string[,] datas = null;
        int[] rowIndices = null;

        Panel pQties = null;

        Label[] lHebdo = new Label[7 * 9];
        List<Panel> panel2 = new List<Panel>();

        Label[] lCplElement = new Label[10];
        Label[] lCplQty = new Label[10];

        List<List<Panel>> rows_qties = new List<List<Panel>>();
        int currentIdx = -1;

        Dictionary<int, OF> OFs = null;
        Dictionary<int, Ordre> ordres = null;

        int selectOrdreId = 0;
        int selectComplement = -1;
        int selectedOF = -1;

        List<int> lst10 = new List<int>();

        Combo cbo;

        Dictionary<int, SchemaPrometerre> schemas = null;

        bool state = false;

        public void Show()
        {
            Workflow.SaveLocalData();
            Bridge.Html5.Window.Open("index.html?func=" + 8000, "MobaWFWkAct", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1264, height=910");
        }

        public void Place(HtmlPage page)
        {
            npage = page;

            Document.Title = "Mobatime JBM workflow - Work Activity";

            CreateWindow();
        }

        void CreateWindow()
        {
System.Action Ask4TB = null;
            pid = Workflow.pid;

            LoadOF10();

            imgdir = Workflow.BaseWebPath + "/wf2018/images/";
            window = new Mobatime.JSControl.Window(npage, 40);

            window.Container.SetScrolling(Overflow.Auto, Overflow.Auto);

            bClose = window.Toolbar.AddButton(1000);
            window.Toolbar.AddSpace(16);
            b1 = window.Toolbar.AddButton(1);
            b2 = window.Toolbar.AddButton(2);
            b3 = window.Toolbar.AddButton(3);
            b4 = window.Toolbar.AddButton(4);
            b5 = window.Toolbar.AddButton(5);

            bClose.OnButtonClick += MenuButton_OnButtonClick;
            b1.OnButtonClick += MenuButton_OnButtonClick;
            b2.OnButtonClick += MenuButton_OnButtonClick;
            b3.OnButtonClick += MenuButton_OnButtonClick;
            b4.OnButtonClick += MenuButton_OnButtonClick;
            b5.OnButtonClick += MenuButton_OnButtonClick;

            bClose.SetImage(imgdir + "63_porte_t.png", 32, 32);
            b1.SetImage(imgdir + "arrow_left.png", 32, 32);
            b2.SetImage(imgdir + "arrow_left.png", 32, 32);
            b3.SetImage(imgdir + "now.png", 32, 32);
            b4.SetImage(imgdir + "arrow_right.png", 32, 32);
            b5.SetImage(imgdir + "arrow_right.png", 32, 32);



            npage.OnBodyResize += Npage_OnBodyResize;

            var w = npage.Width;
            var h = npage.Height;


            var title = "Work Activity";
            lTitle = new Label(title, w - 200, 12, 180, 16, "right");
            window.Toolbar.AddChild(lTitle);
            lTitle.Color = Color.White;
            lTitle.FontSize = 14;

            lDate = new Label(Fmt.DateComplete(date), b5.Right + 16, 12, 180, 16, "left", window.Toolbar);
            lDate.Color = Color.White;
            lDate.FontSize = 10;

            var pers = Tool.LSRead("cbopers", "");

            if (pers.Length > 0)
            {
                var split = pers.Split(';');
                cbo = new Combo("", lDate.Right + 8, 4, 300, 32, window.Toolbar);
                cbo.Clear();
                for(var i = 0; i < split.Length; i+=2)
                {
int pid;
                    int.TryParse(split[i + 0], out pid);
                    cbo.Add(pid, split[i + 1]);
                }
                cbo.SelectByIdx(0);
                pid = cbo.SelectedId;
                cbo.OnChange += Cbo_OnChange;
            }

            back = new Panel(window.Container);
            back.Place(16, 16, window.Container.Width - 32, window.Container.Height - 32);
Ask4TB = () =>
{
    this.OFs = Workflow.OFs;
    this.ordres = Workflow.ordres;
    var func2 = new Api.ApiFunction(Api.ApiFuncType.GetWorkActTB);
    func2.iparam1 = pid;
    var dataTB = new DataApi<Api.ApiReturn>(Workflow.api, func2);
    dataTB.OnSuccess += DataTB_OnSuccess;
}

;

            //Console.WriteLine(Workflow.OFs + "  " + "Workflow.activity_full_orders " + Workflow.activity_full_orders);

            if (Workflow.OFs == null)
            {
                Workflow.OFs = new Dictionary<int, OF>();
                Workflow.ordres = new Dictionary<int, Ordre>();

                var func = new Api.ApiFunction(Api.ApiFuncType.GetWorkActOrdreOF);
                func.iparam1 = pid;
                if (Workflow.activity_full_orders)
                    func.iparam3 = 1;
                func.iparam4 = 1;
                func.iparam5 = 1;   // add text complément
                func.iparam6 = 1;   // add tt à l'ordre

                //Console.WriteLine("Workflow.activity_full_orders " + Workflow.activity_full_orders);

                var dataOrdreOF = new DataApi<Api.ApiReturn>(Workflow.api, func);
                dataOrdreOF.OnSuccess += (Data<Api.ApiReturn> Data, Api.ApiReturn result) =>
                {
                    CreateOFOrdreTable(result.return_s1, result.return_s2, result.return_s3);
                    Ask4TB();
                };
            }
            else
            {
                Ask4TB();
            }

            

        }

        private void Cbo_OnChange(Combo combo)
        {
            pid = combo.SelectedId;
            GetWeekDatas(d1);
        }

        #region load ordres and ofs
        private void CreateOFOrdreTable(string s1, string s2, string s3)
        {
            var csv3 = new CSVTool(s3);
            var csv2 = new CSVTool(s2);

            if (csv3.ObjectsCount > 0)
            {
                var ordres = csv3[0];

                if (ordres.Count >= 7)
                {
                    for (var i = 0; i < ordres.Count; i += 13)
                    {
                        var ordre = new Ordre();
                        ordre.id = ordres.I32(i);
                        ordre.idcode = ordres[i + 1];
                        ordre.name = ordres[i + 2];
                        ordre.desc = ordres[i + 3];
                        ordre.ofid = ordres.I32(i + 4);
                        ordre.desc2 = ordres[i + 5];
                        ordre.op = ordres[i + 6];

                        for (var j = 0; j < 5; j++)
                            ordre.complement[j] = ordres[i + 7 + j];

                        ordre.tt = ordres.I32(i + 12);

                        //Console.WriteLine(ordre.name + " " + ordre.desc + " " + ordre.desc2 + " " + ordre.op);

                        Workflow.ordres.Add(ordre.id, ordre);
                    }
                }

            }

            if (csv2.ObjectsCount > 0)
            {
                var ofs = csv2[0];
                if (ofs.Count >= 52)
                {
                    for (var i = 0; i < ofs.Count; i += 52)
                    {
                        var of = new OF();
                        of.id = ofs.I32(i);
                        of.name = ofs[i + 1];
                        of.ordreid = ofs.I32(i + 2);

                        for (var j = 0; j < 50; j++)
                        {
                            of.ordresid[j] = ofs.I32(i + 2 + j);
                            if (of.ordresid[j] > 0)
                            {
                                var oid = of.ordresid[j];
                                if (Workflow.ordres.ContainsKey(oid))
                                    of.ordreCount++;
                                else
                                    of.ordresid[j] = 0;
                            }
                        }

                        Workflow.OFs.Add(of.id, of);
                    }
                }
            }
        }

        private void DataTB_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
System.Action Loaded = null;
            ttravail = new Dictionary<int, Tuple<int, string, string, string>>();
            machines = new Dictionary<int, Tuple<int, string, string, int, int>>();
            divers = new Dictionary<int, Tuple<int, string, string, int, int>>();

            var csv1 = new CSVTool();
            var csv2 = new CSVTool();
            var csv3 = new CSVTool();
            csv1.LoadData(result.return_s1);
            csv2.LoadData(result.return_s2);
            csv3.LoadData(result.return_s3);

            if (csv1.ObjectsCount > 0 && csv1[0].Count >= 5)
                for (var i = 0; i < csv1[0].Count; i += 5)
                    machines.Add(csv1[0].I32(i), new Tuple<int, string, string, int, int>(csv1[0].I32(i), csv1[0][i + 1], csv1[0][i + 2], csv1[0].I32(i + 3), csv1[0].I32(i + 4)));
            
            if (csv2.ObjectsCount > 0 && csv2[0].Count >= 5)
                for (var i = 0; i < csv2[0].Count; i += 5)
                divers.Add(csv2[0].I32(i), new Tuple<int, string, string, int, int>(csv2[0].I32(i), csv2[0][i + 1], csv2[0][i + 2], csv2[0].I32(i + 3), csv2[0].I32(i + 4)));
            
            if (csv3.ObjectsCount > 0 && csv3[0].Count >= 4)
                for (var i = 0; i < csv3[0].Count; i += 4)
                ttravail.Add(csv3[0].I32(i), new Tuple<int, string, string, string>(csv3[0].I32(i), csv3[0][i + 1], csv3[0][i + 2], csv3[0][i + 3]));

            back.ClearChildren();
Loaded = () =>
{
    CreateHebdo();
    CreateWeekQty();
    CreateSaisie();
    CalculPeriode();
    ActivateFields(false);
    SetState(false);
}

;




            if (Workflow.mode_prometerre)
            {
                var funcS = new Api.ApiFunction(Api.ApiFuncType.GetWorkActSchema);
                funcS.iparam1 = Workflow.pid;
                var dataS = new DataApi<Dictionary<int, SchemaPrometerre>>(Workflow.api, funcS);
                dataS.OnSuccess += (a, r) =>
                {
                    schemas = r;

                    Loaded();
                };
            }
            else
            {
                Loaded();
            }

            
        }
        #endregion 
        
        private void MenuButton_OnButtonClick(Button c, MouseEvent arg)
        {
            switch (c.id1)
            {
                case 1000:
                    Bridge.Html5.Window.Close();
                    break;

                case 1:
                    date = date.AddDays(-7);
                    break;
                case 2:
                    date = date.AddDays(-1);
                    break;
                case 3:
                    date = DateTime.Now.Date;
                    break;
                case 4:
                    date = date.AddDays(1);
                    break;
                case 5:
                    date = date.AddDays(7);
                    break;
            }

            date = date.Date;

            if(c.id1 >= 1 && c.id1 <= 5)
            {
                CalculPeriode();
                lDate.Text = Fmt.DateComplete(date);
            }
        }

        void CreateHebdo()
        {
            var p = new Panel(back);
            p.Place(0, 0, 550, 192);
            p.SetBorderStyle(1, "solid", new Color(200));
            p.BackgroundColor = new Color(240);

            int[] colsz = { 100, 56, 56, 56, 56, 56, 56, 56, 58 };
            int rowH = 32;

            var x = 0;
            var y = 0;

            panel2.Clear();

            for (var i = 0; i < 9; i++)
            {
                y = 0;

                for (var j = 0; j < 6; j++)
                {
                    var p2 = new Panel(p);
                    p2.Place(x, y, colsz[i] - 1, rowH - 1);
                    p2.id1 = 0;
                    //if(j == 5 && i < 8)
                    //    p2.Width = colsz[i] + 0;

                    if (j == 0)
                    {
                        p2.SetAttribute("cid", i.ToString());
                        panel2.Add(p2);
                    }

                    if (j > 0)
                        p2.BackgroundColor = new Color(255, 255, 200);
                    else
                        p2.BackgroundColor = new Color(255, 255, 100);


                    var l = lHebdo[i * 6 + j] = new Label("", 0, 8, colsz[i] - 4, 16, "center", p2);
                    l.FontSize = 9;
                    if (j == 5 && i > 0 && i < 8)
                    {
                        l.FontSize = 7;
                    }
                    if (j == 0)
                    {
                        l.SetAttribute("cid", i.ToString());
                    }

                    y += rowH;
                }

                x += colsz[i];
            }

            lHebdo[48].Text = Res.Get(8004, "Totaux");
            lHebdo[48].FontSize = 9;

            string[] sn = { "Absence Trav.", "Présence", "Ordres", "Ecart", "" };
            sn[0] = Res.Get(8000);
            sn[1] = Res.Get(8001);
            sn[2] = Res.Get(8002);
            sn[3] = Res.Get(8003);
            sn[4] = "Abs";

            for (var i = 0; i < sn.Length; i++)
            {
                lHebdo[1 + i].Text = sn[i];
                lHebdo[1 + i].FontSize = 9;
                lHebdo[1 + i].Align = "right";

                if (i == 0)
                    lHebdo[1].Color = new Color(127);
            }

        }

        void CreateWeekQty()
        {
            var p = pQties = new Panel(back);
            p.Place(566, 0, 600, 192);
            p.SetBorderStyle(1, "solid", new Color(200));
            p.BackgroundColor = new Color(200);
        }
             
        #region qty toolbar
        private void BDelete_OnButtonClick(Button c, MouseEvent arg)
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.GetWorkActQtyModify);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(d1);
            func.iparam3 = currentIdx;
            func.iparam4 = 2;

            currentIdx = -1;
            selectOrdreId = -1;

            var dataSave = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataSave.OnSuccess += (Data<Api.ApiReturn> data, Api.ApiReturn result) =>
            {
                if (result.return_b1)
                    Mobatime.JSControl.Window.Alert("Les saisies sur les jours verrouillés n'ont pas été effacées.");

                SetState(false);
                GetWeekDatas(d1);

                FillNewSaisie();
            };
        }

        private void BCancel_OnButtonClick(Button c, MouseEvent arg)
        {
            selectOrdreId = -1;
            pSelectOF.Visible = Visibility.Hidden;
            pSelectOrdre.Visible = Visibility.Hidden;

            if (currentIdx == -1) FillNewSaisie();
            else LoadDetail(currentIdx);
            
            SetState(false);

            pDays.Visible = Visibility.Hidden;
            pSelectOF.Visible = Visibility.Hidden;
        }

        private void BSave_OnButtonClick(Button c, MouseEvent arg)
        {

            var isNew = currentIdx == -1 && selectOrdreId > 0;

            if (isNew)
            {
                if (((fDuree.ValueI == 0 || fDuree.Value == null) && (fQty == null || fQty.Value == null || fQty.ValueI == 0)) && bC.id1 == 0 && schemas == null)
                {
                    Bridge.Html5.Window.Alert(Res.Get(8200, "SVP, veuillez saisir une quantité!"));
                    fDuree.Focus();
                    return;
                }

                bool oneday = false;
                for (var i = 0; i < 7; i++)
                {
                    if (cDays[i].Checked)
                    {
                        oneday = true;
                        break;
                    }
                }
                if (!oneday)
                {
                    Bridge.Html5.Window.Alert(Res.Get(8201, "SVP, veuillez choisir les jours concernés!"));
                    return;
                }
            }


            pDays.Visible = Visibility.Hidden;

            if (cboTT.Items.Length > 1)
            {
                var n2 = cboTT.Items[1];
                if (n2.Text.ToString().ToLower().Contains("métré"))
                    cboTT.SelectByIdx(1);
            }

            var func = new Api.ApiFunction(Api.ApiFuncType.GetWorkActQtyModify);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(d1);
            func.iparam3 = currentIdx;
            func.iparam4 = 1;
            func.iparam5 = selectOrdreId;
            func.iparam6 = bC.id1;
            func.iparam8 = cboTT.SelectedId;
            if (fDuree.Value != null)
                func.iparam9 = fDuree.ValueI;
            func.param1 = fText.Input.Value;
            if (fQty != null && fQty.Value != null)
                func.param3 = fQty.ValueI.ToString();

            if (isNew)
            {
                var v = 0;
                for(var i = 0; i < 7; i++)
                {
                    if (!cDays[i].Checked) continue;
                    v |= (int)Math.Pow(2, i);
                }
                func.iparam10 = v;
            }


            for (var i = 0; i < 10; i++)
            {
                func.array_int.Add(lCplElement[i].id1);
                func.array_int.Add(lCplQty[i].id1);
            }


            selectOrdreId = -1;

            var dataSave = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataSave.OnSuccess += (Data<Api.ApiReturn> data, Api.ApiReturn result) => 
            {
                if(result.return_b1)
                    Mobatime.JSControl.Window.Alert("Les saisies sur les jours verrouillés n'ont pas été sauvées.");

                SetState(false);
                GetWeekDatas(d1);
            };

        }

        private void BNew_OnButtonClick(Button c, MouseEvent arg)
        {
            fFiltreOF.Input.Value = "";
            fFiltreOrdre.Input.Value = "";

            currentIdx = -1;
            SelectRow(currentIdx);
            pSelectOF.Visible = Visibility.Inherit;

            FillListOF();

            pDays.Visible = Visibility.Visible;


            for (var i = 0; i < 7; i++)
                cDays[i].Checked = false;

            SetState(true, true);

            Bridge.Html5.Window.SetTimeout((global::System.Action)(() => { fFiltreOF.Focus(); }), 100);
        }
        #endregion

        #region onchange...
        private void FDuree_OnValidate(Field field)
        {
            SetState(true);
        }

        private void FTextOnKeypress(KeyboardEvent<HTMLInputElement>e)
        {
            FText_OnValidate(fText);
        }

        private void FText_OnValidate(Field field)
        {
            SetState(true);
        }

        private void CboTT_OnChange(Combo combo)
        {
            SetState(true);
        }
        #endregion

        #region fill week
        void GetWeekDatas(DateTime lundi)
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.GetWorkActWeek);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(d1);

            if (Workflow.activity_full_orders) 
                func.iparam3 = 1;

            var dataGetWeek = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataGetWeek.OnSuccess += DataGetWeek_OnSuccess;
        }

        private void DataGetWeek_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            //Console.WriteLine("A");

            if(result == null || result.return_s1 == null)
            {
                pQties.ClearChildren();
                return;
            } 
            var s = result.return_s1;
            var colCount = result.return_i1;
            var rowCount = result.return_i2;

            datas = new string[rowCount, colCount + 1];
            rowIndices = new int[rowCount - 1];

            //Console.WriteLine("B " + s.Length);

            var splitA = s.Split('☻');

            for(var i = 0; i < splitA.Length; i++)
            {
                var splitB = splitA[i].Split('☺');

                if(i > 0)
                    int.TryParse(splitB[0], out rowIndices[i - 1]);

                for (var j = 0; j < splitB.Length; j++)
                    if (j < colCount + 1)
                        datas[i, j] = splitB[j];
            }

            //Console.WriteLine("C");

            FillQties(colCount, rowCount);

            Console.WriteLine("D");

            if (currentIdx > -1)
                SelectRow(currentIdx);
            else
            {
                Console.WriteLine("E");

                FillNewSaisie();
                Console.WriteLine("F");
                ActivateFields(false);
                Console.WriteLine("G");
            }


            var csv = new CSVTool(result.return_s2);
            var datas2 = csv[0];
            var k = 0;
            var idx = 0;
            for(var i = 0; i < 7; i++)
            {
                idx = (i + 1) * 6;
                lHebdo[idx + 1].Text = datas2[k++];
                lHebdo[idx + 2].Text = datas2[k++];
                lHebdo[idx + 3].Text = datas2[k++];
                lHebdo[idx + 4].Text = datas2[k++];

                var abs = datas2[k++];
                var split = abs.Split('|');
                abs = "";
                if (split.Length > 0) abs = split[0];
                if (split.Length > 1) abs += "<br />" + split[1];
                lHebdo[idx + 5].Text = abs;
            }
            
            idx = 8 * 6;
            lHebdo[idx + 1].Text = datas2[k++];
            lHebdo[idx + 2].Text = datas2[k++];
            lHebdo[idx + 3].Text = datas2[k++];
            lHebdo[idx + 4].Text = datas2[k++];

            Console.WriteLine("H");

        }

        void FillQties(int colCount, int rowCount)
        {
            var sizes = GetColSize(colCount, rowCount, pQties.Width);

            pQties.ClearChildren();

            var pT = new Panel();
            pT.Place(0, 0, pQties.Width, 24, pQties);
            pT.BackgroundColor = new Color(200);

            var pScroll = new Panel();
            pScroll.Place(0, pT.Bottom + 1, pT.Width, pQties.Height - pT.Height - 1, pQties);
            pScroll.SetScrolling(Overflow.Hidden, Overflow.Visible);
            pScroll.BackgroundColor = new Color(255);

            rows_qties.Clear();

            for (var j = 0; j < rowCount; j++)
            {
                var lst = new List<Panel>();
                rows_qties.Add(lst);

                Panel d2 = null;
                if (j > 0)
                {
                    d2 = new Panel(pScroll);
                    d2.Place(0, (j - 1) * 32, pScroll.Width, 32);
                    d2.BackgroundColor = new Color(200);
                    d2.Div.OnClick = RowClick;
                    d2.SetAttribute("rowIdx", j.ToString());
                }

                var x = 0;
                for (var i = 0; i < colCount - 1; i++)
                {
                    var sz = sizes[i + 1];

                    Panel d;

                    if (j == 0)
                    {
                        d = new Panel(pT);
                        d.Place(x, 0, sz - 1, pT.Height);
                        d.BackgroundColor = new Color(240);
                    }
                    else
                    {
                        d = new Panel(d2);
                        rows_qties[j].Add(d);
                        d.Place(x, 0, sz - 1, 31);

                        if (j % 2 == 0) d.BackgroundColor = c1;
                        else d.BackgroundColor = c2;
                    }

                    if (i == colCount - 2)
                        d.Width++;

                    var l = new Label(datas[j, i + 1], 0, 4, d.Width, 16, "center", d);
                    l.FontSize = 8;
                   

                    x += sz;
                }
            }

        }

        int[] GetColSize(int colCount, int rowCount, int width)
        {
            var sizes = new int[colCount];
            var ratio = new float[colCount];
            var qty = new int[colCount];
            var total = 0;

            sizes[1] = 40;
            sizes[2] = 15;
            sizes[3] = 15;

            var sz = (width - 20) - (sizes[1] + sizes[2] + sizes[3]);

            for(var j = 0; j < rowCount; j++)
            {
                for(var i = 4; i < colCount; i++)
                {
                    var s = datas[j, i];
                    qty[i] = Math.Max(s.Length, qty[i]);
                }
            }

            for (var i = 4; i < colCount; i++)
            {
                if (qty[i] < 7) qty[i] = 7;
                total += qty[i];
            }

            for(var i = 4; i < colCount; i++)
                ratio[i] = qty[i] / (float)total;

            var total2 = 0;
            for(var i = 4; i < colCount; i++)
            {
                sizes[i] = (int)(ratio[i] * sz);
                total2 += sizes[i];
            }

            var dif = sz - total2;
            sizes[colCount - 1] += dif;

            return sizes;
        }

        void RowClick(MouseEvent<HTMLDivElement>e)
        {
            if (state) return;

            var sidx = e.CurrentTarget.GetAttribute("rowIdx");
int idx;
            int.TryParse(sidx, out idx);
            
            SelectRow(rowIndices[idx - 1]);

        }
        #endregion

        #region select a qty
        void SelectRow(int idx)
        {

            bool locked = false;

            for (var i = 0; i < rows_qties.Count; i++)
            {
                if (i <= 0) continue;

                var lst = rows_qties[i];

                var nidx = rowIndices[i - 1];

                var c = c2;
                if (nidx == idx)
                {
                    c = csel;
                    var children = lst[2].GetChildren();
                    var t = ((Label)children[0]).Text;
                    if (t == "ok") locked = true;
                    
                }
                else if (i % 2 == 0) c = c1;

                foreach (var p in lst)
                    p.BackgroundColor = c;
            }


            currentIdx = idx;

            LoadDetail(currentIdx, locked);
        }

        private void LoadDetail(int ridx, bool locked = false)
        {
            if (ridx < 0) return;
            var func = new Api.ApiFunction(Api.ApiFuncType.GetWorkActQtyDetail);
            func.iparam1 = pid;
            func.iparam2 = D2000.T(d1);
            func.iparam3 = currentIdx;
            var dataQty = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataQty.OnSuccess += (Data<Api.ApiReturn> data, Api.ApiReturn result) =>
            {
                var csv1 = new CSVTool();
                var csv2 = new CSVTool();
                csv1.LoadData(result.return_s1);
                csv2.LoadData(result.return_s2);

                var ordreId = csv2[0].I32(0);

                lAff.Text = csv2[0][7];         
                lNOrdre.Text = csv2[0][3];
                lNom.Text = csv2[0][1];
                lGammeOP.Text = csv2[0][6];
                //if (Workflow.mode_prometerre) lDesc.Text = csv2[0][4];
                //else 
                lDesc.Text = csv2[0][5];
                var status = csv1[0].I32(1);

                Console.WriteLine("LD A");

                fDuree.Value = csv1[0].I32(0);
                fQty.Value = csv1[0].I32(csv1[0].Count - 1);

                Console.WriteLine("LD B");

                if (cboTT.Count < 2 && Workflow.mode_prometerre)
                {
                    FillCboTTPrometerre();
                }

                Console.WriteLine("LD C");

                cboTT.SelectById(csv1[0].I32(2));
                fText.Input.Value = result.return_s3;

                if(Workflow.mode_prometerre && ordres.ContainsKey(ordreId))
                {
                    var ordre = ordres[ordreId];
                    lNOrdre.Text = ordre.complement[2];
                    lNom.Text = ordre.complement[0];
                    lDesc.Text = ordre.complement[1];
                    lGammeOP.Text = ordre.complement[4];
                }

                Console.WriteLine("LD D");


                int[] qty = new int[10];
                int[] info = new int[10];


                Console.WriteLine("LD E");

                for (var i = 0; i < 10; i++)
                {
                    qty[i] = csv1[0].I32(3 + i);
                    info[i] = csv1[0].I32(13 + i);

                    var idx = info[i];
                    var p1 = (Panel)lCplElement[i].tag;
                    var p2 = (Panel)lCplQty[i].tag;

                    if (idx > 0 && idx < 4096 && machines.ContainsKey(idx))
                    {
                        lCplElement[i].Text = machines[idx].Item2;
                        lCplQty[i].Text = Fmt.HQty(qty[i]);
                        lCplElement[i].id1 = idx;
                        lCplQty[i].id1 = qty[i];
                        p1.BackgroundColor = cMachine;
                    }
                    else if(idx >= 4096 && divers.ContainsKey(idx - 4096))
                    {
                        idx -= 4096;
                        lCplElement[i].Text = divers[idx].Item2;
                        lCplQty[i].Text = Fmt.EntierCentieme(qty[i]);
                        lCplElement[i].id1 = idx + 4096;
                        lCplQty[i].id1 = qty[i];
                        p1.BackgroundColor = cDivers;
                    }
                    else
                    {
                        lCplElement[i].Text = "";
                        lCplQty[i].Text = "";
                        lCplElement[i].id1 = 0;
                        lCplQty[i].id1 = 0;
                        p1.BackgroundColor = Color.White;
                    }

                    lCplElement[i].id1 = info[i];
                    lCplQty[i].id1 = qty[i];

                    p1.SetAttribute("edx", info[i].ToString());
                    p1.SetAttribute("val", qty[i].ToString());
                    p2.SetAttribute("edx", info[i].ToString());
                    p2.SetAttribute("val", qty[i].ToString());

                    p2.BackgroundColor = p1.BackgroundColor;
                }

                Console.WriteLine("LD DD");

                bDelete.Enabled = true;
                pDays.Visible = Visibility.Hidden;

                if (status > 100) locked = true;

                ActivateFields(!locked);
                if (locked)
                    currentIdx = -1;

                pCompl.Visible = Visibility.Hidden;

                ResetButtonC();

                if (locked) bDelete.Visible = Visibility.Hidden;
                else bDelete.Visible = Visibility.Inherit;

            };
        }

        #endregion region

        #region saisie

        #region base
        void CreateSaisie()
        {
            var p = pSaisie = new Panel(back);
            p.Place(566, 192 + 16, back.Width - 632, back.Height - 192 - 16);
            p.SetBorderStyle(1, "solid", new Color(200));
            p.BackgroundColor = Color.White;

            var p2 = new Panel(p);
            p2.Place(0, 0, p.Width, 32);
            p2.BackgroundColor = Color.Mobatime1;


            bNew = new Button(4, 0, 32, 32, imgdir + "63_new_t.png", p2);
            bSave = new Button(44, 0, 32, 32, imgdir + "63save_t.png", p2);
            bCancel = new Button(84, 0, 32, 32, imgdir + "64annule_t.png", p2);
            bDelete = new Button(p2.Width - 44, 4, 24, 24, imgdir + "trash@24px.png", p2);

            bNew.OnButtonClick += BNew_OnButtonClick;
            bSave.OnButtonClick += BSave_OnButtonClick;
            bCancel.OnButtonClick += BCancel_OnButtonClick;
            bDelete.OnButtonClick += BDelete_OnButtonClick;

            var p3 = new Panel(p);
            p3.Place(16, 48, p.Width - 32, 316);
            p3.SetBorderStyle(1, "solid", new Color(200));

            var y = 16;
            var step = 40;
            var lstL1 = new List<Label>();
            var lstL2 = new List<Label>();

            var l = new Label(Res.Get(8020, "Affaire") + ":", 0, y, 150, 16, "right", p3); lstL1.Add(l);
            l = lAff = new Label("", l.Right + 4, l.Top, 200, 16, "left", p3); lstL2.Add(l);

            l = new Label(Res.Get(8027, "Durée") + ":", 380, y, 75, 16, "right", p3); lstL1.Add(l);
            fDuree = new Field(l, 50, Field.FieldFormat.HQty);
            fDuree.OnValidate += FDuree_OnValidate;

            bC = new Button(fDuree.Right + 12, fDuree.Top, 24, 24, "", p3, true);
            bC.Text = "C";
            bC.OnButtonClick += BC_OnButtonClick;

            l = new Label("Quantité" + ":", 380, y + step, 75, 16, "right", p3); lstL1.Add(l);
            fQty = new Field(l, 50, Field.FieldFormat.EntierCentieme);
            fQty.OnValidate += FDuree_OnValidate;

            if (!Workflow.mode_prometerre)
            {
                l.Visible = Visibility.Hidden;
                fQty.Visible = Visibility.Hidden;
            }

            y += step;
            l = new Label(Res.Get(8021, "N° ordre") + ":", 0, y, 150, 16, "right", p3); lstL1.Add(l);
            l = lNOrdre = new Label("", l.Right + 4, l.Top, 200, 16, "left", p3); lstL2.Add(l);

            y += step;
            l = new Label(Res.Get(8022, "Nom") + ":", 0, y, 150, 16, "right", p3); lstL1.Add(l);
            l = lNom = new Label("", l.Right + 4, l.Top, 200, 16, "left", p3); lstL2.Add(l);

            y += step;
            l = new Label(Res.Get(8025, "Description") + ":", 0, y, 150, 16, "right", p3); lstL1.Add(l);
            l = lDesc = new Label("", l.Right + 4, l.Top, 380, 16, "left", p3); lstL2.Add(l);



            y += step;
            l = new Label(Res.Get(8023, "Type de travail") + ":", 0, y, 150, 16, "right", p3); lstL1.Add(l);
            cboTT = new Combo();
            cboTT.Place(l.Right + 4, l.Top, 200, 20, p3);
            cboTT.Style.FontSize = "9pt";
            cboTT.OnChange += CboTT_OnChange;

            cboTT.Add(0, "----");
            if (Workflow.mode_prometerre)
            {
            }
            else
            {
                foreach (var t in ttravail.Values)
                    cboTT.Add(t.Item1, t.Item2);
            }

            y += step;
            l = new Label(Res.Get(8024, "Gamme OP") + ":", 0, y, 150, 16, "right", p3); lstL1.Add(l);
            l = lGammeOP = new Label("", l.Right + 4, l.Top, 200, 16, "left", p3); lstL2.Add(l);

            y += step;
            l = new Label(Res.Get(8026, "Text libre") + ":", 0, y, 150, 16, "right", p3); lstL1.Add(l);
            var f = fText = new Field(l, 380, Field.FieldFormat.Text);
            f.Height = 20;
            f.Style.FontSize = "9pt";
            fText.OnValidate += FText_OnValidate;
            fText.Input.OnKeyPress = FTextOnKeypress;

            foreach (var lab in lstL1)
            {
                lab.FontSize = 9;
            }

            foreach (var lab in lstL2)
            {
                lab.FontSize = 9;
                lab.Style.BorderBottom = "dashed 1px #ccc";
            }

            pDays = new Panel(p3);
            pDays.Place(395, fQty.Visible != Visibility.Hidden ? 88 : 64, 135, 48);
            pDays.Visible = Visibility.Hidden;
            Console.WriteLine("ABC+1");
            for(var i = 0; i < 7; i++)
            {
                var s = Res.days2[i][0].ToString().ToUpperCase();
                var c = new CheckBox("cd" + i, s, "");
                c.Place(i * 20, 18, 16, 16, pDays);
                cDays[i] = c;

                var ld = new Label(s, i * 20, 0, 16, 16, "center", pDays);
                ld.FontSize = 9;
            }

            var bDays = new Button(0, 36, 5 * 20 - 4, 6, "", pDays, true);
            bDays.OnButtonClick += BDays_OnButtonClick;

            CreateComplementView();
            CreateComplementEdit();

            CreateSelectOF();
            CreateSelectOrdre();
        }

        private void BC_OnButtonClick(Button c, MouseEvent arg)
        {
            SetState(true);

            if(c.id1 == 0)
            {
                fDuree.Input.Disabled = true;
                fDuree.Input.Value = "";
                c.id1 = 1;
                c.SetBorderColor(Color.Red);
            }
            else
            {
                fDuree.Input.Disabled = false;
                fDuree.Input.Value = "";
                ResetButtonC();
            }
        }

        private void ResetButtonC()
        {
            var c = bC;
            c.id1 = 0;
            c.SetBorderColor(new Color(200));
        }

        private void BDays_OnButtonClick(Button c, MouseEvent arg)
        {
            var v = cDays[0].Checked;
            v = !v;
            for (var i = 0; i < 5; i++)
                cDays[i].Checked = v;
            cDays[5].Checked = false;
            cDays[6].Checked = false;
        }

        void CreateSelectOF()
        {
            var p = pSelectOF = new Panel(pSaisie);
            p.Place(16, 48, pSaisie.Width - 32, pSaisie.Height - 64);
            p.SetBorderStyle(1, "solid", new Color(200));
            p.BackgroundColor = Color.White;
            p.Visible = Visibility.Hidden;

            var l = new Label(Res.Get(8100, "Sélection d'un OF"), 0, 4, p.Width - 8, 16, "right", p);
            l.Color = new Color(150);

            var f = fFiltreOF = new Field();
            f.Place(8, 4, 200, 20, p);
            f.OnValidateEnter += FiltreOF_OnValidateEnter;

            var p2 = pListOF = new Panel(p);
            p2.Place(8, 32, p.Width - 16, p.Height - 32 - 8);
            p2.SetScrolling(Overflow.Hidden, Overflow.Visible);
            p2.BackgroundColor = new Color(200);
        }

        private void FiltreOF_OnValidateEnter(Field field)
        {
            var count = FillListOF();

            if (!Workflow.mode_prometerre && count == 0)
            {
                pSelectOF.Visible = Visibility.Hidden;
                pSelectOrdre.Visible = Visibility.Inherit;
                fFiltreOrdre.Input.Value = fFiltreOF.Input.Value;
                FillListOrdre();
            }
        }

        void CreateSelectOrdre()
        {
            var p = pSelectOrdre = new Panel(pSaisie);
            p.Place(16, 48, pSaisie.Width - 32, pSaisie.Height - 64);
            p.SetBorderStyle(1, "solid", new Color(200));
            p.BackgroundColor = Color.White;
            p.Visible = Visibility.Hidden;

            var l = new Label(Res.Get(8101, "Sélection d'un Ordre"), 0, 4, p.Width - 8, 16, "right", p);
            l.Color = new Color(150);

            var f = fFiltreOrdre = new Field();
            f.Place(8, 4, 200, 20, p);
            f.OnValidateEnter += FiltreOrdre_OnValidateEnter;

            var p2 = pListOrdre = new Panel(p);
            p2.Place(8, 32, p.Width - 16, p.Height - 32 - 8);
            p2.SetScrolling(Overflow.Hidden, Overflow.Visible);
            p2.BackgroundColor = new Color(200);
        }

        private void FiltreOrdre_OnValidateEnter(Field field)
        {
            FillListOrdre();
        }

        private int FillListOF()
        {
___AddToList_Delegate_0 AddToList = null;
            var count = 0;
            selectedOF = -1;
            var w = pListOF.Width / 3;
            var w2 = w * 2;

            //if (Workflow.mode_prometerre)
            //    w2 = w;

            var colOF = new Color(220, 255, 220);
            pListOF.ClearChildren();
            
            var filtre = fFiltreOF.Input.Value.Trim();
            bool wc = filtre.Length > 0;


            var y = 0;
AddToList = (OF of, string v1, string v2, string v3, Ordre ordre, bool last10) =>
{
    //Console.WriteLine($"{of.id} {ordre.desc} {ordre.desc2} {ordre.op}");
    var backp = new Panel(pListOF);
    backp.Place(0, y * 32, pListOF.Width, 31);
    backp.SetAttribute("ofid", of.id.ToString());
    backp.Div.OnDblClick = OFDblClick;
    var mul = 1f;
    if (last10)
        mul = 0.75f;
    if (Workflow.mode_prometerre)
    {
        var p = new Panel(backp);
        p.BackgroundColor = Color.Mul(colOF, mul);
        p.Place(0, 0, 80, backp.Height);
        var l = new Label(v1, 4, 2, p.Width - 4, 16, "left", p);
        l.FontSize = 9;
        p = new Panel(backp);
        p.BackgroundColor = Color.Mul(colOF, mul);
        p.Place(81, 0, w2 - 30, backp.Height);
        l = new Label(ordre.complement[2], 4, 2, p.Width - 4, 16, "left", p);
        l.FontSize = 9;
        p = new Panel(backp);
        p.BackgroundColor = Color.Mul(colOF, mul);
        p.Place(81 + w2 - 30 + 1, 0, w2, backp.Height);
        l = new Label(ordre.complement[4], 4, 2, p.Width - 4, 16, "left", p);
        l.FontSize = 9;
    }
    else
    {
        var p = new Panel(backp);
        p.BackgroundColor = Color.Mul(colOF, mul);
        p.Place(0, 0, w - 1, backp.Height);
        var l = new Label(v1, 4, 2, p.Width - 4, 16, "left", p);
        l.FontSize = 9;
        p = new Panel(backp);
        p.BackgroundColor = Color.Mul(colOF, mul);
        p.Place(w, 0, w2, backp.Height);
        l = new Label(v2, 4, 2, p.Width - 4, 16, "left", p);
        l.FontSize = 9;
    }
}

;

            if (filtre == "")
            {
                foreach (var key in lst10)
                {
                    if (!OFs.ContainsKey(key))
                        continue;
                    var of = OFs[key];

                    var ordre = ordres[of.ordreid];

                    var v1 = of.name;
                    var v2 = ordre.name;
                    var v3 = ordre.idcode;

                    bool ok = true;
                    if (wc)
                    {
                        ok = WildCardStringSearch(filtre, v1, false) ||
                             WildCardStringSearch(filtre, v2, false) ||
                             WildCardStringSearch(filtre, v3, false);
                    }

                    if (ok && schemas != null)
                    {
                        //Console.WriteLine("schema control");
                        ok = schemas.ContainsKey(key);
                    }

                    if (ok)
                    {
                        AddToList(of, v1, v2, v3, ordre, true);

                        y++;
                        count++;
                        if (y > 100) break;
                    }
                }
            }


            

            var dico = new SortedList<string, Tuple<OF, string, string, string, Ordre>>();
            foreach (var key in OFs.Keys)
            {
                if (!OFs.ContainsKey(key)) continue;
                var of = OFs[key];

                

                if (!ordres.ContainsKey(of.ordreid)) continue;
                var ordre = ordres[of.ordreid];

                var v1 = of.name;
                var v2 = ordre.name;
                var v3 = ordre.idcode;

                bool ok = true;
                if (wc)
                {
                    if (Workflow.mode_prometerre)
                    {
                        ok = WildCardStringSearch(filtre, v1, false) ||
                            WildCardStringSearch(filtre, ordre.complement[2], false) ||
                            WildCardStringSearch(filtre, ordre.complement[4], false);
                    }
                    else
                    {
                        ok = WildCardStringSearch(filtre, v1, false) ||
                             WildCardStringSearch(filtre, v2, false) ||
                             WildCardStringSearch(filtre, v3, false);
                    }
                }

                if (ok && schemas != null)
                {
                    ok = schemas.ContainsKey(key);
                }
                //Console.WriteLine($"{OFs.Keys.Count} ok:{ok} wc:{wc} schemas?{schemas != null} ofid:{key}");
                if (ok)
                {
                    var skey = v1 + " " + count;
                    var t = new Tuple<OF, string, string, string, Ordre>(of, v1, v2, v3, ordre);
                    dico.Add(skey, t);

                    count++;
                    if (count > 100) break;
                }
                //Console.WriteLine("L2");
            }

            foreach (var t in dico.Values)
            {
                AddToList(t.Item1, t.Item2, t.Item3, t.Item4, t.Item5);
                y++;
            }

            Console.WriteLine("test:" + count + "  " + dico.Count + "  " + OFs.Count);
            Console.WriteLine("test:" + (schemas != null).ToString());

            

            return count;
        }

        private void OFDblClick(MouseEvent<HTMLDivElement> e)
        {
            var sid = e.CurrentTarget.GetAttribute("ofid");
int id;
            int.TryParse(sid, out id);
            var of = OFs[id];
            AddOF10(id);
            Console.WriteLine("of.ordreCount: " + of.ordreCount);

            if (of.ordreCount == 1)
            {
                selectOrdreId = of.ordreid;
                pSelectOF.Visible = Visibility.Hidden;
                FillNewSaisie();
                SetState(true, false);
            }
            else
            {
                selectedOF = id;
                pSelectOF.Visible = Visibility.Hidden;
                pSelectOrdre.Visible = Visibility.Inherit;
                FillListOrdre();

                
            }

            Bridge.Html5.Window.SetTimeout((global::System.Action)(() => { fFiltreOrdre.Focus(); }), 100);
        }

        private void FillListOrdre()
        {
System.Action<Ordre, string, string, string> AddToList = null;
            var w = pListOrdre.Width / 3;
            var w2 = w * 2;

            var colOrdre = new Color(220, 220, 255);
            pListOrdre.ClearChildren();

            var filtre = fFiltreOrdre.Input.Value.Trim();
            bool wc = filtre.Length > 0;
            Console.WriteLine(filtre);

            var dico = new SortedList<string, Tuple<Ordre, string, string, string>>();

            var y = 0;
            foreach (var key in ordres.Keys)
            {
                var ordre = ordres[key];
                var of = OFs[ordre.ofid];

                if (selectedOF > -1 && selectedOF != ordre.ofid)
                    continue;

                var v1 = ordre.idcode;
                var v2 = ordre.name;
                var v3 = ordre.desc;

                if(Workflow.mode_prometerre)
                {
                    v1 = ordre.complement[0];// ordre.name;
                    v2 = ordre.complement[1];//ordre.desc2;
                    v3 = "";// ordre.complement[4];//ordre.op;
                }

                bool ok = true;
                if (wc)
                {
                    ok = WildCardStringSearch(filtre, v1, false) ||
                         WildCardStringSearch(filtre, v2, false) ||
                         WildCardStringSearch(filtre, v3, false);
                }

                if (ok && schemas != null)
                {
                    if (schemas.ContainsKey(ordre.ofid))
                    {
                        var proj = schemas[ordre.ofid];
                        if (!proj.ordres.Contains(ordre.id)) ok = false;
                    }
                    else
                        ok = false;
                }

                if (ok)
                {
                    var skey = v1 + " " + y;
                    var t = new Tuple<Ordre, string, string, string>(ordre, v1, v2, v3);
                    dico.Add(skey, t);

                    y++;
                    if (y > 100) break;
                }
            }
            
            y = 0;
AddToList = (ordre, v1, v2, v3) =>
{
    var backp = new Panel(pListOrdre);
    backp.Place(0, y * 32, pListOrdre.Width, 31);
    backp.SetAttribute("oid", ordre.id.ToString());
    backp.Div.OnDblClick = OrdreDblClick;
    backp.Style.Overflow = Overflow.Hidden;
    var p = new Panel(backp);
    p.BackgroundColor = colOrdre;
    p.Place(0, 0, w - 1, 31);
    var l = new Label(v1, 4, 2, p.Width - 4, 16, "left", p);
    l.FontSize = 9;
    var ww = w - 1;
    if (v3 == "")
    {
        ww = w * 2 - 1;
    }

    p = new Panel(backp);
    p.BackgroundColor = colOrdre;
    p.Place(w, 0, ww, 31);
    l = new Label(v2, 4, 2, p.Width - 4, 16, "left", p);
    l.FontSize = 9;
    if (v3 != "")
    {
        p = new Panel(backp);
        p.BackgroundColor = colOrdre;
        p.Place(w * 2, 0, ww, 31);
        l = new Label(v3, 4, 2, p.Width - 4, 16, "left", p);
        l.FontSize = 9;
    }
}

;
            foreach (var t in dico.Values)
            {
                AddToList(t.Item1, t.Item2, t.Item3, t.Item4);
                y++;
            }

            

        }

        private void OrdreDblClick(MouseEvent<HTMLDivElement> e)
        {
            var sid = e.CurrentTarget.GetAttribute("oid");
int id;
            int.TryParse(sid, out id);
            var ordre = ordres[id];

            selectOrdreId = ordre.id;
            pSelectOF.Visible = Visibility.Hidden;
            pSelectOrdre.Visible = Visibility.Hidden;



            FillNewSaisie();
            SetState(true, false);
        }

        private void FillNewSaisie()
        {
            var currentTT = 0;
            if (selectOrdreId > 0)
            {
                var ordre = ordres[selectOrdreId];

                if (schemas == null) lNOrdre.Text = ordre.idcode;
                else lNOrdre.Text = ordre.complement[2];

                if(Workflow.mode_prometerre)
                {

                    lNom.Text = ordre.complement[0];
                    lDesc.Text = ordre.complement[1];// ordre.desc2;
                    lGammeOP.Text = ordre.complement[4];// ordre.op;
                }
                else
                {
                    lNom.Text = ordre.name;
                    lDesc.Text = ordre.desc;
                    lGammeOP.Text = "";
                }

                //if (Workflow.mode_prometerre) lDesc.Text = ordre.desc2;// ordre.desc;
                //else lDesc.Text = ordre.desc;
                

                var of = OFs[ordre.ofid];
                lAff.Text = of.name;

                //if (Workflow.mode_prometerre) lGammeOP.Text = ordre.op;
                //else lGammeOP.Text = "";

                currentTT = ordre.tt;
            }
            else
            {
                lNOrdre.Text = "";
                lDesc.Text = "";
                lNom.Text = "";
                lAff.Text = "";
                lGammeOP.Text = "";
            }

            cboTT.SelectByIdx(currentTT);

            if (Workflow.mode_prometerre)
            {
                FillCboTTPrometerre();
            }


            if(cboTT.Items.Length > 1)
            {
                var n2 = cboTT.Items[1];
                if (n2.Text.ToString().ToLower().Contains("métré"))
                    cboTT.SelectByIdx(1);

                Console.WriteLine("n2: " + n2.Text);
            }


            fText.Input.Value = "";
            fDuree.Value = 0;

            for(var i = 0; i < 10; i++)
            {
                lCplElement[i].Text = "";
                lCplElement[i].id1 = 0;
                var p1 = lCplElement[i].tag as Panel;
                lCplQty[i].Text = "";
                lCplQty[i].id1 = 0;
                var p2 = lCplQty[i].tag as Panel;

                p1.SetAttribute("edx", "0"); p1.SetAttribute("val", "0");
                p2.SetAttribute("edx", "0"); p2.SetAttribute("val", "0");
                p1.BackgroundColor = Color.White;
                p2.BackgroundColor = Color.White;
            }

            var v = selectOrdreId > 0;
            ActivateFields(v);
        }

        void FillCboTTPrometerre()
        {
            cboTT.Clear();
            cboTT.Add(0, "----");

            if (schemas == null)
            {
                cboTT.SelectByIdx(0);
                return;
            }


            SchemaPrometerre first = null;
            foreach (var sp in schemas.Values)
            {
                first = sp;
                break;
            }

            if (first != null)
            {
                var selid = 0;

                foreach (var ttid in first.tt)
                {
                    if (ttravail.ContainsKey(ttid))
                    {
                        var t = ttravail[ttid];
                        cboTT.Add(t.Item1, t.Item4);
                        selid = t.Item1;
                    }
                }
            }
            //else
            //    cboTT.SelectByIdx(0);


            if (cboTT.Count == 2) cboTT.SelectByIdx(1);
            else cboTT.SelectByIdx(0);
        }

        #endregion

        #region complément
        void CreateComplementView()
        {
            var rowH = 25;
            var p = new Panel(pSaisie);
            p.Place(16, 48 + 316 + 16, pSaisie.Width - 32, rowH * 6 - 1);
            p.BackgroundColor = new Color(200);
            p.SetBorderStyle(1, "solid", new Color(200));

            if (Workflow.activity_hide_compl)
                p.Visible = Visibility.Hidden;

            var smallSz = 25;
            var qtySz = 60;
            var fixSz = smallSz * 3 + qtySz * 2;
            var largeSz = (p.Width - fixSz) / 2;

            int[] sz = { smallSz, largeSz, qtySz, smallSz, smallSz, largeSz, qtySz };

            var totalSz = 0;
            for (var i = 0; i < sz.Length; i++)
                totalSz += sz[i];
            var dif = p.Width - totalSz;
            sz[3] += dif + 1;

            string[] titles = { "", "Eléments", "Qty", "", "", "Eléments", "Qty" };
            titles[1] = titles[5] = Res.Get(8050);
            titles[2] = titles[6] = Res.Get(8051);

            for (var j = 0; j < 6; j++)
            {
                var x = 0;
                for (var i = 0; i < 7; i++)
                {
                    var p2 = new Panel(p);
                    p2.Place(x, j * rowH, sz[i] - 1, rowH - 1);
                    p2.BackgroundColor = Color.White;
                    p2.Style.Overflow = Overflow.Hidden;

                    var s = "";

                    var idx = -1;
                    if (j > 0)
                    {
                        if (i < 4) idx = j;
                        else idx = j + 5;
                    }

                    if (j == 0) s = titles[i];
                    else if (i == 0 || i == 4) s = idx.ToString();

                    var l = new Label(s, 0, 5, p2.Width, p2.Height, "center", p2);
                    l.FontSize = 8;

                    if ((i == 2 || i == 6) && j > 0)
                    {
                        l.Align = "right";
                        l.Width -= 4;
                        lCplQty[idx - 1] = l;
                        p2.Div.OnDblClick = ComplementDblClick;
                        p2.SetAttribute("cpid", idx.ToString());
                    }
                    else if ((i == 1 || i == 5) && j > 0)
                    {
                        lCplElement[idx - 1] = l;
                        p2.Div.OnDblClick = ComplementDblClick;
                        p2.SetAttribute("cpid", idx.ToString());
                    }

                    l.tag = p2;

                    x += sz[i];
                }
            }

        }

        void ComplementDblClick(MouseEvent<HTMLDivElement> e)
        {
            if (currentIdx == -1 && selectOrdreId <= 0) return;

            var t = e.CurrentTarget;
            var seid = t.GetAttribute("edx");
            var sval = t.GetAttribute("val");
            var scpid = t.GetAttribute("cpid");
int eid;
            int.TryParse(seid, out eid);
int val;
            int.TryParse(sval, out val);
            int.TryParse(scpid, out selectComplement);

            if (eid < 4096)
            {
                var forceDivers = machines.Count == 0;
                if (forceDivers)
                {
                    eid = 4096;
                    val = 0;
                }
                OpenComplement(eid, val, forceDivers);
            }
            else
            {
                OpenComplement(eid, val);
            }

        }

        void CreateComplementEdit()
        {
            var p = pCompl = new Panel(pSaisie);
            p.Place(16, 280, pSaisie.Width - 32, pSaisie.Height - 296);
            p.BackgroundColor = new Color(220, 255, 220);
            p.SetBorderStyle(1, "solid", new Color(200));
            p.Visible = Visibility.Hidden;

            var l = lTitleComplement = new Label("Machine", 0, 4, p.Width - 4, 16, "right", p);
            l.Color = new Color(150);

            var lst = listComplement = new ListBox();
            lst.OnChange += Lst_OnChange;
            lst.Place(16, 16, 300, p.Height - 32, p);

            l = lItem = new Label("", lst.Right + 16, lst.Top + 24, p.Width - lst.Right - 32, 32, "center", p);
            l.FontSize = 9;

            l = new Label(Res.Get(8152, "Quantité") + ":", lst.Right, lst.Top + 64, 100, 16, "right", p);
            var f = fQtyComplement = new Field(l, 50, Field.FieldFormat.HQty);
            fQtyComplement.OnValidateEnter += FQtyComplement_OnValidate;

            var ptb = tbComplement = new Panel(p);
            ptb.Place(lst.Right + 16, lst.Bottom - 40, p.Width - lst.Right - 32, 40);
            ptb.BackgroundColor = new Color(25, 150, 25);

            var b = new Button(ptb.Width - 80, 4, 32, 32, "", ptb);
            b.SetImage(imgdir + "63save_t.png");
            b.OnButtonClick += BCplApplyOnClick;

            b = new Button(ptb.Width - 40, 4, 32, 32, "", ptb);
            b.SetImage(imgdir + "64annule_t.png");
            b.OnButtonClick += BCplCloseOnClick;

            b = new Button(8, 4, 32, 32, "", ptb);
            b.SetImage(imgdir + "robot-arm@32px.png");
            b.OnButtonClick += BCplType_OnButtonClick;
            if (machines.Count == 0) b.Visible = Visibility.Hidden;
            b.id1 = 1;

            b = new Button(48, 4, 32, 32, "", ptb);
            b.SetImage(imgdir + "money@32px.png");
            b.OnButtonClick += BCplType_OnButtonClick;
            if (divers.Count == 0) b.Visible = Visibility.Hidden;
            b.id1 = 2;
        }

        private void FQtyComplement_OnValidate(Field field)
        {
            CheckMinMax();
        }

        private bool CheckMinMax()
        {
            
            var v = fQtyComplement.ValueI * 10;
            var id = lItem.id1;
            Tuple<int, string, string, int, int> item = null;
            
            if (id < 4096) item = machines[id];
            else item = divers[id - 4096];
            
            var min = item.Item4;
            var max = item.Item5;

            if (max > min)
            {
                if (v < min || v > max)
                {
                    Bridge.Html5.Window.Alert("Valeur à respecter: " + min / 1000 + " - " + max / 1000);
                    return false;
                }

            }

            return true;
        }

        private void Lst_OnChange(Combo combo)
        {
            var id = listComplement.SelectedId;
            Tuple<int, string, string, int, int> item = null;

            if (id >= 0)
            {
                if (id < 4096) item = machines[id];
                else item = divers[id - 4096];

                lItem.Text = item.Item2;
                lItem.id1 = id;
            }
            else
            {
                lItem.Text = "";
                lItem.id1 = 0;
            }



            fQtyComplement.Focus();
        }

        private void BCplType_OnButtonClick(Button c, MouseEvent arg)
        {
            if (c.id1 == 1) OpenComplement(0, 0);
            else if (c.id1 == 2) OpenComplement(4096, 0);
        }

        private void BCplCloseOnClick(Button c, MouseEvent arg)
        {
            pCompl.Visible = Visibility.Hidden;
        }

        private void BCplApplyOnClick(Button c, MouseEvent arg)
        {
            Console.WriteLine("A");

            var r = CheckMinMax();
            Console.WriteLine("B");

            if (!r)
            {
                fQtyComplement.Focus();
                return;
            }

            pCompl.Visible = Visibility.Hidden;

            var idx = lItem.id1;

            var i = selectComplement - 1;
            var p1 = (Panel)lCplElement[i].tag;
            var p2 = (Panel)lCplQty[i].tag;
            var qty = fQtyComplement.ValueI;
            var info = idx;


            if (idx == 0 || idx == 4096)
            {
                lCplElement[i].Text = "";
                lCplQty[i].Text = "";
                lCplElement[i].id1 = 0;
                lCplQty[i].id1 = 0;
                p1.BackgroundColor = Color.White;
            }
            else if (idx < 4096)
            {
                lCplElement[i].Text = machines[idx].Item2;
                lCplQty[i].Text = Fmt.HQty(qty);
                lCplElement[i].id1 = idx;
                lCplQty[i].id1 = qty;
                p1.BackgroundColor = cMachine;
            }
            else
            {
                idx -= 4096;
                lCplElement[i].Text = divers[idx].Item2;
                lCplQty[i].Text = Fmt.EntierCentieme(qty);
                lCplElement[i].id1 = idx + 4096;
                lCplQty[i].id1 = qty;
                p1.BackgroundColor = cDivers;
            }

            p1.SetAttribute("edx", info.ToString());
            p1.SetAttribute("val", qty.ToString());
            p2.SetAttribute("edx", info.ToString());
            p2.SetAttribute("val", qty.ToString());

            p2.BackgroundColor = p1.BackgroundColor;

            SetState(true);
        }

        void OpenComplement(int idx, int qty, bool forceDivers = false)
        {
            var machine = idx < 4096;



            Dictionary<int, Tuple<int, string, string, int, int>> dico = null;

            if (machine && !forceDivers)
            {
                dico = machines;
                pCompl.BackgroundColor = new Color(220, 255, 220);
                lTitleComplement.Text = "Machines";
                tbComplement.BackgroundColor = new Color(25, 150, 25);
                fQtyComplement.SetFormat(Field.FieldFormat.HQty);
            }
            else
            {
                dico = divers;
                pCompl.BackgroundColor = new Color(255, 255, 220);
                lTitleComplement.Text = "Divers";
                tbComplement.BackgroundColor = new Color(150, 150, 25);
                fQtyComplement.SetFormat(Field.FieldFormat.EntierCentieme);
            }

            fQtyComplement.Value = 0;
            lItem.Text = "";
            lItem.id1 = 0;


            listComplement.Clear();

            foreach (var t in dico.Values)
            {
                var id = t.Item1;
                if (!machine) id += 4096;
                listComplement.Add(id, t.Item2);
            }

            if (idx > 0)
            {
                listComplement.SelectById(idx, true);
                fQtyComplement.Value = qty;

            }

            if (!Workflow.activity_hide_compl)
                pCompl.Visible = Visibility.Inherit;
        }

        #endregion

        #endregion

        #region divers

        void CalculPeriode()
        {
            var did = (int)date.DayOfWeek;
            if (did == 0) did = 7;
            did--;

            d1 = date.AddDays(-did);
            d2 = d1.AddDays(6);

            var wid = Tool.ISOWeekNumber(d1);
            lHebdo[0].Text = "w" + wid + " " + Res.month4[d1.Month - 1] + " " + d1.Year;
            lHebdo[0].FontSize = 9;

            for (var i = 0; i < 7; i++)
            {
                var idx = 6 + i * 6;
                var d = d1.AddDays(i);
                var d2 = Res.days2[Tool.DayId(d)];
                lHebdo[idx].Text = d2[0].ToString().ToUpper() + d2.Substring(1) + " " + d.Day;
                lHebdo[idx].FontSize = 9;

                panel2[1 + i].BackgroundColor = new Color(255, 255, 100);
                if (d == date)
                    panel2[1 + i].BackgroundColor = new Color(50, 200, 50);
            }


            Console.WriteLine(d1 + "  " + oldD1);

            if (d1 != oldD1)
            {
                currentIdx = -1;
                selectOrdreId = -1;
                GetWeekDatas(d1);
                oldD1 = d1;
            }
        }

        private void SetState(bool v, bool onlyCancel = false)
        {
            state = v;

            bClose.Enabled = !v;
            b1.Enabled = !v;
            b2.Enabled = !v;
            b3.Enabled = !v;
            b4.Enabled = !v;
            b5.Enabled = !v;

            bNew.Enabled = !v;
            if (!onlyCancel || !v)
                bSave.Enabled = v;
            bCancel.Enabled = v;
            bDelete.Enabled = !v;

            Console.WriteLine(currentIdx);
            if (currentIdx < 0) bDelete.Enabled = false;
            if (currentIdx < 0 && selectOrdreId == -1)
            {
                ActivateFields(false);
            }
        }

        private void ActivateFields(bool v)
        {
            cboTT.List.Disabled = !v;
            fText.Input.Disabled = !v;
            fDuree.Input.Disabled = !v;
            fQty.Input.Disabled = fDuree.Input.Disabled;
            bC.Enabled = v;
        }

        private bool WildCardStringSearch(string pattern, string input, bool casesensitive)
        {
            if (casesensitive == false)
            {
                pattern = pattern.ToUpper(); input = input.ToUpper();
            }

            if (String.Compare(pattern, input) == 0)
            {
                return true;
            }
            else if (String.IsNullOrEmpty(input))
            {
                if (String.IsNullOrEmpty(pattern.Trim(new Char[1] { '*' })))
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else if (pattern.Length == 0)
            {
                return false;
            }
            else if (pattern[0] == '?')
            {
                return WildCardStringSearch(pattern.Substring(1), input.Substring(1), casesensitive);
            }
            else if (pattern[pattern.Length - 1] == '?')
            {
                return WildCardStringSearch(pattern.Substring(0, pattern.Length - 1), input.Substring(0, input.Length - 1), casesensitive);
            }
            else if (pattern[0] == '*')
            {
                if (WildCardStringSearch(pattern.Substring(1), input, casesensitive))
                {
                    return true;
                }
                else
                {
                    return WildCardStringSearch(pattern, input.Substring(1), casesensitive);
                }
            }
            else if (pattern[pattern.Length - 1] == '*')
            {
                if (WildCardStringSearch(pattern.Substring(0, pattern.Length - 1), input, casesensitive))
                {
                    return true;
                }
                else
                {
                    return WildCardStringSearch(pattern, input.Substring(0, input.Length - 1), casesensitive);
                }
            }
            else if (pattern[0] == input[0])
            {
                return WildCardStringSearch(pattern.Substring(1), input.Substring(1), casesensitive);
            }
            return false;
        }

        private void Npage_OnBodyResize()
        {
            var w = npage.Width;
            var h = npage.Height;

            lTitle.Left = w - 200;

        }

        #endregion


        void AddOF10(int ofid)
        {
            var tempo = new List<int>();
            for (var i = lst10.Count - 1; i >= 0; i--)
                if (lst10[i] == ofid) continue;
                else tempo.Add(lst10[i]);
            tempo.Add(ofid);

            lst10.Clear();
            for (var i = tempo.Count - 1; i >= 0; i--)
            {
                lst10.Add(tempo[i]);
                if (lst10.Count == 10) break;
            }

            var s = "";

            foreach(var idx in lst10)
                s += idx + ";";

            if (s.Length > 1)
                s = s.Substring(0, s.Length - 1);

            Tool.LSWrite("lastof10", s);
        }

        void LoadOF10()
        {
            lst10.Clear();

            var s = Tool.LSRead("lastof10", "");
            if(s.Length > 0)
            {
                var split = s.Split(';');
                for(var i = 0; i < split.Length; i++)
                {
int idx;
                    int.TryParse(split[i], out idx);
                    lst10.Add(idx);
                }
            }
        }
private delegate void ___AddToList_Delegate_0(OF of, string v1, string v2, string v3, Ordre ordre, bool last10 = false);

    }
}
