using Bridge.Html5;
using System;
using System.Collections.Generic;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class PanelPrint : Panel
    {
        private Panel pIFramePrint = null;
        private IFrame iFramePrint = null;
        private Combo cboPrint = null;

        private Button bClose, bPrint, bPrintPDF, bPrintCSV;
        private MensualView view = null;

        private Field fDate1 = null;
        private Field fDate2 = null;

        int bPrintY = 0;
        ListPers lstPers = null;

        private CheckBox ckCurrentWF = null;
        private Label lCurrentWF = null;

        bool uchef = false;
        Dictionary<int, string> upers = null;
        int uwfid = -1;

        public PanelPrint(MensualView view) : base()
        {
            BackgroundColor = GetColor("window.back");
            this.view = view;
            Size = new Size(1270, 850);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = GetColor("toolbar.back");// Color.Mobatime1;

            var lTitle = new Label(Res.Get(5000, "Impression"), 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle);
            bClose.OnButtonClick += BClose_OnButtonClick;

            pIFramePrint = new Panel();
            pIFramePrint.Place(350, 32, this.Width - 350, this.Height - 32, this);
            pIFramePrint.BackgroundColor = GetColor("panel.back");// new Color(240);
            iFramePrint = new IFrame();
            iFramePrint.Place(0, 0, pIFramePrint.Width, pIFramePrint.Width, pIFramePrint);
            iFramePrint.iFrame.FrameBorder = "0";
            iFramePrint.Width = pIFramePrint.Width - 0;
            iFramePrint.Height = pIFramePrint.Height - 0;
            iFramePrint.Style.BorderStyle = "none";

            int y = 96;

            ckCurrentWF = new CheckBox("ckWF", "Current WF", "true");
            ckCurrentWF.Place(316 - 20, y - 20, 20, 20, this);
            ckCurrentWF.Visible = Visibility.Hidden;
            ckCurrentWF.Input.OnChange = CkCurrentWF_OnChange;
            lCurrentWF = new Label("*.*", ckCurrentWF.Left - 32, ckCurrentWF.Top, 28, 20, "right", this);
            lCurrentWF.Visible = Visibility.Hidden;

            cboPrint = new Combo();
            cboPrint.Place(16, y, 300, 32, this);
            cboPrint.OnChange += CboPrint_OnChange;
            var lPrint = new Label(Res.Get(5001, "Choix du rapport :"), 0, 0, 0);
            lPrint.PlaceUpperCombo(cboPrint);
            lPrint.Width -= 100;

            y += 96;

            var l = new Label(Res.Get(5002, "Début de la période d'analyse :"), 16, y, 280, 16, "left", this);
            fDate1 = new Field(l, 232, Field.FieldFormat.DateComplete);
            fDate1.Position = new Point(l.Left, l.Bottom);

            y += 64;

            l = new Label(Res.Get(5003, "Fin de la période d'analyse:"), 16, y, 280, 16, "left", this);
            fDate2 = new Field(l, 232, Field.FieldFormat.DateComplete);
            fDate2.Position = new Point(l.Left, l.Bottom);

            fDate1.NextToFocus = fDate2;

            var bx = fDate1.Right + 16;
            var by = fDate1.Top - 32;

            var b1 = new Button(bx - 32 - 2, by - 8, 32, 32, "images/now.png", this);
            var b2 = new Button(bx, by - 8, 32, 32, "images/month_m1.png", this);
            var b3 = new Button(bx + 32 + 2, by - 8, 32, 32, "images/month_p1.png", this);

            var b4 = new Button(bx, by + 28, 32, 32, "images/fleche_gauche1_center.png", this);
            var b5 = new Button(bx + 32 + 2, by + 28, 32, 32, "images/fleche_droite1_center.png", this);


            by = fDate2.Top - 32;

            var b6 = new Button(bx, by + 28, 32, 32, "images/fleche_gauche1_center.png", this);
            var b7 = new Button(bx + 32 + 2, by + 28, 32, 32, "images/fleche_droite1_center.png", this);

            y += 160;

            bPrint = new Button(350 / 2 - 100, y, 200, 48, "", this, true);
            bPrint.Text = Res.Get(5005, "Analyser");
            bPrintY = y;

            bPrintPDF = new Button(350 / 2 - 100, y, 96, 48, "", this, true);
            bPrintPDF.Text = "PDF";
            bPrintPDF.Visible = Visibility.Hidden;
            bPrintPDF.OnButtonClick += (b, e) => { BPrint_OnButtonClick(null, null); };

            bPrintCSV = new Button(350 / 2 + 4, y, 96, 48, "", this, true);
            bPrintCSV.Text = "CSV";
            bPrintCSV.Visible = Visibility.Hidden;
            bPrintCSV.OnButtonClick += BPrintCSV_OnButtonClick;

            y -= 104;
            lstPers = new ListPers(this);
            lstPers.Place(0, y, 350, Height - y - 112);
            lstPers.Resize();
            lstPers.Visible = Visibility.Hidden;

            b1.BackgroundColor = GetColor("toolbar.back2");// Color.Mobatime2;
            b2.BackgroundColor = b1.BackgroundColor;
            b3.BackgroundColor = b1.BackgroundColor;
            b4.BackgroundColor = b1.BackgroundColor;
            b5.BackgroundColor = b1.BackgroundColor;
            b6.BackgroundColor = b1.BackgroundColor;
            b7.BackgroundColor = b1.BackgroundColor;

            b1.id1 = 1;
            b2.id1 = 2;
            b3.id1 = 3;
            b4.id1 = 4;
            b5.id1 = 5;
            b6.id1 = 6;
            b7.id1 = 7;

            b1.OnButtonClick += BPer_OnButtonClick;
            b2.OnButtonClick += BPer_OnButtonClick;
            b3.OnButtonClick += BPer_OnButtonClick;
            b4.OnButtonClick += BPer_OnButtonClick;
            b5.OnButtonClick += BPer_OnButtonClick;
            b6.OnButtonClick += BPer_OnButtonClick;
            b7.OnButtonClick += BPer_OnButtonClick;
            bPrint.OnButtonClick += BPrint_OnButtonClick;
            BPer_OnButtonClick(b1, null);


            Visible = Visibility.Hidden;
        }

        private void BPrintCSV_OnButtonClick(Button c, MouseEvent arg)
        {
            Print(true);
        }

        private void CboPrint_OnChange(Combo combo)
        {
            var id = combo.SelectedId;
            if(id < 30000)
            {
                bPrint.Visible = Visibility.Inherit;
                bPrintPDF.Visible = Visibility.Hidden;
            }
            else
            {
                bPrint.Visible = Visibility.Hidden;
                bPrintPDF.Visible = Visibility.Inherit;
            }
            bPrintCSV.Visible = bPrintPDF.Visible;
        }

        void CkCurrentWF_OnChange(Event<HTMLInputElement>e)
        {
            if (ckCurrentWF.Checked)
                Update(true, null, -1);
            else
                Update(uchef, upers, uwfid);
        }

        void DataQuery_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var csv1 = new CSVTool(result.return_s1)[0];

            cboPrint.Clear();
            for (var i = 0; i < csv1.Count; i += 2)
            {
                if (csv1.I32(i) > 0)
                {
                    var id = csv1.I32(i);
                    var name = csv1.String(i + 1);

                    cboPrint.Add(id, name);
                }
            }

            if (result.return_s2.Length > 3)
            {
                lstPers.Clear();
                var csv2 = new CSVTool(result.return_s2)[0];

                lstPers.Clear();
                lstPers.AddPers(Workflow.pid, "-. " + Workflow.nom + " .-");


                for (var i = 0; i < csv2.Count; i += 2)
                {
                    var pid = csv2.I32(i);
                    var name = csv2[i + 1];
                    lstPers.AddPers(pid, name);
                }

                lstPers.Sort();
            }

            cboPrint.Sort();
            if (cboPrint.Count > 0)
                cboPrint.SelectByIdx(0, true);
        }

        void BPrint_OnButtonClick(Button c, MouseEvent arg)
        {
            Print(false);
        }

        void BPer_OnButtonClick(Button c, MouseEvent arg)
        {
            var id = c.id1;

            if (id == 1)
            {
                var n = DateTime.Now;
                fDate1.Value = new DateTime(n.Year, n.Month, 1);
                fDate2.Value = new DateTime(n.Year, n.Month, DateTime.DaysInMonth(n.Year, n.Month));
            }
            else if (id == 2)
            {
                var n = (DateTime)fDate1.Value;
                n = n.AddMonths(-1);
                fDate1.Value = new DateTime(n.Year, n.Month, 1);
                fDate2.Value = new DateTime(n.Year, n.Month, DateTime.DaysInMonth(n.Year, n.Month));
            }
            else if (id == 3)
            {
                var n = (DateTime)fDate1.Value;
                n = n.AddMonths(1);
                fDate1.Value = new DateTime(n.Year, n.Month, 1);
                fDate2.Value = new DateTime(n.Year, n.Month, DateTime.DaysInMonth(n.Year, n.Month));
            }
            else if (id == 4)
            {
                var n = (DateTime)fDate1.Value;
                n = n.AddDays(-1);
                fDate1.Value = n;
            }
            else if (id == 5)
            {
                var n = (DateTime)fDate1.Value;
                n = n.AddDays(1);
                fDate1.Value = n;
            }
            else if (id == 6)
            {
                var n = (DateTime)fDate2.Value;
                n = n.AddDays(-1);
                fDate2.Value = n;
            }
            else if (id == 7)
            {
                var n = (DateTime)fDate2.Value;
                n = n.AddDays(1);
                fDate2.Value = n;
            }
        }

        void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            view.CloseLock();
        }

        void QueryPDF_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var file = Workflow.BaseWebPath + "/print/" + result.return_s1;
            //Console.WriteLine(file);
            iFramePrint.iFrame.Src = file;
        }

        public void Update(bool chef, Dictionary<int, string> pers, int wfid = 0)
        {
            if(pers != null)
            {
                uchef = chef;
                upers = pers;
                uwfid = wfid;
            }

            if(!chef)
            {
                ckCurrentWF.Visible = Visibility.Hidden;
                lCurrentWF.Visible = Visibility.Hidden;
            }
            else
            {
                ckCurrentWF.Visible = Visibility.Inherit;
                lCurrentWF.Visible = Visibility.Inherit;
            }

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.GetQueryWF;
            func.iparam1 = 0;
            if (chef) func.iparam1 = 1;
            func.iparam2 = Workflow.wfid_su;
            func.iparam3 = wfid;
            func.iparam4 = Workflow.pid;
            if (pers == null)
            {
                var r = Workflow.RightSU;
                foreach (var id in r.WFChefIDs)
                {
                    func.array_int.Add(id);
                    Console.WriteLine("print wf: " + id);
                } 
            }

            var dataQuery = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataQuery.OnSuccess += DataQuery_OnSuccess;


            if (chef)
            {
                bPrint.Top = Height - 80;
                bPrintPDF.Top = bPrintCSV.Top = bPrint.Top;
                lstPers.Visible = Visibility.Inherit;
                ckCurrentWF.Visible = Visibility.Inherit;
                lCurrentWF.Visible = Visibility.Inherit;

                if (pers != null)
                {
                    lstPers.Clear();

                    lstPers.AddPers(Workflow.pid, "-. " + Workflow.nom + " .-");

                    foreach (var p in pers)
                        lstPers.AddPers(p.Key, p.Value);

                    lstPers.Sort();
                }
                
            }
            else
            {
                bPrint.Top = bPrintY;
                bPrintPDF.Top = bPrintCSV.Top = bPrint.Top;
                lstPers.Visible = Visibility.Hidden;
            }
        }


        void Print(bool csvfile)
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.QueryPDF;

            var d1 = (DateTime)fDate1.Value;
            var d2 = (DateTime)fDate2.Value;

            var csv = new CSVTool();
            if (lstPers.Visible == Visibility.Hidden)
            {
                csv.A(1);
                csv.A(Workflow.pid);
            }
            else
            {
                csv.A(lstPers.Count);
                foreach (var p in lstPers.List.Items)
                {
int pid;
                    int.TryParse(p.Value, out pid);
                    csv.A(pid);
                }
            }

            func.iparam1 = D2000.T(d1);
            func.iparam2 = D2000.T(d2);
            func.iparam3 = cboPrint.SelectedId;
            func.iparam4 = Res.CurrentLg;
            func.param1 = csv.ToString();
            func.iparam5 = csvfile ? 1 : 0;
            func.iparam6 = Res.CurrentLg;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += QueryPDF_OnSuccess;
        }

    }
}
