using Bridge.Html5;
using Mobatime.JSControl;
using System;

namespace Workflow_bridge.MensualView
{
    public class pUpload : Panel
    {
        Button bClose = null;

        MensualView view = null;


        public pUpload(MensualView view)
        {
            this.view = view;

            this.Size = new Size(500, 282);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = Color.Mobatime1;

            var lTitle = new Label("Upload", 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;


            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle);
            bClose.OnButtonClick += BClose_OnButtonClick;

            var date = MensualView.CurrentDate;
            var spid = MensualView.self.CurrentPers.ToString().PadLeft(5, '0');
            var sdate = date.Year + date.Month.ToString().PadLeft(2, '0') + "00";
            var empl = Workflow.empl;
            

            var p = new Panel();
            p.Place(0, 32, 500, 250, this);
            var iFrame = new IFrame();
            iFrame.Place(0, 0, 500, 250, p);
            iFrame.iFrame.FrameBorder = "0";
            iFrame.Width = 500;
            iFrame.Height = 250;
            iFrame.Style.BorderStyle = "none";
            iFrame.iFrame.Src = "FrmUpload.aspx?lgid=" + Res.CurrentLg + "&param=" + spid + "_" + sdate + "99_00_" + empl + "&tick=" + Environment.TickCount;
            
        }

        public void Open()
        {
            view.OpenLock(this);

        }


        


        void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            view.CloseLock();
            PanelSaisieDebours.self.LoadImagesName(true);
        }

    }
}
