using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Mobatime.JSControl;
using Bridge.Html5;


namespace Workflow_bridge.Pip
{
    public class PipView
    {

        class Person
        {
            public int id = 0;
            public string name = "";
            public int status = 0;
            public string phone = "";
            public string abs = "";
            public string ptg = "";

            public string ptg1 = "";
            public string ptg2 = "";
            public bool fromWF = false;
        }

        class PNL : Panel
        {
            public Label l1 = null;
            public Label l2 = null;
            public Label l3 = null;
            public Label l4 = null;
            public Person person = null;
            public bool fromWF = false;
        }

        HtmlPage npage = null;
        Mobatime.JSControl.Window window = null;
        Button bClose = null, bAll, bPres, bSorti, bMotif, bAbs, bPrint;
        Label lTitle = null;
        List<Person> persons = new List<Person>();
        Combo cboFiltre = null;
        int status = 0;
        Panel p;
        int pnlW = 300;
        List<PNL> pnls = new List<PNL>();
        int filtre = 1;

        short[] m5_f = null;

        p4Weeks p4 = null;
        bool local = false;

        int func = 0;
        int p0 = 0;
        bool mode6000 = false;

        bool larger = false;

        public PipView(int func = 0, bool mode6000 = false, int p0 = 0)
        {
            this.func = func;
            this.p0 = p0;
            this.mode6000 = mode6000;

            Console.WriteLine("mode6000:" + mode6000);

            if (mode6000)
            {
                Workflow.Init();

                var func1 = new Api.ApiFunction();
                func1.function = Api.ApiFuncType.get_catabsence;
                func1.token = Workflow.token;
                func1.iparam1 = Res.LgCom;
                var data1 = new DataApi<Api.API_CatAbs>(Workflow.api, func1);
                data1.OnSuccess += ((Data<Api.API_CatAbs> data, Api.API_CatAbs result) =>
                {
                    Workflow.CatAbs = result;
                    //Console.WriteLine("cat abs loaded");
                });
            }
        }

        public void Show()
        {
            Workflow.SaveLocalData();

            var id = 5000;

            Bridge.Html5.Window.Open("index.html?func=" + id, "MobaPipView", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1440, height=900");
        }

        public void Place(HtmlPage page, bool local = false)
        {
            npage = page;
            this.local = local;
            Document.Title = "Mobatime JBM pip";

            CreateWindow();
        }

        void CreateWindow()
        {
            //Console.WriteLine("test time out A");

            //npage = new HtmlPage(win.Document, win.Document.Body);
            Res.LoadColor();
            window = new Mobatime.JSControl.Window(npage, 40);

            bClose = window.Toolbar.AddButton(1000);

            window.Toolbar.AddSpace(32);

            bAll = window.Toolbar.AddButton(1);
            bPres = window.Toolbar.AddButton(2);
            bSorti = window.Toolbar.AddButton(3);
            bMotif = window.Toolbar.AddButton(4);
            bAbs = window.Toolbar.AddButton(5);
            window.Toolbar.AddSpace(128);
            bPrint = window.Toolbar.AddButton(20);

            bClose.OnButtonClick += Button_OnButtonClick;
            bAll.OnButtonClick += Button_OnButtonClick;
            bPres.OnButtonClick += Button_OnButtonClick;
            bSorti.OnButtonClick += Button_OnButtonClick;
            bMotif.OnButtonClick += Button_OnButtonClick;
            bAbs.OnButtonClick += Button_OnButtonClick;
            bPrint.OnButtonClick += BPrint_OnButtonClick;

            var imgdir = Workflow.BaseWebPath + "/wf2018/";

            bClose.SetImage(imgdir + "images/63_porte_t.png", 32, 32);
            bAll.SetImage(imgdir + "images/pip.png", 32, 32);
            bPres.SetImage(imgdir + "images/present.png", 32, 32);
            bSorti.SetImage(imgdir + "images/sortis.png", 32, 32);
            bMotif.SetImage(imgdir + "images/absentmotif.png", 32, 32);
            bAbs.SetImage(imgdir + "images/sansmotif.png", 32, 32);
            bPrint.SetImage(imgdir + "images/printer-64.png", 32, 32);

            cboFiltre = new Combo("", 600, 8, 200, 24, window.Toolbar);
            cboFiltre.OnChange += CboFiltre_OnChange;
            cboFiltre.Visible = Visibility.Hidden;
            npage.OnBodyResize += Npage_OnBodyResize;

            var w = npage.Width;// win.InnerWidth;
            var h = npage.Height;// win.InnerHeight;


            p = new Panel(window.Container);
            p.Place(0, 0, window.Container.Width, window.Container.Height);
            p.BackgroundColor = new Color(255);
            p.SetScrolling(Overflow.Auto, Overflow.Hidden);

            //var p2 = new Panel(p);
            //p2.Place(0, 48, p.Width - 1, p.Height - 1);
            //p2.BackgroundColor = new Color(255);


            var title = "JBM info";
            lTitle = new Label(title, w - 200, 12, 180, 16, "right");
            window.Toolbar.AddChild(lTitle);
            lTitle.Color = Color.White;
            lTitle.FontSize = 14;

            LoadFiltre();



            if (func == 0) Button_OnButtonClick(bAll, null);
            else if (func == 1) Button_OnButtonClick(bPres, null);
            else if (func == 2) Button_OnButtonClick(bSorti, null);
            else if (func == 3) Button_OnButtonClick(bMotif, null);
            else if (func == 4) Button_OnButtonClick(bAbs, null);

            if (func > 0)
            {
                window.Toolbar.Visible = Visibility.Hidden;
                window.Container.Place(0, 0, window.Width, window.Height);
            }



        }

        private void BPrint_OnButtonClick(Button c, MouseEvent arg)
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.PrintPip;
             
            var csv = new CSVTool();

            foreach (var p in pnls)
                if (p.person != null && p.Visible != Visibility.Hidden) 
                    csv.A(p.person.id);

            func.param1 = csv.ToString();
            func.iparam1 = Res.CurrentLg;

            var dataPrint = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataPrint.OnSuccess += (a, b) =>
            {
                Bridge.Html5.Window.Open("../print/" + b.return_s1, "PiP PDF", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1200, height=810");
            };
        }

        private void CboFiltre_OnChange(Combo combo)
        {
            Load();
        }

        void LoadFiltre()
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.jumbo_get_filtre;
            func.iparam1 = Workflow.pid;
            func.iparam2 = 1;
            func.iparam3 = 2;
            func.iparam4 = Workflow.wfid_su;
            var dataFiltre = new DataApi<Api.ApiReturn>(Workflow.api, func);
            
            dataFiltre.OnSuccess += (a, b) => 
            {
                cboFiltre.Clear();
                cboFiltre.Add(0, "----");

                var splitted = b.return_s1.Split(';');
                var count = b.return_i1;

                for (var i = 0; i < count; i++)
                {
int id;
                    int.TryParse(splitted[i * 2 + 0], out id);
                    cboFiltre.Add(id, splitted[i * 2 + 1]);
                }

                if (cboFiltre.Count > 1)
                    cboFiltre.Visible = Visibility.Inherit;

                Load();
            };
        }

        void Load()
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.pip);
            func.iparam1 = Workflow.pfunc;
            func.iparam2 = Workflow.wfid_su;
            func.iparam3 = p0;
            func.iparam4 = cboFiltre.SelectedId;
            func.iparam5 = Workflow.pid;
            var dataPip = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataPip.OnSuccess += DataPip_OnSuccess;

            Console.WriteLine("p0: " + p0);
        }

        private void DataPip_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            persons.Clear();

            var s = result.return_s1.Split('\r');
            for(var i = 0; i < s.Length; i++)
                if (s[i][0] == '\n')
                    s[i] = s[i].Substring(1);


            m5_f = result.data;

            var lg = result.data[19];
            Console.WriteLine("lg:" + lg + "  local:" + local);

            if(local)
            {
                if (lg == 1) lg = 2;
                Res.CurrentLg = lg;
                Res.LgCom = lg;
                Res.Load();

                bClose.Visible = Visibility.Hidden;
            }

            

            foreach (var s2 in s)
            {


                var s3 = s2.Split(';');

                if (s3.Length > 5)
                {
                    //Console.WriteLine(s2);

                    var p = new Person();
                    {
                        p.name = s3[1];
                        int.TryParse(s3[0], out p.id);
                        int.TryParse(s3[4], out p.status);
                        p.phone = s3[14];

                        p.abs = s3[5] + " " + s3[6];

                        p.ptg1 = s3[8].Trim();
                        p.ptg2 = s3[9].Trim();

                        if(s3[9].Trim() != "" && p.status == 1)
                            p.ptg = "in:" + s3[9];
                        else if (s3[9].Trim() == "" && s3[8].Trim() != "" && p.status == 1)
                            p.ptg = "in:" + s3[8];
                        if (p.status == 2 && s3[8].Trim() != "")
                            p.ptg = "in:" + s3[8] + " out:" + s3[9];

                        var interrupt = "";
                        if(m5_f[9] > 0 && s3.Length > 20 && s3[20].Trim().Length > 0)
                            interrupt = string.Format(" [{0}]",s3[20].Trim());
                        p.ptg += interrupt;
int ptgType;

                        int.TryParse(s3[18], out ptgType);

                        var ptgLieu = "";
                        if (ptgType > 10000 && ptgType < 20000)
                        {
                            ptgLieu = "workflow";
                            p.fromWF = true;
                            larger = true;
                        }
                        else if(ptgType == 31100)
                        {
                            ptgLieu = "mobile";
                            p.fromWF = true;
                            larger = true;
                        }

                        p.ptg += " " + ptgLieu;



                        if (m5_f[0] == 1) p.ptg = interrupt;        // si on ne veut pas voir le timbrage, on met quand même l'interruption
                        if (m5_f[8] == 1 && p.abs.Trim().Length > 2) p.abs = "Abs";
                        if (m5_f[1] == 0) p.phone = "";
                    }

                    var key = p.name + persons.Count.ToString().PadLeft(6, '0');

                    persons.Add(p);
                }
            }


            Sort();
            MkView();

            Bridge.Html5.Window.SetTimeout((global::System.Action)Load, 5000);

        }


        private void MkView()
        {
            var count = persons.Count;

            var x = 8;
            var y = 8;

            pnlW = 300;
            if (larger) pnlW = 400;

            foreach(var p in pnls)
                p.Visible = Visibility.Hidden;

            int countP = 1;
            foreach (var p in persons)
            {

                var ok = false;

                if (filtre == 1) ok = true;
                else if (filtre == 2 && p.status == 1) ok = true;
                else if (filtre == 3 && p.status == 2 && p.ptg1 != "" && p.ptg2 != "") ok = true;
                else if (filtre == 4 && p.status == 3) ok = true;
                else if (filtre == 5 && p.status == 2) ok = true;

                if (!ok) continue;

                PNL pnl = null;

                if (countP > pnls.Count)
                {
                    pnl = new PNL();
                    pnl.Place(x, y, pnlW, 48, this.p);
                    pnl.BackgroundColor = new Color(200);
                    pnls.Add(pnl);
                }
                else
                {
                    pnl = pnls[countP - 1];
                    pnl.Place(x, y, pnlW, 48);
                    pnl.Visible = Visibility.Inherit;
                }

                SetPnl(p, pnl);

                y += 56;
                if(y >= this.p.Height - 72)
                {
                    y = 8;
                    x += pnlW + 8;
                }

                countP++;
            }
        }


        void SetPnl(Person p, PNL pnl)
        {

            pnl.person = p;

            if (p.status == 1 && !p.fromWF)
                pnl.BackgroundColor = new Color(100, 230, 100);
            else if (p.status == 1 && p.fromWF)
                pnl.BackgroundColor = new Color(50, 150, 50);
            else if(p.status == 3)
                pnl.BackgroundColor = new Color(248, 225, 101);
            else
                pnl.BackgroundColor = new Color(255, 150, 150);

            if (pnl.l1 == null)
                pnl.l1 = new Label("", 4, 4, pnl.Width - 64, 16, "left", pnl);

            if (pnl.l2 == null)
                pnl.l2 = new Label("", pnl.Width - 60, 4, 56, 16, "right", pnl);

            if (pnl.l3 == null)
                pnl.l3 = new Label("", 4, 28, pnl.Width / 2 + 48, 16, "left", pnl);

            if (pnl.l4 == null)
                pnl.l4 = new Label("", pnl.Width / 2, 28, pnl.Width / 2 - 4, 16, "right", pnl);

            pnl.l1.Text = p.name;
            pnl.l2.Text = p.phone;
            pnl.l3.Text = p.ptg;
            pnl.l4.Text = p.abs;

            pnl.Div.OnDblClick = pnlDblClick;

            
            pnl.SetAttribute("pid", p.id.ToString());
            pnl.l1.SetAttribute("pid", p.id.ToString());
            pnl.l2.SetAttribute("pid", p.id.ToString());
            pnl.l3.SetAttribute("pid", p.id.ToString());
            pnl.l4.SetAttribute("pid", p.id.ToString());
        }

        private void pnlDblClick(MouseEvent<HTMLDivElement> arg)
        {
            if (m5_f[8] > 0) return;

            if(p4 == null)
            {
                p4 = new p4Weeks(window.Container);
            }

            
            var spid = arg.Target.GetAttribute("pid");
int pid;
            
            int.TryParse(spid, out pid);

            p4.Update(pid, m5_f[8] == 0);
            
        }

        private void Npage_OnBodyResize()
        {
            var w = npage.Width;// win.InnerWidth;
            var h = npage.Height;// win.InnerHeight;
            p.Place(0, 0, w, h - 48);
            //pPers.Place(0, 41, 200, h - 64 - 20);
            //pData.Place(pPers.Width + 1, pPers.Top, w - pPers.Width - 1, h - 41 - 40);
            //pHeader.Place(pPers.Width + 1, 0, w - pPers.Width - 1 - 20, 40);

            lTitle.Left = w - 200;

            MkView();
        }

        private void Button_OnButtonClick(Button c, Bridge.Html5.MouseEvent arg)
        {
            var id = c.id1;

            if (id == 1000)
            {
                Bridge.Html5.Window.Close();
            }
            else
            {
                filtre = id;
                MkView();
            }
        }


        public void Sort()
        {
            var arr = new Person[persons.Count];

            var k = 0;
            foreach (var o in persons)
                arr[k++] = o;

            Array.Sort<global::Workflow_bridge.Pip.PipView.Person>(arr, (global::System.Comparison<global::Workflow_bridge.Pip.PipView.Person>)Compare);

            persons.Clear();

            for (var i = 0; i < arr.Length; i++)
                persons.Add(arr[i]);

        }

        private int Compare(Person o1, Person o2)
        {
            var s1 = o1.name;
            var s2 = o2.name;

            return string.Compare(s1, s2);
        }

    }
}
