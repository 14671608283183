using System;
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using System.Linq;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class PanelWkData : Panel
    {
        public static PanelWkData Self { get; private set; }

        Dictionary<int, Tuple<int, string, string>> ttravail = null;
        Dictionary<int, Tuple<int, string, string, int, int>> machines = null;
        Dictionary<int, Tuple<int, string, string, int, int>> divers = null;

        Dictionary<int, WorkActivity.OF> OFs = null;
        Dictionary<int, WorkActivity.Ordre> ordres = null;

        MensualView view = null;
        Button bClose = null, bPer1, bPer2, bPer3;
        Combo cbo = null;
        DateTime curDate = DateTime.Now.Date;

        int pid = -1;
        Label lDate;
public int CurPid
{
    get
    {
        return pid;
    }
}public DateTime CurDate
{
    get
    {
        return curDate;
    }
}
        GridWkData grid = null;
        DetailOrdre pDetailOrdre = null;
        TotalOrdres pTotalOrdres = null;
        pShowGT showGT = null;
        pShowQty showQty = null;

        WkSelectTable selectTable = null;

        bool chef = false;

        public PanelWkData(MensualView view)
        {
            Self = this;
            this.view = view;
            this.Size = new Size(1200, 850);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = Color.Mobatime1;

            var lTitle = new Label("Work Data", 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle);
            bClose.OnButtonClick += BClose_OnButtonClick;

            bPer1 = new Button(bClose.Right + 16, 0, 32, 32, "images/63_porte_t.png", pTitle, false, 1);
            bPer2 = new Button(bPer1.Right + 4, 0, 32, 32, "images/63_porte_t.png", pTitle, false, 2);
            bPer3 = new Button(bPer2.Right + 4, 0, 32, 32, "images/63_porte_t.png", pTitle, false, 3);

            bPer1.SetImage("images/arrow_left.png", 32, 32);
            bPer2.SetImage("images/now.png", 32, 32);
            bPer3.SetImage("images/arrow_right.png", 32, 32);

            bPer1.OnButtonClick += BPer1_OnButtonClick;
            bPer2.OnButtonClick += BPer1_OnButtonClick;
            bPer3.OnButtonClick += BPer1_OnButtonClick;
                                                           
            var bswap = new Button(720, 0, 32, 32, "images/view-details-nowin.png", pTitle, false, 10);
            bswap.OnButtonClick += (a, b) => 
            { 
                if(pDetailOrdre.Visible == Visibility.Inherit)
                {
                    pDetailOrdre.Visible = Visibility.Hidden;
                    pTotalOrdres.Visible = Visibility.Inherit;
                }
                else
                {
                    pDetailOrdre.Visible = Visibility.Inherit;
                    pTotalOrdres.Visible = Visibility.Hidden;
                }
            };

            lDate = new Label("", bPer3.Right + 16, 8, 250, 16, "left", pTitle);
            lDate.Color = Color.White;
            //lDate.Text = Fmt.DateComplete(curDate);

            cbo = new Combo("", lDate.Right + 8, 0, 300, 32, pTitle);
            cbo.OnChange += Cbo_OnChange;

            grid = new GridWkData(this, this);
            grid.OnSelect += Grid_OnSelect;
            if (pid > 0)
                grid.LoadGrid(pid, curDate, pTotalOrdres);

            pDetailOrdre = new DetailOrdre(this);
            pDetailOrdre.Visible = Visibility.Inherit;
            pTotalOrdres = new TotalOrdres(this);
            pTotalOrdres.Visible = Visibility.Hidden;

            selectTable = new WkSelectTable(this, pDetailOrdre);
            selectTable.Visible = Visibility.Hidden;

            showQty = new pShowQty(this, this, selectTable);
            showQty.OnChange += ShowQty_OnChange;
            showQty.OnStateChange += ShowQty_OnStateChange;

            showGT = new pShowGT(this);



            BackgroundColor = new Color(240);

        }

        private void ShowQty_OnStateChange(pShowQty pShowQty, bool v)
        {
            cbo.List.Disabled = v;
            bPer1.Enabled = !v;
            bPer2.Enabled = !v;
            bPer3.Enabled = !v;
            bClose.Enabled = !v;
            grid.Enabled = !v;
        }

        private void ShowQty_OnAskToShowTable(pShowQty pShowQty, int listType)
        {
            selectTable.Show(listType);
        }

        private void ShowQty_OnChange(pShowQty pShowQty)
        {
            SelectDay();
        }

        private void Cbo_OnChange(Combo combo)
        {
            UpdateGrid();
        }

        public void UpdateGrid()
        {
            pid = cbo.SelectedId;
            grid.LoadGrid(pid, curDate, pTotalOrdres);
            //Console.WriteLine(pid + "   " + curDate);
        }

        private void BPer1_OnButtonClick(Button c, MouseEvent arg)
        {
            switch(c.id1)
            {
                case 1:
                    curDate = curDate.AddDays(-1);
                    break;
                case 2:
                    curDate = DateTime.Now.Date;
                    break;
                case 3:
                    curDate = curDate.AddDays(1);
                    break;
            }

            SelectDay();

            //grid.LoadGrid(pid, curDate);
            //lDate.Text = Fmt.DateComplete(curDate);
        }

        private void Grid_OnSelect(GridWkData grid, GridWkData.pRow row)
        {
            pDetailOrdre.ShowOrder(row.Ordre, PanelWkData.Self.CurPid, PanelWkData.Self.CurDate);
            showQty.ShowRow(row);
        }

        private void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            view.CloseLock();
        }

        public void Open(bool chef)
        {
System.Action Ask4TB = null;
            view.OpenLock(this);
            BackgroundColor = new Color(240);

            this.chef = chef;

            cbo.Clear();
            foreach (var item in view.GetPersFromCombo())
                cbo.Add(item);
            cbo.SelectById(view.CurrentPers);

            if (!chef)
            {
                cbo.Visible = Visibility.Hidden;
                //showQty.Visible = Visibility.Hidden;
            }
            else
            {
                cbo.Visible = Visibility.Inherit;
                //showQty.Visible = Visibility.Inherit;
            }

            pid = view.CurrentPers;


            curDate = DateTime.Now;

            var sel = MensualView.Grid.Selected;
            if(sel.Count > 0)
                curDate = sel[0].Date;

            SelectDay();
Ask4TB = () =>
{
    this.OFs = Workflow.OFs;
    this.ordres = Workflow.ordres;
    var func2 = new Api.ApiFunction(Api.ApiFuncType.GetWorkActTB);
    func2.iparam1 = Workflow.pid;
    var dataTB = new DataApi<Api.ApiReturn>(Workflow.api, func2);
    dataTB.OnSuccess += DataTB_OnSuccess;
}

;

            if (Workflow.OFs == null || Workflow.OFs.Count == 0 || Workflow.ordres == null || Workflow.ordres.Count == 0)
            {
                Workflow.OFs = new Dictionary<int, WorkActivity.OF>();
                Workflow.ordres = new Dictionary<int, WorkActivity.Ordre>();

                var func = new Api.ApiFunction(Api.ApiFuncType.GetWorkActOrdreOF);
                func.iparam1 = Workflow.pid;
                func.iparam3 = 1;
                var dataOrdreOF = new DataApi<Api.ApiReturn>(Workflow.api, func);
                dataOrdreOF.OnSuccess += (Data<Api.ApiReturn> Data, Api.ApiReturn result) =>
                {
                    CreateOFOrdreTable(result.return_s1, result.return_s2, result.return_s3);
                    Ask4TB();
                };
            }
            else
            {
                Ask4TB();
            }

            
        }

        public void SelectDay()
        {
            lDate.Text = Fmt.DateComplete(curDate);
            grid.LoadGrid(pid, curDate, pTotalOrdres);
            pDetailOrdre.ShowOrder(0, PanelWkData.Self.CurPid, PanelWkData.Self.CurDate);
            showGT.Show(pid, curDate, chef, Workflow.RightSU);
            showQty.ShowRow(null);
        }

        public void SetGtData(string data)
        {
            showGT.SetData(pid, CurDate, data);
        }


        #region load ordres and ofs
        private void CreateOFOrdreTable(string s1, string s2, string s3)
        {
            var csv1 = new CSVTool(s1);
            var csv2 = new CSVTool(s3);

            if (csv1.ObjectsCount > 0)
            {
                var ordres = csv1[0];

                //Console.WriteLine("ordre count: " + ordres.Count);

                if (ordres.Count >= 5)
                {
                    for (var i = 0; i < ordres.Count; i += 5)
                    {
                        var ordre = new WorkActivity.Ordre();
                        ordre.id = ordres.I32(i);
                        ordre.idcode = ordres[i + 1];
                        ordre.name = ordres[i + 2];
                        ordre.desc = ordres[i + 3];
                        ordre.ofid = ordres.I32(i + 4);


                        Workflow.ordres.Add(ordre.id, ordre);
                    }
                }

            }

            if (csv2.ObjectsCount > 0)
            {
                var ofs = csv2[0];
                if (ofs.Count >= 52)
                {
                    for (var i = 0; i < ofs.Count; i += 52)
                    {
                        var of = new WorkActivity.OF();
                        of.id = ofs.I32(i);
                        of.name = ofs[i + 1];
                        of.ordreid = ofs.I32(i + 2);

                        for (var j = 0; j < 50; j++)
                        {
                            of.ordresid[j] = ofs.I32(i + 2 + j);
                            if (of.ordresid[j] > 0)
                            {
                                var oid = of.ordresid[j];
                                if (Workflow.ordres.ContainsKey(oid))
                                    of.ordreCount++;
                                else
                                    of.ordresid[j] = 0;
                            }
                        }

                        Workflow.OFs.Add(of.id, of);
                    }
                }
            }
        }

        private void DataTB_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            ttravail = new Dictionary<int, Tuple<int, string, string>>();
            machines = new Dictionary<int, Tuple<int, string, string, int, int>>();
            divers = new Dictionary<int, Tuple<int, string, string, int, int>>();

            var csv1 = new CSVTool();
            var csv2 = new CSVTool();
            var csv3 = new CSVTool();
            csv1.LoadData(result.return_s1);
            csv2.LoadData(result.return_s2);
            csv3.LoadData(result.return_s3);

            if (csv1.ObjectsCount > 0 && csv1[0].Count >= 5)
                for (var i = 0; i < csv1[0].Count; i += 5)
                    machines.Add(csv1[0].I32(i), new Tuple<int, string, string, int, int>(csv1[0].I32(i), csv1[0][i + 1], csv1[0][i + 2], csv1[0].I32(i + 3), csv1[0].I32(i + 4)));

            if (csv2.ObjectsCount > 0 && csv2[0].Count >= 5)
                for (var i = 0; i < csv2[0].Count; i += 5)
                    divers.Add(csv2[0].I32(i), new Tuple<int, string, string, int, int>(csv2[0].I32(i), csv2[0][i + 1], csv2[0][i + 2], csv2[0].I32(i + 3), csv2[0].I32(i + 4)));

            if (csv3.ObjectsCount > 0 && csv3[0].Count >= 3)
                for (var i = 0; i < csv3[0].Count; i += 3)
                    ttravail.Add(csv3[0].I32(i), new Tuple<int, string, string>(csv3[0].I32(i), csv3[0][i + 1], csv3[0][i + 2]));
    
        }

        #endregion


    
private static PanelWkData __Property__Initializer__Self=null;}
}
