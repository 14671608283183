using System;
using Bridge.Html5;
using Mobatime.JSControl;

namespace Workflow_bridge.MensualView
{
    public class PanelSaisie
    {
        public delegate void DOnChange(PanelSaisie p);

        public event DOnChange OnChange = null;
        public event DOnChange OnOpenClose = null;

        protected readonly Panel _pParent = null;
        protected readonly Panel _pHeader = null;
        protected readonly Label _lHeader = null;
        protected readonly Panel _pBack = null;

        protected readonly Button _bUpDown = null;

        Color backHeaderColor = Color.Mobatime1;


        protected bool toCheck = false;
        protected bool ToCheck
{
    get
    {
        return toCheck;
    }

    
    set
    {
        toCheck = value;
        UpdateButton();
    }
}
        protected string lsKey = "";

        int fixe_back_y = 0;
        protected int sz1 = 0;

        public bool Closed = false;

        public int id = 0;
public int Bottom
{
    get
    {
        return _pBack.Bottom;
    }
}
        private bool _visible = true;
        public bool Visible
{
    get
    {
        return _visible;
    }

    
    set
    {
        _visible = value;
        if (_visible)
            _pHeader.Visible = Visibility.Inherit;
        else
            _pHeader.Visible = Visibility.Hidden;
        if (!Closed)
            _pBack.Visible = _pHeader.Visible;
        else
            _pBack.Visible = Visibility.Hidden;
    //ChangeVisibility();
    }
}        
        private bool _modified = false;
        public bool Modified
{
    get
    {
        return _modified;
    }

    
    set
    {
        _modified = value;
        if (_modified)
        {
            _pHeader.BackgroundColor = new Color(160, 128, 255);
            Change();
        }
        else
            _pHeader.BackgroundColor = backHeaderColor; // Color.Mobatime1;
    }
}        
        public PanelSaisie(Panel pParent)
        {
            _pParent = pParent;

            backHeaderColor = ResT.GetColor("toolbar.back2");// Color.Mobatime2;// new Color(120);

            _pHeader = new Panel();
            _pHeader.BackgroundColor = backHeaderColor;// new Color(150);// Color.Mobatime1;
            _pHeader.Place(8, 16, 600 - 24, 28, _pParent);

            _lHeader = new Label("", 8, 2, _pHeader.Width - 16, 16, "left", _pHeader);
            _lHeader.Color = Color.White;
            _lHeader.FontSize = 14;

            _pBack = new Panel();
            _pBack.BackgroundColor = ResT.GetColor("panel.back");// Color.White;
            _pBack.Place(_pHeader.Left + 8, _pHeader.Bottom, _pHeader.Width - 8, 300, _pParent);

            _bUpDown = new Button(_pHeader.Width - 32, 2, 24, 24, "images/double_up.png", _pHeader);
            _bUpDown.OnButtonClick += _bUpDown_OnButtonClick;


            UpdateButton();
        }

        private void _bUpDown_OnButtonClick(Button c, MouseEvent arg)
        {
            if(_bUpDown.id1 == 0)
            {
                Closed = true;
            }
            else
            {
                Closed = false;
            }

            Tool.LSWrite(lsKey, Closed.ToString());

            OnOpenClose!=null?global::Bridge.Script.FromLambda(()=>OnOpenClose.Invoke(this)):null;

            _bUpDown.id1++;
            _bUpDown.id1 %= 2;

            UpdateButton();
        }

        public void UpdateButton()
        {
            if (_bUpDown.id1 == 0) _bUpDown.SetImage("images/28_chevron_haut_blanc.png");
            else
            {
                if (!toCheck) _bUpDown.SetImage("images/28_chevron_bas_blanc.png");
                else _bUpDown.SetImage("images/28_chevron_bas.png");
            }
        }

        public void Close()
        {
                Closed = true;
            //OnOpenClose?.Invoke(this);

            _bUpDown.id1 = 1;

            UpdateButton();
        }

        public virtual void Open()
        {
            Closed = false;

            _bUpDown.id1 = 0;

            UpdateButton();
        }

        public void SetHeaderHeight(int v)
        {
            _pHeader.Height = v;
            _lHeader.Height = v;
            _pBack.Place(_pHeader.Left + 8, _pHeader.Bottom, _pHeader.Width - 8, 300);
        }

        public void SetY(int y)
        {
            if(Closed || !_visible)
            {
                _pBack.Height = 0;
                _pBack.Visible = Visibility.Hidden;
            }
            else
            {
                _pBack.Height = GetHeight();
                _pBack.Visible = Visibility.Inherit;
            }

            _pHeader.Position = new Point(8, y);
            _pBack.Position = new Point(_pHeader.Left + 8, _pHeader.Bottom);
        }

        public virtual void Change()
        {
            OnChange!=null?global::Bridge.Script.FromLambda(()=>OnChange.Invoke(this)):null;
        }

        public virtual void ChangeVisibility()
        {
            
        }
        
        protected virtual int GetHeight()
        {
            return sz1;
        }

        public void BlockUpDown(bool v)
        {
            _bUpDown.Enabled = !v;
        }

        public void CheckLS()
        {
            var sv = Tool.LSRead(lsKey);
bool v;
            bool.TryParse(sv, out v);

            if (v) Close();
            else Open();
        }

    }

}