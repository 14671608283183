using System;
using System.Collections.Generic;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class pPunch : Panel
    {
        Button bClose = null;
        Button bPunch = null;
        MensualView view = null;

        Button[] bAbs = new Button[16];

        Label lPunch = null;
        bool saved = false;
        bool tpoint = false;

        int absSel = -1;

        public pPunch(MensualView view)
        {
            this.view = view;

            this.Size = new Size(640, 400);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = Color.Mobatime1;

            var lTitle = new Label(Res.Get(7000, "Pointage"), 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            var w = (Width - 32) / 4;
            var w2 = w - 16;

            for(var i = 0; i < bAbs.Length; i++)
            {
                var x = 24 + w * (i % 4);
                var y = 48 + i / 4 * 64;

                var b = bAbs[i] = new Button(x, y, w2, 48, "", this, true);
                b.Text = "Abs 1";
                b.id1 = i;
                b.OnButtonClick += B_OnButtonClick;
                b.Visible = Visibility.Hidden;
            }

            bPunch = new Button(320 - 75, 64 + 4 * 64, 150, 48, "", this, true);
            bPunch.Text = Res.Get(7001, "Pointer !");
            bPunch.OnButtonClick += BPunch_OnButtonClick;
            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle);
            bClose.OnButtonClick += BClose_OnButtonClick;

            lPunch = new Label("", 0, 0, Width, 16, "center", pTitle);
            lPunch.FontSize = 18;
            lPunch.Color = Color.White;
        }

        private void B_OnButtonClick(Button c, MouseEvent arg)
        {
            if (absSel > -1)
                bAbs[absSel].SetBorderColor(Button.def_borderColor1);

            if (absSel != c.id1) absSel = c.id1;
            else absSel = -1;

            if (absSel > -1)
                c.SetBorderColor(Color.Red);
        }

        public void Open()
        {
            saved = false;
            bPunch.Visible = Visibility.Visible;
            view.OpenLock(this);
            UpdateLPunch();

            absSel = -1;
            for (var i = 0; i < bAbs.Length; i++)
                if (bAbs[i] != null)
                    bAbs[i].SetBorderColor(Button.def_borderColor1);

            var func = new Api.ApiFunction(Api.ApiFuncType.GetInterruption);
            func.iparam1 = Workflow.wfid_su;
            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, r) => 
            {
                if (r.return_s1 != "")
                {
                    var csv = new CSVTool(r.return_s1)[0];

                    for (var i = 0; i < csv.Count; i += 3)
                    {
                        var id = csv.I32(i);
                        var abs = csv.I32(i + 1);
                        if (abs > 0)
                            bAbs[id].Visible = Visibility.Inherit;
                        else
                            bAbs[id].Visible = Visibility.Hidden;

                        bAbs[id].id2 = abs;
                        var txt = csv[i + 2];
                        bAbs[id].Text = txt;
                    }
                }

            };



        }

        void UpdateLPunch()
        {
            if (!saved)
            {
                var n = DateTime.Now;

                if(tpoint)
                    lPunch.Text = n.Hour + ":" + n.Minute.ToString().PadLeft(2, '0');
                else
                    lPunch.Text = n.Hour + "&nbsp;" + n.Minute.ToString().PadLeft(2, '0');

                tpoint = !tpoint;


                Bridge.Html5.Window.SetTimeout((global::System.Action)UpdateLPunch, 500);
            }
        }

        void BPunch_OnButtonClick(Button c, MouseEvent arg)
        {
            var abs = 0;

            if (absSel > -1) abs = bAbs[absSel].id2;

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.punch;
            func.iparam1 = Workflow.pid;
            func.iparam2 = 10000 + Workflow.WFSU;
            func.iparam3 = abs;
            func.iparam4 = Workflow.RightSU.PunchType;
            func.iparam5 = DateTime.Now.Hour * 60 + DateTime.Now.Minute;
            func.iparam6 = DateTime.Now.Year;
            func.iparam7 = DateTime.Now.Month;
            func.iparam8 = DateTime.Now.Day;
            func.token = Workflow.token;
            var dataPunch = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataPunch.OnSuccess += DataPunch_OnSuccess;
        }


        void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            view.CloseLock();
            view.SetDate(99, true);
        }
        void DataPunch_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {

            if (result.return_b1)
            {
                lPunch.Text = Res.Get(7002, "Pointage Sauvé");
                saved = true;
                bPunch.Visible = Visibility.Hidden;
                view.DisableBPunch();
                Bridge.Html5.Window.SetTimeout((global::System.Action)view.EnableBPunch, 60000);
                //Bridge.Html5.Window.SetTimeout(view.EnableBPunch, 5000);
            }
            else
            {
                lPunch.Text = "Error";
            }

            Bridge.Html5.Window.SetTimeout((global::System.Action)ClosePPunch, 2000);
        }

        void ClosePPunch()
        {
            view.CloseLock();
            view.SetDate(99, true);
        }
    }
}
