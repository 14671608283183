using System;               
using System.Collections.Generic;
using System.ComponentModel.DataAnnotations;
using System.Linq;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class pShowQty : Panel
    {
        public delegate void dOnChange(pShowQty pShowQty);
        public event dOnChange OnChange = null;

        public delegate void dOnStateChange(pShowQty pShowQty, bool v);
        public event dOnStateChange OnStateChange = null;

        public delegate void dOnAskToShowTable(pShowQty pShowQty, int listType);
        public event dOnAskToShowTable OnAskToShowTable = null;

        Button bnew, bmodify, bsave, bcancel, btrash, bkor, bsplit;
        GridWkData.pRow selected;

        Label lOrdre, lTT, lCF, lMRebut;
        Field fDate1, fHeure1, fDate2, fHeure2, fQtyA, fQtyR, fDuree;
        Button bC;

        WkSelectTable selectTable = null;
        PanelWkData wkData = null;
        pSplit pSplit = null;

        bool korMode = false;

        public pShowQty(Panel parent, PanelWkData WkData, WkSelectTable selectTable) : base(16, 414, 700 - 32, parent.Height - 414 - 16, Color.White, parent)
        {
            var pTitle = new Panel(this);
            pTitle.Place(0, 0, 40, this.Height);
            pTitle.BackgroundColor = Color.Mobatime2;

            this.wkData = WkData;

            this.selectTable = selectTable;
            selectTable.OnSelect += SelectTable_OnSelect;

            Label l;

            var lTitle = l = new Label(Res.Get(9100, "Quantité"), 40, 4, this.Width - 44, 16, "right", this);
            l.Color = new Color(200);

            bnew = new Button(4, 4, 32, 32, "images/new.png", pTitle, false, 1);
            bmodify = new Button(4, 44, 32, 32, "images/pen.png", pTitle, false, 2);
            bsave = new Button(4, 84, 32, 32, "images/save@32px.png", pTitle, false, 3);
            bcancel = new Button(4, 124, 32, 32, "images/32_annule_t.png", pTitle, false, 4);
            bkor = new Button(4, bcancel.Bottom + 24, 32, 32, "images/operators@32px.png", pTitle, false, 6);
            bsplit = new Button(4, bkor.Top + 40, 32, 32, "images/task-split@32px.png", pTitle, false, 7);
            btrash = new Button(4, pTitle.Height - 44, 32, 32, "images/trash@32px.png", pTitle, false, 5);

            if (!Workflow.show_work_data_switch)
                bsplit.Visible = Visibility.Hidden;

            bnew.OnButtonClick += BTB_OnButtonClick;
            bmodify.OnButtonClick += BTB_OnButtonClick;
            bsave.OnButtonClick += BTB_OnButtonClick;
            bcancel.OnButtonClick += BTB_OnButtonClick;
            bkor.OnButtonClick += BTB_OnButtonClick;
            bsplit.OnButtonClick += BTB_OnButtonClick;
            btrash.OnButtonClick += BTB_OnButtonClick;

            var y = 32;
            var step = 32;
            var w = 200;
            var w2 = 200;
            var x = 44;

            var lt1 = l = new Label(Res.Get(9101, "Ordre sélectionné") + ":", 44, y, w, 16, "right", this); y += step;
            var ld1 = l = new Label(Res.Get(9102, "Date et heure de début") + ":", 44, y, w, 16, "right", this); y += step;
            var ld2 = l = new Label(Res.Get(9103, "Date et heure de fin") + ":", 44, y, w, 16, "right", this); y += step;

            var lt2 = l = new Label("Type de travail:", 44, y, w, 16, "right", this); y += step;
            var lt3 = l = new Label("Centre de frais:", 44, y, w, 16, "right", this); y += step;
            var lqty = l = new Label("Quantité:", 44, y, w + 200, 16, "right", this); y += step;
            var ldur = l = new Label(Res.Get(9104, "Durée") + ":", 44, y, w + 200, 16, "right", this); y += step;
            var lt4 = l = new Label("Motif de rebut:", 44, y, w, 16, "right", this); y += step;

            y = 32;
            x = 44 + w + 4;
            lOrdre = new Label("----", x, y, this.Width - x - 16, 16, "left", this); y += step;
            
            fDate1 = new Field(ld1, w2, Field.FieldFormat.DateComplete); y += step;
            fDate2 = new Field(ld2, w2, Field.FieldFormat.DateComplete); y += step;
            fHeure1 = new Field(fDate1, 60, Field.FieldFormat.H24);
            fHeure2 = new Field(fDate2, 60, Field.FieldFormat.H24);
            fHeure1.Top += 4; fHeure1.Left += 16;
            fHeure2.Top += 4; fHeure2.Left += 16;

            lTT = new Label("----", x, y, w2, 16, "left", this); y += step;
            lCF = new Label("----", x, y, w2, 16, "left", this); y += step;

            bC = new Button(fHeure1.Right + 16, fHeure1.Top + 8, 32, 32, "", this, true);
            bC.Text = "C";
            bC.OnButtonClick += BC_OnButtonClick;
            bC.Visible = Visibility.Hidden;

            fQtyA = new Field(lqty, 60, Field.FieldFormat.EntierMillieme); y += step;
            fQtyR = new Field(fQtyA, 60, Field.FieldFormat.EntierMillieme);
            fQtyR.Top += 4; fQtyR.Left += 16;
            fDuree = new Field(ldur, 60, Field.FieldFormat.HQty);
            //fDuree.Top += 4; fDuree.Left = fHeure1.Left;

            if (!Workflow.workdata_show_qty)
            {
                lqty.Visible = Visibility.Hidden;
                fQtyA.Visible = Visibility.Hidden;
            }

            fQtyR.Visible = Visibility.Hidden;
               
            lMRebut = new Label("----", x, y, w2, 16, "left", this); y += step;

            var lst = GetChildren();
            foreach(var c in lst)
            {
                if (c is Label) ((Label)c).FontSize = 9;
                else if (c is Field)
                {
                    var f = (Field)c;
                    f.Style.FontSize = "9pt";
                    f.Height = 20;
                    f.Top += 2;
                }
            }

            lTitle.FontSize = 11;

            lOrdre.Paragraph.OnMouseDown += ((e) => { Label_OnClick(0); });
            lTT.Paragraph.OnMouseDown += ((e) => { Label_OnClick(1); });
            lCF.Paragraph.OnMouseDown += ((e) => { Label_OnClick(2); });
            lMRebut.Paragraph.OnMouseDown += ((e) => { Label_OnClick(3); });

            lt2.Visible = Visibility.Hidden;
            lt3.Visible = Visibility.Hidden;
            lt4.Visible = Visibility.Hidden;
            lTT.Visible = Visibility.Hidden;
            lCF.Visible = Visibility.Hidden;
            lMRebut.Visible = Visibility.Hidden;


            pSplit = new pSplit(WkData, selectTable, this);
            pSplit.Hide();

            SetState(false);
        }

        private void BC_OnButtonClick(Button c, MouseEvent arg)
        {
            if (!bC.Selected)
            {
                bC.SelectMe(true);

                fDate1.Value = DateTime.Now.Date;
                fDate2.Value = DateTime.Now.Date;
                fHeure1.Value = 0;
                fHeure2.Value = 0;
                fQtyA.Value = 0;
                fQtyR.Value = 0;
                fDate1.Input.ReadOnly = true;
                fDate2.Input.ReadOnly = true;
                fHeure1.Input.ReadOnly = true;
                fHeure2.Input.ReadOnly = true;
                fQtyA.Input.ReadOnly = true;
                fQtyR.Input.ReadOnly = true;
            }
            else
            {
                bC.SelectMe(false);

                fDate1.Value = DateTime.Now.Date;
                fDate2.Value = DateTime.Now.Date;
                fHeure1.Value = 0;
                fHeure2.Value = 0;
                fQtyA.Value = 0;
                fQtyR.Value = 0;
                fDate1.Input.ReadOnly = false;
                fDate2.Input.ReadOnly = false;
                fHeure1.Input.ReadOnly = false;
                fHeure2.Input.ReadOnly = false;
                fQtyA.Input.ReadOnly = false;
                fQtyR.Input.ReadOnly = false;
            }
        }

        private void SelectTable_OnSelect(WkSelectTable grid, int type, int idx)
        {
            Label[] ls = { lOrdre, lTT, lCF, lMRebut };

            var l = ls[type];
            l.id1 = idx;

            Console.WriteLine("idx: " + idx);

            if(type == 0)
            {
                var ordre = Workflow.ordres[idx];
                l.Text = ordre.idcode + ", " + ordre.desc;
            }
            else if (type == 1)
            {

            }
            else if (type == 2)
            {

            }
            else if (type == 3)
            {

            }

        }

        private void BTB_OnButtonClick(Button c, MouseEvent arg)
        {

            switch(c.id1)
            {
                case 1:
                    selected = null;
                    SetState(true);
                    ShowRow(null);

                    fDate1.Value = wkData.CurDate.Date;
                    fDate2.Value = wkData.CurDate.Date;

                    if (Workflow.wkdata_complement)
                        bC.Visible = Visibility.Inherit;
                    bC.SelectMe(false);
                    break;
                case 2:
                    SetState(true);

                    break;
                
                case 3:
                    var ok = true;

                    if (pSplit.Visible) pSplit.Save();
                    else ok = Save();

                    if (ok)
                    {
                        korMode = false;
                        SetState(false);
                    }

                    bC.Visible = Visibility.Hidden;
                    bC.SelectMe(false);

                    break;
                
                case 4:

                    korMode = false;
                    ShowRow(selected);
                    SetState(false);
                    bC.Visible = Visibility.Hidden;
                    bC.SelectMe(false);
                    break;

                case 5:
                    Delete();
                    SetState(false);
                    break;
                
                case 6:
                    korMode = true;
                    SetState(true);

                    fDate1.Value = wkData.CurDate.Date;
                    fDate2.Value = wkData.CurDate.Date;
                    fHeure1.Value = 0;
                    fHeure2.Value = 0;

                    fDuree.Focus();
                    break;

                case 7:
                    pSplit.Show(selected);
                    SetState(true);
                    break;

            }

        }

        private void Label_OnClick(int idx)
        {
            if (bnew.Enabled) return;
            //OnAskToShowTable?.Invoke(this, idx);
            selectTable.Show(idx);
        }

        public void SetState(bool v)
        {
            bnew.Enabled = !v;
            bmodify.Enabled = !v;
            bsave.Enabled = v;
            bcancel.Enabled = v;
            btrash.Enabled = !v;
            bkor.Enabled = !v;
            bsplit.Enabled = !v;

            if (selected == null) bsplit.Enabled = false;
            if (!v) pSplit.Hide();


            if (selected == null || selected.status % 100 != 7)
            {
                fHeure1.Visible = Visibility.Inherit;
                fHeure2.Visible = Visibility.Inherit;
            }

            if(korMode)
            {
                fHeure1.Visible = Visibility.Hidden;
                fHeure2.Visible = Visibility.Hidden;
            }

            var lBC = Color.White;

            if (v) lBC = new Color(240);

            if(v)
            {
                lOrdre.Style.Border = "1px solid #ccc";
            }
            else
            {
                lOrdre.Style.Border = "0px none #ccc";
            }

            //lOrdre.BackgroundColor = lBC;
            lTT.BackgroundColor = lBC;
            lCF.BackgroundColor = lBC;
            lMRebut.BackgroundColor = lBC;

            fDate1.Input.ReadOnly = !v;
            fDate2.Input.ReadOnly = !v;
            fHeure1.Input.ReadOnly = !v;
            fHeure2.Input.ReadOnly = !v;
            fQtyA.Input.ReadOnly = !v;
            fQtyR.Input.ReadOnly = !v;
            fDuree.Input.ReadOnly = !v;

            if (!korMode)
            {
                if (selected == null)
                {
                    bmodify.Enabled = false;
                    btrash.Enabled = false;

                    fDuree.Input.ReadOnly = true;
                }
                else
                {
                    if (selected.status >= 100)
                    {
                        bmodify.Enabled = false;
                        btrash.Enabled = false;
                    }

                    var stats = selected.status % 100;
                    if (stats == 7 || stats == 9)
                    {
                        fDate1.Input.ReadOnly = true;
                        fDate2.Input.ReadOnly = true;
                        fHeure1.Input.ReadOnly = true;
                        fHeure2.Input.ReadOnly = true;
                    }
                    else
                    {
                        fDuree.Input.ReadOnly = true;
                    }
                }
            }
            else
            {
                fDate1.Input.ReadOnly = true;
                fDate2.Input.ReadOnly = true;
                fHeure1.Input.ReadOnly = true;
                fHeure2.Input.ReadOnly = true;
            }

            if (!v)
                selectTable.Hide();

            OnStateChange!=null?global::Bridge.Script.FromLambda(()=>OnStateChange.Invoke(this, v)):null;
        }

        public void ShowRow(GridWkData.pRow row)
        {
            selected = row;

            lOrdre.id1 = 0;
            lOrdre.Text = "----";
            fDate1.Value = DateTime.MinValue;
            fDate2.Value = DateTime.MinValue;
            fHeure1.Value = 0;
            fHeure2.Value = 0;
            lTT.id1 = 0;
            lTT.Text = "----";
            lCF.id1 = 0;
            lCF.Text = "----";
            fQtyA.Value = 0;
            fQtyR.Value = 0;
            fDuree.Value = 0;
            lMRebut.Text = "----";

            if (row == null) return;

            lOrdre.id1 = row.ordre;
            lOrdre.Text = row.ordreIDSaisie + ", " + row.ordreDesc;
            fDate1.Value = row.d1;
            fDate2.Value = row.d2;
            fHeure1.Value = row.d1.Hour * 60 + row.d1.Minute;
            fHeure2.Value = row.d2.Hour * 60 + row.d2.Minute;
            fDuree.Value = row.duree;
            fQtyA.Value = row.qtyA;
            fQtyR.Value = row.qtyB;
            
            if(row.status % 100 == 7)
            {
                fHeure1.Value = 0;
                fHeure2.Value = 0;
                fHeure1.Visible = Visibility.Hidden;
                fDate2.Visible = Visibility.Hidden;
                fDate2.LinkedControl.Visible = Visibility.Hidden;
                fHeure2.Visible = Visibility.Hidden;
                fDate2.Visible = Visibility.Hidden;
                fDate1.Value = row.dSave.Date;
            }
            else
            {
                fHeure1.Visible = Visibility.Inherit;
                fHeure2.Visible = Visibility.Inherit;
                fDate2.Visible = Visibility.Inherit;
                fDate2.LinkedControl.Visible = Visibility.Inherit;
                fDate2.Visible = Visibility.Inherit;
            }

            SetState(false);
        }

        private void Split()
        {

        }

        bool Save()
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.SaveDataEntry);

            var project = false;
            project = selected != null && selected.status % 100 == 7;

            var d1 = fDate1.ValueDate;
            var d2 = fDate2.ValueDate;

            var hh = fHeure1.ValueI / 60;
            var mm = fHeure1.ValueI % 60;
            if(project)
            {
                hh = 0;
                mm = 0;
            }
            d1 = new DateTime(d1.Year, d1.Month, d1.Day, hh, mm, 0);
            
            hh = fHeure2.ValueI / 60;
            mm = fHeure2.ValueI % 60;
            if (project)
            {
                d2 = d1;
                hh = 0;
                mm = 0;
            }
            d2 = new DateTime(d2.Year, d2.Month, d2.Day, hh, mm, 0);
            var dif = (d2 - d1).TotalHours;

            Console.WriteLine(d1 + "  " + d2);

            if(d2 < d1)
            {
                Window.Alert("Vérifiez les dates et heures saisies!");
                return false;
            }
            if(dif >= 24)
            {
                Window.Alert("Veuillez saisir une durée de moins de 24 heures.");
                return false;
            }

            if (korMode)
            {
                selected = null;
                func.iparam6 = 9;
            }

            func.iparam3 = 1;   // save
            if (selected == null)
            {
                func.iparam1 = wkData.CurPid;
                func.iparam2 = D2000.T(wkData.CurDate);
                func.iparam3 = 0;  // save new
                func.iparam7 = bC.Selected ? 1 : 0;
            }
            else
            {
                func.iparam1 = selected.pid;
                func.iparam2 = D2000.T(selected.dSave);
                func.iparam4 = selected.blockIdx;
                func.iparam5 = selected.idx;
            }



            func.array_int.Add(lOrdre.id1);
            func.array_int.Add(D2000.T(d1));
            func.array_int.Add(D2000.T(d2));
            func.array_int.Add(d1.Hour * 60 + d1.Minute);
            func.array_int.Add(d2.Hour * 60 + d2.Minute);
            func.array_int.Add(lTT.id1);
            func.array_int.Add(lCF.id1);
            func.array_int.Add(fQtyA.ValueI);
            func.array_int.Add(fQtyR.ValueI);
            func.array_int.Add(fDuree.ValueI);
            func.array_int.Add(lMRebut.id1);

            var dataSave = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataSave.OnSuccess += ((d, result) => { OnChange!=null?global::Bridge.Script.FromLambda(()=>OnChange.Invoke(this)):null; });

            return true;

        }

        void Delete()
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.SaveDataEntry);
            func.iparam1 = selected.pid;
            func.iparam2 = D2000.T(selected.dSave);
            func.iparam3 = 2;   // delete
            func.iparam4 = selected.blockIdx;
            func.iparam5 = selected.idx;

            var dataDelete = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataDelete.OnSuccess += ((d, result) => { OnChange!=null?global::Bridge.Script.FromLambda(()=>OnChange.Invoke(this)):null; });
        }

    }
}
