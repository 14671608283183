using System;
using System.Collections.Generic;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class pPunchWKSwap : Panel
    {
        Button bClose = null;
        MensualView view = null;

        Label lPunch = null;
        bool saved = false;
        bool tpoint = false;

        int absSel = -1;

        int[] bID = { 1, 2, 3, 4, 5, 6 };
        string[] bImg = { "images/01-factory.png", "images/06-newton-cradle.png", "images/05-desktop-work-area-coffee-keyboard.png", "images/04-desktop-work-area-coffee-imac.png", "images/03-night-time.png", "images/13-industry-engineer-female-african-american-2.png" };
        string[] bTxt = { "Démarrage de la journée", "Swap / Démarrage d'une activité", "Début de la pause", "Retour de pause", "Fin de journée", "Stop activité" };
        bool[] bOk = { false, false, false, false, false, false };
        Panel[] fPunch = new Panel[6];
        Button[] bPunch = new Button[6];

        pSwapOption pSwapConfirmation = null, pSwapOrdre = null;
        Panel pResume = null;

        public pPunchWKSwap(MensualView view)
        {
            this.view = view;

            this.Size = new Size(640, 800);

            var pTitle = new Panel(this);
            pTitle.Place(0, 0, this.Width, 32);
            pTitle.BackgroundColor = Color.Mobatime1;

            var lTitle = new Label("Pointage Swap", 0, 4, this.Width - 8, 16, "right", pTitle);
            lTitle.FontSize = 14;
            lTitle.Color = Color.White;

            bClose = new Button(4, 0, 32, 32, "images/63_porte_t.png", pTitle);
            bClose.OnButtonClick += BClose_OnButtonClick;

            lPunch = new Label("", 0, 0, Width, 16, "center", pTitle);
            lPunch.FontSize = 18;
            lPunch.Color = Color.White;

            var spaceX = (this.Width - 64) / 4;
            var x = 32;
            var y = 64;
            for (var i = 0; i < bOk.Length; i++)
            {
                var f = new Panel(x, y, spaceX - 8, 160, Color.Mobatime2, this);
                f.Visible = Visibility.Hidden;

                var b = bPunch[i] = new Button(0, f.Height - f.Width + 8, f.Width, f.Width, bImg[i], f, false, i);
                b.OnButtonClick += B_OnButtonClick;
                x += spaceX;

                var l = new Label(bTxt[i], 0, 8, f.Width, 16, "center", f);
                l.Color = Color.White;

                fPunch[i] = f;
            }

            bOk[0] = true;
            bOk[1] = true;
            bOk[2] = true;
            bOk[3] = true;

            pResume = new Panel(0, fPunch[0].Bottom + 32, Width, Height - 64 - fPunch[0].Bottom, Color.White, this);
            pResume.SetScrolling(Overflow.Hidden, Overflow.Scroll);
            

            pSwapOrdre = new PSwapOrdre(this);
            pSwapOrdre.Hide();
            pSwapConfirmation = new PSwapConfirmation(this);
            pSwapConfirmation.Hide();

            PlaceButton();
            
        }

        private void B_OnButtonClick(Button c, MouseEvent arg)
        {
            pSwapConfirmation.Hide();
            pSwapOrdre.Hide();

            var id = c.id1;
            if (c.id2 == 0)
            {
                c.id2 = 1;

                for (var i = 0; i < bOk.Length; i++)
                    bOk[i] = i == id;

                if (id == 0 || id == 1) pSwapOrdre.Show(id);
                else if (id == 2 || id == 3 || id == 4 || id == 5) pSwapConfirmation.Show(id - 2);
            }
            else
            {
                c.id2 = 0;
                CheckButtonPlace();
            }
            PlaceButton();
        }

        public void Open()
        {
            saved = false;
            view.OpenLock(this);
            UpdateLPunch();

            for (var i = 0; i < bOk.Length; i++)
                bOk[i] = false;
            PlaceButton();

            pResume.Place(0, fPunch[0].Bottom + 32, Width, Height - 64 - fPunch[0].Bottom);

            FillResume(null);
            CheckButtonPlace();
        }

        void UpdateLPunch()
        {
            if (!saved)
            {
                var n = DateTime.Now;

                if(tpoint)
                    lPunch.Text = n.Hour + ":" + n.Minute.ToString().PadLeft(2, '0');
                else
                    lPunch.Text = n.Hour + "&nbsp;" + n.Minute.ToString().PadLeft(2, '0');

                tpoint = !tpoint;


                Bridge.Html5.Window.SetTimeout((global::System.Action)UpdateLPunch, 500);
            }
        }

        void BClose_OnButtonClick(Button c, MouseEvent arg)
        {
            view.CloseLock();
            view.SetDate(99, true);
        }

        void CheckButtonPlace()
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.SwapGetResumeDay);
            func.iparam1 = Workflow.pid;
            func.iparam2 = D2000.T(DateTime.Now);

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, ret) =>
            {
                var v = ret.return_i1;
                for(var i = 0; i < bOk.Length; i++)
                {
                    var v2 = v & (int)Math.Pow(2, i);
                    bOk[i] = v2 > 0;
                }

                PlaceButton();

                FillResume(ret.return_s1, ret.return_s2);
            };
        }

        void PlaceButton()
        {
            if (pSwapConfirmation.Visible == Visibility.Inherit || pSwapOrdre.Visible == Visibility.Inherit)
            {
                pResume.Visible = Visibility.Hidden;
            }
            else
                pResume.Visible = Visibility.Inherit;

            var spaceX = (this.Width - 64) / 4;
            var x = 32;
            var y = 64;

            for (var i = 0; i < bOk.Length; i++)
            {
                fPunch[i].Visible = Visibility.Hidden;

                if (!bOk[i]) continue;

                fPunch[i].Visible = Visibility.Inherit;
                fPunch[i].Place(x, y, spaceX - 8, 160);

                x += spaceX;
            }
        }

        public void CloseOption(bool fullResume = false)
        {
            foreach (var b in bPunch)
                b.id2 = 0;

            CheckButtonPlace();
            PlaceButton();

            if(fullResume)
            {
                pResume.Place(32, 64, Width - 64, Height - 96);
            }

            pSwapConfirmation.Hide();
            pSwapOrdre.Hide();


        }

        void FillResume(string s = null, string s2 = null)
        {
            pResume.ClearChildren();

            if(s == null)
                return;

            var csv = new CSVTool(s)[0];
            var count = csv.I32(0);
            var idx = 1;

            var y = 0;
            var oldTime = -1;
            for (var i = 0; i < count; i++)
            {
                var key = csv.I32(idx);
                var ev = csv[idx + 1];
                var time = key / 10;
                var stime = Fmt.Ptg(time);

                if (oldTime == time) stime = "";
                else
                {
                    oldTime = time;
                    y += 16;
                }

                var l = new Label(stime, 16, y, 100, 32, "right", pResume);
                l.FontSize = 14;
                var l2 = new Label(ev, l.Right + 8, y + 4, pResume.Width - l.Right - 24, 32, "left", pResume);

                y += 32;
                idx += 2;
            }

            ((PSwapConfirmation)pSwapConfirmation).SetOrdre(s2);
        }

    }
}
