using System;
using System.Collections.Generic;
using System.Text;
using Bridge.Html5;
using Mobatime.JSControl;
using Newtonsoft.Json;
using Window = Mobatime.JSControl.Window;

namespace Workflow_bridge.MensualView
{
    public class MensualView : Window
    {
        public static MensualView self = null;

        public static bool todo_loaded = false;
        public static WindowInstance todo_win = null;
private bool IamChef
{
    get
    {
        return cboPers.Visible == Visibility.Visible;
    }
}        public int CurrentPers
        {
            get
            {
                if (IamChef) return cboPers.SelectedId;
                else return Workflow.pid;
            }
        }
        public int CurrentWF
        {
            get
            {
                if (IamChef && cboWF.SelectedId != -1) return Workflow.WFProfil[cboWF.SelectedId];
                else return Workflow.wfid_su;
            }
        }

        //public static bool Verrou = false;
        public static bool[] Verrou = new bool[40];

        private bool saveBlink = false;
        private int intervalBlink = 0;
        float angle = 0;
public static MensualGrid Grid
{
    get
    {
        return self._grid;
    }
}
        private readonly Panel _pParent = null;
        private readonly Panel _pBack = null;
        private readonly Panel _pFooter = null;
        private readonly MensualGrid _grid = null;
        private Button b0, b1, b2, b3, bDeleg, bPip, bAct, bCockpit, bPlan, bWkData, bOrdreCreation, bOrdreActivity, bOrdreValidation;
        private Button bPrint, bPunch, bJumbo, bFrais, bTodo, bVerrou;
        private Button bQR;
        private Button bValid;
        private readonly DateTime _date = new DateTime(2018, 9, 1);

        private Panel _pUnderDetail = null;
        private Panel _pBackMenu = null;
        private Panel _pMenu = null;
        private Panel _pBackDetail = null;
        private PanelSaisiePtg _pSaisiePtg = null;
        private PanelSaisieDebours _pSaisieDebours = null;
        private PanelSaisieAnomalie _pSaisieAno = null;
        private PanelSaisieMsg _pSaisieMsg = null;
        private PanelSaisieAbs _pSaisieAbs = null;
        private PanelSaisieMultiAbs _pSaisieMultiAbs = null;
        private PanelSaisieMultiValid _pSaisieMultiValid = null;
        private PanelSaisieMultiExc _pSaisieMultiExc = null;
        private PanelSaisieKor _pSaisieKor = null;
        private PanelSaisieSeco _pSaisieSeco = null;
        private Label[] _lMsg = new Label[7];

        private readonly Combo cboWF, cboPers, cboPersExt;
public Combo CboWF
{
    get
    {
        return cboWF;
    }
}
        private PanelPlan pPlan;
        private PanelPrint pPrint;
        private PanelOrdreCreation pOrdreCreation;
        private PanelOFCreation pOFCreation;
        private PanelActivity pOrdreActivity;
        private PanelValidationQ pOrdreValidation;
        private FraisView pFrais;
        private ActivityView pActivity;

        public static DateTime CurrentDate = DateTime.Now;
        private DateTime _selectedDate = CurrentDate;
        private bool _keepDate = false;

        public Label _lNom = null;
        public Label _lDate = null;
        public Button _bSave = null, _bCancel = null;
        private HtmlPage _page = null;
         
        private Right _right = new Right();

        private pPunch pPunch = null, pPunchBack = null;
        private pPunchWKSwap pPunchWKSwap = null;
        private PanelWkData pWkData = null, pWkDataBack = null;
        private pUpload pUpload = null;
        private pDeleg pDeleg = null;
        private Label lPunch = null;

        private int force_select_pers = -1;
        public DateTime force_select_date = DateTime.MinValue;

        public static void Select(int wfid, int pid, DateTime date)
        {
            self.force_select_pers = pid;
            self.force_select_date = date;
            self.cboWF.SelectById(wfid, true);
        }


        public MensualView(HtmlPage page) : base(page, 40)
        {
            self = this;
            _page = page;
            _pParent = Container;

            var mas = Tool.LSReadULong("MAS");
            bool wMas = mas != 0;

            wMas = wMas && Workflow.MAS;


            Console.WriteLine(string.Format("MAS State=> mas:{0} wMas:{1} Workflow.MAS:{2}",mas,wMas,Workflow.MAS));

            if (wMas)
            {
                b0 = Toolbar.AddHamburger(-1, 0, 0);
                ((Hamburger)b0).OnMenuClick += B0_OnMenuClick;
                ((Hamburger)b0).SetParam(Workflow.nom);
            }
            else b0 = Toolbar.AddButton(99);
            Toolbar.AddSpace(16);
            b1 = Toolbar.AddButton(1);
            b2 = Toolbar.AddButton(2);
            b3 = Toolbar.AddButton(3);
            
            Toolbar.AddSpace(128);
            bPunch = Toolbar.AddButton(60);
            bPrint = Toolbar.AddButton(50);
            bFrais = Toolbar.AddButton(80);
            //Toolbar.AddSpace(128);
            bJumbo = Toolbar.AddButton(70);
            Toolbar.AddSpace(32);
            bTodo = Toolbar.AddButton(70);

            bValid = Toolbar.AddButton(150);
            bVerrou = Toolbar.AddButton(160);
            bDeleg = Toolbar.AddButton(4);
            bPip = Toolbar.AddButton(200);

            bOrdreCreation = Toolbar.AddButton(600);
            bAct = Toolbar.AddButton(90);
            bOrdreValidation = Toolbar.AddButton(602);

            bCockpit = Toolbar.AddButton(100);
            bPlan = Toolbar.AddButton(110);
            bWkData = Toolbar.AddButton(130);
            //bWkAct = Toolbar.AddButton(300);
            bQR = Toolbar.AddButton(120);
            


            if (!wMas) b0.Div.Title = Res.Get(170, "Fermer");
            bPunch.Div.Title = Res.Get(171, "Pointer");
            bPrint.Div.Title = Res.Get(172, "Print");
            bFrais.Div.Title = Res.Get(173, "Frais");
            bJumbo.Div.Title = Res.Get(174, "Jumbo");
            bTodo.Div.Title = Res.Get(175, "Todo");
            bValid.Div.Title = Res.Get(176, "Valider");
            bVerrou.Div.Title = Res.Get(177, "Verrou");
            bDeleg.Div.Title = Res.Get(178, "Déléguer");
            bPip.Div.Title = Res.Get(179, "Pip");
            bAct.Div.Title = Res.Get(180, "Activity");
            bCockpit.Div.Title = Res.Get(181, "Cockpit");
            bWkData.Div.Title = Res.Get(182, "WorkData");
            //bPlan.Div.Title = Res.Get(181, "Cockpit");

            bPunch.Visible = Visibility.Hidden;
            bPrint.Visible = Visibility.Hidden;
            bAct.Visible = Visibility.Hidden;
            bJumbo.Visible = Visibility.Hidden;
            bWkData.Visible = Visibility.Hidden;
            bOrdreValidation.Visible = Visibility.Hidden;
            bOrdreCreation.Visible = Visibility.Hidden;


            if (!wMas) b0.SetImage("images/63_porte_t.png", 32, 32);
            b1.SetImage("images/arrow_left.png", 32, 32);
            b2.SetImage("images/now.png", 32, 32);
            b3.SetImage("images/arrow_right.png", 32, 32);
            bPrint.SetImage("images/printer-64.png", 32, 32);
            bPunch.SetImage("images/iconPunch.png", 32, 32);
            bFrais.SetImage("images/dollar.png", 32, 32);
            bJumbo.SetImage("images/jumbo.png", 32, 32);
            bTodo.SetImage("images/todo.png", 32, 32);
            bValid.SetImage("images/taskok.png", 32, 32);
            bVerrou.SetImage("images/padlock.png", 32, 32);
            bDeleg.SetImage("images/deleg.png", 32, 32);
            bDeleg.SetImage("images/deleg.png", 32, 32);
            bPip.SetImage("images/pip.png", 32, 32);
            bAct.SetImage("images/turbine-2@1x.png", 32, 32);
            bOrdreCreation.SetImage("images/manufacturing-process-1-gear-edit.png", 32, 32);
            bOrdreValidation.SetImage("images/manufacturing-process-1-gear-empty-ok-circle.png", 32, 32);
            bCockpit.SetImage("images/diagram@32px.png", 32, 32);
            bPlan.SetImage("images/planning@1x.png", 32, 32);
            //bWkAct.SetImage("images/turbine-2@1x.png", 32, 32);
            bQR.SetImage("images/label-qrcode@32px.png", 32, 32);
            bWkData.SetImage("images/tool-hammer-user@1x.png", 32, 32);


            b0.OnButtonClick += B1OnButtonClick;
            b1.OnButtonClick += B1OnButtonClick;
            b2.OnButtonClick += B1OnButtonClick;
            b3.OnButtonClick += B1OnButtonClick;
            bPrint.OnButtonClick += B1OnButtonClick;
            bPunch.OnButtonClick += BPunch_OnButtonClick;
            bFrais.OnButtonClick += B1OnButtonClick;
            bJumbo.OnButtonClick += BJumbo_OnButtonClick;
            bTodo.OnButtonClick += BTodo_OnButtonClick;
            bValid.OnButtonClick += B1OnButtonClick;
            bDeleg.OnButtonClick += BDeleg_OnButtonClick;
            bVerrou.OnButtonClick += B1OnButtonClick;
            bPip.OnButtonClick += B1OnButtonClick;
            bAct.OnButtonClick += B1OnButtonClick;
            bOrdreCreation.OnButtonClick += BOrdreCreation_OnButtonClick;
            bOrdreValidation.OnButtonClick += BOrdreValidation_OnButtonClick;
            bQR.OnButtonClick += BQR_OnButtonClick;
            bQR.Visible = Visibility.Hidden;
            bCockpit.OnButtonClick += B1OnButtonClick;
            bPlan.OnButtonClick += B1OnButtonClick;
            bWkData.OnButtonClick += BWkData_OnButtonClick;


            _pBack = new Panel();
            _pBack.Size = new Size(_pParent.Width, _pParent.Height);
            _pParent.AddChild(_pBack);

            _pParent.OnResize += OnResize;

            _lNom = new Label(Workflow.nom, _pParent.Width - 600, 6 - 32, 600, 16, "center", _pParent);
            _lNom.ZIndex = "100";
            _lNom.FontSize = 14;
            _lNom.Color = Color.White;


            cboWF = new Combo();
            cboWF.Place(_pParent.Width - 600, -36, 292, 32, _pParent);
            cboWF.Visible = Visibility.Hidden;
            cboWF.OnChange += CboWF_OnChange;
            cboWF.Style.FontSize = "12pt";

            cboPers = new Combo();
            cboPers.Place(cboWF.Right + 8, -36, 292, 32, _pParent);
            cboPers.Visible = Visibility.Hidden;
            cboPers.OnChange += CboPers_OnChange;
            cboPers.Style.FontSize = "12pt";

            cboPersExt = new Combo();
            cboPersExt.Place(cboWF.Right + 8, -36, 292, 32, _pParent);
            cboPersExt.Visible = Visibility.Hidden;
            //cboPersExt.OnChange += CboPers_OnChange;
            cboPersExt.OnChange += CboPersExt_OnChange;
            cboPersExt.Style.FontSize = "12pt";

            bQR.Left = cboWF.Left - 48;

            FillCBOWF(null);
            cboWF.SelectById(0);

            _pFooter = new Panel();
            _pFooter.BackgroundColor = ResT.GetColor("toolbar.back");// new Color(7, 66, 108);
            _pFooter.Position = new Point(0, _pBack.Height - 32);
            _pFooter.Size = new Size(_pBack.Width, 32);
            _pFooter.ZIndex = "99";
            _pBack.AddChild(_pFooter);


            _grid = new MensualGrid(this, _pBack);
            _grid.SetSize(_pBack.Width - 648, _pBack.Height - 32);
            _grid.OnSelectChange += GridOnOnSelectChange;

            CurrentDate = new DateTime(DateTime.Now.Year, DateTime.Now.Month, 1);
            LoadRight();
        }
        
        private void BOrdreActivity_OnButtonClick(Button c, MouseEvent arg)
        {
            OpenOrdreActivity();
        }

        private void BOrdreCreation_OnButtonClick(Button c, MouseEvent arg)
        {
            //OpenOrdreCreation();
            OpenOFCreation();
        }

        private void BOrdreValidation_OnButtonClick(Button c, MouseEvent arg)
        {
            OpenOrdreValidation();
        }

        private void CboPersExt_OnChange(Combo combo)
        {
            Tool.LSWrite("forcep", combo.SelectedId);
            Bridge.Html5.Window.Location.Reload(true);
        }

        void RightLoaded0()
        {
            //SetDate(0);

            for (var i = 0; i < _lMsg.Length; i++)
            {
                var l = _lMsg[i] = new Label("", i * 216, 6, 200, 16, "center", _pFooter);
                l.FontSize = 14;
                l.Color = Color.White;
            }

            LoadGridConfig(CurrentDate);
            LoadMessage();


            _pBackMenu = new Panel();
            _pBackMenu.Place(_pBack.Width - 600, 0, 600, 48, _pBack);
            _pBackMenu.BackgroundColor = ResT.GetColor("window.back2");// new Color(230);

            _pMenu = new Panel();
            _pMenu.Place(8, 8, 600 - 24, 36, _pBackMenu);
            _pMenu.BackgroundColor = ResT.GetColor("toolbar.back");// Color.Mobatime1;

            _pBackDetail = new Panel();
            _pBackDetail.BackgroundColor = ResT.GetColor("window.back");// new Color(240);
            _pBackDetail.Place(_pBack.Width - 600, 48, 600, _pBack.Height - 48 - 32 - 16, _pBack);
            _pBackDetail.SetScrolling(Overflow.Hidden, Overflow.Auto);

            _pUnderDetail = new Panel();
            _pUnderDetail.BackgroundColor = new Color(230);
            _pUnderDetail.Place(_pBackDetail.Left, _pBackDetail.Bottom, _pBackDetail.Width, 16, _pBack);

            _pSaisiePtg = new PanelSaisiePtg(_pBackDetail);
            _pSaisiePtg.SetY(0);
            _pSaisiePtg.OnChange += PSaisiePtgOnOnChange;
            _pSaisiePtg.OnOpenClose += _pSaisiePtg_OnOpenClose;

            _pSaisieMsg = new PanelSaisieMsg(_pBackDetail);
            _pSaisieMsg.SetY(_pSaisiePtg.Bottom + 8);
            _pSaisieMsg.OnChange += PSaisie2OnOnChange;
            _pSaisieMsg.OnOpenClose += _pSaisiePtg_OnOpenClose;

            _pSaisieAno = new PanelSaisieAnomalie(_pBackDetail);
            _pSaisieAno.SetY(_pSaisieMsg.Bottom + 8);
            _pSaisieAno.OnChange += PSaisie2OnOnChange;
            _pSaisieAno.OnOpenClose += _pSaisiePtg_OnOpenClose;

            _pSaisieAbs = new PanelSaisieAbs(_pBackDetail);
            _pSaisieAbs.SetY(_pSaisieAno.Bottom + 8);
            _pSaisieAbs.OnChange += _pSaisieAbs_OnChange;
            _pSaisieAbs.OnOpenClose += _pSaisiePtg_OnOpenClose;

            _pSaisieKor = new PanelSaisieKor(_pBackDetail);
            _pSaisieKor.SetY(_pSaisieAbs.Bottom + 8);
            _pSaisieKor.OnChange += _pSaisieKor_OnChange;
            _pSaisieKor.OnOpenClose += _pSaisiePtg_OnOpenClose;
            _pSaisieKor.Visible = false;

            _pSaisieSeco = new PanelSaisieSeco(_pBackDetail);
            _pSaisieSeco.SetY(_pSaisiePtg.Bottom + 8);
            _pSaisieSeco.OnChange += _pSaisieSeco_OnChange;
            _pSaisieSeco.OnOpenClose += _pSaisiePtg_OnOpenClose;
            //_pSaisieSeco.Visible = false;

            _pSaisieDebours = new PanelSaisieDebours(_pBackDetail);
            _pSaisieDebours.SetY(_pSaisieKor.Bottom + 8);
            _pSaisieDebours.OnChange += _pSaisieDebours_OnChange;
            _pSaisieDebours.OnOpenClose += _pSaisiePtg_OnOpenClose;
            _pSaisieDebours.Visible = true;

            _pSaisieMultiValid = new PanelSaisieMultiValid(_pBackDetail);
            _pSaisieMultiValid.SetY(0);
            _pSaisieMultiValid.Visible = false;
            _pSaisieMultiValid.OnNeedRefresh += _pSaisieMultiValid_OnNeedRefresh;
            _pSaisieMultiValid.Close();

            _pSaisieMultiAbs = new PanelSaisieMultiAbs(_pBackDetail);
            _pSaisieMultiAbs.SetY(_pSaisieMultiValid.Bottom + 32);
            _pSaisieMultiAbs.Visible = false;
            _pSaisieMultiAbs.OnNeedRefresh += _pSaisieMulti_OnNeedRefresh;
            _pSaisieMultiAbs.Open();

            _pSaisieMultiExc = new PanelSaisieMultiExc(_pBackDetail);
            _pSaisieMultiExc.SetY(_pSaisieMultiAbs.Bottom + 32);
            _pSaisieMultiExc.Visible = false;
            _pSaisieMultiExc.OnNeedRefresh += _pSaisieMultiExc_OnNeedRefresh;
            _pSaisieMultiExc.Close();

            CreateMenu();

            SetState(false);

            b0.Enabled = b1.Enabled = b2.Enabled = b3.Enabled = false;


            _pSaisiePtg!=null?global::Bridge.Script.FromLambda(()=>_pSaisiePtg.CheckLS()):null;
            _pSaisieMsg!=null?global::Bridge.Script.FromLambda(()=>_pSaisieMsg.CheckLS()):null;
            _pSaisieAbs!=null?global::Bridge.Script.FromLambda(()=>_pSaisieAbs.CheckLS()):null;
            _pSaisieAno!=null?global::Bridge.Script.FromLambda(()=>_pSaisieAno.CheckLS()):null;
            _pSaisieSeco!=null?global::Bridge.Script.FromLambda(()=>_pSaisieSeco.CheckLS()):null;
            _pSaisieMultiAbs!=null?global::Bridge.Script.FromLambda(()=>_pSaisieMultiAbs.CheckLS()):null;
            _pSaisieMultiValid!=null?global::Bridge.Script.FromLambda(()=>_pSaisieMultiValid.CheckLS()):null;
            _pSaisieKor!=null?global::Bridge.Script.FromLambda(()=>_pSaisieKor.CheckLS()):null;
            _pSaisieDebours!=null?global::Bridge.Script.FromLambda(()=>_pSaisieDebours.CheckLS()):null;


        }


        private void MensualView_OnRightRespLoaded()
        {
            
        }

        private void B0_OnMenuClick(int id)
        {
            if (id == 99)
            {
                Tool.LSWrite("MAS", 0);
                Bridge.Html5.Window.Location.Reload(true);
            }
            else if(id == 2)
            {
                Bridge.Html5.Window.Location.Replace(Workflow.BaseWebPath + "/plan/");
            }
        }

        private void BQR_OnButtonClick(Button c, MouseEvent arg)
        {

            var func = new Api.ApiFunction(Api.ApiFuncType.mkQR);
            func.iparam1 = Workflow.pid;
            func.iparam2 = Workflow.wfid_su;

            var dataQR = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataQR.OnSuccess += DataQR_OnSuccess;


        }

        private void DataQR_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            //var file = result.return_s1;
            //var id = result.return_i1;
            var tick = Environment.TickCount;

            Bridge.Html5.Window.Open(string.Format("qr.html?qr={0}&tick={1}",Workflow.pid,tick), "QRCode", "toolbar=no, directories=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=593, height=593");
        }

        private void BDeleg_OnButtonClick(Button c, MouseEvent arg)
        {
            if (pDeleg == null)
            {
                pDeleg = new pDeleg(this);
                pDeleg.ZIndex = "999";
                pDeleg.BackgroundColor = Color.White;
                pDeleg.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }

            pDeleg.Visible = Visibility.Visible;
            pDeleg.Open();
        }

        private void _pSaisieMultiValid_OnNeedRefresh(PanelSaisieMultiValid panelSaisieMulti)
        {
            SetDate(99, true);

            if (todo_win != null && !todo_win.Closed)
                BTodo_OnButtonClick(bTodo, null);
        }

        private void _pSaisieMultiExc_OnNeedRefresh(PanelSaisieMultiExc panelSaisieMulti)
        {
            SetDate(99, true);

            if (todo_win != null && !todo_win.Closed)
                BTodo_OnButtonClick(bTodo, null);
        }


        private void _pSaisiePtg_OnOpenClose(PanelSaisie p)
        {
            SaisieSetY();
        }

        void BPunch_OnButtonClick(Button c, MouseEvent arg)
        {
            if (Workflow.RightSU.PunchType == 1 || Workflow.RightSU.PunchType == 3)
            {
                if (pPunch == null)
                {
                    pPunch = new pPunch(this);
                    pPunch.ZIndex = "999";
                    pPunch.BackgroundColor = Color.White;
                    pPunch.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
                }

                pPunch.Visible = Visibility.Visible;
                pPunch.Open();
            }
            else if(Workflow.RightSU.PunchType == 2)
            {
                if (pPunchWKSwap == null)
                {
                    pPunchWKSwap = new pPunchWKSwap(this);
                    pPunchWKSwap.ZIndex = "999";
                    pPunchWKSwap.BackgroundColor = Color.White;
                    pPunchWKSwap.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
                }

                pPunchWKSwap.Visible = Visibility.Visible;
                pPunchWKSwap.Open();
            }
        }

        void BWkData_OnButtonClick(Button c, MouseEvent arg)
        {
            if (pWkData == null)
            {
                pWkData = new PanelWkData(this);
                pWkData.ZIndex = "999";
                pWkData.BackgroundColor = Color.White;
                pWkData.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }

            pWkData.Visible = Visibility.Visible;
            pWkData.Open(cboPers.Visible == Visibility.Visible);
        }

        public void OpenUpload()
        {
            if (pUpload == null)
            {
                pUpload = new pUpload(this);
                pUpload.ZIndex = "999";
                pUpload.BackgroundColor = Color.White;
                pUpload.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }

            pUpload.Visible = Visibility.Visible;
            pUpload.Open();
        }

        void BJumbo_OnButtonClick(Button c, MouseEvent arg)
        {
            var jumbo = new Jumbo.JumboView();
            jumbo.Show(cboPers.Visible == Visibility.Hidden);
        }

        void BTodo_OnButtonClick(Button c, MouseEvent arg)
        {
            Bridge.Html5.Window.Document.Cookie = "todo=0";
            Bridge.Html5.Window.LocalStorage.SetItem("tododblclick", "0");

            var wfcsv = "";
            foreach(var wf in cboWF.Items)
            {
int id;
                int.TryParse(wf.Value, out id);
                if (id == 0) continue;
                wfcsv += wf.Value + ";" + wf.Text + ";";
            }

            if (wfcsv.Length > 3) wfcsv = wfcsv.Substring(0, wfcsv.Length - 1);

            Tool.LSWrite("todowf", wfcsv);

            var todo = new TodoList.TodoWindow();
            todo.Show(false);
        }

        void CheckModif2()
        {
            //var s = Bridge.Html5.Window.LocalStorage.GetItem("tododblclick") as string;
            //Console.WriteLine("AA2: " + s.ToString());
        }

        public void DisableBPunch()
        {
            bPunch.Enabled = false;
        }
        public void EnableBPunch()
        {
            bPunch.Enabled = true;
        }

        void _pSaisieMulti_OnNeedRefresh(PanelSaisieMultiAbs panelSaisieMulti)
        {
            SetDate(99, true);
            
            if (todo_win != null && !todo_win.Closed)
                BTodo_OnButtonClick(bTodo, null);
        }

        private void B1OnButtonClick(Button c, MouseEvent arg)
        {
            var id = c.id1;

            switch (id)
            {
                case 99:
                    Tool.LSWrite("MAS", 0);
                    Bridge.Html5.Window.Location.Reload(true);
                    break;
                case 1:
                    SetDate(-1, false);
                    FillCBOPers(true);
                    break;
                case 2:
                    SetDate(0, false);
                    FillCBOPers(true);
                    break;
                case 3:
                    SetDate(1, false);
                    FillCBOPers(true);
                    break;

                case 50:
                    OpenPrinter();
                    break;

                case 80:
                    OpenFrais();
                    break;

                case 90:
                    Console.WriteLine("SaisieActiviteType: " + Workflow.RightSU.SaisieActiviteType + " " + Workflow.RightSU.ChefWorkData);
                    if (Workflow.RightSU.SaisieActiviteType == 9 || Workflow.RightSU.ChefActivityWork)// || Workflow.RightSU.ChefWorkData)
                    {

                        if (cboPers.Visible == Visibility.Visible)
                        {
                            var s = "";
                            foreach (var item in cboPers.Items)
                                s += item.Value + ";" + item.Text + ";";
                            if (s.Length > 2) s = s.Substring(0, s.Length - 1);
                            Tool.LSWrite("cbopers", s);
                        }
                        else
                        {
                            Tool.LSWrite("cbopers", "");
                        }

                        var wkact = new WorkActivity.WorkActivityView();
                        wkact.Show();
                    }
                    else
                        OpenActivity();
                    break;
                case 601:
                    OpenOrdreActivity();
                    return;
                    break;

                case 110:
                    OpenPlan();
                    break;

                case 130:
                    break;

                case 150:
                    {
                        var function = new Api.ApiFunction();
                        function.function = Api.ApiFuncType.WFValidSU;
                        function.iparam1 = cboPers.SelectedId;
                        function.iparam2 = 1;
                        function.iparam3 = cboWF.SelectedId;
                        function.iparam4 = Workflow.pid;

                        var csv = new CSVTool();

                        int nbday = DateTime.DaysInMonth(CurrentDate.Year, CurrentDate.Month);
                        csv.A(nbday);

                        var d1 = new DateTime(CurrentDate.Year, CurrentDate.Month, 1);
                        var d2 = d1.AddDays(nbday - 1);
                        for (var d = d1; d <= d2; d = d.AddDays(1))
                            csv.A(d);
                        csv.A(160);

                        function.param1 = csv.ToString();
                        var dataValid = new DataApi<Api.ApiReturn>(Workflow.api, function);
                        dataValid.OnSuccess += DataValid_OnSuccess;
                    }
                    break;

                case 160:
                    {
                        var r = Bridge.Html5.Window.Confirm(Res.Get(250, "Etes-vous certain ?"));

                        if (r)
                        {
                            var function = new Api.ApiFunction();
                            function.function = Api.ApiFuncType.WFValidSU;
                            function.iparam1 = cboPers.SelectedId;
                            function.iparam2 = 1;
                            function.iparam3 = cboWF.SelectedId;
                            function.iparam4 = Workflow.pid;
                            function.iparam5 = Workflow.wfid_re;

                            var csv = new CSVTool();

                            int nbday = DateTime.DaysInMonth(CurrentDate.Year, CurrentDate.Month);
                            csv.A(nbday);

                            var d1 = new DateTime(CurrentDate.Year, CurrentDate.Month, 1);
                            var d2 = d1.AddDays(nbday - 1);
                            for (var d = d1; d <= d2; d = d.AddDays(1))
                                csv.A(d);
                            csv.A(200);

                            function.param1 = csv.ToString();
                            var dataValid = new DataApi<Api.ApiReturn>(Workflow.api, function);
                            dataValid.OnSuccess += DataVerrou_OnSuccess;
                        }
                    }
                    break;

                case 100:
                    var cockpit = new Cockpit.CockpitView();
                    cockpit.Show();
                    break;


                case 200:
                    var pip = new Pip.PipView();
                    pip.Show();
                    break;

                //case 300:
                //    var wkact = new WorkActivity.WorkActivityView();
                //    wkact.Show();
                //    break;
            }

            if (id != 50 && id != 80 && id != 150 && id != 160 && id != 200 && id != 90 && id != 100 && id != 110 && id != 130 && id != -1)
                b0.Enabled = b1.Enabled = b2.Enabled = b3.Enabled = false;
        }

        private void DataValid_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            SetDate(99, true);
        }

        private void DataVerrou_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var s = "";

            if (result.return_b1) s += Res.Get(802, "Tous les pointages ne sont pas validés !\r\n");
            if (result.return_b2) s += Res.Get(800, "Toutes les absences ne sont pas validées !\r\n");
            if (result.return_b3) s += Res.Get(801, "Toutes les anomalies ne sont pas validées/corrigées !\r\n");
            if (result.return_b4) s += Res.Get(803, "Tous les frais n'ont pas été vus !\r\n");

            if (s.Length > 0)
            {
                Bridge.Html5.Window.Alert(s);
            }
            else
                SetDate(99, true);

        }

        private void PSaisie2OnOnChange(PanelSaisie p)
        {
            SetState(true);
        }

        private void PSaisiePtgOnOnChange(PanelSaisie p)
        {
            SetState(true);
        }

        void _pSaisieAbs_OnChange(PanelSaisie p)
        {
            SetState(true);
        }
        void _pSaisieKor_OnChange(PanelSaisie p)
        {
            SetState(true);
        }
        void _pSaisieSeco_OnChange(PanelSaisie p)
        {
            SetState(true);
        }
        private void _pSaisieDebours_OnChange(PanelSaisie p)
        {
            SetState(true);
        }



        private void CreateMenu()
        {
            _lDate = new Label("", 0, 8, _pMenu.Width - 8, 32, "right", _pMenu);
            _lDate.FontSize = 14;
            _lDate.Color = Color.White;

            _bSave = new Button();
            _bSave.Place(8, 2, 32, 32, _pMenu);
            _bSave.SetImage("images/63save_t.png", 32, 32);
            _bSave.OnButtonClick += BSave_Click;

            _bCancel = new Button();
            _bCancel.Place(_bSave.Right + 8, 2, 32, 32, _pMenu);
            _bCancel.SetImage("images/64annule_t.png", 32, 32);
            _bCancel.OnButtonClick += BCancel_Click;
        }

        private void BSave_Click(Button c, MouseEvent arg)
        {
            Console.WriteLine("save click");
            Save(1); 
        }

        private void BCancel_Click(Button c, MouseEvent arg)
        {
            GridOnOnSelectChange(_grid);

            SetState(false);
        }

        private void GridOnOnSelectChange(MensualGrid grid)
        {

            var count = grid.Selected.Count;

            var multiVerrou = false;
            var countVerrou = 0;
            for (var i = 0; i < Verrou.Length; i++)
                if (Verrou[i]) countVerrou++;

            if (countVerrou >= 28) multiVerrou = true;



            if (count == 1)
            {
                var p = grid.Selected[0];

                _pMenu.Visible = Visibility.Visible;
                _pSaisiePtg.Visible = Workflow.RightSU.ModifyPtg || Workflow.RightSU.ForceShowPtg;
                _pSaisieAbs.Visible = Workflow.RightSU.Absence;
                _pSaisieMsg.Visible = Workflow.RightSU.MessageUser || Workflow.RightSU.MessageChef;
                _pSaisieAno.Visible = true;
                _pSaisieSeco.Visible = Workflow.RightSU.ModifySeco;
                _pSaisieDebours.Visible = Workflow.RightSU.Debours || Workflow.RightSU.SaisieDebours;

                if (cboPers.Visible == Visibility.Visible)
                    _pSaisieKor.Visible = Workflow.RightSU.Kor;

                _pSaisieMultiAbs.Visible = false;
                _pSaisieMultiValid.Visible = false;
                _pSaisieMultiExc.Visible = false;

                _lDate.Text = Fmt.DateComplete(p.Date);

                var func = new Api.ApiFunction();
                func.function = Api.ApiFuncType.get_day_data;

                if (cboPers.Visible == Visibility.Visible) func.iparam1 = cboPers.SelectedId;
                else func.iparam1 = Workflow.pid;

                func.iparam2 = (int)(p.Date - new DateTime(2000, 1, 1)).TotalDays;
                func.iparam4 = Workflow.RightSU.DeboursWKType;
                if (Workflow.mode_prometerre) func.iparam4 += 100;

                var dataGetDataDay = new DataApi<Api.API_Data_Day>(Workflow.api, func);
                dataGetDataDay.OnSuccess += DataGetDayOnSuccess;

            }
            else if (count > 1 && !multiVerrou)
            {
                _pMenu.Visible = Visibility.Hidden;
                _pSaisiePtg.Visible = false;
                _pSaisieAbs.Visible = false;
                _pSaisieMsg.Visible = false;
                _pSaisieAno.Visible = false;
                _pSaisieKor.Visible = false;
                _pSaisieDebours.Visible = false;

                _pSaisieMultiValid.Visible = cboPers.Visible == Visibility.Visible;
                _pSaisieMultiExc.Visible = cboPers.Visible == Visibility.Visible;

                _pSaisieMultiAbs.Visible = true;

                int wfid = 0;
                if(cboPers.Visible == Visibility.Visible)
                {
                    wfid = cboWF.SelectedId;
                }
                int pid = Workflow.pid;
                if (cboPers.Visible == Visibility.Visible)
                    pid = cboPers.SelectedId;

                var seldays = GetSelectedDay();

                _pSaisieMultiAbs.SetDays(seldays, cboPers.Visible == Visibility.Visible, pid, wfid);

                if (cboPers.Visible == Visibility.Hidden)
                    _pSaisieMultiAbs.next_wfid = -1;
                else
                    _pSaisieMultiAbs.next_wfid = cboWF.SelectedId;

                if (!_pSaisieMultiAbs.Closed || _pSaisieMultiAbs.ForceUpdate)
                {
                    if (cboPers.Visible == Visibility.Hidden)
                        _pSaisieMultiAbs.Init();
                    else
                        _pSaisieMultiAbs.Init(cboWF.SelectedId);
                }
                //else
                if(IamChef)
                {
                    _pSaisieMultiValid.Init(cboWF.SelectedId, cboPers.SelectedId, seldays.Count);
                    _pSaisieMultiExc.Init(cboWF.SelectedId, cboPers.SelectedId, seldays.Count, Workflow.RightSU);
                }

                SaisieMultiSetY();

                _pSaisieMultiAbs.InitShow(CurrentDate.Year);
            }

            if(count > 0)
            {
                var p = grid.Selected[0].Date;
                var key = p.Year * 10000 + p.Month * 100 + p.Day;
                Tool.LSWrite("wfsel", key);
            }

        }

        private void DataGetDayOnSuccess(Data<Api.API_Data_Day> data, Api.API_Data_Day result)
        {

            if (result == null) return;

            var wfid = 0;

            if (cboWF != null && cboWF.Visible == Visibility.Visible)
                wfid = cboWF.SelectedId;
            
            Console.WriteLine("DataGetDayOnSuccess3 " + CurrentPers + "  " + CurrentWF + "  " + _grid.Selected[0].Date);

            if (_pSaisiePtg != null)
                Console.WriteLine("DataGetDayOnSuccess2 visible:" +  _pSaisiePtg.Visible);
            else
                Console.WriteLine("DataGetDayOnSuccess2 _pSaisiePtg == null");

            bool chef = cboPers.Visible == Visibility.Visible;
            var date = _grid.Selected[0].Date;
            
            if (_pSaisiePtg != null && _pSaisiePtg.Visible) _pSaisiePtg.Update(result, chef, Workflow.RightSU, date);
            if (_pSaisieAno != null && _pSaisieAno.Visible) _pSaisieAno.Update(result, date, chef, Workflow.RightSU);
            if (_pSaisieMsg != null && _pSaisieMsg.Visible) _pSaisieMsg.Update(result, chef, date);
            if (_pSaisieAbs != null && _pSaisieAbs.Visible) _pSaisieAbs.Update(result, chef, CurrentPers, wfid, date, Workflow.RightSU);
            if (_pSaisieKor != null && _pSaisieKor.Visible) _pSaisieKor.Update(result, Workflow.RightSU, date);
            if (_pSaisieSeco != null && _pSaisieSeco.Visible) _pSaisieSeco.Update(result, date, Workflow.RightSU);
            if (_pSaisieDebours != null && _pSaisieDebours.Visible) _pSaisieDebours.Update(result, date, Workflow.RightSU);

            SaisieSetY();
        }

        private void OnResize(Control c)
        {
            _pBack.Size = new Size(_pParent.Width, _pParent.Height);
            _pFooter.Position = new Point(0, _pBack.Height - 32);
            _pFooter.Width = _pBack.Width;
            _pBackMenu.Place(_pBack.Width - 600, 0, 600, 48);
            _grid.SetSize(_pBack.Width - 644, _pBack.Height - 32);

            _lNom.Left = _pParent.Width - 600;

            cboWF.Left = _pParent.Width - 600;
            cboPers.Left = cboWF.Right + 8;
            bQR.Left = cboWF.Left - 48;

            _pBackDetail.Place(_pBack.Width - 600, 48, 600, _pBack.Height - 48 - 32 - 16);
            _pUnderDetail.Place(_pBackDetail.Left, _pBackDetail.Bottom, _pBackDetail.Width, 16);
        }

        public void SetDate(int sens, bool update = false, bool keepDate = false)
        {
            var date = CurrentDate;
            _selectedDate = CurrentDate;

            if (keepDate)
            {
                var pday = _grid.Selected[0];
                _selectedDate = pday.Date;
            }

            _keepDate = keepDate;

            if(sens == 96)
            {
                var key = Tool.LSReadInt("wfsel", 0);
                if (key > 0)
                {
                    var year = key / 10000;
                    var month = (key % 10000) / 100;
                    var day = key % 100;
                    date = new DateTime(year, month, day);
                }
                else
                    date = DateTime.Now;

                Console.WriteLine("key: " + key);
            }
            else if (sens != 0 && sens != 99) date = date.AddMonths(sens);
            else if (sens == 99) date = _grid.Date;//  _grid.Selected[0].Date;
            else if (sens == 98) { }
            else date = DateTime.Now;


            if (sens == 96)
            {
                CurrentDate = date;
                force_select_date = CurrentDate;
            }
            else if (sens == 98)
                CurrentDate = _grid.Selected[0].Date;
            else if (sens == 97)
                CurrentDate = _grid.Selected[0].Date.AddDays(1);
            else
                CurrentDate = new DateTime(date.Year, date.Month, 1);


            _grid.NbDay = DateTime.DaysInMonth(CurrentDate.Year, CurrentDate.Month);

            
            if (update)
            {
                LoadGridConfig(CurrentDate);
                LoadMessage();
            }
            else
            {
                _grid.Reset(CurrentDate);
            }



        }

        public void SetVisibility(Visibility visibility)
        {
            _pBack.Visible = visibility;
        }

        private void LoadGridConfig(DateTime selDate)
        {
            var now = DateTime.Now.Date;
            if (force_select_date.Year > 1920)
            {
                CurrentDate = force_select_date;
                //force_select_date = DateTime.MinValue;
            }
            //else if (CurrentDate.Year == now.Year && CurrentDate.Month == now.Month)
            //    CurrentDate = now;
            //else
                CurrentDate = new DateTime(CurrentDate.Year, CurrentDate.Month, 1);


            _grid.Reset(CurrentDate);
            
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.get_grid_cfg;

            if (cboPers.Visible == Visibility.Hidden)
                func.iparam1 = Workflow.wfid_su;
            else
                func.iparam1 = Workflow.WFProfil[cboWF.SelectedId];

            func.token = Workflow.token;

            var data = new DataApi<Api.API_GridCfg>(Workflow.api, func);
            data.d1 = selDate;
            data.OnSuccess += AjaxGetGridCfg;
        }

        private void AjaxGetGridCfg(Data<Api.API_GridCfg> data, Api.API_GridCfg result)
        {

            int count = 0;

            foreach (var col in result.columns)
            {
                count++;
                if (col.id == 50) count++;
                if (col.id == 51) count++;
            }

            _grid.SetColumnCount(count);

            int x = 0;
            foreach (var col in result.columns)
            {
                if (col.id == 50 || col.id == 51)
                {
                    _grid.SetColumn(x, "", 0, 10, 0);
                    x++;
                }

                _grid.SetColumn(x, col.title, col.txtid, col.size, col.fontSize);

                x++;
            }

            _grid.Apply();

            LoadGridData(data.d1);
        }

        private void LoadGridData(DateTime selDate)
        {
            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.get_grid_data;
            func.iparam1 = CurrentPers;
            func.iparam2 = CurrentWF;
            if (cboWF.Visible != Visibility.Hidden)
                func.iparam5 = cboWF.SelectedId;
            else
                func.iparam5 = -2;
            func.iparam6 = Workflow.pid;
            func.iparam7 = Res.CurrentLg;
            func.token = Workflow.token;

            func.iparam3 = (int)(CurrentDate - new DateTime(2000, 1, 1)).TotalDays;
            func.iparam4 = _grid.NbDay;

            var data = new DataApi<Api.API_GridData>(Workflow.api, func);
            data.d1 = selDate;
            data.OnSuccess += DataOnOnSuccess;
        }

        private void DataOnOnSuccess(Data<Api.API_GridData> data, Api.API_GridData result)
        {
            //Verrou = false;
            for (var i = 0; i < Verrou.Length; i++)
                Verrou[i] = false;

            bool chef = cboPers.Visible == Visibility.Visible;

            int y = 0;

            //Console.WriteLine("Days count :" + result.days.Count);

            //foreach(var day in result.days)
            //{
            //    Console.WriteLine("dcount: " + day.datas.Count);
            //}

            foreach (var day in result.days)
            {


                int x = 0;

                bool anyMsg = (chef && day.anyMsgSU) || (!chef && day.anyMsgChef);

                if (day.ctrId <= 0)
                {
                    for (x = 0; x < _grid.DataCount; x++)
                    {

                        var c = _grid[x, y];
                        c.AddBackColor(new Color(160));
                    }
                }
                else
                {

                    foreach (var d in day.datas)
                    {
                        if (d.StartsWith("[reel]"))
                        {
                            var v = d.Substring(6);
                            var split = v.Split('|');
int start;
                            int.TryParse(split[0], out start);
int end;
                            int.TryParse(split[1], out end);

                            for (var i = start; i <= end; i++)
                            {
                                _grid[i, y].InvColor = false;
                                if (!Workflow.Dark) _grid[i, y].AddBackColor(new Color(255, 220, 160));
                                else _grid[i, y].AddBackColor(new Color(255, 160, 100));
                            }
                            continue;
                        }


                        var c = _grid[x, y];
                        var sflag = "";
                        var said = "";
                        var sintropid = "";
                        var nd = d;

                        if (d.StartsWith("[absflag]"))
                        {
                            sflag = d.Substring(9, 3);
                            said = d.Substring(12, 3);
                            sintropid = d.Substring(15, 5);
int flag;
                            int.TryParse(sflag, out flag);
int aid;
                            int.TryParse(said, out aid);
int intropid;
                            int.TryParse(sintropid, out intropid);

                            if (cboPers.Visible == Visibility.Hidden)
                            {
                                if (flag == 1) c.AddBackColor(new Color(0, 0, 255));
                                else if (flag == 3) c.AddBackColor(new Color(255, 128, 0));
                                else if (flag == 7)
                                {
                                    //if (intropid == 0 || intropid != Workflow.pid)
                                    c.AddBackColor(new Color(0, 255, 0));
                                }
                                else if (flag == 15) c.AddBackColor(new Color(255, 0, 0));
                                else if (flag == 55) c.AddBackColor(new Color(0, 255, 0));
                                else if (flag == 247) c.AddBackColor(new Color(0, 255, 0));
                                else if (flag > 15) c.AddBackColor(new Color(255, 255, 0));
                            }
                            else
                            {
                                // si j'ai le droit de valider
                                if (aid <= 0 || aid >= 70)
                                {

                                }
                                else if (flag == 15 && Workflow.wf_absrefused_alwaysred)
                                {
                                    c.AddBackColor(new Color(255, 0, 0));
                                }
                                else if (Workflow.RightSU.ChefAbsRight[aid - 1] == 1 || Workflow.RightSU.ChefAbsRight[aid - 1] == 3)
                                {
                                    if (flag == 3) c.AddBackColor(new Color(255, 128, 0));
                                    else if (flag == 7) c.AddBackColor(new Color(0, 255, 0));
                                    else if (flag == 15) c.AddBackColor(new Color(255, 0, 0));
                                    else if (flag == 55) c.AddBackColor(new Color(0, 255, 0));
                                    else if (flag == 247) c.AddBackColor(new Color(0, 255, 0));
                                    else if (flag > 15) c.AddBackColor(new Color(255, 255, 0));
                                }
                                else if (Workflow.RightSU.ChefAbsRight[aid - 1] == 4)
                                {
                                    if (flag == 23) c.AddBackColor(new Color(255, 128, 0));
                                    else if (flag == 55) c.AddBackColor(new Color(0, 255, 0));
                                    else if (flag == 119) c.AddBackColor(new Color(255, 255, 0));
                                }
                                else if (Workflow.RightSU.ChefAbsRight[aid - 1] == 8)
                                {
                                    if (flag == 119) c.AddBackColor(new Color(255, 128, 0));
                                    else if (flag == 247) c.AddBackColor(new Color(0, 255, 0));
                                }
                            }

                            nd = "";
                        }

                        if (d == "r")
                        {
                            _grid[x, y].AddBackColor(new Color(255, 0, 0));
                            nd = "";
                        }
                        else if (d == "o")
                        {
                            _grid[x, y].AddBackColor(new Color(255, 127, 0));
                            nd = "";
                        }
                        else if (d == "g")
                        {
                            _grid[x, y].AddBackColor(new Color(50, 200, 50));
                            nd = "";
                        }
                        else if (d == "b")
                        {
                            _grid[x, y].AddBackColor(new Color(50, 50, 200));
                            nd = "";
                        }

                        if (d == ".") nd = "";
                        _grid[x, y].Text = nd;
                        x++;
                    }
                }



                if (day.ctrId <= 0)
                {
                    _grid.SetHorsContrat(y);

                }
                if (day.verrou_chef || day.verrou_salaire)
                {
                    _grid.SetVerrou(y, day.verrou_chef | day.verrou_salaire);
                    Verrou[y + 1] = true;           // +1 car les jours commencent à 1
                }

                if (anyMsg)
                {
                    _grid.SetMessage(y);
                }



                y++;


            }

            //if (_keepDate)
            //{
            //    _grid.SelectDate(_selectedDate);
            //}

            _keepDate = false;

            b0.Enabled = b1.Enabled = b2.Enabled = b3.Enabled = true;

            ////Console.WriteLine(force_select_date.ToString());
            if (force_select_date.Year > 1920)
            {
                Console.WriteLine("force_select_date: " + force_select_date);
                _grid.SelectDate(force_select_date, false);
                force_select_date = DateTime.MinValue;
            }
            else
            {
                var n = DateTime.Now;
                var ds = data.d1;
                if (data.d1.Month == n.Month && data.d1.Year == n.Year) ds = n.Date;

                Console.WriteLine("first date: " + ds);

                _grid.SelectDate(ds, false);
            }


            SetButtonVerrou();

            if (Workflow.hide_outdated_pers)
                Grid.ShowHide(result.show);
            else Grid.ShowHide(true);

            if (bOrdreValidation.Visible != Visibility.Hidden)
                CheckQtyValidation();

            if (bAct.id1 == 601 && bAct.Visible != Visibility.Hidden)
                CheckQtyDifference();
        }

        public void SetState(bool v)
        {
            _bSave.Enabled = v;
            _bCancel.Enabled = v;
            _grid.Enabled = !v;

            if(v)
            {
                _pMenu.BackgroundColor = new Color(200, 25, 25);
            }
            else
            {
                _pMenu.BackgroundColor = ResT.GetColor("toolbar.back");// Color.Mobatime1;
            }

            //if(_bSave.Enabled)
            //{
            //    if (intervalBlink == 0)
            //        intervalBlink = Bridge.Html5.Window.SetInterval(blinkSave, 40);
            //}
            //else
            //{
            //    if (intervalBlink != 0)
            //    {
            //        Bridge.Html5.Window.ClearInterval(intervalBlink);
            //        intervalBlink = 0;
            //    }
            //        _bSave.Enabled = v;
            //}

            if (!v)
            {
                _pSaisiePtg.Modified = false;
                _pSaisieAbs.Modified = false;
                _pSaisieAno.Modified = false;
                _pSaisieMsg.Modified = false;
                _pSaisieKor.Modified = false;
                _pSaisieSeco.Modified = false;
            }
        }

        void blinkSave()
        {
            //angle += 0.04f * 5;
            //var v = Math.Cos(angle) + 1;
            //v /= 4.0;

            //_bSave.SetOpacity(v + 0.5);

        }

        public void Save(int nextday = 0)
        {
            var p = _grid.Selected[0];
            var date = p.Date;

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.save_day_data;
            func.iparam1 = CurrentPers;
            func.iparam2 = (int)(date - new DateTime(2000, 1, 1)).TotalDays;
            func.iparam3 = Workflow.wfid_re;
            func.iparam4 = Workflow.wfid_su;
            func.iparam5 = Workflow.pid;
            func.token = Workflow.token;

            var dat = new Api.API_Data_Day();

            dat.flags.Clear();
            for (var i = 0; i < 8; i++)
                dat.flags.Add(0);

            if (_pSaisiePtg.Modified && !_pSaisieMsg.Modified && Workflow.RightSU.MessageMandatory)
            {
                if (_pSaisieMsg.TxtSu.Length == 0)
                {
                    Bridge.Html5.Window.Alert(Res.Get(320, "Justifiez votre correction de pointage par un message, svp!"));
                    _pSaisieMsg.Focus();
                    return;
                }
            }

            if(_pSaisieAbs.Modified && !_pSaisieAbs.CheckIfMsg())
            {
                if (_pSaisieMsg.TxtSu.Length == 0)
                {
                    Bridge.Html5.Window.Alert(Res.Get(321, "Justifiez votre/vos absence(s) par un message, svp!"));
                    _pSaisieMsg.Focus();
                    return;
                }
            }

            // ptg
            if (_pSaisiePtg.Modified)
            {
                dat.modify_ptg = true;
                _pSaisiePtg.Save(dat);
            }
            // message
            if (_pSaisieMsg.Modified)
            {
                dat.modify_msg = true;
                _pSaisieMsg.Save(dat);
            }
            // ano
            if(_pSaisieAno.Modified)
            {
                dat.modify_ano = true;
                _pSaisieAno.Save(dat);
            }
            // abs
            if (_pSaisieAbs.Modified)
            {
                for (var i = 0; i < 2; i++)
                {
                    dat.jr_AC.Add(0);
                    dat.jr_AT.Add(0);
                    dat.jr_AD.Add(0);
                    dat.jr_A_DE.Add(0);
                    dat.jr_A_A.Add(0);
                    dat.jr_A_PID.Add(0);
                    dat.jr_A_Compl.Add(0);
                }

                if(dat.flags.Count < 3)
                {
                    for(var i = dat.flags.Count; i < 8; i++)
                    {
                        dat.flags.Add(0);
                    }
                }

                dat.modify_abs = true;
                _pSaisieAbs.Save(dat);
                
            }
            // kor
            if (_pSaisieKor.Modified)
            {
                _pSaisieKor.Save(dat);
                dat.modify_kor = true;
            }

            if(_pSaisieSeco.Modified)
            {
                _pSaisieSeco.Save(dat);
                dat.modify_seco = true;
            }

            var s = JsonConvert.SerializeObject(dat);
            var b = Encoding.UTF8.GetBytes(s);

            func.array_int.Clear();
            for (var i = 0; i < b.Length; i++)
                func.array_int.Add(b[i]);

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.id1 = nextday;
            data.OnSuccess += ((rdata, result) =>
            {
                SetState(false);

                Console.WriteLine("data.id1 = " + data.id1);

                if (data.id1 == 1)
                {
                    SetDate(96, true, true);
                }
                else
                    SetDate(98, true, true);

                RefreshTodo();

            });

        }

        private void LoadMessage()
        {
            int pid = Workflow.pid;

            if(cboPers.Visible != Visibility.Hidden)
            {
                pid = cboPers.SelectedId;
            }

            Console.WriteLine("msg pid: " + pid);

            var func = new Api.ApiFunction();
            func.iparam1 = pid;
            func.token = Workflow.token;
            func.function = Api.ApiFuncType.get_messages;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += LoadMessage_OnOnSuccess;
        }

        private void LoadMessage_OnOnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            var m1 = result.return_s1;
            var m2 = result.return_s2;
            var m3 = result.return_s3;
            var m4 = result.return_s4;
            var m5 = result.return_s5;
            var m6 = result.return_s6;
            var m7 = result.return_s7;

            //Console.WriteLine(m1 + " " + m2 + " " + m3 + " " + m4);

            _lMsg[0].Text = m1;
            _lMsg[1].Text = m2;
            _lMsg[2].Text = m3;
            _lMsg[3].Text = m4;
            _lMsg[4].Text = m5;
            _lMsg[5].Text = m6;
            _lMsg[6].Text = m7;


            for(var i = 0; i < 7; i++)
            {
                var addspace = -1;
                var s = _lMsg[i].Text;

                for(var j = 0; j < s.Length; j++)
                {
                    if(s[j] == ':' && j < 9)
                    {
                        addspace = j;
                        break;
                    }
                }

                if(addspace > -1)
                {
                    s = s.Insert(addspace + 1, "&nbsp;");
                    _lMsg[i].Text = s;
                }

            }

        }

        public List<pDay> GetSelectedDay()
        {
            return _grid.Selected;
        }

        private void OpenPrinter()
        {

            if (pPrint == null)
            {
                pPrint = new PanelPrint(this);
                pPrint.ZIndex = "999";
                //pPrint.BackgroundColor = Color.White;
                //pPrint.Place(0, 0, 1280, 850);
                pPrint.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }

            Dictionary<int, string> dicoPers = null;

            if(cboPers.Visible == Visibility.Visible)
            {
                dicoPers = new Dictionary<int, string>();

                foreach(var opt in cboPers.Items)
                {
int pid;
                    int.TryParse(opt.Value, out pid);
                    dicoPers.Add(pid, opt.Text);
                }
            }

            int wfid = 0;
            if (cboWF.Visible == Visibility.Visible) wfid = Workflow.wfid_re;
            pPrint.Update(cboPers.Visible == Visibility.Visible, dicoPers, wfid);
            pPrint.Visible = Visibility.Visible;

            OpenLock(pPrint);


        }

        private void OpenOrdreCreation()
        {

            if (pOrdreCreation == null)
            {
                pOrdreCreation = new PanelOrdreCreation(this);
                pOrdreCreation.ZIndex = "999";
                //pPrint.BackgroundColor = Color.White;
                //pPrint.Place(0, 0, 1280, 850);
                pOrdreCreation.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }


            pOrdreCreation.Reset();
            OpenLock(pOrdreCreation);
        }
        private void OpenOFCreation()
        {

            if (pOFCreation == null)
            {
                pOFCreation = new PanelOFCreation(this);
                pOFCreation.ZIndex = "999";
                pOFCreation.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }


            pOFCreation.Reset();
            OpenLock(pOFCreation);
        }

        private void OpenOrdreActivity()
        {

            if (pOrdreActivity == null)
            {
                pOrdreActivity = new PanelActivity(this);
                pOrdreActivity.ZIndex = "999";
                pOrdreActivity.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }


            OpenLock(pOrdreActivity);
            pOrdreActivity.Show();
        }

        private void OpenOrdreValidation()
        {
            if (pOrdreValidation == null)
            {
                pOrdreValidation = new PanelValidationQ(this);
                pOrdreValidation.ZIndex = "999";
                pOrdreValidation.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }


            OpenLock(pOrdreValidation);
            pOrdreValidation.Show();
        }

        private void OpenPlan()
        {
            if (pPlan == null)
            {
                pPlan = new PanelPlan(this);
                pPlan.ZIndex = "999";
                pPlan.BackgroundColor = Color.White;
                pPlan.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }

            var dte = DateTime.Now.Date;
            var did = (int)dte.DayOfWeek;
            if (did == 0) did = 7;
            did--;


            dte = dte.AddDays(-did);

            pPlan.Update(dte, Workflow.pid);
            pPlan.Visible = Visibility.Visible;

            OpenLock(pPlan);
        }

        private void OpenFrais()
        {

            if (pFrais == null)
            {
                var kmtime = false;
                kmtime = Workflow.RightSU.KMTimeControl;

                pFrais = new FraisView(this, kmtime);
                pFrais.ZIndex = "999";
                //pFrais.BackgroundColor = Color.White;
                //pFrais.Place(0, 0, 1280, 768);
                pFrais.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }

            var pid = Workflow.pid;
            var name = Workflow.nom;
            if (cboPers.Visible == Visibility.Visible)
            {
                pid = cboPers.SelectedId;
                name = cboPers.Selected.Text;
            }

            pFrais.Update(CurrentDate, pid, name);
            pFrais.Visible = Visibility.Visible;

            OpenLock(pFrais);


        }

        private void OpenActivity()
        {

            if (pActivity == null)
            {
                List<Tuple<int, string>> lst1 = null;
                List<Tuple<int, string>> lst2 = null;

                if (cboWF.Visible != Visibility.Hidden)
                {
                    lst1 = new List<Tuple<int, string>>();
                    foreach(var opt in cboWF.Items)
                    {
int id;
                        int.TryParse(opt.Value, out id);
                        lst1.Add(new Tuple<int, string>(id, opt.Text));
                    }
                }

                if (cboPers.Visible != Visibility.Hidden)
                {
                    lst2 = new List<Tuple<int, string>>();
                    foreach (var opt in cboPers.Items)
                    {
int id;
                        int.TryParse(opt.Value, out id);
                        lst2.Add(new Tuple<int, string>(id, opt.Text));
                    }
                }

                var wfid = -1;
                var persid = -1;

                if (cboWF.Visible != Visibility.Hidden) wfid = cboWF.SelectedId;
                if (cboPers.Visible != Visibility.Hidden) persid = cboPers.SelectedId;

                pActivity = new ActivityView(this, lst1, lst2, wfid, persid, Workflow.RightSU);
                pActivity.ZIndex = "999";

                pActivity.SetBorderStyle(1, "solid", Color.Mobatime1, 0);
            }

            pActivity.SetRight();
            //pFrais.Update(CurrentDate, cboPers.SelectedId, cboPers.Selected.Text);
            pActivity.Visible = Visibility.Visible;

            OpenLock(pActivity);


        }

        private void SaisieSetY()
        {
            var y = 16;

            _pSaisiePtg.SetY(y);

            if (_pSaisiePtg.Visible) y = _pSaisiePtg.Bottom + 8;

            _pSaisieSeco.SetY(y);

            if (_pSaisieSeco.Visible) y = _pSaisieSeco.Bottom + 8;

            _pSaisieMsg.SetY(y);

            if (_pSaisieMsg.Visible) y = _pSaisieMsg.Bottom + 8;

            _pSaisieAno.SetY(y);

            if (_pSaisieAno.Visible) y = _pSaisieAno.Bottom + 8;

            _pSaisieAbs.SetY(y);

            if (_pSaisieAbs.Visible) y = _pSaisieAbs.Bottom + 8;

            _pSaisieKor.SetY(y);

            if (_pSaisieKor.Visible) y = _pSaisieKor.Bottom + 8;

            _pSaisieDebours.SetY(y);

            if (_pSaisieDebours.Visible) y = _pSaisieDebours.Bottom + 8;
        }

        public static void SaisieMultiSetY()
        {
            var y = 16;

            PanelSaisieMultiValid.Self.SetY(y);
            if (PanelSaisieMultiValid.Self.Visible) y = PanelSaisieMultiValid.Self.Bottom + 16;

            PanelSaisieMultiAbs.Self.SetY(y);
            if (PanelSaisieMultiAbs.Self.Visible) y = PanelSaisieMultiAbs.Self.Bottom + 16;

            PanelSaisieMultiExc.Self.SetY(y);

        }

        void SetButtonVerrou()
        {
            var multiVerrou = false;
            var countVerrou = 0;
            for (var i = 1; i < Verrou.Length; i++)
                if (Verrou[i])
                    countVerrou++;

            if (countVerrou >= 28) multiVerrou = true;

            if(multiVerrou)
            {
                bVerrou.Visible = Visibility.Hidden;
                bValid.Visible = Visibility.Hidden;
            }
            else
            {
                if (Workflow.perm_bValidMonth)
                {
                    bValid.Visible = cboPers.Visible;
                    if (!Workflow.RightSU.GlobalValidation) bValid.Visible = Visibility.Hidden;
                }
                else
                    bValid.Visible = Visibility.Hidden;

                //bVerrou.Visible = cboPers.Visible;
                if(cboPers.Visible == Visibility.Visible)
                {
                    if (Workflow.RightSU.Verrou) bVerrou.Visible = Visibility.Visible;
                    else bVerrou.Visible = Visibility.Hidden;
                }
                else
                {
                    bVerrou.Visible = Visibility.Hidden;
                }

                
            }

            if (Grid.Date.Date > DateTime.Now.Date)
                bVerrou.Visible = Visibility.Hidden;

            SaisieSetY();
        }

        public void RefreshTodo()
        {
            if (todo_win != null && !todo_win.Closed)
                BTodo_OnButtonClick(bTodo, null);
        }

        #region Right CBO WF + PERS


        void CboWF_OnChange(Combo combo)
        {

            if (combo.SelectedId == 0)
            {
                cboPers.Visible = Visibility.Hidden;
                SetDate(99, true);

                // enlever le 1.10.2020... à voir si nécessaire
                //_pSaisieMultiAbs.Open();
                //_pSaisieMultiValid.Visible = false;
                SaisieMultiSetY();
            }
            else
            {
                cboPers.Visible = Visibility.Visible;

                // enlever le 1.10.2020... à voir si nécessaire
                //_pSaisieMultiAbs.Close();
                //_pSaisieMultiValid.Visible = true;
                //_pSaisieMultiValid.Open();
                SaisieMultiSetY();
            }

            LoadRight(1);
        }

        void CboPers_OnChange(Combo combo)
        {
            SetDate(99, true);
        }

        private void LoadRight(int from = 0)
        {
            var wfid = -1;
            if (cboPers.Visible != Visibility.Hidden)
            {
                wfid = cboWF.SelectedId;
            }

            // chargement cat abs selon droits ici pour éviter certains problèmes

            var funcCA = new Api.ApiFunction(Api.ApiFuncType.get_absences_su);
            if (wfid == -1)
            {
                funcCA.iparam1 = Workflow.wfid_su;
                funcCA.iparam3 = Workflow.pid;
            }
            else
            {
                funcCA.iparam1 = Workflow.WFProfil[wfid];
                funcCA.iparam2 = 1;
                funcCA.iparam3 = Workflow.pid;
            }

            var dataCA = new DataApi<Api.API_AbsSU>(Workflow.api, funcCA);
            dataCA.id1 = wfid;
            dataCA.b1 = funcCA.iparam2 == 1;
            dataCA.OnSuccess += ((rdata, rresult) => 
            {

                var func = new Api.ApiFunction();
                func.function = Api.ApiFuncType.get_workflow_right;
                func.token = Workflow.token;
                func.iparam1 = Workflow.pid;
                func.iparam2 = CurrentWF;
                func.iparam3 = IamChef ? 0 : 1;
                func.iparam4 = D2000.T(CurrentDate);
                func.iparam5 = cboWF.SelectedId;

                Workflow.wfid_re = CurrentWF;

                var data = new DataApi<Right>(Workflow.api, func);
                data.OnSuccess += LoadRightOnSuccess;
                data.id1 = from;
                data.o5 = rresult;
                data.id5 = dataCA.id1;
                data.b5 = dataCA.b1;
            });

            // *******************************************************************


        }

        private void LoadRightOnSuccess(Data<Right> data, Right result)
        {
            Workflow.RightSU = result;

            if(data.id1 == 0)
                RightLoaded0();

            if (result.Punch) bPunch.Visible = Visibility.Visible;
            else bPunch.Visible = Visibility.Hidden;

            if (result.VueTeam) bJumbo.Visible = Visibility.Inherit;
            else bJumbo.Visible = Visibility.Hidden;

            if (result.VuePlan) bPlan.Visible = Visibility.Inherit;
            else bPlan.Visible = Visibility.Hidden;

            if (result.Print) bPrint.Visible = Visibility.Visible;
            else bPrint.Visible = Visibility.Hidden;

            if (result.Pip) bPip.Visible = Visibility.Visible;
            else bPip.Visible = Visibility.Hidden;

            if(result.CreateOrdre)
            {
                bOrdreCreation.Visible = Visibility.Inherit;
            }
            else
                bOrdreCreation.Visible = Visibility.Hidden;

            if (result.SaisieActivite || result.ChefModifActivite || result.ChefValidActivite || result.SaisieActiviteType > 0 || result.ChefActivityWork)
            {
                bAct.Visible = Visibility.Inherit;

                //bAct.Visible = Visibility.Hidden;
                //bOrdreActivity.Visible = Visibility.Hidden;

                Console.WriteLine("Workflow.perm_HideOldQtyButton:" + Workflow.perm_HideOldQtyButton + "  " + result.ChefModifActivite);
                if (Workflow.perm_HideOldQtyButton && cboPers.Visible == Visibility.Visible) bAct.Visible = Visibility.Hidden;

                if (result.SaisieActiviteType == 10)
                {
                    bAct.id1 = 601;
                    bAct.SetImage("images/manufacturing-process-1-gear.png", 32, 32);
                    CheckQtyDifference();
                }
                else
                    bAct.id1 = 90;

                if(result.ChefActivityWork)
                    bOrdreValidation.Visible = Visibility.Inherit;
                else
                    bOrdreValidation.Visible = Visibility.Hidden;
            }
            else bAct.Visible = Visibility.Hidden;

            if (result.ChefWorkData || result.SaisieWork > 0) bWkData.Visible = Visibility.Visible;
            else bWkData.Visible = Visibility.Hidden;

            _pSaisiePtg.Visible = true;
             

            if (result.ModifyPtg) _pSaisiePtg.Mode(result.ModifyPtgType, false); else _pSaisiePtg.Mode(0, false);
            if(result.ModifySeco)
            {
                _pSaisiePtg.Visible = false;
                _pSaisieSeco.Visible = true;
            }
            if (result.MessageUser || result.MessageChef) _pSaisieMsg.Visible = true; else _pSaisieMsg.Visible = false;

            if (cboPers.Visible == Visibility.Hidden && result.QRCode) bQR.Visible = Visibility.Inherit;
            else bQR.Visible = Visibility.Hidden;

            if (result.Absence) _pSaisieAbs.Visible = true;
            else                                                           
            {
                _pSaisieAbs.Visible = false;
            }

            _pSaisieKor.Visible = false;

            if (result.Chef)
            {
                _lNom.Visible = Visibility.Hidden;
                cboWF.Visible = Visibility.Visible;
                bJumbo.Visible = Visibility.Inherit;

                if (cboWF.Count <= 1)
                    FillCBOWF(result);

                if(cboPers.Visible == Visibility.Visible)
                {
                    if (result.CostControl || result.Debours) bFrais.Visible = Visibility.Inherit;
                    else bFrais.Visible = Visibility.Hidden;
                }
                else
                {
                    if (result.SaisieDebours || result.Debours) bFrais.Visible = Visibility.Inherit;
                    else bFrais.Visible = Visibility.Hidden;
                }

                if(cboPers.Visible == Visibility.Hidden)
                {
                    if(Workflow.show_todo_su)
                        bTodo.Visible = Visibility.Visible;
                    else
                        bTodo.Visible = Visibility.Hidden;
                }
                else if (result.TodoAccess)
                    bTodo.Visible = Visibility.Visible;
                else
                    bTodo.Visible = Visibility.Hidden;

                if (result.CanDelegate)
                    bDeleg.Visible = Visibility.Visible;
                else
                    bDeleg.Visible = Visibility.Hidden;

                if (result.Cockpit)
                    bCockpit.Visible = Visibility.Visible;
                else
                    bCockpit.Visible = Visibility.Hidden;

                if (cboPers.Visible == Visibility.Visible)
                {
                    if (Workflow.RightSU.Verrou) bVerrou.Visible = Visibility.Visible;
                    else bVerrou.Visible = Visibility.Hidden;

                    if (Workflow.perm_bValidMonth)
                        bValid.Visible = Visibility.Visible;
                    else
                        bValid.Visible = Visibility.Hidden;
                }
                else
                {
                    bVerrou.Visible = Visibility.Hidden;
                    bValid.Visible = Visibility.Hidden;
                }

                if (result.CanDelegate) bDeleg.Visible = Visibility.Inherit;
                else bDeleg.Visible = Visibility.Hidden;

                if (result.Cockpit) bCockpit.Visible = Visibility.Inherit;
                else bCockpit.Visible = Visibility.Hidden;

            }
            else
            {
                _lNom.Visible = Visibility.Visible;
                cboWF.Visible = Visibility.Hidden;
                //bFrais.Visible = Visibility.Hidden;

                if (Workflow.RightSU.SuExt > 0)
                {
                    cboPersExt.Visible = Visibility.Inherit;
                    _lNom.Visible = Visibility.Hidden;
                    FillCBOPers(false, Workflow.WFSU);
                    
                }

                if (result.Debours) bFrais.Visible = Visibility.Inherit;
                else bFrais.Visible = Visibility.Hidden;

                bTodo.Visible = Visibility.Hidden;
                bDeleg.Visible = Visibility.Hidden;
                bValid.Visible = Visibility.Hidden;
                bVerrou.Visible = Visibility.Hidden;
                bCockpit.Visible = Visibility.Hidden;
            }

            if (cboWF.SelectedId > 0)
            {
                cboPers.Visible = Visibility.Visible;

                FillCBOPers();
            }
            else cboPers.Visible = Visibility.Hidden;

            if (cboPers.Visible == Visibility.Visible)
            {
                _pSaisieAbs.Init(data.b5, data.id5, (Api.API_AbsSU)data.o5);
                //_pSaisieAbs.Init(cboWF.SelectedId);
            }
            else
            {
                _pSaisieAbs.Init(data.b5, data.id5, (Api.API_AbsSU)data.o5);
                //_pSaisieAbs.Init();
            }  

            if (cboPers.Visible == Visibility.Visible)
            {
                _pSaisieKor.Visible = result.Kor;
                _pSaisieKor.Init(cboWF.SelectedId);
            }
            else
            {
                _pSaisieKor.Visible = false;
                _pSaisieDebours.Init();
            }

            SaisieSetY();

            if (data.id1 == 0)
                SetDate(0);

        }

        private void FillCBOWF(Right result)
        {
            var current_id = cboWF.SelectedId;

            cboWF.Clear();
            var opt = cboWF.Add(0, "-. " + Workflow.nom + " .-");

            Workflow.WFProfil.Clear();
            var now = DateTime.Now.Date;


            if (result != null)
            {
                for (var i = 0; i < result.WFChefIDs.Count; i++)
                {

                    var id = result.WFChefIDs[i];
                    var name = result.WFChefNames[i];
                    var d1 = result.WFChefDate1[i];
                    var d2 = result.WFChefDate2[i];
                    if (d2.Year < 1920) d2 = new DateTime(3000, 1, 1);
                    var prof = result.WFChefProf[i];

                    if (!Workflow.WFProfil.ContainsKey(id)) Workflow.WFProfil.Add(id, 0);
                    Workflow.WFProfil[id] = prof;

                    if (d1 <= now && d2 >= now)
                        cboWF.Add(id, name);
                }
            }

            cboWF.Sort();
            cboWF.SelectById(current_id);

            if(cboWF.Count == 1)              
            {
                cboWF.Visible = Visibility.Hidden;
                _lNom.Visible = Visibility.Visible;
            }

        }

        private void FillCBOPers(bool keepCurrent = false, int wf = 0)
        {
            Console.WriteLine("cboPers update");

            var func = new Api.ApiFunction();
            func.function = Api.ApiFuncType.get_workflow_pop;
            var oldwf = wf;
            if (wf == 0) wf = cboWF.SelectedId;
            func.iparam1 = wf;
            func.iparam2 = D2000.T(CurrentDate);
            func.iparam3 = D2000.T(CurrentDate.AddDays(DateTime.DaysInMonth(CurrentDate.Year, CurrentDate.Month) - 1));

            var dataGetPers = new DataApi<Api.ApiReturn>(Workflow.api, func);
            dataGetPers.b1 = keepCurrent;
            if (wf == 0)
                dataGetPers.id1 = cboPers.SelectedId;
            else
            {
                if (oldwf > 0)
                    dataGetPers.id1 = Workflow.pid;
                else
                    dataGetPers.id1 = cboPers.SelectedId;
            }

            dataGetPers.OnSuccess += DataGetPers_OnSuccess;
            dataGetPers.b2 = oldwf > 0;
        }

        void DataGetPers_OnSuccess(Data<Api.ApiReturn> data, Api.ApiReturn result)
        {
            //Console.WriteLine("b1/2 " + data.b1 + "   " + data.b2);

            var csv = new CSVTool();
            csv.LoadData(result.return_s1);

            var count = result.return_i1;

            var cbo = cboPers;
            if (data.b2)
                cbo = cboPersExt;
            
            cbo.Clear();
            for (var i = 0; i < count; i++)
            {
                var id = csv[0].I32(i * 2 + 0);
                var name = csv[0].String(i * 2 + 1);

                cbo.Add(id, name);
            }

            cbo.Sort();

            if (data.b2)
            {
                cbo.SelectById(data.id1, false);
            }
            else
            {
                if (data.b1)
                {
                    var force = true;
                    foreach (var item in cbo.Items)
                        if (item.Value == data.id1.ToString())
                        {
                            force = false;
                            break;
                        }

                    //Console.WriteLine("force " + force + "   " + data.id1);

                    if (!force)
                        cbo.SelectById(data.id1, true);
                    else
                        cbo.SelectByIdx(0, true);

                    force_select_pers = -1;
                }
                else if (force_select_pers > 0)
                {
                    cbo.SelectById(force_select_pers, true);
                    force_select_pers = -1;
                    //SetDate(99, true);
                }
                else
                {
                    cbo.SelectByIdx(0);
                    SetDate(99, true);
                }
            }
        }

        public IEnumerable<HTMLOptionElement> GetPersFromCombo()
        {
            foreach (var item in cboPers.Items)
            {
                var nitem = new HTMLOptionElement();
                nitem.Value = item.Value;
                nitem.Text = item.Text;
                Console.WriteLine(nitem);
                yield return nitem;
            }
        }


        #endregion


        #region Check Saisie Qté


        public void CheckQtyValidation()
        {
            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityQties2);
            func.iparam1 = Workflow.pid;
            func.iparam2 = D2000.T(DateTime.Now);
            func.iparam3 = 21;
            func.iparam4 = 1;
            func.iparam5 = 40;
            func.iparam6 = 0;

            var sbPid = new StringBuilder();

            foreach (var item in CboWF.Items)
            {
                if (item.Value != "0")
                {
                    sbPid.Append(item.Value);
                    sbPid.Append(';');
                }
            }                               

            func.param1 = sbPid.ToString();

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                if (b.return_s1.Length > 0)
                {
                    var csv = new CSVTool(b.return_s1)[0];
                    if(csv.Count > 0)
                        bOrdreValidation.SetImage("images/manufacturing-process-1-gear-empty-ok-circle_red.png", 32, 32);
                    else
                        bOrdreValidation.SetImage("images/manufacturing-process-1-gear-empty-ok-circle.png", 32, 32);
                }
                else
                {
                    bOrdreValidation.SetImage("images/manufacturing-process-1-gear-empty-ok-circle.png", 32, 32);
                }

            };
        }


        public void CheckQtyDifference(bool keepSel = false)
        {

            var monday = Fmt.GetMonday(DateTime.Now).AddDays(-14);

            var func = new Api.ApiFunction(Api.ApiFuncType.GetActivityHebdo2);
            func.iparam1 = Workflow.pid;        // user que l'on veut voir
            func.iparam3 = Workflow.pid;        // user pour profil de saisie
            func.iparam2 = D2000.T(monday);
            func.iparam4 = 21;

            var data = new DataApi<Api.ApiReturn>(Workflow.api, func);
            data.OnSuccess += (a, b) =>
            {
                var csv = new CSVTool(b.return_s2)[0];

                var ok = true;
                
                var idx = 0;
                for (var i = 0; i < func.iparam4; i++)
                {
                    var date = csv.Date(idx++);
                    var pres = csv.I32(idx++);
                    var ta = csv.I32(idx++);
                    var absT = csv.I32(idx++);
                    var abs = csv.I32(idx++);
                    var ecart = csv.I32(idx++);
                    var count = csv.I32(idx++);

                    for (var j = 0; j < count; j++)
                        idx += 13;

                    if (ecart < 0)
                    {
                        ok = false;
                        break;
                    }
                }


                if(ok) bAct.SetImage("images/manufacturing-process-1-gear.png", 32, 32);
                else bAct.SetImage("images/manufacturing-process-1-gear_red.png", 32, 32);

            };
        }


        #endregion

    }
}